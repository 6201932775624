<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Archivio Certificati <small>Lista dei certificati archiviati.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="filter-container">
            <div class="filter-container-main">
                <div class="over_md_6">
                    <div class="n_order_search over_n_order">
                        <label>Ricerca libera</label>
                        <input class="search-input" v-model="search.search" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Ordine</label>
                        <input class="search-input" v-model="search.n_ordine" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Certificato</label>
                        <input class="search-input" v-model="search.certificato" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Denominazione</label>
                        <input class="search-input" v-model="search.denominazione" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    
                </div>
                <div class="btn-cont-search over_md_6" >
                    <button v-on:click="get_certificati">Cerca</button>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                </div>
                <div class="block-content block-content-full">
                    <h3 v-if="certificati.length == 0">Nessun certificato presente</h3>
                    <div v-if="certificati.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="certificati.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="text-center">NUMERO ORDINE</th>
                                <th class="d-none d-sm-table-cell">NUMERO CERTIFICATO</th>
                                <th class="d-none d-sm-table-cell">AGENZIA</th>
                                <th class="d-none d-sm-table-cell">CONSORZIO</th>
                                <th class="d-none d-sm-table-cell">COMUNE</th>
                                <th class="d-none d-sm-table-cell">DENOMINAZIONE CERT.</th>
                                <th class="d-none d-sm-table-cell">SPECIE</th>
                                <th style="text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(certificato, index) in certificati" :key="index">
                                <td class="font-size-sm">
                                    {{certificato.ordine_id}}
                                </td>
                                <td class="font-size-sm">
                                    {{certificato.certificato}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.agenzia.codice_identificativo}} - {{certificato.agenzia.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{certificato.consorzio.codice}} - {{certificato.consorzio.name}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{certificato.comune.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.denominazione}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.specie.etichetta}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="editCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Edit Certificato">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <button v-if="canPerito('full')" @click="rimuovereUltimaEvasione(certificato)" type="button" class="btn btn-sm btn-light" title="Rimuovere ultima Evasione">
                                            <i class="fas fa-lock"></i>
                                        </button>
                                        <button v-if="canPerito('owner')" @click="deleteCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Delete Certificato">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <button @click="openHailbox(certificato.ordine_id)" type="button" class="btn btn-sm btn-light" title="Open Hailbox">
                                            <i class="fas fa-cloud"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="certificati.length > 0" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                </div>
            </div>
        </div>

        <ModalTrueFalse
            :isModalVisible="isModalRemoveUltimaEvasione" 
            @closeModalCallback="callbackUltimaEvasione"
            >
             <template v-slot:body-true>
                <h3>Vuoi rimuovere l'ultima evasione?</h3>
             </template>
        </ModalTrueFalse>
        
        <ModalTrueFalse
            :isModalVisible="isModalDeleteVisible" 
            @closeModalCallback="close_delete_cert_modal"
            >
             <template v-slot:body-true>
                <h3>Vuoi eliminare il certificato?</h3>
             </template>
        </ModalTrueFalse>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ModalTrueFalse from "../../components/ModalTrueFalse"
import Utility from '../../services/utility'
import CertificatoRepository from "../../services/apis/certificato"
export default {
    components: {
        Paginate,
        ModalTrueFalse
    },
    data(){
        return {
            isModalDeleteVisible: false,
            isModalRemoveUltimaEvasione: false,
            certificati:[],
            data: {
                last_page:1
            },
            search: {
                ispettore_id: 0,
                per_page: 10,
                page: 1,
                search:'',
                numero_ordine:'',
                certificato:'',
                denominazione:'',
            },
            certificato: null,
            ispettorePermission:'',
        }
    },
    watch: {
        '$route.params.ispettore_id': function (id) {        
            this.search.ispettore_id = id
            this.get_certificati()
        }
    },
    methods: {
         canPerito(permissions){
            return Utility.canPerito(permissions, this.ispettorePermission)
        },
        async get_certificati(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await CertificatoRepository.get_lista_certificati_archiviati(this.search).catch(()=>{})

            if(_resp){
                this.certificati = _resp.data.data.data
                this.data = _resp.data.data   
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        perPage(){
            this.search.page = 1
            this.get_certificati()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_certificati()
        },
        goTo(name){
            this.$router.push({ name: name })
        },
        editCertificato(certificato){
            this.$router.push({ name: 'modifica-certificato', params:{certificato_id:certificato.id} })
        },
        deleteCertificato(cert){
            this.certificato = cert
            this.isModalDeleteVisible = true
        },
        openHailbox(ordine_id){
            const user = this.$store.getters.user
          /* if(user.role == "ispettore"){
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/check-token-service/${ordine_id}?t=${user.service_token}`,'_blank');
            }else{
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/check-token-service/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}`,'_blank');
            }*/
            if(user.role == "ispettore"){
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&source=myara`,'_blank');
            }else{
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&i=${this.search.ispettore_id}&source=myara`,'_blank');
            }
        },
        async close_delete_cert_modal(action){
            this.isModalDeleteVisible = false
            
            if(action){                
                this.$store.dispatch('updateLoadingState', true);
                const _resp = await CertificatoRepository.delete_certificato(this.certificato.id, this.ispettore_id).catch(() =>{})

                if(_resp){
                    this.$toasted.success(_resp.data.message).goAway(3000)
                    this.get_certificati()
                }
                this.$store.dispatch('updateLoadingState', false);
            }
            this.certificato = null
        },
        rimuovereUltimaEvasione(certificato){
            this.certificato = certificato
            this.isModalRemoveUltimaEvasione = true
        },
        async callbackUltimaEvasione(action){
            this.isModalRemoveUltimaEvasione = false

            if(action){
                this.$store.dispatch('updateLoadingState', true);
                const _resp = await CertificatoRepository.rimuovi_ultima_evasione({id:this.certificato.id, ispettore_id:this.ispettore_id}).catch(()=>{})

                if(_resp){
                    this.$toasted.success(_resp.data.message).goAway(3000)
                    this.get_certificati()
                }
            }

            this.certificato = null

        }
    },
    mounted(){
        this.search.ispettore_id = this.$route.params.ispettore_id
        this.ispettorePermission = Utility.getIspettorePeritoById(this.$route.params.ispettore_id)
        this.get_certificati()
    }
}
</script>
<style>
    @import '../../assets/css/style.css';

    .btn-cont-search > button {
        display: flex;
        justify-content: center;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        --jexcel-border-color: #000;
        --button-color: #298BA8;
        --active-color: #007aff;
        -webkit-font-smoothing: antialiased;
        -webkit-box-direction: normal;
        box-sizing: border-box;
        margin: 0;
        overflow: visible;
        text-transform: none;
        -webkit-appearance: button;
        display: inline-block;
        font-weight: 600;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        color: #fff;
        background-color: #5c80d1 !important;
        border-color: #5c80d1;
        padding: .25rem .5rem;
        font-size: .875rem;
        line-height: 1.5;
        border-radius: .2rem;
        font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        cursor: pointer;
        margin-top: 8px;
        width: 65px;
    
    }

</style>
