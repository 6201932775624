<template>
    <div id="page-container">

        <!-- Main Container -->
        <main id="main-container">

            <!-- Page Content -->
            <div class="bg-image" style="background-image: url('assets/media/photos/photo6@2x.jpg');">
                <div class="hero-static bg-white-95">
                    <div class="content">
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-lg-6 col-xl-4">
                                <!-- Reminder Block -->
                                <div class="block block-themed block-fx-shadow mb-0">
                                    <div class="block-header">
                                        <h3 class="block-title">RECUPERA PASSWORD</h3>
                                        <router-link to="/login">
                                            <div class="block-options">
                                                <a class="btn-block-option" href="op_auth_signin.html" data-toggle="tooltip" data-placement="left" title="Sign In">
                                                    <i class="fa fa-sign-in-alt"></i>
                                                </a>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="block-content">
                                        <div class="p-sm-3 px-lg-4 py-lg-5">
                                            <h1 class="mb-2">MyARA 1857</h1>
                                            <p>Inserisci l'e-mail del tuo account, ti verrà inviata una nuova password momentanea sulla tua e-mail.</p>

                                            <!-- Reminder Form -->
                                            <!-- jQuery Validation (.js-validation-reminder class is initialized in js/pages/op_auth_reminder.min.js which was auto compiled from _es6/pages/op_auth_reminder.js) -->
                                            <!-- For more info and examples you can check out https://github.com/jzaefferer/jquery-validation -->
                                            <ValidationObserver ref="observer" v-slot="{ invalid }">
                                                <form class="js-validation-reminder" action="be_pages_auth_all.html" method="POST">
                                                    <div class="form-group py-3">
                                                        <label for="example-text-input">Email</label>
                                                        <ValidationProvider rules="required|email" name="Email" v-slot="{ errors }">
                                                            <input v-model="email" type="text" class="form-control" name="email" placeholder="Email">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-6 col-xl-5">
                                                            <button :disabled="invalid" v-on:click.prevent="recovery_password" type="submit" class="btn btn-block btn-primary">
                                                                <i class="fa fa-fw fa-envelope mr-1"></i> Invia Mail
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </ValidationObserver>
                                            <!-- END Reminder Form -->
                                        </div>
                                    </div>
                                </div>
                                <!-- END Reminder Block -->
                            </div>
                        </div>
                    </div>
                    <Copyright />
                </div>
            </div>
            <!-- END Page Content -->

        </main>
        <!-- END Main Container -->
    </div>
</template>

<script>

import Copyright from '../components/Copyright'
import AuthenticationRepository from "../services/apis/authentication"

export default {
     data(){
        return {
            email:'',
        }
    },
    components: {
        Copyright
    },
    methods: {
        recovery_password: async function(){
            
            let obj = {
                "email":this.email,
            }

            this.$store.dispatch('updateLoadingState', true)

            let _rec = await AuthenticationRepository.recovery_password(obj).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)     
                   
            if(_rec){
                this.$toasted.show(_rec.data.message).goAway(3000)
                this.email = ''
            }  
        }
    }
}
</script>