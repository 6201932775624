<template>
    <div style="width: 100%;overflow-x: auto;">
        <table v-if="data_comparazione_index.length > 1" style="width:100%; margin-bottom: 50px;min-width: 1350px;">
            <thead>
                <tr>
                    <th :style="{
                        width:index !== 0 ? 100/data_comparazione[0].length-1+'%' : '10%', 
                        border:'1px solid #f0f0f0',
                        fontSize: '15px',
                        padding: '5px'}"  v-for="(e, index) in data_comparazione[0]" :key="index" class="text-center">
                        <span v-if="index !== 0">Specifica {{index}}</span>
                    </th>
                </tr>
            </thead>
            <tbody class="data_comparazione">
                <tr v-for="(spec, index) in data_comparazione" :key="index">
                    <td v-for="(data, i) in spec" :key="i">
                        <div v-if="!Array.isArray(data)">{{data}}</div>
                        <div v-else>
                            <div style="display: flex;flex-direction: column;" v-for="(elem, j) in data" :key="j">
                                <ul class="lista_per_head">
                                    <li>Cert.</li>
                                    <li>Agenz.</li>
                                    <li>Cons.</li>
                                    <li>Tipo</li>
                                    <li>Note</li>
                                </ul>
                                <ul class="lista_per">
                                    <li>{{elem.certificato ? elem.certificato : 'non disponibile'}}</li>
                                    <li>{{elem.agenzia}}</li>
                                    <li>{{elem.consorzio_id}}</li>
                                    <li>{{elem.tipo_perizia}}</li>
                                    <li>{{elem.note}}</li>
                                </ul>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>    
</template>

<script>
export default {
    name:'ComparaSpecifiche',
    data(){
        return {
            data_comparazione_index:[0],
            data_comparazione:[
                ['Data Specifica'],
                ['Perito'],
                ['Onorario'],
                ['Vitto'],
                ['Alloggio'],
                ['Km Residenza'],
                ['Km Perizia'],
                ['Pedaggi (€)'],
                ['Telefono (€)'],
                ['Varie (€)'],
                ['Annotazioni'],
                ['Perizie'],
            ],
        }
    },
    methods: {
        compara(specifica){
            if(specifica.selected){
                let index = this._.findIndex(this.data_comparazione_index, function(o) { return o == specifica.id });
                this.data_comparazione_index.splice(index,1)
                for (let i = 0; i < this.data_comparazione.length; i++) {
                    this.data_comparazione[i].splice(index, 1)
                }
                specifica.selected = null
            }else{
                if(this.data_comparazione_index.length < 4){
                    this.data_comparazione_index.push(specifica.id)
                    specifica.selected = true
                    for (let i = 0; i < this.data_comparazione.length; i++) {
                        const element = this.data_comparazione[i];
                        if(typeof element[0] !== 'undefined'){
                            var data = element[0].toLowerCase().replace(" (€)","").replace(/ /g,"_");
                            if(this._.has(specifica, data)){
                                if(data == 'perito'){
                                    this.data_comparazione[i].push(specifica[data].name+' '+specifica[data].surname)
                                }else{
                                    this.data_comparazione[i].push(specifica[data])
                                }
                            }
                        }
                    }
                }

                
            }
        }
    }
}
</script>

<style>
    .lista_per, .lista_per_head {
        list-style: none;
        margin-bottom: 0;
        padding: 0;
    }
    .lista_per_head li:nth-child(odd){
        font-weight: bold;
        background: #F9F9F9;
    }
    .lista_per li:nth-child(odd){
        background: #F9F9F9;
    }
    .lista_per_head li{
        padding: 10px;
    }
    .lista_per li, .lista_per_head li {
        float: left;
        
        max-width: 75px;
        width: 100%;
        text-align: center;
        font-size: 12px;
    }
    .data_comparazione td, .data_comparazione tr {
        border: 1px solid #f0f0f0;
        font-size: 13px;
        padding: 5px;
    }
    .data_comparazione tr:nth-child(odd){
        background: #F9F9F9;
    }
</style>