<template>
<div>
    <PageHeaderNoSidebar v-if="controlledUser" />
<!-- Hero -->
    <div class="bg-image overflow-hidden" :style="{'background-image': 'url('+background_image+')'}">
        <div class="bg-primary-dark-op">
            <div class="content content-narrow content-full">
                <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mt-5 mb-2 text-center text-sm-left">
                    <div class="flex-sm-fill">
                        <h1 class="font-w600 text-white mb-0" data-toggle="appear">ARA1857 - Campagna {{campaignYear}}</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content bg-white border-b">

    <div class="content bg-white border-b ng-scope">
        <div class="row items-push text-uppercase">
            <div class="col-xs-6 col-sm-6">
                <a @click="routeChange('/dashboard')" class="h2 font-w700 text-primary animated flipInX">
                    SPECIFICHE
                </a>
            </div>
            <div v-if="user.role == 'ispettore'" class="col-xs-6 col-sm-6">
                <a @click="routeChange(`/certificati/${user.id}/lista`)" class="h2 font-w700 text-primary animated flipInX">
                    REGISTRO DI CARICO
                </a>
            </div>
            <div v-else class="col-xs-6 col-sm-6">
                <a @click="routeChange(`/multi`)" class="h2 font-w700 text-primary animated flipInX">
                    REGISTRO DI CARICO
                </a>
            </div>
        </div>
    </div>

    </div>
</div>
<!-- END Hero -->
</template>

<script>
import PageHeaderNoSidebar from '../components/PageHeaderNoSidebar'
import UtilityRepository from '../services/apis/utility'

export default {
    data(){
        return {
            controlledUser:false,
            background_image: require('@/assets/images/photo3@2x.jpg'),
            campaignYear:''
        }
    },
    components: {
        PageHeaderNoSidebar
    },
    computed: {
        user(){
            return this.$store.getters.user
        }
    },
    methods: {
        async check(){
            await UtilityRepository.check().catch(() => {})
        },
        routeChange(name){
            if(name == '/dashboard'){
                this.$store.dispatch('updateChoiseState','specifiche')
                localStorage.setItem('choise', 'specifiche')
            }else{
                if(this.$store.getters.user.role == "perito"){
                    name = '/dashboard'
                }
                this.$store.dispatch('updateChoiseState','certificati')
                localStorage.setItem('choise', 'certificati')
            }         

            this.$router.push({ path: name })
        }
    },
    mounted(){
        if(localStorage.getItem(process.env.VUE_APP_ADMIN_TOKEN_STORAGE_NAME)){
            this.controlledUser = true
        }

        this.campaignYear = process.env.VUE_APP_YEAR

        this.check()
    }
}
</script>

<style>
    a.h2 {
        cursor: pointer;
        color: black !important;
    }
</style>