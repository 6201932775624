<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Archivio mie Specifiche <small>Lista delle mie specifiche.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title">LISTA SPECIFICHE</h3>
                </div>
                <div class="block-content block-content-full">
                    <div v-if="specifiche.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div v-if="specifiche.length == 0" class="row">
                        <div class="col-md-12" style="text-align:center;padding:20px 0px;">
                            <h4>Nessuna specifica presente.</h4>
                        </div>
                    </div>
                    <table v-if="specifiche.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="data_specifica" name="DATA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="approvata" name="STATO APP." :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="fatturata" name="STATO FATT." :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell" style="width: 20%;">DETTAGLI</th>
                                <th style="width: 20%; text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(specifica, index) in specifiche" v-bind:key="index">
                                <td class="font-size-sm">
                                    {{data_specifica(specifica.data_specifica)}}
                                </td>
                                <td class="font-size-sm">
                                    <span v-html="approvataStatus(specifica.approvata)"></span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="specifica.fatturata == 0" class="badge badge-danger">non fatturata</span>
                                    <span v-else class="badge badge-success">fatturata</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <button @click="showModalView(specifica)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Perizia">
                                        <i class="fa fa-fw fa-eye"></i>
                                    </button>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button v-if="specifica.approvata != 1" @click="editSpecifica(specifica.id)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Perizia">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button v-if="specifica.approvata != 1" @click="deleteSpecifica(specifica)" type="button" class="btn btn-sm btn-danger" data-toggle="tooltip" title="Remove Perizia">
                                            <i class="fa fa-fw fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <Pagination :show="specifiche.length > 0" v-model="search.page" :lastPage="data.last_page" @paginateClick="paginateClick" />
                </div>
            </div>
        </div>

        <ModalSpecifica ref="modal_specifica"
            :ispettori_int="ispettori"
            :consorzi_int="consorzi" 
            :agenzie_int="agenzie" 
            :specifica="selected_specifica"
            :isModalVisible="isModalVisible" 
            @closeModalCallback="close_modal_callback" />
        
        <Modal v-if="isModalEliminaVisible" @close="close_modal_elimina">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Elimina Specifica</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal_elimina"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <p style="margin-bottom:0px">Sei sicuro di voler eliminare la specifica?</p>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="close_modal_elimina"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-danger"
                    @click="elimina_specifica"
                    aria-label="Elimina">
                    Elimina
                </button>
            </template>
        </Modal>
    </div>
</template>

<script>
import ModalSpecifica from "../../components/Specifiche/ModalSpecifica"
import Modal from "../../components/Modal"
import UtilityRepository from "../../services/apis/utility"
import SpecificaRepository from "../../services/apis/specifica"
import ThTable from '../../components/ThTable'
import Pagination from "../../components/Pagination/Pagination"

export default {
    components: {
        ThTable, Pagination, ModalSpecifica, Modal
    },
    data(){
        return {
            isModalVisible:false,
            isModalEliminaVisible:false,
            specifiche:[],
            ispettori:[],
            consorzi:[],
            agenzie:[],
            data: {
                last_page:1
            },
            search: {
                per_page: 10,
                page:1,
                orderBy:'data_specifica:desc'
            },
            selected_specifica:null
        }
    },
    methods: {
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_specifiche()
        },
        async get_lista_ispettori(){
            let _resp = await SpecificaRepository.get_lista_ispettori().catch(() => {})

            if(_resp){
                this.ispettori = _resp.data.data
            }
            
        },
        async get_consorzi(){
            let _resp = await UtilityRepository.search_consorzi('').catch(()=> {})
            
            if(_resp){
                this.consorzi = _resp.data.data
            }
        },
         async get_agenzie(){
            let _resp = await UtilityRepository.search_agenzie('').catch(()=> {})

            if(_resp){
                this.agenzie = _resp.data.data
            }
        },
        close_modal_callback(){
            this.isModalVisible = false
            this.selected_specifica = null
        },
        async get_specifiche(){
            this.search.perito_id = this.ispettoreSelected ? this.ispettoreSelected.id : "" 
            const _resp = await SpecificaRepository.get_specifiche(this.search).catch(() => {})
            
            if(_resp){
                this.specifiche = _resp.data.data.data
                this.data = _resp.data.data
            }
        },
        data_specifica(data){
            return data ? this.$moment(data).format('DD-MM-YYYY') : ''
        },
        approvataStatus(key){
            let code = ''
            switch (key) {
                case 0:
                    code = '<span class="badge badge-info">in approvazione</span>'
                break;
                case 1:
                    code = '<span class="badge badge-success">approvata</span>'
                break;
                case 2:
                    code = '<span class="badge badge-danger">non approvata</span>';
                break;
            }

            return code
        },
        showModalView(specifica){
            this.selected_specifica = specifica
            this.isModalVisible = true
        },
        editSpecifica(id){
             this.$router.push({ name: 'edit-specifica', params: { id: id }})
        },
        deleteSpecifica(specifica){
            this.selected_specifica = specifica
            this.isModalEliminaVisible = true
        },
        perPage(){
            this.search.page = 1
            this.get_specifiche()
        },
        paginateClick(){
            this.get_specifiche()
        },
        close_modal_elimina(){
            this.isModalEliminaVisible = false
            this.selected_specifica = null
        },
        async elimina_specifica(){
            if(!this.selected_specifica){
                this.$toasted.error('Nessuna specifica selezionata, se il problema persiste contattare l\'amministratore.').goAway(3000)
            }

            this.$store.dispatch('updateLoadingState', true)
            this.isModalEliminaVisible = false

            const _resp = await SpecificaRepository.delete_specifica(this.selected_specifica.id).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.selected_specifica = null
                this.get_specifiche()
            }
        }
    },
    created(){
        this.get_lista_ispettori()
        this.get_consorzi()
        this.get_agenzie()
        this.get_specifiche()
    }
}
</script>

<style scope>
    .table {
        text-align: center;
    }
    .pagination, .dataTables_filter {
        justify-content: flex-end;
        display: flex;
    }
</style>