<template>
    <ValidationObserver ref="form" v-slot="{ invalid }">
        <form class="js-validation-reminder">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Email Attuale</label>
                        <input v-model="current_email" disabled type="email" class="form-control" name="email">
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Nuova Email</label>
                        <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                            <input v-model="obj.email" :disabled="!isEdit" type="email" class="form-control" name="email" placeholder="Inserisci nuova Email">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Ripeti Nuova Email</label>
                        <ValidationProvider rules="required|email|confirmed:email" name="repeat_email" v-slot="{ errors }">
                            <input v-model="obj.repeat_email" :disabled="!isEdit" type="email" class="form-control" name="repeat_email" placeholder="Ripeti nuova Email">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Inserisci password</label>
                        <ValidationProvider rules="required" name="password" v-slot="{ errors }">
                            <input v-model="obj.password" :disabled="!isEdit" type="password" class="form-control" name="password" placeholder="Inserisci Password">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="form-group row">
                <div class="col-md-12 col-xl-12">
                    <button :disabled="invalid" v-on:click.prevent="modifica_email" type="submit" class="btn btn-block btn-primary">
                        <i class="fa fa-fw fa-envelope mr-1"></i> Modifica Email
                    </button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import UserRepository from "../../services/apis/user"

export default {
    props:['isEdit', 'user'],
    data(){
        return {
            current_email:'',
            obj: {
                email: '',
                repeat_email:'',
                password:''
            }
        }
    },
    watch: {
        'isEdit': function(newVal) {
            if(!newVal){
                this.$refs.form.reset()
                let _user = JSON.parse( JSON.stringify(  this.user ) ) 
                this.current_email = _user.email
            }
        },
        'user': function(newVal){
            if(newVal){
                let _user = JSON.parse( JSON.stringify(  newVal ) ) 
                this.current_email = _user.email
            }
        }
    },
    methods: {
        async modifica_email(){
             this.$store.dispatch('updateLoadingState', true)

            const _resp = await UserRepository.update_user_email(this.obj).catch(() => {})
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
            }

            this.$store.dispatch('updateLoadingState', false)
            this.$emit('callbackReload');
        }
    }
}
</script>