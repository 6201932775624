<template>
    <div id="page-container">

        <!-- Main Container -->
        <main id="main-container">

            <!-- Page Content -->
            <div class="bg-image" style="background-image: url('assets/media/photos/photo6@2x.jpg');">
                <div class="hero-static bg-white-95">
                    <div class="content">
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <!-- Reminder Block -->
                                <div class="block block-themed block-fx-shadow mb-0">
                                    <div class="block-header">
                                        <h3 class="block-title">COMPILA DATI FATTURAZIONE</h3>
                                        <div class="block-options">
                                            <a @click="logout" class="btn-block-option" title="Sign In">
                                                <i class="fa fa-sign-in-alt"></i>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="block-content">
                                        <div class="">

                                            <ValidationObserver ref="form" v-slot="{ invalid }">
                                                <form class="js-validation-reminder">
                                                   <div class="row">
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Password</label>
                                                                <ValidationProvider rules="required|min:6" name="password" v-slot="{ errors }">
                                                                    <input v-model="obj.password" type="password" class="form-control" name="password" placeholder="Password">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-6">
                                                           <div class="form-group">
                                                                <label for="example-text-input">Ripeti Password</label>
                                                                <ValidationProvider rules="required|min:6|confirmed:password" name="Ripeti Password" v-slot="{ errors }">
                                                                    <input v-model="obj.repeat_password" type="password" class="form-control" name="repeat_password" placeholder="Ripeti Password">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row">
                                                       <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Codice Fiscale</label>
                                                                <ValidationProvider rules="required" name="codice_fiscale" v-slot="{ errors }">
                                                                    <input v-model="obj.codice_fiscale" type="text" class="form-control" name="codice_fiscale" placeholder="Inserisci codice fiscale">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-4">
                                                           <div class="form-group">
                                                                <label for="example-text-input">Soggetto Fiscale</label>
                                                                <ValidationProvider rules="required" name="soggetto_fiscale" v-slot="{ errors }">
                                                                    <input v-model="obj.soggetto_fiscale" type="text" class="form-control" name="soggetto_fiscale" placeholder="Inserisci soggetto fiscale">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-4">
                                                           <div class="form-group">
                                                                <label for="example-text-input">Partita Iva</label>
                                                                <ValidationProvider rules="required" name="partita_iva" v-slot="{ errors }">
                                                                    <input v-model="obj.partita_iva" type="text" class="form-control" name="partita_iva" placeholder="Inserisci partita iva">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row">
                                                       <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Indirizzo</label>
                                                                <ValidationProvider rules="required" name="indirizzo" v-slot="{ errors }">
                                                                    <input v-model="obj.indirizzo" type="text" class="form-control" name="indirizzo" placeholder="Inserisci indirizzo">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row">
                                                       <div class="col-md-4">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Cap</label>
                                                                <ValidationProvider rules="required" name="cap" v-slot="{ errors }">
                                                                    <input v-model="obj.cap" type="text" class="form-control" name="cap" placeholder="Inserisci cap">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-4">
                                                           <div class="form-group">
                                                                <label for="example-text-input">Comune</label>
                                                                <ValidationProvider rules="required" name="comune" v-slot="{ errors }">
                                                                    <input v-model="obj.comune" type="text" class="form-control" name="comune" placeholder="Inserisci comune">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-4">
                                                           <div class="form-group">
                                                                <label for="example-text-input">Provincia</label>
                                                                <ValidationProvider rules="required" name="partita_iva" v-slot="{ errors }">
                                                                    <input v-model="obj.provincia" type="text" class="form-control" name="provincia" placeholder="Inserisci provincia">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row">
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Telefono</label>
                                                                <ValidationProvider rules="required" name="telefono" v-slot="{ errors }">
                                                                    <input v-model="obj.telefono" type="text" class="form-control" name="telefono" placeholder="Inserisci telefono">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row">
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Contributo Previdenziale</label>
                                                                <ValidationProvider rules="required" name="contributo_previdenziale" v-slot="{ errors }">
                                                                    <select v-model="obj.contributo_previdenziale" class="form-control" name="contributo_previdenziale">
                                                                        <option value>Seleziona Contributo Previdenziale</option>
                                                                        <option v-for="(contributo_previdenziale, index) in invoice_data.contributo_previdenziale" :key="index" :value="contributo_previdenziale.value">{{contributo_previdenziale.name}}</option>
                                                                    </select>
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Contributo Integrativo</label>
                                                                <ValidationProvider rules="required" name="contributo_integrativo" v-slot="{ errors }">
                                                                    <select v-model="obj.contributo_integrativo" class="form-control" name="contributo_integrativo">
                                                                        <option value>Seleziona Contributo Integrativo</option>
                                                                        <option v-for="(contributo_integrativo, index) in invoice_data.contributo_integrativo" :key="index" :value="contributo_integrativo.value">{{contributo_integrativo.name}}</option>
                                                                    </select>
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row">
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Ritenuta d’acconto in deduzione</label>
                                                                <ValidationProvider rules="required" name="ritenuta_deduzione" v-slot="{ errors }">
                                                                    <select v-model="obj.ritenuta_deduzione" class="form-control" name="ritenuta_deduzione">
                                                                        <option value>Seleziona Ritenuta d’acconto in deduzione</option>
                                                                        <option v-for="(ritenuta_deduzione, index) in invoice_data.ritenuta_deduzione" :key="index" :value="ritenuta_deduzione.value">{{ritenuta_deduzione.name}}</option>
                                                                    </select>
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Aliquota IVA</label>
                                                                <ValidationProvider rules="required" name="aliquota_iva" v-slot="{ errors }">
                                                                    <select v-model="obj.aliquota_iva" class="form-control" name="aliquota_iva">
                                                                        <option value>Seleziona Aliquota Iva</option>
                                                                        <option v-for="(aliquota_iva, index) in invoice_data.aliquota_iva" :key="index" :value="aliquota_iva.value">{{aliquota_iva.name}}</option>
                                                                    </select>
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row" v-if="obj.aliquota_iva == 0">
                                                       <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Dicitura da riportare in fattura</label>
                                                                <ValidationProvider rules="required" name="dicitura_fattura" v-slot="{ errors }">
                                                                    <select v-model="obj.dicitura_fattura" class="form-control" name="dicitura_fattura">
                                                                        <option value>Seleziona Dicitura da riportare in fattura</option>
                                                                        <option v-for="(altro_regime_fiscale, index) in invoice_data.altro_regime_fiscale" :key="index" :value="altro_regime_fiscale.value">{{altro_regime_fiscale.name}}</option>
                                                                    </select>
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row" v-if="obj.dicitura_fattura == 5">
                                                       <div class="col-md-12">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Riporta la dicitura</label>
                                                                <ValidationProvider rules="required" name="altro_regime_fiscale" v-slot="{ errors }">
                                                                    <input v-model="obj.altro_regime_fiscale" type="text" class="form-control" name="altro_regime_fiscale" placeholder="Inserisci altro regime fiscale">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                   <div class="row">
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">Nome della banca</label>
                                                                <ValidationProvider rules="required" name="nome_banca" v-slot="{ errors }">
                                                                    <input v-model="obj.nome_banca" type="text" class="form-control" name="nome_banca" placeholder="Inserisci nome della banca">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                       <div class="col-md-6">
                                                            <div class="form-group">
                                                                <label for="example-text-input">IBAN</label>
                                                                <ValidationProvider rules="required" name="iban" v-slot="{ errors }">
                                                                    <input v-model="obj.iban" type="text" class="form-control" name="iban" placeholder="Inserisci IBAN">
                                                                    <span class="validation-error">{{ errors[0] }}</span>
                                                                </ValidationProvider>
                                                            </div>
                                                       </div>
                                                   </div>
                                                    <div class="form-group row">
                                                        <div class="col-md-12 col-xl-12">
                                                            <button :disabled="invalid" v-on:click.prevent="conferma_dati" type="submit" class="btn btn-block btn-primary">
                                                                <i class="fa fa-fw fa-envelope mr-1"></i> Conferma dati
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </ValidationObserver>
                                            
                                        </div>
                                    </div>
                                </div>
                                <!-- END Reminder Block -->
                            </div>
                        </div>
                    </div>
                    <Copyright />
                </div>
            </div>
            <!-- END Page Content -->

        </main>
        <!-- END Main Container -->
    </div>
</template>

<script>
import Copyright from "../components/Copyright"

export default {
    data(){
        return {
           obj: {
                password:'',
                repeat_password:'',
                codice_fiscale: '',
                soggetto_fiscale:'',
                partita_iva:'',
                indirizzo:'',
                cap:'',
                comune:'',
                provincia:'',
                telefono:'',
                contributo_previdenziale:'',
                contributo_integrativo:'',
                ritenuta_deduzione:'',
                aliquota_iva:'',
                dicitura_fattura:'',
                altro_regime_fiscale:'',
                nome_banca:'',
                iban:''
           },
           invoice_data: {
                contributo_previdenziale:[
                    {value:0, name:'0%'},
                    {value:4, name:'4%'}
                ],
                contributo_integrativo:[
                    {value:0, name:'0%'},
                    {value:2, name:'2%'},
                    {value:4, name:'4%'},
                    {value:5, name:'5%'}
                ],
                ritenuta_deduzione:[
                    {value:0, name:'0%'},
                    {value:20, name:'20%'}
                ],
                aliquota_iva:[
                    {value:0, name:'0%'},
                    {value:22, name:'22%'}
                ],
                altro_regime_fiscale:[
                    {value:1, name:'Operazione effettuata ai sensi dell’art. 1, commi da 54 a 89 della Legge n. 190/2014 – Regime forfettario'},
                    {value:2, name:'Operazione fuori campo IVA per mancanza del presupposto soggettivo o oggettivo (Art. 2, 3, 4 e 5 DPR 633/1972), territoriale (Art. da 7 a 7-septies DPR 633/1972)'},
                    {value:3, name:'Operazione esente da IVA (Art. 10 DPR 633/1972)'},
                    {value:4, name:'Compenso non assogettato a ritenuta d\'acconto ai sensi dell\'art. 27 del D.L. 98 del 06/07/2011 Operazione effettuata da soggetto in regime fiscale di vantaggio di cui all\'art. 27 commi 1 e 2 D.L. 98/2011'},
                    {value:5, name:'Altro regime fiscale'}
                ]
           }

        }
    },
    components: {
        Copyright
    },
    methods: {
        logout(){
            this.$store.dispatch('logout')
        },
        async conferma_dati(){
            this.$store.dispatch('updateLoadingState', true)
            setTimeout(() => {
                this.$store.dispatch('updateLoadingState', false)
                this.$store.dispatch('updateLogginState', true)
                this.$router.push({ path: '/dashboard' })
            }, 1000);
        }
    }
}
</script>