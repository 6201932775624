<template>
    <Modal :width="width" v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <div class="content">
                <div class="row">
                    <div class="col-sm-10">
                        <h1 class="page-heading">
                            INFO CERTIFICATO
                        </h1>
                    </div>
                    <div class="col-sm-2">
                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="closeModal"
                            aria-label="Close modal">
                            Chiudi
                        </button>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:body>
            <div class="content">
                <!-- Dynamic Table Full -->
                <div class="block">
                    <div class="block-content block-content-full">
                        <form>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-select">Certificato</label>
                                        <input disabled :value="certificato.certificato + '/' + certificato.edizione" type="text" class="form-control" name="certificato" placeholder="">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-select">Agenzia</label>
                                        <input disabled :value="formattedData(certificato.agenzia_id, 'agenzie')" type="text" class="form-control" name="agenzia" placeholder="">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-select">Consorzio</label>
                                        <input disabled :value="formattedData(certificato.consorzio_id, 'consorzio')" type="text" class="form-control" name="consorzio" placeholder="">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-select">Denominazione</label>
                                        <ValidationProvider rules="required" name="denominazione" v-slot="{ errors }">
                                        <input :disabled="!editMode" v-model="certificato.denominazione" type="text" class="form-control" name="denominazione" placeholder="">
                                            <span class="validation-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-text-input">Prov</label>
                                        <ValidationProvider rules="required" name="agenzia" v-slot="{ errors }">
                                            <model-list-select
                                                id="search-select"
                                                :list="provincia.found"
                                                :isDisabled="!editMode"
                                                option-value="id"
                                                option-text="short"
                                                v-model="certificato.provincia_id"
                                                placeholder="Seleziona"
                                                @searchchange="searchProvincia"
                                            >
                                            </model-list-select>
                                            <span class="validation-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-text-input">Comune</label>
                                        <ValidationProvider rules="required" name="comune" v-slot="{ errors }">
                                            <model-list-select
                                                :isDisabled="!editMode"
                                                id="search-select"
                                                :list="comune.found"
                                                option-value="id"
                                                option-text="nome"
                                                v-model="certificato.comune_id"
                                                placeholder="Seleziona"
                                                @searchchange="searchComune"
                                            >
                                            </model-list-select>
                                            <span class="validation-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-select">Specie</label>
                                        <input disabled :value="formattedData(certificato.specie_id, 'specie')" type="text" class="form-control" name="specie" placeholder="">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-ltf-email">Data Carico</label>
                                        <ValidationProvider rules="required|is_valid_date" name="data_carico" v-slot="{ errors }">
                                            <DatePicker :disabled="!editMode" isId="data_carico" v-model="certificato.data_carico" />
                                            <span class="validation-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-ltf-email">Data Notifica</label>
                                        <ValidationProvider rules="required|is_valid_date" name="data_notifica" v-slot="{ errors }">
                                            <DatePicker :disabled="!editMode" isId="data_notifica" v-model="certificato.data_notifica" />
                                            <span class="validation-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label for="example-select">Franchigia</label>
                                        <ValidationProvider :rules="{regex: /^[0-9]/}" name="franchigia" v-slot="{ errors }">
                                        <input :disabled="!editMode" v-model="certificato.franchigia" type="number" class="form-control" name="franchigia" placeholder="">
                                            <span class="validation-error">{{ errors[0] }}</span>
                                        </ValidationProvider>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox mb-1">
                                            <input :disabled="!editMode" v-model="certificato.soglia" type="checkbox" class="custom-control-input" id="soglia" name="soglia">
                                            <label class="custom-control-label" for="soglia">Soglia</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <div class="custom-control custom-checkbox mb-1">
                                            <input :disabled="!editMode" v-model="certificato.provvisorio" type="checkbox" class="custom-control-input" id="provvisorio" name="provvisorio">
                                            <label class="custom-control-label" for="provvisorio">Provvisorio</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="form-group">
                                        <label for="example-text-input">Certificati Associati</label>
                                        <!-- <ValidationProvider rules="required" name="associa_certificati" v-slot="{ errors }">
                                            <multi-select
                                                id="search-select"
                                                :options="certificati.found"
                                                :selected-options="certificato.ordini"
                                                placeholder="Seleziona"
                                                @searchchange="searchCertificati"
                                            >
                                            </multi-select>
                                            <span class="validation-error">{{ errors[0] }}</span>
                                        </ValidationProvider> -->
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="example-text-input">Note</label>
                                        <textarea :disabled="!editMode" v-model="certificato.note" name="note" id="" cols="30" rows="5" class="form-control"></textarea>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div style="margin-top:50px;" class="row">
                            <ListaPartite v-if="ispettore_id && certificato_id" 
                                :editMode="editMode"
                                :readOnly="readOnly"
                                :varieta="varieta"
                                :eventi="eventi"
                                :consorzio="consorzio"
                                :comuni="comune.list"
                                :specie="specie"
                                :agenzie="agenzie"
                                :ispettore_id="ispettore_id"
                                :certificato_id="certificato_id"
                                :certificato="certificato"
                                :is_direzione="is_direzione"    
                            ></ListaPartite>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div />
        </template>
        
    </Modal>
</template>

<script>
import Modal from "../Modal"
import { ModelListSelect } from 'vue-search-select'
import DatePicker from "../../components/DatePicker"
import 'vue-search-select/dist/VueSearchSelect.css'
import ListaPartite from "../../components/Certificato/ListaPartite"
import UtilityRepository from "../../services/apis/utility"
//import CatastoApi from "../../services/apis/catasto"
export default {
    name:'ModalInfoCert',
    props:['isModalVisible','certificato','certificato_id','ispettore_id','readOnly','denunceNoAction', 'is_direzione', 'width'],
    components: {
        ModelListSelect,
        DatePicker,
        ListaPartite,
        Modal
    },
    data(){
        return{
            certificato_copy:{},
            editMode:false,
            agenzie:[],
            consorzio:[],
            provincia:{
                list:[],
                found:[]
            },
            comune:{
                list:[],
                provincia_list:[],
                found:[]
            },
            specie:[],
            certificati:{
                list:[],
                found:[]
            },
            varieta:[],
            eventi:[],
        }
    },
    watch: {
        'certificato.provincia_id' (item_new, item_old){

            if(item_old && item_new !== this.certificato_copy.provincia_id){
                this.certificato.comune_id = ''
            }
            
            this.filterComuneByIdProvincia(item_new)
        }
    },
    computed: {
        numero_denuncia(){
            return this.certificato.ultima_ricolpita ? this.certificato.ultima_ricolpita[0].numero_denuncia : 1
        }
    },
    methods: {
        async get_variables(){
            // TODO: inserire ricerca certificati
            const _resp = await UtilityRepository.get_variables({agenzia:1, consorzio:1, provincia:1, comune:1, specie:1, varieta:1, evento:1})
            
            if(_resp){
                this.agenzie = _resp.data.data.agenzia   
                this.consorzio = _resp.data.data.consorzio   
                this.provincia.list = _resp.data.data.provincia   
                this.provincia.found = _resp.data.data.provincia   
                this.comune.list = _resp.data.data.comune   
                this.specie = _resp.data.data.specie   
                this.varieta = _resp.data.data.varieta   
                this.eventi = _resp.data.data.evento   
            }
        },
        formattedData(id, type){
            let formatted_text = ''
            const _found = this[type].find((el) => {
                return el.id == id
            })

            if(_found){
                switch (type) {
                    case 'agenzie':
                        formatted_text = `${_found.codice_identificativo} - ${_found.nome}`
                        break;
                    case 'consorzio':
                        formatted_text = `${_found.codice} - ${_found.name}`
                        break;
                    case 'specie':
                        formatted_text = `${_found.codice_identificativo} - ${_found.etichetta}`
                        break;
                
                    default:
                        break;
                }   
            }
            return formatted_text
        },
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        searchComune(text){
            const _c = this.comune.provincia_list.filter((item) => {
                return item.nome.toLowerCase().includes(text)
            })

            this.comune.found = _c
        },
        searchProvincia(text){
            const _c = this.provincia.list.filter((item) => {
                return item.short.toLowerCase().includes(text)
            })

            this.provincia.found = _c
        },
        filterComuneByIdProvincia(id){
            const _c = this.comune.list.filter((item) => {
                return item.provincia_id == id
            })

            this.comune.provincia_list = _c
            this.comune.found = _c
        },
        closeEditMode(){
            this.certificato = this._.cloneDeep(this.certificato_copy, true);
            this.editMode = false
        },
        modifica_certificato(){
        },
    },
    async mounted(){
        await this.get_variables()
    }
}
</script>