<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Report periti ispettore
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <div class="row" style="margin-bottom:20px">
                        <div class="col-sm-12 col-md-6">
                            <label class="form-material">Seleziona Perito</label>
                                <SelettoreRicerca
                                    type="perito"
                                    placeholder="Seleziona un Perito"
                                    voidText="Nessun perito trovato"
                                    :selectedModel="peritoSelected"
                                    @updateSelector="updateSelector"
                                />
                        </div>
                    </div>
                    <div class="row" style="margin-bottom:20px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div v-if="users.length == 0" class="row">
                        <div class="col-md-12" style="text-align:center;padding:20px 0px;">
                            <h4>Nessun utente presente.</h4>
                        </div>
                    </div>
                    <table v-if="users.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="name" name="NOME" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="surname" name="COGNOME" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="codice_perito" name="COD. PERITO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="total_perizie" name="TOT. PERIZIE" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="total_perizie_ispettore" name="TOT. PERIZIE ISPETTORE" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="isAvailable" name="IMPEGNATO OGGI" :orderBy="search.orderBy" />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(perito, index) in users" :key="index">
                                <td class="font-size-sm">
                                    {{perito.name}}
                                </td>
                                <td class="font-size-sm">
                                    {{perito.surname}}
                                </td>
                                <td class="font-size-sm">
                                    {{perito.codice_perito}}
                                </td>
                                <td class="font-size-sm">
                                    {{perito.total_perizie}}
                                </td>
                                <td class="font-size-sm">
                                    {{perito.total_perizie_ispettore}}
                                </td>
                                <td class="font-size-sm">
                                    <div class="badge-availability" :class="[perito.isAvailable == 1 ? 'not-available' : 'available']"></div>
                                </td>
                                
                            </tr>
                        </tbody>
                    </table>

                    <Pagination :show="users.length > 0" v-model="search.page" :lastPage="data.last_page" @paginateClick="paginateClick" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ThTable from '../../components/ThTable'
import UserRepository from "../../services/apis/user"
import SelettoreRicerca from "../../components/SelettoreRicerca"
import Pagination from "../../components/Pagination/Pagination"
export default {
    components: {
        ThTable, SelettoreRicerca, Pagination
    },
    data(){
        return {
            users:[],
            data: {
                last_page:1
            },
            search: {
                status:1,
                per_page: 50,
                page:1,
                orderBy:'total_perizie_ispettore:desc'
            },
            peritoSelected:null,
        }
    },
    watch: {
        'peritoSelected': function() {
            this.search.page = 1
            this.get_users()
        }
    },
    methods: {
        updateSelector(type, item){
            if(type == 'perito'){
                this.peritoSelected = item
            }
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_users()
        },
        async get_users(){
            this.search.perito_id = this.peritoSelected ? this.peritoSelected.id : ""
            const _resp = await UserRepository.get_report_periti(this.search).catch(() => {})
            
            if(_resp){
                this.users = _resp.data.data.data
                this.data = _resp.data.data
            }
        },
        perPage(){
            this.search.page = 1
            this.get_users()
        },
        paginateClick(){
            this.get_users()
        }
    },
    created(){
        this.get_users()
    }
}
</script>

<style>
    .badge-availability {
        width:10px; 
        height:10px; 
        border-radius: 10px;
        margin: 0 auto;
    }
    .not-available {
        background-color:red; 
    }
    .available {
        background-color: green;
    }
</style>