<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Report statistici <small>Compensi economici per perito.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <form>
                            <div class="row" style="margin-top: 0px">
                                <div class="col-sm-12 col-md-6">
                                    <label class="form-material">Seleziona Ispettore</label>
                                    <model-list-select
                                        id="search-select"
                                        :list="ispettori.found"
                                        option-value="id"
                                        :custom-text="ispettoriListText"
                                        v-model="search.ispettore"
                                        placeholder="Seleziona un Ispettore"
                                        @searchchange="searchIspettore"
                                    >
                                    </model-list-select>
                                </div>
                                <div class="col-sm-12 col-md-6">
                                    <label class="form-material">Seleziona Consorzio</label>
                                    <model-list-select
                                        id="search-select"
                                        :list="consorzi.found"
                                        option-value="id"
                                        :custom-text="consorziListText"
                                        v-model="search.consorzio"
                                        placeholder="Seleziona un Consorzio"
                                        @searchchange="searchConsorzio"
                                    >
                                    </model-list-select>
                                </div>
                            </div>
                            <div class="row" style="margin-top: 0px">
                                <div class="col-sm-12 col-md-6">
                                    <div style="float:left;margin-right: 10px;margin-top: 4px;" class="form-material">
                                        <input type="checkbox" v-model="flag_dettaglio" true-value="1" false-value="" class="ng-pristine ng-untouched ng-valid ng-empty">
                                    </div>
                                    <label class="form-material">Report in Dettaglio?</label>
                                </div>
                                <div class="col-sm-12 col-md-6" style="text-align: right; margin-top: 15px">
                                    <button v-if="search.ispettore || search.consorzio" style="margin-right: 20px;" @click="resetAll" type="button" class="btn btn-sm btn-primary" title="Edit Perizia">
                                        Reset Filtro
                                    </button>
                                    <button class="btn btn-sm btn-primary" type="button" @click="printReport">Stampa Report</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="report.length == 0">Nessun Report</h3>
                    <table v-if="report.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="d-none d-sm-table-cell">COGNOME PERITO</th>
                                <th class="d-none d-sm-table-cell">COD. PERITO</th>
                                <th v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell">ONORARIO</th>
                                <th v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell">VITTO</th>
                                <th v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell">ALLOGGIO</th>
                                <th v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell">KM</th>
                                <th v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell">PEDAGGI</th>
                                <th v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell">TEL</th>
                                <th v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell">VARIE</th>
                                <th v-if="flag_dettaglio === ''" class="d-none d-sm-table-cell">GIORNATE</th>
                                <th class="d-none d-sm-table-cell">TOTALE IMPONIBILE</th>
                                <th v-if="flag_dettaglio === ''" class="d-none d-sm-table-cell">TOTALE FATTURATO</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(rep, index) in report" :key="index">
                                <td v-if="report.length - 1 == index" colspan="2" class="font-size-sm">
                                    TOTALE
                                </td>
                                <td v-if="report.length - 1 != index" class="font-size-sm">
                                    {{rep.surname}}
                                </td>
                                <td v-if="report.length - 1 != index" class="font-size-sm">
                                    {{rep.id_perito}}
                                </td>
                                <td v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell font-size-sm">
                                    {{rep.single_value.onorario}}
                                </td>
                                <td v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell font-size-sm">
                                    {{rep.single_value.vitto}}
                                </td>
                                <td v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell font-size-sm">
                                    {{rep.single_value.alloggio}}
                                </td>
                                <td v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell font-size-sm">
                                    {{chekNumberValue(rep.single_value.km_residenza + rep.single_value.km_perizia)}}
                                </td>
                                <td v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell font-size-sm">
                                    {{chekNumberValue(rep.single_value.pedaggi)}}
                                </td>
                                <td v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell font-size-sm">
                                    {{chekNumberValue(rep.single_value.telefono)}}
                                </td>
                                <td v-if="flag_dettaglio === '1'" class="d-none d-sm-table-cell font-size-sm">
                                    {{chekNumberValue(rep.single_value.varie)}}
                                </td>
                                <td v-if="flag_dettaglio === ''" class="d-none d-sm-table-cell font-size-sm">
                                    {{chekNumberValue(rep.giornate)}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{chekNumberValue(rep.compensi_totali)}}
                                </td>
                                <td v-if="flag_dettaglio === ''" class="d-none d-sm-table-cell font-size-sm">
                                    {{chekNumberValue(rep.totale_fatturato)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import UtilityRepository from "../../../services/apis/utility"
import SpecificheRepository from "../../../services/apis/specifica"
export default {
    components: {
        ModelListSelect
    },
    data(){
        return {
            ispettori:{
                list:[],
                found:[]
            },
            flag_dettaglio: '',
            report: [],
            consorzi:{
                list:[],
                found:[]
            },
            search: {
                consorzio: null,
                ispettore: null
            }
        }
    },
    watch: {
        search:{
            handler: function (){
                if(!this.isTyping)
                    this.getReport();
            },
            deep: true
        }
    },
    methods: {
        chekNumberValue(value){
            if(isNaN(value)){
                return 0
            }

            return value.toFixed(2)
        },
        async getReport(){
            this.$store.dispatch('updateLoadingState', true);

            var search = {
                ispettore_id: this.search.ispettore === null || this.search.ispettore === "" ? 0 : this.search.ispettore,
                consorzio_id: this.search.consorzio === null || this.search.consorzio === "" ? 0 : this.search.consorzio
            }

            const _resp = await SpecificheRepository.get_reports(search).catch(()=>{})

            if(_resp) {
                let temp = [];
                let obj = {
                    single_value:{}
                }
                Object.values(_resp.data.data).forEach(function (value) {
                    temp.push(value);
                    for (const key in value) {
                        if (value.hasOwnProperty(key)) {
                            const element = value[key];
                            if(!isNaN(element) && key !== 'id_perito'){
                                if(obj[key]){
                                    obj[key] += element
                                }else{
                                    obj[key] = element
                                }
                            }else if(key == 'single_value'){
                                for (const k in element) {
                                    if (element.hasOwnProperty(k)) {
                                        const _el = element[k];
                                        if(obj.single_value[k]){
                                            obj.single_value[k] += _el
                                        }else{
                                            obj.single_value[k] = _el
                                        }
                                    }
                                }
                            }
                        }
                    }

                });
                temp.push(obj)
                this.report = temp;
            }
            
            this.$store.dispatch('updateLoadingState', false);
        },
        async searchIspettore(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    type: 'ispettore',
                    cerca: text
                }

                const _resp = await UtilityRepository.search_utenti(search).catch(()=>{})

                if(_resp)
                    this.ispettori.found = _resp.data.data;
            } 
        },
        async searchConsorzio(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_consorzi(search).catch(()=>{})

                if(_resp)
                    this.consorzi.found = _resp.data.data;
            }
        },
        async printReport(){
            const totale = this.report[this.report.length -1]
            const rows = this.report.filter(rep => rep.id_perito)
            let consorzio = "TUTTI"
            let ispettore = "TUTTI"
            if(this.search.consorzio){
                const found = this.consorzi.found.find(el => el.id == this.search.consorzio)
                if(found){
                    consorzio = found.name
                }
            }
            if(this.search.ispettore){
                const _found = this.ispettori.found.find(el => el.id == this.search.ispettore)
                if(_found){
                    ispettore = _found.surname+' '+_found.name
                }
            }
            
            const obj = {
                totale:totale,
                rows:rows,
                consorzio: consorzio,
                ispettore: ispettore,
                type: this.flag_dettaglio ? 1 : 0,
            }
            
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await SpecificheRepository.print_report_statistico(obj).catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download =`report_statistico.pdf` ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);

        },
        resetAll() {
            this.ispettori = {
                list:[],
                found:[]
            };
            this.consorzi = {
                list:[],
                found:[]
            };
            this.search = {
                consorzio: null,
                ispettore: null
            };
        },
        
        consorziListText(item){
            return `${item.codice} - ${item.name}`
        },
        ispettoriListText(item){
             return `${item.codice_perito} - ${item.name} ${item.surname}`
        },
    },
    mounted(){
        this.getReport();
    }
}
</script>