<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>LISTA BOLLETTINI</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>

            <table class="table table-bordered table-striped table-vcenter js-dataTable-full">
                <thead>
                    <tr>
                        <th class="d-none d-sm-table-cell">ID</th>
                        <th class="d-none d-sm-table-cell">EVASO</th>
                        <th class="d-none d-sm-table-cell">DATA PERIZIA</th>
                        <th class="d-none d-sm-table-cell">DATA CREAZIONE</th>
                        <th class="d-none d-sm-table-cell">AZIONI</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(bollettino, index) in bollettini" :key="index">
                        <td class="font-size-sm">
                            {{bollettino.id}}
                        </td>
                        <td class="font-size-sm">
                            <i v-if="!bollettino.bozza && !bollettino.approvato" class="si si-check text-danger"></i> 
                            <i v-if="!bollettino.bozza && bollettino.approvato" class="si si-check text-success"></i> 
                        </td>
                        <td class="font-size-sm">
                            {{bollettino.data_perizia | toEuropeData}}
                        </td>
                        <td class="font-size-sm">
                            {{bollettino.created_at | toEuropeData}}
                        </td>
                        <td class="d-none d-sm-table-cell font-size-sm">
                            <div class="btn-group">
                                <button @click="goToBollettino(bollettino.id)" type="button" class="btn btn-sm btn-light" title="Vai al Bollettino">
                                    <i class="fas fa-money-bill-alt"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>


        </template>
        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"

export default {
    name:'ModalBollettini',
    props:['isModalVisible', 'certificato_id', 'ispettore_id', 'is_direzione'],
    components: {
        Modal
    },
    data(){
        return {
            bollettini:[]
        }
    },
    methods: {
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        setData(bollettini){
            this.bollettini = bollettini
        },
        goToBollettino(id){
            if(this.is_direzione){
                const edit_bollettino = this.$router.resolve({name:'modifica-bollettino', params: { certificato_id: this.certificato_id, bollettino_id:id, ispettore_id:this.ispettore_id}})
                window.open(edit_bollettino.href, '_blank');
            }else{
                this.$router.push({name:'modifica-bollettino', params: { certificato_id: this.certificato_id, bollettino_id:id, ispettore_id:this.ispettore_id, }})
            }
        }
    }
}
</script>