<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Specifiche da approvare <small>Funzione dedicata agli ispettori per l'approvazione delle specifiche inserite dai propri periti.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <div class="row" style="margin-bottom:20px">
                        <div class="col-sm-12 col-md-6">
                            <label class="form-material">Seleziona Perito</label>
                                <SelettoreRicerca
                                    type="all"
                                    placeholder="Seleziona un Perito"
                                    voidText="Nessun perito trovato"
                                    :selectedModel="ispettoreSelected"
                                    @updateSelector="updateSelector"
                                />
                        </div>
                    </div>
                    <div class="row" style="margin-bottom:20px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div v-if="specifiche.length == 0" class="row">
                        <div class="col-md-12" style="text-align:center;padding:20px 0px;">
                            <h4>Nessuna specifica presente.</h4>
                        </div>
                    </div>
                    <table v-if="specifiche.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="data_specifica" name="DATA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="codice_perito" name="COD. PERITO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="onorario" name="ONORARIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="vitto" name="VITTO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="alloggio" name="ALLOGGIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="km_totali" name="KM TOTALI" :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">DETTAGLI</th>
                                <th style="text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(specifica, index) in specifiche" :key="index">
                                <td class="font-size-sm">
                                    {{data_specifica(specifica.data_specifica)}}
                                </td>
                                <td class="font-size-sm">
                                    {{specifica.perito.codice_perito}} - {{specifica.perito.name}} {{specifica.perito.surname}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{specifica.onorario}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{specifica.vitto}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{specifica.alloggio}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{specifica.km_residenza + specifica.km_perizia}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="showModalView(specifica)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Perizia">
                                            <i class="fa fa-fw fa-eye"></i>
                                        </button>
                                        <button @click="compareSpecifica(specifica)" type="button" :class="['btn btn-sm', specifica.selected ? 'btn-success':'btn-light']" data-toggle="tooltip" title="Compare Specifica">
                                            <i class="fas fa-arrows-alt-h"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button @click="changeStatusSpecifica(specifica.id, 1)" type="button" class="btn btn-sm btn-success" data-toggle="tooltip" title="Change status specifica approva">
                                            <i class="fa fa-fw fa-check"></i>
                                        </button>
                                        <button @click="changeStatusSpecifica(specifica.id, 2)" type="button" class="btn btn-sm btn-danger" data-toggle="tooltip" title="Change status specifica non approvare">
                                            <i class="fa fa-fw fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <Pagination :show="specifiche.length > 0" v-model="search.page" :lastPage="data.last_page" @paginateClick="paginateClick" />
                    <ComparaSpecifiche ref="compara_specifiche"></ComparaSpecifiche>
                </div>
            </div>
        </div>
        
        <ModalSpecifica ref="modal_specifica"
            :ispettori_int="ispettori"
            :consorzi_int="consorzi"
            :agenzie_int="agenzie"  
            :specifica="selected_specifica"
            :isModalVisible="isModalVisible" 
            @closeModalCallback="close_modal_callback" />

        <Modal v-if="isModalNonApprovaVisible" @close="close_modal_approvare">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Non Approva Specifica</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal_approvare"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <p style="margin-bottom:0px">Sei sicuro di non volerla approvare?</p>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="close_modal_approvare"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-danger"
                    @click="approva_status"
                    aria-label="Non Approvare">
                    Non Approvare
                </button>
            </template>
        </Modal>
        
        <Modal v-if="isModalApprovaVisible" @close="close_modal_approvare">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Approva Specifica</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal_approvare"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <p style="margin-bottom:0px">Sei sicuro di volerla approvare?</p>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="close_modal_approvare"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-success"
                    @click="approva_status"
                    aria-label="Non Approvare">
                    Approva
                </button>
            </template>
        </Modal>

    </div>
</template>

<script>
import _ from 'lodash';
import Modal from "../../components/Modal"
import ModalSpecifica from "../../components/Specifiche/ModalSpecifica"
import ComparaSpecifiche from "../../components/Specifiche/ComparaSpecifiche"
import ThTable from '../../components/ThTable'
import SpecificaRepository from "../../services/apis/specifica"
import UtilityRepository from "../../services/apis/utility"
import SelettoreRicerca from "../../components/SelettoreRicerca"
import Pagination from "../../components/Pagination/Pagination"
export default {
    components: {
        ThTable, Pagination, Modal, ModalSpecifica, ComparaSpecifiche, SelettoreRicerca
    },
    data(){
        return {
            specifiche:[],
            data: {
                last_page:1
            },
            search: {
                status:0,
                per_page: 10,
                page:1,
                search:'',
                orderBy:'data_specifica:desc'
            },
            ispettori:[],
            consorzi:[],
            agenzie:[],
            ispettoreSelected:null,
            selected_specifica:null,
            isModalVisible:false,
            isModalNonApprovaVisible:false,
            isModalApprovaVisible:false,
            selected_specifica_status:null,
            isTyping: false,
        }
    },
    watch: {
        'search.search': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
            isTyping: function(value) {
            if (!value) {
                this.search.page = 1
                this.get_specifiche()
            }
        },
        'ispettoreSelected': function() {
            this.search.page = 1
            this.get_specifiche()
        }
    },
    methods: {
        updateSelector(type, item){
            if(type == 'all'){
                this.ispettoreSelected = item
            }
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_specifiche()
        },
        async get_lista_ispettori(){
            let _resp = await SpecificaRepository.get_lista_ispettori().catch(() => {})

            if(_resp){
                this.ispettori = _resp.data.data
            }
            
        },
        async get_consorzi(){
            let _resp = await UtilityRepository.search_consorzi('').catch(()=> {})
            
            if(_resp){
                this.consorzi = _resp.data.data
            }
        },
        async get_agenzie(){
            let _resp = await UtilityRepository.search_agenzie('').catch(()=> {})

            if(_resp){
                this.agenzie = _resp.data.data
            }
        },
        async get_specifiche(){
            this.search.perito_id = this.ispettoreSelected ? this.ispettoreSelected.id : ""
            const _resp = await SpecificaRepository.filtrate_per_status(this.search).catch(() => {})
            
            if(_resp){
                this.specifiche = _resp.data.data.data
                this.data = _resp.data.data
            }
        },
        data_specifica(data){
            return data ? this.$moment(data).format('DD-MM-YYYY') : ''
        },
        perPage(){
            this.search.page = 1
            this.get_specifiche()
        },
        paginateClick(){
            this.get_specifiche()
        },
        showModalView(specifica){
            this.selected_specifica = specifica
            this.isModalVisible = true
        },
        close_modal_callback(){
            this.isModalVisible = false
            this.selected_specifica = null
        },
        changeStatusSpecifica(id, status){
            this.selected_specifica_status = {id:id, status:status}

            if(status == 2){
                this.isModalNonApprovaVisible = true
                return
            }
            this.isModalApprovaVisible = true
        },
        close_modal_approvare(){
            this.isModalNonApprovaVisible = false
            this.isModalApprovaVisible = false
            this.selected_specifica_status = null
        },
        approva_status(){
            this.isModalNonApprovaVisible = false
            this.isModalApprovaVisible = false
            const {id, status} = this.selected_specifica_status
            this.update_specifica_status(id, status)
        },
        async update_specifica_status(id, status){

            this.$store.dispatch('updateLoadingState', true)

            const _resp = await SpecificaRepository.update_status_specifica({id:id, status:status}).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.selected_specifica_status = null
                this.get_specifiche()
            }
        },
        compareSpecifica(specifica){
            this.$refs.compara_specifiche.compara(specifica)
            this.$forceUpdate();
        }
    },
    created(){
        this.get_lista_ispettori()
        this.get_consorzi()
        this.get_agenzie()
        this.get_specifiche()
    }
}
</script>

