<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">NUOVA PERIZIA</h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <ValidationObserver ref="form">
                <form>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-text-input">Certificato</label>
                                <ValidationProvider :rules="`${!perizia.cer_non_disponibile ? 'required' : ''}|numeric`" name="certificato" v-slot="{ errors }">
                                    <input :disabled="perizia.cer_non_disponibile" v-model="perizia.certificato" @input="checkisallok" type="text" class="form-control" name="certificato" placeholder="Numero certificato">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox mb-1">
                                    <input @click="changeCheckbox(perizia.cer_non_disponibile)" v-model="perizia.cer_non_disponibile" type="checkbox" class="custom-control-input" id="example-checkbox-custom1" name="example-checkbox-custom1">
                                    <label class="custom-control-label" for="example-checkbox-custom1">Certificato non disponibile</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-text-input">Agenzia</label>
                                <ValidationProvider rules="required" name="agenzia" v-slot="{ errors }">
                                    <model-list-select
                                        :isDisabled="agenzia_non_disponibile"
                                        id="search-select"
                                        :list="agenzie_int"
                                        option-value="id"
                                        :custom-text="agenziaListText"
                                        v-model="perizia.agenzia"
                                        @input="checkisallok"
                                        placeholder="Seleziona"
                                        @searchchange="searchAgenzia"
                                        >
                                    </model-list-select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox mb-1">
                                    <input @click="changeCheckboxAgenzia(agenzia_non_disponibile)" v-model="agenzia_non_disponibile" type="checkbox" class="custom-control-input" id="example-checkbox-agenzia" name="example-checkbox-agenzia">
                                    <label class="custom-control-label" for="example-checkbox-agenzia">Agenzia non disponibile</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-text-input">Consorzio</label>
                                <ValidationProvider rules="required" name="consorzio" v-slot="{ errors }">
                                    <model-list-select
                                        id="search-select"
                                        :isDisabled="consorzio_non_disponibile"
                                        :list="consorzi_int"
                                        option-value="id"
                                        :custom-text="consorziListText"
                                        v-model="perizia.consorzio_id"
                                        placeholder="Seleziona"
                                        @searchchange="searchConsorzio"
                                        @input="checkisallok"
                                        >
                                    </model-list-select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox mb-1">
                                    <input @click="changeCheckboxConsorzio(consorzio_non_disponibile)" v-model="consorzio_non_disponibile" type="checkbox" class="custom-control-input" id="example-checkbox-consorzio" name="example-checkbox-consorzio">
                                    <label class="custom-control-label" for="example-checkbox-consorzio">Consorzio non disponibile</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-text-input">Tipo di Perizia</label>
                                <ValidationProvider rules="required" name="tipoperizia" v-slot="{ errors }">
                                    <select v-model="perizia.tipo_perizia" class="form-control" name="tipoperizia">
                                        <option value>Seleziona</option>
                                        <option value="Preventiva">Preventiva</option>
                                        <option value="Parziale">Parziale</option>
                                        <option value="Definitiva">Definitiva</option>
                                        <option value="Rilevata e non definita">Rilevata e non definita</option>
                                        <option value="Lavoro di Ufficio">Lavoro di Ufficio</option>
                                        <option value="Perizia con assenza di certificato">Perizia con assenza di certificato</option>
                                        <option value="Altro">Altro</option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-select">Note</label>
                                <textarea v-model="perizia.note" name="note" id="" cols="30" rows="3" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div v-if="checkSelectPeriti()" class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-select">Seleziona Periti</label>
                                <v-select :clearable="false" :disabled="!canSelectPeriti() || !data_specifica"  multiple  v-model="perizia.periti" label="id"
                                    :options="optionsPeriti" @search="fetchOptions" :filterable="false" v-on:option:deselected="deselectOption" 
                                    >
                                    <template slot="no-options">
                                        Nessun utente trovato
                                    </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ `${option.name} ${option.surname} - ${option.codice_perito}` }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option">
                                        <div class="selected d-center">
                                            {{ `${option.name} ${option.surname} - ${option.codice_perito}` }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="custom-control custom-checkbox mb-1">
                                    <input v-if="checkSelectPeriti()" v-model="perizia.useCar" :disabled="canSelectCar()" type="checkbox" class="custom-control-input" id="example-checkbox-useCar" name="example-checkbox-useCar" >
                                    <label v-if="(!perizia.peritoUseCar || perizia.peritoUseCar.id == user.id) && checkSelectPeriti()" class="custom-control-label" for="example-checkbox-useCar">Ho utilizzato la mia auto per lo spostamento connesso a questa perizia</label>
                                    <label v-if="(perizia.peritoUseCar && perizia.peritoUseCar.id != user.id) && checkSelectPeriti()" class="custom-control-label" for="example-checkbox-useCar">Il perito {{perizia.peritoUseCar.name}} {{perizia.peritoUseCar.surname}} ha già dichiarato di aver utilizzato la propria auto per lo spostamento connesso a questa perizia</label> 
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </template>
        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                @click="confirmNewPerizia"
                aria-label="Nuova">
                Salva
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import UtilityRepository from "../../services/apis/utility";
import SpecificaRepository from "../../services/apis/specifica"

const basePerizia = {
        consorzio_id:"",
        certificato:"",
        agenzia:"",
        periti:[],
        useCar:false,
        peritoUseCar:null,
        cer_non_disponibile:false,
    }

export default {
    props:{
        isModalVisible:{
            type:Boolean,
            required:true
        },
        data_specifica:{
            required:true
        },
        perizia_selezionata:{
            required:true
        },
        consorzi:{
            type: Array
        },
        agenzie:{
            type: Array
        },
        auto:{
            type: Boolean
        },
    },
    data(){
        return{
            perizia:{...basePerizia},
            agenzia_non_disponibile:false,
            consorzio_non_disponibile:false,
            searchText: '',
            consorzi_int:[],
            agenzie_int:[],
            periti:[],
            optionsPeriti:[],
            timeout: null,
            user:null,
            firstOpen:false,
            checked:{

            }
        }
    },
    components: {
        Modal, ModelListSelect
    },
    watch:{
        'agenzia_non_disponibile':function(){this.changeData()},
        'consorzio_non_disponibile':function(){this.changeData()},
        'perizia.cer_non_disponibile':function(){this.changeData()},
        'perizia.certificato':function(){this.changeData()},
        'perizia.consorzio_id':function(){this.changeData()},
        'perizia.agenzia':function(){this.changeData()}
    },
    methods: {
        changeData(){
            if(!this.firstOpen){
                if(this.isModalVisible){
                    this.callPeriziaPeriti()
                }
            }
        },
        checkisallok(){
            // verifica se tutti i campi sono stati compilati e se può checckare auto
            if(this.perizia.certificato && this.perizia.consorzio_id && this.perizia.agenzia){
               
           
            if(this.checked[this.perizia.consorzio_id+this.perizia.agenzia+this.perizia.certificato]){
                //
               
            }else {
                this.checked[this.perizia.consorzio_id+this.perizia.agenzia+this.perizia.certificato] = true
                setTimeout(() => {
                    this.perizia.useCar = (this.perizia.peritoUseCar && this.perizia.peritoUseCar.id != this.user.id) || !this.canSelectPeriti() || !this.data_specifica ? false: true
                }, 1600);
                
            }
        }

        },
        canSelectCar(){
            //
            return (this.perizia.peritoUseCar && this.perizia.peritoUseCar.id != this.user.id) || !this.canSelectPeriti() || !this.data_specifica
        },
        checkSelectPeriti(){
            if(this.perizia.cer_non_disponibile || this.consorzio_non_disponibile || this.agenzia_non_disponibile){
                console.log("blocca")
                return false
            }

            return true
        },
        async getPeriziaPeriti(){

            if(!this.checkSelectPeriti()){
                return
            }

            this.$store.dispatch('updateLoadingState', true)
            this.perizia.useCar = false
            const {perizia} = this
            const resp = await SpecificaRepository.getPeriziaPeriti({
                certificato:perizia.certificato,
                consorzio:perizia.consorzio_id,
                agenzia:perizia.agenzia,
                data_specifica: this.data_specifica ? this.$moment(this.data_specifica, 'DD-MM-YYYY').format('YYYY-MM-DD') : null
            }).catch(() => {})
            if(resp){
                const _periti = resp.data.periti
                const found = _periti.find(_p => _p.id == this.$store.getters.user.id)
                if(!found){
                    _periti.push(this.$store.getters.user)
                }

                this.perizia.peritoUseCar = resp.data.peritoUseCar
                this.perizia.periti = _periti
            }
            this.$store.dispatch('updateLoadingState', false)
        },
        callPeriziaPeriti(){
            if(this.canSelectPeriti()){
                clearTimeout(this.timeout);

                var self = this;
                this.timeout = setTimeout(function () {
                    self.getPeriziaPeriti()
                }, 1000);
            }
        },
        canSelectPeriti(){
            const _certificato = this.perizia.cer_non_disponibile ? true :(this.perizia.certificato && this.perizia.certificato !== "" ? true : false)
            const _consorzio = this.perizia.consorzio_id && this.perizia.consorzio_id !== "" ? true : false
            const _agenzia = this.perizia.agenzia && this.perizia.agenzia !== "" ? true : false
            return _certificato && _consorzio && _agenzia
        },
        deselectOption(value){
            if(value.id == this.$store.getters.user.id){
                this.perizia.periti.push(value)
                this.$toasted.error('Non puoi eliminare te stesso.').goAway(3000)
            }
        },
        fetchOptions (search, loading) {
            if(!search || search.length < 3){
                return;
            }
            loading(true);

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                loading(true);
                self.getUsers(search, loading)
            }, 1000);
        },
        async getUsers(search, loading){
            const resp = await UtilityRepository.search_utenti({cerca:search}).catch(() => {})

            if(resp){
                this.optionsPeriti = resp.data.data
            }

            loading(false)
        },
        initData(perizia){
            this.perizia = this._.cloneDeep(perizia)
            const meFound = this.perizia.periti.find(_perito => _perito.id == this.$store.getters.user.id)
            if(!meFound){
                this.perizia.periti.push(meFound)
            }

            if(this.perizia.agenzia == 40){
                this.agenzia_non_disponibile = true
            }
            if(this.perizia.consorzio_id == 44){
                this.consorzio_non_disponibile = true                
            }

            const self = this
            setTimeout(() => {
                self.firstOpen = false
            }, 500);
        },
        closeModal() {
            this.$emit('closeModalCallback', null)
        },
        changeCheckbox(check){
            this.perizia.certificato = ''
            this.perizia.cer_non_disponibile = !check 
        },
        changeCheckboxAgenzia(check){
            this.perizia.agenzia = check === undefined || check === false ? 40 : '' 
            this.agenzia_non_disponibile = !check            
        },
        changeCheckboxConsorzio(check){
            this.perizia.consorzio_id = check === undefined || check === false ? 44 : '' 
            this.consorzio_non_disponibile = !check            
        },
        searchConsorzio(text){
            const _c = this.consorzi.filter((item) => {
                return item.name.toLowerCase().includes(text.toLowerCase()) || item.codice.toLowerCase().includes(text.toLowerCase())
            })

            this.consorzi_int = _c
        },
        searchAgenzia(text){
            const _c = this.agenzie.filter((item) => {
                return item.nome.toLowerCase().includes(text.toLowerCase()) || item.codice_identificativo.toString().includes(text.toLowerCase())
            })

            this.agenzie_int = _c
        },
        async confirmNewPerizia(){

            let success = await this.$refs.form.validate()

            if (!success) {
                this.$toasted.error('Tutti i campi devono essere compilati e corretti.').goAway(3000)
                return;
            }
            
            let perizia = this._.cloneDeep(this.perizia)

            if(!this.checkSelectPeriti()){
                perizia.periti = []
                perizia.useCar = false
            }


            this.$emit('closeModalCallback', perizia)
        },
        consorziListText(item){
            return `${item.codice} - ${item.name}`
        },
        agenziaListText(item){
            return `${item.codice_identificativo} - ${item.nome}`
        },
    },
    mounted(){
        const user = {...this.$store.getters.user}
        this.perizia.periti = [user]
        this.user = user
        this.consorzi_int = this.consorzi
        this.agenzie_int = this.agenzie
        if(this.perizia_selezionata.index != null){
            this.firstOpen = true
            this.initData(this.perizia_selezionata.perizia)
        }
        console.log(this.auto)
        if(this.auto){
            this.perizia.useCar = (this.perizia.peritoUseCar && this.perizia.peritoUseCar.id != this.user.id) || !this.canSelectPeriti() || !this.data_specifica ? false: true
        }
        
    },
}
</script>

<style scope>
    .ui.dropdown .menu .item{
        width: 100%;
    }
</style>