<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Gestione Periti <small>Lista dei periti connessi con l'ispettore.</small>
                    </h1>
                </div>
            </div>
        </div>
        
        <div class="content">
            <div class="block">
                <div class="block-content block-content-full">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <button @click="openInvitoModal" class="btn btn-primary">Invita Perito</button>
                            </div>
                        </div>
                    </div>
                    <h3 v-if="this.periti.length == 0">Nessuna perito presente</h3>
                    <table v-if="this.periti.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="d-none d-sm-table-cell">NOME</th>
                                <th class="d-none d-sm-table-cell">COGNOME</th>
                                <th class="d-none d-sm-table-cell">COD. PERITO</th>
                                <th class="d-none d-sm-table-cell">PERMESSO</th>
                                <th style="text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(perito, index) in this.periti" :key="index">
                                <td class="font-size-sm">
                                    {{perito.name}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{perito.surname}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{perito.codice_perito}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <select v-model="perito.permission" @change="onChangePermission(perito)">
                                        <option :selected="perito.permission == 'view'" value="view">Sola Lettura</option>
                                        <option :selected="perito.permission == 'edit'" value="edit">User</option>
                                        <option :selected="perito.permission == 'full'" value="full">Super User</option>
                                    </select>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <button @click="openFilterModal(perito)" class="btn btn-sm btn-primary" type="button" title="Modal Filters" style="margin-right: 10px">
                                        <i class="fas fa-filter"></i>
                                    </button>
                                    <button @click="openDeleteModal(perito)" class="btn btn-sm btn-danger" type="button" title="Modal Remove">
                                        <i class="fas fa-user-slash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="periti.length > 0" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="paginate.page"
                            :page-count="paginate.page_count"
                            :click-handler="paginateClick"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                </div>
            </div>
        </div>

        <ModalFiltroPerito 
            :isModalVisible="isModalFilterVisible" 
            ref="modal_filtro_perito" 
            :ispettore_id="this.ispettore_id"
            @closeModalCallback="close_filter_modal"
            @updatePeritoCallback="filter_perito">
        </ModalFiltroPerito>

        <ModalInvitoPerito 
            :isModalVisible="isModalInvitoVisible" 
            ref="modal_invito_perito" 
            @closeModalCallback="close_invito_modal"
            @invitoPeritoCallback="invito_perito">
        </ModalInvitoPerito>

        <ModalTrueFalse
            :isModalVisible="isModalRemoveDisassociaPeritoVisible" 
            @closeModalCallback="callbackDisassociaPerito"
            >
            <template v-slot:header-true>
                <h3>Dissocia Utente</h3>
             </template>
             <template v-slot:body-true>
                <h3>Sei sicuro di voler rimuovere l'utente tra i tuoi periti?</h3>
             </template>
        </ModalTrueFalse>

    </div>
</template>

<script>
import UserRepository from "../../services/apis/user"
import Paginate from 'vuejs-paginate'
import ModalFiltroPerito from "../../components/GestioneUtenti/ModalFiltroPerito"
import ModalInvitoPerito from "../../components/GestioneUtenti/ModalInvitoPerito"
import ModalTrueFalse from "../../components/ModalTrueFalse"
export default {
    components: {
        ModalFiltroPerito,
        ModalInvitoPerito,
        Paginate,
        ModalTrueFalse
    },
    data(){
        return {
            periti:[],
            paginate: {
                page_count:1,
                page:1,
                per_page:10,
                original_data:[]
            },
            perito_selected:null,
            // actualPerito: {},
            ispettore_id:0,
            isModalFilterVisible:false,
            isModalInvitoVisible:false,
            isModalRemoveDisassociaPeritoVisible:false,
        }
    },
    methods: {
        async get_periti(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await UserRepository.get_periti_associati().catch(() => {})
            
            if(_resp){
                this.periti = _resp.data.data;
                this.partite = _resp.data.data
                this.paginate.original_data = this._.cloneDeep(this.partite, true)
                this.paginate.page_count = Math.ceil(this.paginate.original_data.length / this.page_count);
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        async update_perito(perito, obj){
            this.$store.dispatch('updateLoadingState', true);

            var search = {
                perito_id: perito.id,
                permission: perito.permission,
                can_upload: 0,
            }

            if(obj !== null && obj !== undefined) {
                if(obj.comuni !== null) {
                    search.comuni = obj.comuni;
                }
                if(obj.ordini !== null) {
                    search.ordini = obj.ordini;
                }
            }

            const _resp = await UserRepository.update_associa_perito(search).catch(() => {})

            if(_resp) {
                this.$toasted.success(_resp.data.message).goAway(3000);
                this.get_periti();
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        async filter_perito(obj){
            this.$store.dispatch('updateLoadingState', true);

            var search = {
                perito_id: this.actualPerito.id,
                permission: this.actualPerito.permission,
                can_upload: 0,
            }

            if(obj !== null && obj !== undefined) {
                if(obj.comuni !== null) {
                    search.comuni_id = obj.comuni;
                }
                if(obj.ordini !== null) {
                    search.ordini_id = obj.ordini;
                }
            }

            const _resp = await UserRepository.update_associa_perito(search).catch(() => {})

            if(_resp) {
                this.$toasted.success(_resp.data.message).goAway(3000);
            }

            this.close_filter_modal();
            this.get_periti();
            this.$store.dispatch('updateLoadingState', false);
        },
        async invito_perito(obj){

            var search = {
                can_upload: 0,
            }

            if(obj !== null && obj !== undefined) {
                if(obj.codice_perito !== null) {
                    search.codice_perito = obj.codice_perito;
                }
                if(obj.permission !== null) {
                    search.permission = obj.permission;
                }
            }

            const _resp = await UserRepository.associa_perito(search).catch(() => {})

            if(_resp) {
                this.$toasted.success(_resp.data.message).goAway(3000);
            }

            this.close_invito_modal();
            this.get_periti();
        },
        async callbackDisassociaPerito(isTrue){
            this.isModalRemoveDisassociaPeritoVisible = false

            if(isTrue){
                this.$store.dispatch('updateLoadingState', true);
                const _resp = await UserRepository.disassocia_perito({perito_id: this.perito_selected.id}).catch(() => {})

                this.$store.dispatch('updateLoadingState', false);
                if(_resp) {
                    this.$toasted.success(_resp.data.message).goAway(3000);
                }

                this.get_periti();
            }

            
            this.perito_selected = null
        },
        paginateClick(pageNum){
            this.paginate.page = pageNum
            this.fakePagination()
        },
        fakePagination(){
            const {per_page, original_data, page} = this.paginate
            this.partite = original_data.slice((page - 1) * per_page, page * per_page) 
        },
        openBollettino(){
            this.$router.push({name:'modifica-bollettino', parameters:{certificato_id:10}})
        },
        close_filter_modal(){
            this.isModalFilterVisible = false
        },
        close_invito_modal(){
            this.isModalInvitoVisible = false
        },
        openFilterModal(perito){
            this.actualPerito = perito;
            this.$refs.modal_filtro_perito.getData(perito)
            this.isModalFilterVisible = true
        },
        openInvitoModal(){
            this.isModalInvitoVisible = true
        },
        openDeleteModal(perito){
            this.perito_selected = perito
            this.isModalRemoveDisassociaPeritoVisible = true
        },
        onChangePermission(perito) {
            this.update_perito(perito, null);
        }
    },
    mounted(){
        const user = this.$store.getters.user
        this.ispettore_id = user.role == 'ispettore' ? user.id : 0
        this.get_periti()
    }
}
</script>