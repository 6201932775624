<template>
    <!-- DELETE MODAL CERT -->
    <Modal v-if="isModalVisible">
        <template v-slot:header>
            <slot name="header-true">
                <h1 style="margin-bottom:0px;">
                    <span>DETTAGLI ELEMENTI AGGIORNATI</span>
                </h1>
            </slot>
        </template>
        <template v-slot:body>
            <div class="content">
            <!-- Dynamic Table Full -->
                <div class="block">
                    <div class="block-content block-content-full">
                        <h3 v-if="csv.length == 0">Nessun Csv presente</h3>
                        <div v-if="csv.length > 0" class="row" style="margin-bottom:30px;">
                            <div class="col-sm-12 col-md-6">
                                <div class="dataTables_length" id="DataTables_Table_0_length">
                                    <label>
                                        <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                            <option value="10">10</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                        </select>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <table v-if="csv.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                            <thead>
                                <tr>
                                    <th v-for="(key_, i) in csv_keys" :key="i" class="d-none d-sm-table-cell">
                                        <span v-if="key_ == 'ordine_id'">CERTIFICATO</span>
                                        <span v-if="key_ == 'partita_id'">PARTITA NR.</span>
                                        <span v-if="key_ != 'partita_id' && key_ != 'ordine_id'">{{key_}}</span>
                                    </th>
                                    <th style="text-align:center;">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(csv_int, index) in csv" :key="index">
                                    <td v-for="(key_, i) in csv_keys" :key="i" class="d-none d-sm-table-cell font-size-sm">
                                        <span v-if="key_ == 'ordine_id'">{{csv_int.ordine.ordine_id}}</span>
                                        <span v-if="key_ == 'partita_id'">{{csv_int.partita.numero_partita}}</span>
                                        <span v-if="key_ == 'denuncia_id'">{{csv_int.denuncia.n_danno}}</span>
                                        <span v-if="key_ != 'partita_id' && key_ != 'ordine_id'">{{csv_int[key_]}}</span>
                                    </td>
                                    <td class="d-none d-sm-table-cell font-size-sm">
                                        <div class="btn-group">
                                            <button @click="goToResource(csv_int)" type="button" class="btn btn-sm btn-light" title="Watch Certificato">
                                                <i class="fas fa-eye"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div v-if="csv.length > 0" class="col-sm-12 col-md-12">
                            <paginate
                                v-model="search.page"
                                :page-count="data.last_page"
                                :click-handler="paginateClick"
                                :prev-text="'Prev'"
                                :next-text="'Next'"
                                :container-class="'pagination'"
                                :page-class="'page-item'"
                                :prev-class="'prev'"
                                :next-class="'next'">
                            </paginate>
                        </div>
                        
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "./Modal"
import Paginate from 'vuejs-paginate'
import CertificatoRepository from "../services/apis/certificato"
export default {
    name:'ModalCsvHistories',
    props:['isModalVisible'],
    components: {
        Paginate, Modal
    },
    data(){
        return {
            csv:[],
            csv_keys:[],
            data: {
                last_page:1
            },
            search: {
                ispettore_id: 0,
                per_page: 10,
                page: 1,
            },
        }
    },
    methods: {
        async get_csv_histories(){
            this.$store.dispatch('updateLoadingState', true);

            const _resp = await CertificatoRepository.get_csv_actions_histories(this.search).catch(()=>{})

            if(_resp){
                this.csv = _resp.data.data.data
                if(this.csv.length > 0){
                    let _csv_keys = []

                    this.csv.forEach(_cs => {
                        for (const key in _cs) {
                            if (_cs.hasOwnProperty(key)) {
                                const element = _cs[key];
                                if(element){
                                    if(key !== "id" && key !== "created_at" && 
                                        key !== "updated_at" && key !== "ordine" && key !== "table_ordine_id" 
                                        && key !== "partita" && key !== "denuncia" ){
                                        _csv_keys.push(key)
                                    }
                                }
                            }
                        }

                    });

                    
                    this.csv_keys = this._.uniq(_csv_keys)
                    
                }
                this.data = _resp.data.data   
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        perPage(){
            this.search.page = 1
            this.get_csv_histories()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_csv_histories()
        },
        goToResource(csv){
            this.$router.push({name:'modifica-certificato', params:{ispettore_id:this.search.ispettore_id, certificato_id:csv.ordine_id}})
        },
        initModal(obj){
            this.search.ispettore_id = obj.user_id
            this.search.type = obj.type
            this.search.resource_number = obj.resource_number
            this.get_csv_histories()
        },
        closeModal(){
            this.$emit('closeModalCallback')
        }
    }
}
</script>