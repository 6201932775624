<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Report totale certificati
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="col-sm-12 col-md-12">
                        <div class="row">
                        <div class="col-sm-12 col-md-4">
                            <label class="form-material">Certificato</label>

                            <SelettoreRicerca
                                type="certificato"
                                placeholder="Seleziona un Certificato"
                                voidText="Nessun certificato trovato"
                                :selectedModel="searchInput.certificato"
                                :ispettoreId="parseInt(search.ispettore_id)"
                                @updateSelector="updateSelector"
                            />

                            <label class="form-material">Data Notifica</label>
                                <DatePicker isId="data_notifica_report" v-model="search.data_notifica" placeholder="dd-mm-yyyy" />
                            <label class="form-material">Data Carico</label>
                                <DatePicker isId="data_carico_report" v-model="search.data_carico" placeholder="dd-mm-yyyy" />
                            <label class="form-material">Denominazione</label>
                                <SelettoreRicerca
                                    type="denominazione"
                                    placeholder="Seleziona Intestatario"
                                    voidText="Nessun intestatario trovato"
                                    :selectedModel="searchInput.denominazione"
                                    :ispettoreId="parseInt(search.ispettore_id)"
                                    @updateSelector="updateSelector"
                                />
                            <label class="form-material">Ricolpite</label>
                            <div class="form-material">
                                <input type="checkbox" v-model="search.ricolpite" true-value="1" false-value="" class="ng-pristine ng-untouched ng-valid ng-empty">
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <label class="form-material">Agenzia</label>
                            <SelettoreRicerca
                                type="agenzia"
                                placeholder="Seleziona un Agenzia"
                                voidText="Nessuna agenzia trovata"
                                :selectedModel="searchInput.agenzia"
                                :ispettoreId="parseInt(search.ispettore_id)"
                                @updateSelector="updateSelector"
                            />


                            <div>
                                <label class="form-material">Prov</label>
                                <SelettoreRicerca
                                    type="provincia"
                                    placeholder="Seleziona Provincia"
                                    voidText="Nessuna provincia trovata"
                                    :selectedModel="searchInput.provincia"
                                    @updateSelector="updateSelector"
                                />
                            </div>
                            <div>
                                <label class="form-material">Specie</label>
                                <SelettoreRicerca
                                    type="specie"
                                    placeholder="Seleziona Specie"
                                    voidText="Nessuna specie trovata"
                                    :selectedModel="searchInput.specie"
                                    @updateSelector="updateSelector"
                                />
                            </div>
                            <div>
                                <label class="form-material">Foglio Catastale</label>
                                <SelettoreRicerca
                                    type="foglio_catastale"
                                    placeholder="Seleziona Foglio Catastale"
                                    voidText="Nessun foglio catastale trovato"
                                    :selectedModel="searchInput.foglio_catastale"
                                    :ispettoreId="parseInt(search.ispettore_id)"
                                    @updateSelector="updateSelector"
                                />
                            </div>
                            <div>
                                <label class="form-material">Data preventiva?</label>
                                <div class="form-material">
                                    <input type="checkbox" v-model="search.flag_data_preventiva" true-value="1" false-value="" class="ng-pristine ng-untouched ng-valid ng-empty">
                                </div>
                            </div>

                        </div>
                        <div class="col-sm-12 col-md-4">
                            <label class="form-material">Consorzio</label>
                            <SelettoreRicerca
                                    type="consorzio"
                                    placeholder="Seleziona un Consorzio"
                                    voidText="Nessun consorzio trovato"
                                    :selectedModel="searchInput.consorzio"
                                    :ispettoreId="parseInt(search.ispettore_id)"
                                    @updateSelector="updateSelector"
                                />
                            <div>
                                <label class="form-material">Comune</label>
                                <SelettoreRicerca
                                    type="comune"
                                    placeholder="Seleziona un Comune"
                                    voidText="Nessun comune trovato"
                                    :selectedModel="searchInput.comune"
                                    :ispettoreId="parseInt(search.ispettore_id)"
                                    @updateSelector="updateSelector"
                                />
                                <div class="form_material_small" style="margin-top: 16px">
                                    <div class="radio">
                                        <label>
                                            <input type="radio" v-model="search.evasa" value="da_evadere" :checked="search.evasa == 'da_evadere'">
                                            Totalmente liquidati / da evadere
                                        </label>
                                    </div>
                                    <div class="radio">
                                        <label>
                                            <input type="radio" v-model="search.evasa" value="parzialmente" :checked="search.evasa == 'parzialmente'">
                                            Parzialmente liquidati
                                        </label>
                                    </div>
                                    <div class="radio">
                                        <label>
                                            <input type="radio" v-model="search.evasa" value="evasa" :checked="search.evasa == 'evasa'">
                                            Evase
                                        </label>
                                    </div>
                                    <div class="radio">
                                        <label>
                                            <input type="radio" v-model="search.evasa" value="non_evasa" :checked="search.evasa == 'non_evasa'">
                                            Non evase
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <label class="form-material">Data Evento</label>
                                <DatePicker isId="data_evento" v-model="search.data_evento" placeholder="dd-mm-yyyy" />
                                <div v-if="search.flag_data_preventiva == 1" class="form_material">
                                    <label class="form-material_small">Seleziona data preventiva</label>
                                    <DatePicker isId="data_preventiva" v-model="search.data_preventiva" placeholder="dd-mm-yyyy" />
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-4">
                            <label class="form-material">Bollettino in Bozza</label>
                            <div class="form-material">
                                <input type="checkbox" v-model="search.bozza" true-value="1" false-value="" class="ng-pristine ng-untouched ng-valid ng-empty">
                            </div>
                        </div>
                    </div>
                   
                   <div v-if="ispettorePermission == 'direzione'" class="row">
                       <div class="col-sm-12 col-md-6">
                            <label class="form-material">Seleziona Ispettore</label>
                            <SelettoreRicerca
                                    type="ispettore"
                                    placeholder="Seleziona un Ispettore"
                                    voidText="Nessun ispettore trovato"
                                    :selectedModel="searchInput.ispettore"
                                    @updateSelector="updateSelector"
                                />
                        </div>
                   </div>

                   <div class="row">
                       <div class="col-sm-12 col-md-4">
                           <div class="form-material" style="margin-top: 50px;">
                                <button @click="print('pdf')" type="submit" class="btn btn-sm btn-primary" title="Crea Pdf" style="margin-right: 10px;" >Crea Pdf</button>
                                <button @click="print('csv')" type="submit" class="btn btn-sm btn-primary" title="Crea Csv" style="margin-right: 10px;" >Crea Csv</button>
                                <button class="btn btn-sm btn-primary" type="submit" @click="resetAll"> Reset</button>
                            </div>
                       </div>
                   </div>
                    </div>
                    
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="certificati.list.length == 0">Nessun certificato presente</h3>
                    <div v-if="certificati.list.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="certificati.list.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="text-center">NUMERO ORDINE</th>
                                <th class="d-none d-sm-table-cell">NUMERO CERTIFICATO</th>
                                <th class="d-none d-sm-table-cell">ISPETTORE</th>
                                <th class="d-none d-sm-table-cell">AGENZIA</th>
                                <th class="d-none d-sm-table-cell">SPECIE</th>
                                <th class="d-none d-sm-table-cell">COMUNE</th>
                                <th class="d-none d-sm-table-cell">DENOMINAZIONE CERT.</th>
                                <th style="text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(certificato, index) in certificati.list" :key="index">
                                <td class="font-size-sm">
                                    {{certificato.ordine_id}}
                                </td>
                                <td class="font-size-sm">
                                    {{certificato.certificato}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.ispettore.name}} {{certificato.ispettore.surname}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.agenzia.codice_identificativo}} - {{certificato.agenzia.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.specie.etichetta}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{certificato.comune.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.denominazione}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="infoCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Info Certificato">
                                            <i class="fas fa-search"></i>
                                        </button>
                                        <button v-if="canPerito('edit|full')" @click="editCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Edit Certificato">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button @click="openHailbox(certificato.ordine_id)" type="button" class="btn btn-sm btn-light" title="Open Hailbox">
                                            <i class="fas fa-cloud"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="certificati.list.length > 0" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="paginate.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="prev"
                            :next-text="next"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                </div>
            </div>
        </div>

        <ModalInfoCert 
            :isModalVisible="isModalInfoVisible" 
            ref="modal_info_cert"
            :is_direzione="ispettorePermission == 'direzione' ? true : false" 
            :certificato="certificato"
            :ispettore_id="certificato.ispettore_id" 
            :certificato_id="certificato.id"
            :readOnly="true"
            :width="ispettorePermission == 'direzione' ? 'width:80% !important' :''"
            @closeModalCallback="close_info_cert_modal">
        </ModalInfoCert>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import Config from '../../services/config'
import ModalInfoCert from "../../components/Certificato/ModalInfoCert"
import DatePicker from "../../components/DatePicker"
import UtilityRepository from "../../services/apis/utility"
import CertificatoRepository from "../../services/apis/certificato"
import Utility from "../../services/utility"
import SelettoreRicerca from "../../components/SelettoreRicerca"

export default {
    components: {
        Paginate,
        ModalInfoCert,
        DatePicker,
        SelettoreRicerca
    },
    data(){
        return {
            ispettorePermission:null,
            isModalInfoVisible: false,
            data: {
                last_page:1
            },
            paginate: {
                page: 1
            },
            search: {
                ispettore_id: 0,
                per_page: 10,
                // form ricerca
                agenzia_id: null,
                foglio_catastale: null,
                certificato: null,
                comune_id: null,
                consorzio_id: null,
                data_carico: null,
                data_evento: null,
                data_notifica: null,
                data_preventiva: null,
                denominazione: null,
                evasa: null,
                flag_data_preventiva: null,
                provincia_id: null,
                ricolpite: null,
                specie_id: null,
            },
            certificati:{
                list:[],
                found:[]
            },
            certificato: {
                ispettore_id:null,
                certificato:null,
                agenzia_id:'',
                denominazione:'',
                provincia_id:'',
                comune_id:'',
                data_carico:'',
                data_notifica:'',
                franchigia:10,
                soglia:true,
                provvisorio:false,
                ordini:[],
                agevolata:false,
                note:''
            },
            prev:Config.lang.prev,
            next:Config.lang.next,
            searchInput: {
                certificato:null,
                denominazione:null,
                agenzia:null,
                provincia:null,
                specie:null,
                foglio_catastale:null,
                consorzio:null,
                comune:null,
                ispettore:null
            }
        }
    },
    watch: {
        search:{
            handler: function (){
                if(!this.search.flag_data_preventiva){
                    this.search.data_preventiva = null;
                }
                this.paginate.page = 1
                this.get_certificati();
            },
            deep: true
        },
        '$route.params.ispettore_id': function (id) {        
            this.search.ispettore_id = id
            this.get_certificati()
        },
        'paginate.page': function () {        
            this.get_certificati()
        },
        'searchInput': {
            handler:function () {        
                this.get_certificati()
            },
            deep:true
        },
    },
    
    methods: {
        updateSelector(type, item){
            if(type == "ispettore" && this.$route.params.ispettore_id == "direzione"){
                this.search.ispettore_id = item?.id || 0
            }
            this.searchInput[type] = item
        },
        formatSearch(){
            let search = this._.cloneDeep(this.search, true)
            search.data_carico = Utility.convertDataToDBFormat(search.data_carico)
            search.data_notifica = Utility.convertDataToDBFormat(search.data_notifica)
            search.data_evento = Utility.convertDataToDBFormat(search.data_evento)
            search.page = this.paginate.page

            search.certificato = this.searchInput.certificato?.certificato
            search.denominazione = this.searchInput.denominazione?.nome
            search.agenzia_id = this.searchInput.agenzia?.id
            search.provincia_id = this.searchInput.provincia?.id
            search.foglio_catastale = this.searchInput.foglio_catastale?.nome
            search.consorzio_id = this.searchInput.consorzio?.id
            search.comune_id = this.searchInput.comune?.id
            search.specie_id = this.searchInput.specie?.id
            
            if(this.$route.params.ispettore_id == "direzione"){
                search.ispettore_id = this.searchInput.ispettore?.id || 0
            }

            return search
        },
        canPerito(permissions){
            return Utility.canPerito(permissions, this.ispettorePermission)
        },
        async get_certificati(){
            this.$store.dispatch('updateLoadingState', true);
            let search = this.formatSearch()
            const _resp = await CertificatoRepository.report_totale_certificati(search).catch(()=>{})

            if(_resp){
                this.certificati.list = _resp.data.data.data
                this.data.last_page = _resp.data.data.last_page
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        async print(type){
            this.search.type = type;
            this.$store.dispatch('updateLoadingState', true);
            let search = this.formatSearch()
            const _resp = await UtilityRepository.print_ordini(search).catch(()=>{})

            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                if(type == 'csv'){
                    a.download ='report_csv.csv' ;
                }else{
                    a.download ='report_pdf.pdf' ;
                }

                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        resetAll() {
            let _search = this._.cloneDeep(this.search);
            let _searchInput = this._.cloneDeep(this.searchInput);
            
            for (const key in _search) {
                if (_search.hasOwnProperty(key)) {
                    if(key !== 'ispettore_id') {
                        if(key == 'per_page') {
                            _search[key] = 10;
                        }
                        else if(key == 'page') {
                            _search[key] = 1;
                        }
                        else {
                            _search[key] = null;
                        }
                    }
                }
            }

            for (const key in _searchInput) {
                if (Object.hasOwnProperty.call(_searchInput, key)) {
                    _searchInput[key] = null                    
                }
            }

            this.searchInput = _searchInput
            this.search = _search;
        },
        perPage(){
            this.paginate.page = 1
            // this.get_certificati()
        },
        paginateClick(pageNum){
            this.paginate.page = pageNum
            // this.get_certificati()
        },
        goTo(name){
            this.$router.push({ name: name })
        },
        infoCertificato(cert){
            this.certificato = cert
            this.isModalInfoVisible = true
        },
        editCertificato(certificato){
            const edit_route = this.$router.resolve({ name: 'modifica-certificato', params:{certificato_id:certificato.id} })
            window.open(edit_route.href, '_blank');
        },
        openHailbox(ordine_id){
            const user = this.$store.getters.user
            /*if(user.role == "ispettore"){
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&source=myara`,'_blank');
            }else{
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&i=${this.search.ispettore_id}&source=myara`,'_blank');
            }*/
            if(user.role == "ispettore"){
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&source=myara`,'_blank');
            }else{
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&i=${this.search.ispettore_id}&source=myara`,'_blank');
            }
        },
        close_info_cert_modal(){
            this.isModalInfoVisible = false
        },
        agenzieListText(item){
            return `${item.codice_identificativo} - ${item.nome}`
        },
        consorziListText(item){
            return `${item.codice} - ${item.name}`
        },
        specieListText(item){
            return `${item.codice_identificativo} - ${item.etichetta}`
        },
        provinceListText(item){
            return `${item.short} - ${item.nome}`
        },
    },
    mounted(){
        if(this.$route.params.ispettore_id == "direzione"){
            const user = this.$store.getters.user
            if(user.role !== 'direzione'){
                this.$toasted.error('Non hai il ruolo per visualizzare la pagina')
                this.$router.push({name:'dashboard'})
                return 
            }
            this.ispettorePermission = 'direzione'
        }else{
            this.search.ispettore_id = this.$route.params.ispettore_id
            this.ispettorePermission = Utility.getIspettorePeritoById(this.$route.params.ispettore_id)
        }

        
        this.get_certificati()
    }
}
</script>
<style>
    @import '../../assets/css/style.css';
</style>