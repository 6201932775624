<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>FRANCHIGIA CONTRATTUALE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <select @change="changeSelect" v-model="select" class="form-control" name="grade">
                            <option value="FC">FC</option>
                            <option value="INSERISCI">INSERISCI VALORE</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" v-if="select == 'INSERISCI'">
                <div class="col-md-12">
                    <div class="form-group">
                        <input @change="changeInput" v-model="value" type="text" class="form-control" name="value">
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                :disabled="!can_save"
                @click="set_data">
                <span>Salva Modifiche</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../../Modal"
export default {
    name:'BollettinoModalFranchigia',
    props:['isModalVisible'],
    components: {
        Modal
    },
    data(){
        return {
            row_position:{
                x:null,
                y:null
            },
            select:"FC",
            value:"FC",
            can_save: true
        }
    },
    methods: {
        get_data(value, x, y){
            
            if(value){
                this.value = value
            }

            if(this.value !== "FC"){
                this.select = "INSERISCI"
            }

            this.row_position = {
                x:x, y:y
            }
        },
        changeInput(){
            if(this.value !== ""){
                this.can_save = true
                return
            }
            this.can_save = false
        },
        changeSelect(){
            if(this.select == "INSERISCI"){
                this.value = ""
                this.can_save = false
                return
            }
            this.value = "FC"
            this.can_save = true
        },
        reset(){
            this.select = "FC"
            this.value = "FC"
            this.can_save = true
            this.row_position = {
                x:null,
                y:null
            }
        },
        set_data(){
            this.$emit('closeModalCallback', 'franchigia', this.value, this.row_position)
            this.reset()
        },
        closeModal() {
            this.$emit('closeModalCallback', 'causa')
        },
    }
}
</script>