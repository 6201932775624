<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Periti impegnati
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="block">
                <div class="block-content block-content-full">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <h3 v-if="!alreadySetSquadra()"><b>Seleziona i periti per il {{today}}</b></h3>
                                <h3 v-if="alreadySetSquadra()"><b>Già hai selezionato i periti per la giornata di oggi</b></h3>
                                <p v-if="existsDayBefore()"><b>NB.</b>I periti selezionati di seguito sono relativi al giorno: {{existsDayBefore()}}.</p>
                            </div>
                        </div>
                        <div v-if="peritiNotAvailables.length > 0" class="row" style="margin-bottom:50px; margin-top:20px">
                            <div class="col-sm-12 col-md-12">
                                <label class="form-material">Periti presenti nella giornata {{existsDayBefore()}} ma non più disponibili per oggi perchè già inseriti in un'altra squadra.</label>
                                <div v-for="perito in peritiNotAvailables" :key="perito.id">
                                    {{ `${perito.codice_perito} -  ${perito.name} ${perito.surname}` }} <br>
                                </div>
                            </div>
                        </div>
                        <div class="row" style="margin-bottom:50px; margin-top:20px">
                            <div class="col-sm-12 col-md-6">
                                <label class="form-material">Seleziona Periti</label>
                                <v-select 
                                    :clearable="false"
                                    multiple
                                    :options="periti" 
                                    :filterable="false" 
                                    @search="search" 
                                    :value="peritiSelected" 
                                    label="id"
                                    @input="setSelected"
                                    placeholder="Seleziona periti"  >
                                    <template slot="no-options">
                                        Nessun perito trovato
                                    </template>
                                    <template slot="option" slot-scope="option">
                                        <div class="d-center">
                                            {{ `${option.codice_perito} -  ${option.name} ${option.surname}` }}
                                        </div>
                                    </template>
                                    <template slot="selected-option" slot-scope="option" >
                                        <div class="d-center">
                                            {{ `${option.codice_perito} -  ${option.name} ${option.surname}` }}
                                        </div>
                                    </template>
                                </v-select>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button
                                        type="button"
                                        class="btn btn-danger"
                                        @click="confirm(true)"
                                        aria-label="Nuova">
                                        Nessuna squadra
                                    </button>

                                    <button
                                        style="margin-left:10px;"
                                        :disabled="peritiSelected.length == 0"
                                        type="button"
                                        class="btn btn-primary"
                                        @click="confirm(false)"
                                        aria-label="Nuova">
                                        {{!alreadySetSquadra() ? 'Salva' : 'Modifica'}}
                                    </button>
                                     
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UserRepository from "../../services/apis/user"
export default {
    components: {

    },
    data(){
        return {
            peritiSelected:[],
            peritiNotAvailables:[],
            periti:[],
            timeout:null,
            today:'',
            squadra:null
        }
    },
    watch: {

    },
    methods: {
        setSelected(value){
            if(this.peritiSelected < value){
                const notAvailableFound = value.find(val => !val.isAvailable)
                if(notAvailableFound){
                    this.$toasted.error('Non puoi aggiungere il perito perchè è già stato impiegato.').goAway(3000)
                    return
                }
            }

            this.peritiSelected = value            
        },
        search(text, loading){
            if(!text || text == ""){
                return
            }

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                loading(true);
                var _search = {
                    search:text
                }
                self.getPeriti(loading, _search)
                
            }, 1000);
        },
        alreadySetSquadra(){
            let date = this.squadra ? this.$moment(this.squadra.date) : null;
            if(!date) {
                return false
            }
            const today = this.$moment()
            if(today.isSame(date, 'day')){
                return true
            }
            return false
        },
        existsDayBefore(){
            let date = this.squadra ? this.$moment(this.squadra.date) : null;
            if(!date) {
                return false
            }

            const today = this.$moment()
            if(today.isSame(date, 'day')){
                return false
            }

            return date.format('DD-MM-YYYY')
        },
        async confirm(noSquadra){
            let periti = []

            if(!noSquadra){
                periti = this.peritiSelected.map(perito => perito.id)
            }
            this.$store.dispatch('updateLoadingState', true)
            let resp = null

            if(!this.alreadySetSquadra()){
                resp = await UserRepository.post_squadra_periti({periti}).catch(() => {})
            }else{
                resp = await UserRepository.put_squadra_periti(this.squadra.id,{periti}).catch(() => {})
            }

            if(resp){
                this.$toasted.success(resp.data.message).goAway(3000)
                await this.getSquadraPeriti()
            } 

            this.$store.dispatch('updateLoadingState', false)
        },
        async getPeriti(loading, search){
             const _resp = await UserRepository.cerca_perito_libero(search).catch(()=>{})

            if(_resp){
                this.periti = _resp.data.periti
            }

            loading(false)
        },
        async getSquadraPeriti(){
            const resp = await UserRepository.get_squadra_periti().catch(() => {})
            this.peritiNotAvailables = []
            this.peritiSelected = []
            if(resp){
                this.squadra = resp.data.squadra
                if(resp.data.squadra){
                    let date = resp.data.squadra ? this.$moment(resp.data.squadra.date) : null;
                    if(date){
                        const today = this.$moment()
                        if(today.isSame(date, 'day')){
                            this.peritiSelected = resp.data.squadra.periti
                            
                            return
                        }
                    }

                    this.peritiSelected = resp.data.squadra.periti.filter(perito => perito.isAvailable)
                    this.peritiNotAvailables = resp.data.squadra.periti.filter(perito => !perito.isAvailable)
                } 
            }
        }
    },
    mounted(){
        this.today = this.$moment().format('DD-MM-YYYY')
        this.getSquadraPeriti()
    }
}
</script>