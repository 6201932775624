<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>AGGIUNGI DATA DI TRASFERIMENTO</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label style="margin-bottom:10px;" class="form-material">Data</label>
                        <DatePicker isId="data_trasferimento" v-model="value" placeholder="Inserisci data" />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                :disabled="!can_save"
                @click="set_data">
                <span>Salva</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../components/Modal"
import DatePicker from '../components/DatePicker'

export default {
    name:'ModalDataDiTrasferimento',
    props:['isModalVisible'],
    components: {
        Modal, DatePicker
    },
    data(){
        return {
            value:null,
            can_save: false
        }
    },
    watch: {
        'value':function(){
            this.changeInput()
        }
    },
    methods: {
        changeInput(){
            if(this.value !== ""){
                this.can_save = true
                return
            }
            this.can_save = false
        },
        set_data(){            
            this.$emit('closeModalCallback',this.value)
            this.value = null
        },
        closeModal() {
            this.$emit('closeModalCallback')
            this.value = null
        },
    }
}
</script>