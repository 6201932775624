export default({
    //base_url:'/',
    // base_url:'/admin',
    // website_url:'http://localhost',
    // url_storage:`http://localhost/storage`,
    // website_url:'https://myara1857-refactor.innobe.app',
    // url_storage:`https://myara1857-refactor.innobe.app/storage`,
    // website_url:'https://myara1857.it',
    // url_storage:`https://myara1857.it/storage`,
    // year:2020,
    // last_year:2019,
    // development: {
        // hailbox_url:"https://hailbox.myara1857.it",
        // aws_url:"https://s3.eu-west-3.amazonaws.com/hailbox-myara",
        // hailbox_url:"https://hailbox-myara.innobe.app",
    //     aws_url:"https://s3.eu-west-3.amazonaws.com/hailbox-myara-dev",
    // },
    // production: {
        // hailbox_url:"https://hailbox.myara1857.it",
        // aws_url:"https://s3.eu-west-3.amazonaws.com/hailbox-myara",
    //     hailbox_url:"https://hailbox-myara.innobe.app",
    //     aws_url:"https://s3.eu-west-3.amazonaws.com/hailbox-myara-dev",
    // },
    lang: {
        prev:'Precedente',
        next:'Successiva',
    }
})