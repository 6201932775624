import api from '../api.js'
import store from '../../store'

export default({
    login(obj){        
        return api.post(`/v2/auth/login`, obj);
    },
    logout(){        
        return api.post(`/v2/auth/logout?token=${store.getters.access_token}`);
    },
    recovery_password(obj){        
        return api.post(`/v2/auth/recovery-password`, obj);
    },
    validate_recovery_password(token){        
        return api.get(`/v2/auth/reset-password/${token}`);
    },
    me(){
        return api.get(`/v2/auth/me?token=${store.getters.access_token}`);
    },
    reset_password(obj){
        return api.post(`/v2/auth/new-password?token=${obj.token}`, obj);
    },
    email_verification(email){
        return api.get(`/v2/auth/email-verification/${email}`);
    },
});