<template>
    <!-- BOLLETTINI MODAL -->
        <Modal v-if="isModalVisible" @close="closeModal">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">
                    <span>LISTA BOLLETTINI IN CUI SEI PRESENTE</span>
                </h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="closeModal"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                
                <h2 v-if="bollettini.length == 0">Nessuna bollettino presente</h2>

                <table v-if="bollettini.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                    <thead>
                        <tr>
                            <th class="d-none d-sm-table-cell">CERTIFICATO</th>
                            <th class="d-none d-sm-table-cell">AZIONI</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(bollettino, index) in bollettini" :key="index">
                            <td class="font-size-sm">
                                {{bollettino.ordine.certificato}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <div class="btn-group">

                                    <button v-if="bollettinoIsSelected(bollettino.id)" @click="selectBollettino(bollettino)" type="button" class="btn btn-sm btn-light" title="Seleziona Bollettino">
                                        <i class="fas fa-check"></i>
                                    </button>
                                    
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="closeModal"
                    aria-label="Close modal">
                    Chiudi
                </button>
                
            </template>
            
        </Modal>
    <!-- END BOLLETTINI MODAL -->
</template>

<script>
import Modal from "../Modal"

export default {
    name:'ModalBollettiniAssociati',
    props:['isModalVisible', 'bollettini', 'bollettini_selected'],
    components: {
        Modal
    },
    data(){
        return {}
    },
    methods: {
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        bollettinoIsSelected(bollettino_id){
            const _found = this.bollettini_selected.find(bollettino => bollettino.id === bollettino_id)
            if(!_found){
                return true
            }
            return false
        },
        selectBollettino(bollettino){
            this.$emit('closeModalCallback', bollettino)
        }
    }
}
</script>