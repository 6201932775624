<template>
    <div class="multiselect-cmoon">
        <ul style="display: flex; flex-wrap:wrap;background: white;align-items: center;border: 1px solid #ced4da;border-radius: 3px; min-height:38px;margin-bottom: 2px;padding: 0px;">
            <li style="font-size: 13px;padding: 5px;background: lightgray;border-radius: 4px;margin: 5px;cursor:pointer;" 
                v-for="(tipo, i) in selected_lists" :key=i @click="$emit('removeTag', tipo)">
                {{tipo.name}}
                <div class="delete-controls" style="float: right;margin-left: 5px;">
                    <i class="fa fa-times fa-fw"></i>
                </div>
            <li>
            <li style="margin: 5px;padding-left: 2px;">
                <input @focus="onFocus" style="border: none; outline-style:none;box-shadow:none;border-color:transparent;" @blur="onBlur" @input="onInput" name="scuola" :placeholder="placeholder" />
            </li>
        </ul>
        <ul class="resources-list" @keyup='nextItem' :style="[lists_.length > 4 ? {height:'200px'} : {height:lists_.length*40+'px'}]" >
            <li :tabindex="index" v-for="(resource, index) in lists_" :key="index" style="cursor: default;" @mouseover="hoverSelect(index)" @click="selectTag(index)" 
                :data-index="index" :class="[selected_value_index == index ? 'selected' : '', 'resource-item']">
                <div class="resource-info">
                <div class="resource-title" :id="index">
                    <span>{{ resource.name }}</span>
                </div>
                </div>
                <!-- <div class="delete-controls" v-on:click.prevent="removeDependency(index)">
                    <i class="fa fa-times fa-fw"></i>
                </div> -->
            </li>
            <li v-if="lists_.length == 0 && custom_element" style="padding:8px; color: rgb(143, 157, 170);">Nessun elemento trovato, premi invio per crearlo.</li>
            <li v-if="lists_.length == 0 && !custom_element" style="padding:8px; color: rgb(143, 157, 170);">Nessun elemento trovato.</li>
        </ul>
    </div>
</template>

<script>
export default {
    name:'MultiselectCMOON',
    props: {
        is_object:{
            type: Boolean,
            default:false
        },
        custom_element: {
            type: Boolean,
            default:false
        },
        selected_lists:{
            type: Array,
            required: true
        },
        lists:{
            type: Array,
            required: true
        },
        object_name:{
            type: String,
        },
        placeholder:{
            type: String,
            default:'Seleziona elemento'
        }
    },
    data(){
        return {
            selected_value_index:0,
            target:null,
            lists_:[]
        }
    },
    watch: {
        object_name: {
            handler(value) {
            if(this.is_object){
                if(value && typeof this.data === 'undefined') {
                    console.error("property 'object_name' is required. ")
                }	
            }
            },
            immediate: true
        },
        'lists': function(values){
            this.selected_value_index = 0
            document.getElementsByClassName('resources-list')[0].scrollTop = 0

            this.lists_ = values.filter(el => {
                return !this.selected_lists.find(l => l.id == el.id)
            })
        }
    },
    methods: {
        onInput(e){
            var value = e.target.value;

            this.$emit('searchChange', value);

            if(this.target && !this.target.nextElementSibling.classList.contains("open")){
                this.target.nextElementSibling.classList.add("open")
            }

        },
        onFocus(e){
            this.$emit('searchChange', e.target.value);

            this.input_target = e.target
            this.target = e.target.closest("ul")
            if(this.lists_.length > 0){
                this.target.nextElementSibling.classList.add("open")
            }
            document.addEventListener("keyup", this.nextItem);
            document.addEventListener('keydown', this.preventFormSubmit);
        
        },
        onBlur(){
            const _this = this
            setTimeout(function() {
                _this.target.nextElementSibling.classList.remove("open")
                _this.target = null;
                _this.selected_value_index = 0
                document.removeEventListener('keyup', _this.nextItem)
                document.removeEventListener('keydown', _this.preventFormSubmit);
            }, 100)
        },
        selectTag(index){
            this.$emit('selectedTag', this.lists_[index])
            this.input_target.value = ''
        },
        hoverSelect(index){
            this.selected_value_index = index;
        },
        nextItem () {
            if (event.keyCode == 38 && this.selected_value_index > 0 ) {
                this.selected_value_index--
                if(this.target && !this.target.nextElementSibling.classList.contains("open")){
                    this.target.nextElementSibling.classList.add("open")
                }else{
                    if(document.getElementsByClassName('selected resource-item').length >0){
                        document.getElementsByClassName('resources-list')[0].scrollTop = document.getElementsByClassName('selected resource-item')[0].previousElementSibling.offsetTop -40  
                    }
                }
            } else if (event.keyCode == 40 && this.selected_value_index < (this.lists_.length - 1) ) {
                this.selected_value_index++
                if(this.target && !this.target.nextElementSibling.classList.contains("open")){
                    this.target.nextElementSibling.classList.add("open")
                }else{
                    if(document.getElementsByClassName('selected resource-item').length >0){
                        document.getElementsByClassName('resources-list')[0].scrollTop = document.getElementsByClassName('selected resource-item')[0].offsetTop - 40
                    }
                }
            } else if (event.keyCode == 40 && this.selected_value_index == (this.lists_.length - 1)){
                if(this.target && !this.target.nextElementSibling.classList.contains("open")){
                    this.target.nextElementSibling.classList.add("open")
                }
            }
        },
        preventFormSubmit(){

            if(event.keyCode == 8){
                if(this.input_target.value == ''){
                    this.target.nextElementSibling.classList.remove("open")
                    if(this.selected_lists.length > 0){
                        this.$emit('removeTag', this.selected_lists[this.selected_lists.length -1])
                    }
                }
            }

            if(event.keyCode == 188 && this.custom_element){
                event.preventDefault()
                if(this.input_target.value.length > 1 && this.lists_.length == 0){
                    this.target.nextElementSibling.classList.remove("open")
                    //this.formData.tipologia.push(this.input_target.value)
                    this.$emit('selectedTag', {name:this.input_target.value})
                    this.input_target.value = ''
                }

            }

            if(event.keyCode == 13 || event.keyCode == 9){
                event.preventDefault()
                
                if(this.target.nextElementSibling.getElementsByClassName("selected")[0] && this.lists_.length > 0){
                    this.$emit('selectedTag', this.lists_[this.target.nextElementSibling.getElementsByClassName("selected")[0].getAttribute("data-index")])
                    this.selected_value_index = 0
                    this.target.nextElementSibling.classList.remove("open")

                }else{
                    if(this.input_target.value !== '' && this.custom_element){
                        this.target.nextElementSibling.classList.remove("open")
                        this.$emit('selectedTag', {name:this.input_target.value})
                    }
                }
                this.input_target.value = ''
            }
        }
    },
}
</script>

<style lang="scss" scoped>
    .multiselect-cmoon{
        ul, li {
            list-style: none;
        }
    }
    .bootstrap-tagsinput .tag {
        padding: 5px;
        color: black;
        background: lightgray;
    }
    .resources-list {
        position: absolute;
        width: 100%;
        background: white;
        border: 1px solid rgb(206, 212, 218);
        display: none;
        max-height: 300px;
        overflow-y: auto;
        z-index: 999;
        padding: 0px;
    }
    .resource-title {
        font-size: 14px;
    }
    .resources-list.open {
        display: block;
    }
    .resource-item {
        padding:8px;
    }
    .resource-item.selected {
        background: #f1f1f1;
    }
</style>