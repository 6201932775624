import api from '../api.js'
import store from '../../store'
import utility from '../utility'

export default({
    get_mie_fatture(search){        
        var _search = utility.appendParameters(search);         
        return api.get(`/v2/fatture/mie?token=${store.getters.access_token}&${_search}`);
    },
    calcolo_previsionale(ids){        
        return api.get(`/v2/fatture/calcoloPrevisionale?token=${store.getters.access_token}&specifiche_id=${ids}`);
    },
    calcolo_residuo(){        
        return api.get(`/v2/fatture/calcoloResiduo?token=${store.getters.access_token}`);
    },
    nuova_fattura(obj){        
        return api.post(`/v2/fatture/nuova?token=${store.getters.access_token}`, obj);
    },
    download_fattura(link){        
        return api.get(`/v2/fatture/downloadPDF?token=${store.getters.access_token}&nome_file=${link}`, {responseType: 'arraybuffer'});
    },
    stampa_registro_fatture(obj){        
        return api.post(`/v2/print/registro-fatture?token=${store.getters.access_token}`, obj, {responseType: 'arraybuffer'});
    },
    da_perito(search){
        var _search = utility.appendParameters(search);         
        return api.get(`/v2/fatture/da-perito?token=${store.getters.access_token}&${_search}`);
    },
    elimina_fattura(id){        
        return api.delete(`/v2/fatture/${id}?token=${store.getters.access_token}`);
    },
    update_data_contabilita(obj){        
        return api.put(`/v2/fatture/update-data-contabilita?token=${store.getters.access_token}`, obj);
    },
    get_fattureH499(search){
        var _search = utility.appendParameters(search);
        return api.get(`/v2/fatture/fattureH499?token=${store.getters.access_token}&${_search}`);
    }
});