<template>
    <div>
        <div v-if="this.$route.params.type === 'registro-presenze'">
            <ReportisticaPresenze />
        </div>
        <div v-if="this.$route.params.type === 'registro-perito'">
            <ReportisticaPerito />
        </div>
        <div v-if="this.$route.params.type === 'specifiche-da-fatturare'">
            <ReportisticaDaFatturare />
        </div>
        <div v-if="this.$route.params.type === 'registro-fatture'">
            <RegistroFatture />
        </div>
    </div>
</template>

<script>
import ReportisticaPresenze from './Reportistica/ReportisticaPresenze'
import ReportisticaPerito from './Reportistica/ReportisticaPerito'
import ReportisticaDaFatturare from './Reportistica/ReportisticaDaFatturare'
import RegistroFatture from './Reportistica/RegistroFatture'

export default {
    components: {
        ReportisticaPresenze,
        ReportisticaPerito,
        ReportisticaDaFatturare,
        RegistroFatture
    },
    data(){
        return {
            type: '',
            ispettore_id: ''
        }
    },
    mounted(){
        const user = this.$store.getters.user
        this.ispettore_id = user.role == 'admin' ? user.id : 0
        this.type = this.$route.params.type;
    }
}
</script>