import axios from "axios"
import store from "../store"
import router from "../router"
import Vue from "vue"

const baseURL = process.env.VUE_APP_API_URL
const versionKey = process.env.VUE_APP_VERSION_KEY

const instance =  axios.create({
    baseURL
});

instance.interceptors.response.use(function(response){
    if(response.headers.version){
        if(localStorage.getItem(versionKey)){
            if(response.headers.version !== localStorage.getItem(versionKey)){
                Vue.toasted.show('App aggiornata all\'ultima versione: '+response.headers.version).goAway(3000) 

                setTimeout(() => {
                    localStorage.setItem(versionKey, response.headers.version)
                    location.reload(true)     
                }, 3000);
            }
        }else{
            localStorage.setItem(versionKey, response.headers.version)
        }
    }

    if(response.status == 200){
        if(response.data && response.data.status == 'error' || 
            response.data && response.data.status == 'errors' ){

                if(response.data.status == 'error'){
                    Vue.toasted.error(response.data.message).goAway(3000)  
                }

                if(response.data.status == 'errors'){
                    var message = [];
                    for (const key in response.data.message) {
                        if (response.data.message.hasOwnProperty(key)) {
                            const element = response.data.message[key];
                            element.forEach(mess => {
                                message.push(mess)
                            });
                        }
                    }

                    Vue.toasted.error(message.join(',')).goAway(3000)  
                }

                return Promise.reject(response);
            }
    }

    return response
    
}, function (err) {
    switch (err.response.status) {
        case 400:

            var _data = err.response.data
            var _message = []
            if('message' in _data && typeof _data.message == 'object'){
                for (const key in _data.message) {
                    if (Object.hasOwnProperty.call(_data.message, key)) {
                        const element = _data.message[key];
                        element.forEach(mess => {
                            _message.push(mess)
                        });
                    }
                }
            }

            Vue.toasted.error(_message.join(',')).goAway(3000) 

            break;
        case 405:
            // if(err.response.data.state == 'profile_not_completed'){
            //     let user = err.response.data.user
            //     store.dispatch('updateUserObjState', user)

            //     if(user.role == 'candidato'){
            //         router.push({ path: '/completa-profilo' })
            //     }else if(user.role == 'admin' || user.role == 'ufficio'){
            //         router.push({ path: '/reset-password' })
            //     }
            // }
            break;
        case 404:
            router.push({path:'/404'})
            break
        case 402:
            if(router.currentRoute.name !== 'dashboard'){
                router.push({path:'/dashboard'})
            }
            Vue.toasted.error(err.response.data.message).goAway(3000) 
            break
        case 401:
            store.dispatch('updateAccessTokenState', null);
            store.dispatch('updateLogginState', false)

            if(router.currentRoute.name !== 'login'){
                router.push({path:'/login'})
            }

            Vue.toasted.error(err.response.data.message).goAway(3000)  
            break;
        case 452:
            
            Vue.toasted.error(err.response.data.message).goAway(3000)  
            router.push({path:'/conferma-profilo'})
            
            break
        default:
            break;
    }

    return Promise.reject(err);
  });

export default instance