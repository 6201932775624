<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Specifiche per Perito <small>Lista delle specifiche inserite per la campagna corrente per perito.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <label style="margin-bottom:10px;" class="form-material">Filtro per perito</label>
                                    <SelettoreRicerca
                                        type="all"
                                        placeholder="Seleziona perito"
                                        :selectedModel="perito_selected"
                                        @updateSelector="updateSelector"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <label style="margin-bottom:10px;" class="form-material">Filtro per data</label>
                                    <date-range-picker
                                        style="width: 100%;"
                                        ref="picker"
                                        :locale-data="localeRange"
                                        :autoApply="true"
                                        :ranges="false"
                                        v-model="dateRange"
                                        @select="dayClicked"
                                    >
                                        <template v-slot:input="picker" style="width:100%;">
                                            <span v-if="picker.startDate">{{ picker.startDate | toEuropeData }} - {{ picker.endDate | toEuropeData }}</span>
                                            <span v-else>Seleziona Range di ricerca</span>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </div>
                            <div v-if="dateRange.startDate" style="margin-top: 20px;" class="row">
                                <div class="col-sm-12 col-md-12">
                                    <button @click="resetAll" type="button" class="btn btn-sm btn-primary" title="Edit Perizia">
                                        Reset Filtro
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="specifiche.length == 0">Nessuna Specifica</h3>
                    <div v-if="specifiche.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="specifiche.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>

                                <ThTable @updateOrder="orderBy" orderKey="data_specifica" name="DATA" :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">COD. PERITO</th>
                                <th class="d-none d-sm-table-cell">COGNOME</th>
                                <ThTable @updateOrder="orderBy" orderKey="onorario" name="ONORARIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="vitto" name="VITTO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="alloggio" name="ALLOGGIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="km_totali" name="KM TOTALI" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="fatturata" name="STATO. FATT." :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">DETTAGLI</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(specifica, index) in specifiche" :key="index">
                                <td class="font-size-sm">
                                    {{specifica.data_specifica | toEuropeData}}
                                </td>
                                <td class="font-size-sm">
                                    {{specifica.perito.codice_perito}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.perito.surname}} {{specifica.perito.name}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.onorario}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.vitto}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.alloggio}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.km_residenza + specifica.km_perizia}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="specifica.fatturata === 0" class="label label-danger ng-scope">non fatturata</span>
                                    <span v-else class="label label-success ng-scope">fatturata</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="infoCertificato(specifica)" type="button" class="btn btn-sm btn-light" title="Info Specifica">
                                            <i class="fas fa-search"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="specifiche.length > 0" class="col-sm-12 col-md-12">
                        <button @click="printRegister()" type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Stampa Registro">
                            Stampa Registro
                        </button>
                    </div>
                    <div v-if="data.last_page > 1" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="prev"
                            :next-text="next"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                </div>
            </div>
        </div>

        <ModalSpecifica ref="modal_specifica"
            :print="true" 
            :ispettori_int="ispettori"
            :consorzi_int="consorzi"
            :agenzie_int="agenzie"
            :specifica="selected_specifica"
            :isModalVisible="isModalInfoVisible" 
            @closeModalCallback="close_modal_callback" />

    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ModalSpecifica from '../../../components/Specifiche/ModalSpecifica'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import UtilityRepository from "../../../services/apis/utility"
import SpecificaRepository from "../../../services/apis/specifica"
import Utility from '../../../services/utility'
import Config from '../../../services/config'
import ThTable from '../../../components/ThTable'
import SelettoreRicerca from '../../../components/SelettoreRicerca'

export default {
    components: {
        SelettoreRicerca,
        ThTable,
        Paginate,
        ModalSpecifica,
        DateRangePicker
    },
    data(){
        return {
            dateRange:{},
            ispettore_id: 0,
            isModalInfoVisible: false,
            data: {
                last_page:1
            },
            specifiche: [],
            certificato: null,
            periti:{
                list:[],
                found:[]
            },
            ispettori:[],
            consorzi:[],
            agenzie:[],
            selected_specifica:null,
            perito_selected:null,
            search: {
                perito_id: null,
                per_page: 10,
                page: 1,
                orderBy:'data_specifica:desc'
            },
        }
    },
    computed: {
        localeRange(){
            return Utility.dateRangeLocale()
        },
        prev(){
            return Config.lang.prev
        },
        next(){
            return Config.lang.next
        }
    },
    watch: {
        'search.perito_id': function (){
            this.get_specifiche();
        },
        'perito_selected': function(item){
            if(item){
                this.search.perito_id = item.id
            }else{
                this.search.perito_id = null
            }
        }
    },
    methods: {
        updateSelector(_, item){
            this.perito_selected = item
        },
        async get_specifiche(){

            if(!this.search.perito_id){
                this.specifiche = []
                this.data = {
                    last_page:1
                }
                return
            }

            if(this.dateRange.startDate && this.dateRange.endDate){
                this.search.start_date = Utility.convertDataToDB(this.dateRange.startDate)
                this.search.end_date = Utility.convertDataToDB(this.dateRange.endDate)
            }else{
                delete this.search.start_date
                delete this.search.end_date
            }

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await SpecificaRepository.specifiche_perito(this.search).catch(()=>{})
            
            if(_resp){
                if(_resp.data.data.data !== null && _resp.data.data.data !== undefined)
                    this.specifiche = _resp.data.data.data
                    this.data = _resp.data.data
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        async searchPerito(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_utenti(search).catch(()=>{})

                if(_resp)
                    this.periti.found = _resp.data.data;
                
            } 
        },
        perPage(){
            this.search.page = 1
            this.get_specifiche()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_specifiche()
        },
        dayClicked(data){
            this.dateRange = data
            this.get_specifiche()
        },
        infoCertificato(cert){
            this.selected_specifica = cert
            this.isModalInfoVisible = true
        },
        close_modal_callback(){
            this.isModalInfoVisible = false
            this.selected_specifica = null
        },
        peritiListText(item){
            return `${item.codice_perito} - ${item.name} ${item.surname}`
        },
        resetAll() {
            this.search.per_page = 10
            this.search.page = 1 

            for (const key in this.dateRange) {
                if (this.dateRange.hasOwnProperty(key)) {
                    this.dateRange[key] = null;
                }
            }
            this.get_specifiche()
        },
        async get_lista_ispettori(){
            let _resp = await SpecificaRepository.get_lista_ispettori().catch(() => {})

            if(_resp){
                this.ispettori = _resp.data.data
            }
            
        },
        async get_consorzi(){
            let _resp = await UtilityRepository.search_consorzi('').catch(()=> {})
            
            if(_resp){
                this.consorzi = _resp.data.data
            }
        },
        async get_agenzie(){
            let _resp = await UtilityRepository.search_agenzie('').catch(()=> {})

            if(_resp){
                this.agenzie = _resp.data.data
            }
        },
         async printRegister(){
            const obj = {
                specifiche_id:this.specifiche.map(spec => spec.id),
                start_date:Utility.convertDataToEuropeanFormat(this.search.start_date),
                end_date:Utility.convertDataToEuropeanFormat(this.search.end_date),
                perito_id: this.search.perito_id
            }

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await SpecificaRepository.print_specifiche_presenze(obj)

            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download ='registro_presenze.pdf' ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_specifiche()
        }
    },
    mounted(){
        this.get_lista_ispettori()
        this.get_consorzi()
        this.get_agenzie()
        this.get_specifiche()
    }
}
</script>
<style>
    span.label {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }
    span.label-danger {
        background-color: #d26a5c;
    }
    span.label-success {
        background-color: #60d25c;
    }
</style>