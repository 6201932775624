<template>
    <form class="js-validation-reminder">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Nome</label>
                    <input v-model="user.name" disabled type="text" class="form-control" name="name">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Cognome</label>
                    <input v-model="user.surname" disabled type="text" class="form-control" name="surname">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="example-text-input">Email</label>
                    <input v-model="user.email" disabled type="text" class="form-control" name="email">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="example-text-input">Grado</label>
                    <input v-model="user.grade_attuale[0].name" disabled type="text" class="form-control" name="email">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-text-input">Codice Fiscale</label>
                    <input v-model="user.invoice.codice_fiscale" disabled type="text" class="form-control" name="codice_fiscale" placeholder="Inserisci codice fiscale">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-text-input">Soggetto Fiscale</label>
                    <input v-model="user.invoice.soggetto_fiscale" disabled type="text" class="form-control" name="soggetto_fiscale" placeholder="Inserisci soggetto fiscale">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-text-input">Partita Iva</label>
                    <input v-model="user.invoice.partita_iva" disabled type="text" class="form-control" name="partita_iva" placeholder="Inserisci partita iva">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="example-text-input">Indirizzo</label>
                    <input v-model="user.invoice.indirizzo" disabled type="text" class="form-control" name="indirizzo" placeholder="Inserisci indirizzo">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-text-input">Cap</label>
                    <input v-model="user.invoice.cap" disabled type="text" class="form-control" name="cap" placeholder="Inserisci cap">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-text-input">Comune</label>
                    <input v-model="user.invoice.comune" disabled type="text" class="form-control" name="comune" placeholder="Inserisci comune">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-text-input">Provincia</label>
                    <input v-model="user.invoice.provincia" disabled type="text" class="form-control" name="provincia" placeholder="Inserisci provincia">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Telefono</label>
                    <input v-model="user.telephone_number" disabled type="text" class="form-control" name="telefono" placeholder="Inserisci telefono">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Contributo Previdenziale</label>
                    <select v-model="user.invoice.contributo_previdenziale" disabled class="form-control" name="contributo_previdenziale">
                        <option value>Seleziona Contributo Previdenziale</option>
                        <option v-for="(contributo_previdenziale, index) in invoice_data.contributo_previdenziale" :key="index" :value="contributo_previdenziale.value">{{contributo_previdenziale.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Contributo Integrativo</label>
                    <select v-model="user.invoice.contributo_integrativo" disabled class="form-control" name="contributo_integrativo">
                        <option value>Seleziona Contributo Integrativo</option>
                        <option v-for="(contributo_integrativo, index) in invoice_data.contributo_integrativo" :key="index" :value="contributo_integrativo.value">{{contributo_integrativo.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Ritenuta d’acconto in deduzione</label>
                    <select v-model="user.invoice.ritenuta_deduzione" disabled class="form-control" name="ritenuta_deduzione">
                        <option value>Seleziona Ritenuta d’acconto in deduzione</option>
                        <option v-for="(ritenuta_deduzione, index) in invoice_data.ritenuta_deduzione" :key="index" :value="ritenuta_deduzione.value">{{ritenuta_deduzione.name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Aliquota IVA</label>
                    <select v-model="user.invoice.aliquota_iva" disabled class="form-control" name="aliquota_iva">
                        <option value>Seleziona Aliquota Iva</option>
                        <option v-for="(aliquota_iva, index) in invoice_data.aliquota_iva" :key="index" :value="aliquota_iva.value">{{aliquota_iva.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="user.invoice.aliquota_iva == 0">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="example-text-input">Dicitura da riportare in fattura</label>
                    <select v-model="user.invoice.altro_regime_fiscale" disabled class="form-control" name="dicitura_fattura">
                        <option value>Seleziona Dicitura da riportare in fattura</option>
                        <option v-for="(altro_regime_fiscale, index) in invoice_data.altro_regime_fiscale" :key="index" :value="altro_regime_fiscale.value">{{altro_regime_fiscale.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="row" v-if="user.invoice.dicitura_fattura == 5">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="example-text-input">Riporta la dicitura</label>
                    <input v-model="user.invoice.altro_regime_fiscale" disabled type="text" class="form-control" name="altro_regime_fiscale" placeholder="Inserisci altro regime fiscale">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">Nome della banca</label>
                    <input v-model="user.invoice.nome_banca" disabled type="text" class="form-control" name="nome_banca" placeholder="Inserisci nome della banca">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="example-text-input">IBAN</label>
                    <input v-model="user.invoice.iban" disabled type="text" class="form-control" name="iban" placeholder="Inserisci IBAN">
                </div>
            </div>
        </div>
    </form>
</template>

<script>
export default {
    props:['global_user'],
    data(){
        return {
            user: {
                name:'',
                surname:'',
                grade_attuale:[{name:''}],
                invoice: {
                    password:'',
                    repeat_password:'',
                    codice_fiscale: '',
                    soggetto_fiscale:'',
                    partita_iva:'',
                    indirizzo:'',
                    cap:'',
                    comune:'',
                    provincia:'',
                    telefono:'',
                    contributo_previdenziale:'',
                    contributo_integrativo:'',
                    ritenuta_deduzione:'',
                    aliquota_iva:'',
                    dicitura_fattura:'',
                    altro_regime_fiscale:'',
                    nome_banca:'',
                    iban:''
                }
            },
            invoice_data: {
                contributo_previdenziale:[
                    {value:0, name:'0%'},
                    {value:4, name:'4%'}
                ],
                contributo_integrativo:[
                    {value:0, name:'0%'},
                    {value:2, name:'2%'},
                    {value:4, name:'4%'},
                    {value:5, name:'5%'}
                ],
                ritenuta_deduzione:[
                    {value:0, name:'0%'},
                    {value:20, name:'20%'}
                ],
                aliquota_iva:[
                    {value:0, name:'0%'},
                    {value:22, name:'22%'}
                ],
                altro_regime_fiscale:[
                    {value:1, name:'Operazione effettuata ai sensi dell’art. 1, commi da 54 a 89 della Legge n. 190/2014 – Regime forfettario'},
                    {value:2, name:'Operazione fuori campo IVA per mancanza del presupposto soggettivo o oggettivo (Art. 2, 3, 4 e 5 DPR 633/1972), territoriale (Art. da 7 a 7-septies DPR 633/1972)'},
                    {value:3, name:'Operazione esente da IVA (Art. 10 DPR 633/1972)'},
                    {value:4, name:'Compenso non assogettato a ritenuta d\'acconto ai sensi dell\'art. 27 del D.L. 98 del 06/07/2011 Operazione effettuata da soggetto in regime fiscale di vantaggio di cui all\'art. 27 commi 1 e 2 D.L. 98/2011'},
                    {value:5, name:'Altro regime fiscale'}
                ]
            }
        }
    },
    watch: {
        'global_user': function(newVal) {
          this.user = newVal
          if(this.user.grade_attuale.length == 0){
              this.user.grade_attuale.push({name:''})
          }
        }
    }
}
</script>