<template>
    <ValidationObserver ref="form" v-slot="{ invalid }">
        <form class="js-validation-reminder">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-select">Grado Utente</label>
                            <select v-model="obj.grade_id" disabled class="form-control" name="grade">
                                <option value>Seleziona Grado Utente</option>
                                <option v-for="grade in grades" :key="grade.id" :value="grade.id">{{grade.name}}</option>
                            </select>
                    </div>
                </div>
            </div>
             <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="example-text-input">Telefono</label>
                        <ValidationProvider rules="required" name="telephone_number" v-slot="{ errors }">
                            <input v-model="obj.telephone_number" :disabled="!isEdit" type="text" class="form-control" name="telephone_number" placeholder="Inserisci telephone_number">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-text-input">Codice Fiscale</label>
                        <ValidationProvider rules="required" name="codice_fiscale" v-slot="{ errors }">
                            <input v-model="obj.codice_fiscale" :disabled="!isEdit" type="text" class="form-control" name="codice_fiscale" placeholder="Inserisci codice fiscale">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-text-input">Soggetto Fiscale</label>
                        <ValidationProvider rules="required" name="soggetto_fiscale" v-slot="{ errors }">
                            <input v-model="obj.soggetto_fiscale" :disabled="!isEdit" type="text" class="form-control" name="soggetto_fiscale" placeholder="Inserisci soggetto fiscale">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-text-input">Partita Iva</label>
                        <ValidationProvider rules="required" name="partita_iva" v-slot="{ errors }">
                            <input v-model="obj.partita_iva" :disabled="!isEdit" type="text" class="form-control" name="partita_iva" placeholder="Inserisci partita iva">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Indirizzo</label>
                        <ValidationProvider rules="required" name="indirizzo" v-slot="{ errors }">
                            <input v-model="obj.indirizzo" :disabled="!isEdit" type="text" class="form-control" name="indirizzo" placeholder="Inserisci indirizzo">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-text-input">Cap</label>
                        <ValidationProvider rules="required" name="cap" v-slot="{ errors }">
                            <input v-model="obj.cap" :disabled="!isEdit" type="text" class="form-control" name="cap" placeholder="Inserisci cap">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-text-input">Comune</label>
                        <ValidationProvider rules="required" name="comune" v-slot="{ errors }">
                            <input v-model="obj.comune" :disabled="!isEdit" type="text" class="form-control" name="comune" placeholder="Inserisci comune">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group">
                        <label for="example-text-input">Provincia</label>
                        <ValidationProvider rules="required" name="partita_iva" v-slot="{ errors }">
                            <input v-model="obj.provincia" :disabled="!isEdit" type="text" class="form-control" name="provincia" placeholder="Inserisci provincia">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="example-text-input">Contributo Previdenziale</label>
                        <ValidationProvider rules="required" name="contributo_previdenziale" v-slot="{ errors }">
                            <select v-model="obj.contributo_previdenziale" :disabled="!isEdit" class="form-control" name="contributo_previdenziale">
                                <option value>Seleziona Contributo Previdenziale</option>
                                <option v-for="(contributo_previdenziale, index) in invoice_data.contributo_previdenziale" :key="index" :value="contributo_previdenziale.value">{{contributo_previdenziale.name}}</option>
                            </select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="example-text-input">Contributo Integrativo</label>
                        <ValidationProvider rules="required" name="contributo_integrativo" v-slot="{ errors }">
                            <select v-model="obj.contributo_integrativo" :disabled="!isEdit" class="form-control" name="contributo_integrativo">
                                <option value>Seleziona Contributo Integrativo</option>
                                <option v-for="(contributo_integrativo, index) in invoice_data.contributo_integrativo" :key="index" :value="contributo_integrativo.value">{{contributo_integrativo.name}}</option>
                            </select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="example-text-input">Ritenuta d’acconto in deduzione</label>
                        <ValidationProvider rules="required" name="ritenuta_deduzione" v-slot="{ errors }">
                            <select v-model="obj.ritenuta_deduzione" :disabled="!isEdit" class="form-control" name="ritenuta_deduzione">
                                <option value>Seleziona Ritenuta d’acconto in deduzione</option>
                                <option v-for="(ritenuta_deduzione, index) in invoice_data.ritenuta_deduzione" :key="index" :value="ritenuta_deduzione.value">{{ritenuta_deduzione.name}}</option>
                            </select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="example-text-input">Aliquota IVA</label>
                        <ValidationProvider rules="required" name="aliquota_iva" v-slot="{ errors }">
                            <select v-model="obj.aliquota_iva" :disabled="!isEdit" class="form-control" name="aliquota_iva">
                                <option value>Seleziona Aliquota Iva</option>
                                <option v-for="(aliquota_iva, index) in invoice_data.aliquota_iva" :key="index" :value="aliquota_iva.value">{{aliquota_iva.name}}</option>
                            </select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row" v-if="obj.aliquota_iva == 0">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Dicitura da riportare in fattura</label>
                        <ValidationProvider rules="required" name="altro_regime_fiscale" v-slot="{ errors }">
                            <select v-model="obj.altro_regime_fiscale" :disabled="!isEdit" class="form-control" name="altro_regime_fiscale">
                                <option value>Seleziona Dicitura da riportare in fattura</option>
                                <option v-for="(altro_regime_fiscale, index) in invoice_data.altro_regime_fiscale" :key="index" :value="altro_regime_fiscale.value">{{altro_regime_fiscale.name}}</option>
                            </select>
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row" v-if="obj.altro_regime_fiscale == 5">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Riporta la dicitura</label>
                        <ValidationProvider rules="required" name="dicitura_fattura" v-slot="{ errors }">
                            <input v-model="obj.dicitura_fattura" :disabled="!isEdit" type="text" class="form-control" name="dicitura_fattura" placeholder="Inserisci altro regime fiscale">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="example-text-input">Nome della banca</label>
                        <ValidationProvider rules="required" name="nome_banca" v-slot="{ errors }">
                            <input v-model="obj.nome_banca" :disabled="!isEdit" type="text" class="form-control" name="nome_banca" placeholder="Inserisci nome della banca">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="example-text-input">IBAN</label>
                        <ValidationProvider rules="required" name="iban" v-slot="{ errors }">
                            <input v-model="obj.iban" :disabled="!isEdit" type="text" class="form-control" name="iban" placeholder="Inserisci IBAN">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="form-group row">
                <div class="col-md-12 col-xl-12">
                    <button :disabled="invalid" v-on:click.prevent="conferma_dati" type="submit" class="btn btn-block btn-primary">
                        <i class="fa fa-fw fa-envelope mr-1"></i> Modifica Dati
                    </button>
                </div>
            </div>
        </form>
    </ValidationObserver>
</template>

<script>
import UserRepository from "../../services/apis/user"

export default {
    props:['isEdit', 'user', 'grades'],
    data(){
        return {
           obj: {
                codice_fiscale: '',
                soggetto_fiscale:'',
                partita_iva:'',
                indirizzo:'',
                cap:'',
                comune:'',
                provincia:'',
                telephone_number:'',
                contributo_previdenziale:'',
                contributo_integrativo:'',
                ritenuta_deduzione:'',
                aliquota_iva:'',
                dicitura_fattura:'',
                altro_regime_fiscale:'',
                nome_banca:'',
                grade_id:'',
                iban:''
           },
           invoice_data: {
                contributo_previdenziale:[
                    {value:0, name:'0%'},
                    {value:4, name:'4%'}
                ],
                contributo_integrativo:[
                    {value:0, name:'0%'},
                    {value:2, name:'2%'},
                    {value:4, name:'4%'},
                    {value:5, name:'5%'}
                ],
                ritenuta_deduzione:[
                    {value:0, name:'0%'},
                    {value:20, name:'20%'}
                ],
                aliquota_iva:[
                    {value:0, name:'0%'},
                    {value:22, name:'22%'}
                ],
                altro_regime_fiscale:[
                    {value:1, name:'Operazione effettuata ai sensi dell’art. 1, commi da 54 a 89 della Legge n. 190/2014 – Regime forfettario'},
                    {value:2, name:'Operazione fuori campo IVA per mancanza del presupposto soggettivo o oggettivo (Art. 2, 3, 4 e 5 DPR 633/1972), territoriale (Art. da 7 a 7-septies DPR 633/1972)'},
                    {value:3, name:'Operazione esente da IVA (Art. 10 DPR 633/1972)'},
                    {value:4, name:'Compenso non assogettato a ritenuta d\'acconto ai sensi dell\'art. 27 del D.L. 98 del 06/07/2011 Operazione effettuata da soggetto in regime fiscale di vantaggio di cui all\'art. 27 commi 1 e 2 D.L. 98/2011'},
                    {value:5, name:'Altro regime fiscale'}
                ]
           }

        }
    },
    watch: {
        'isEdit': function(newVal) {
            if(!newVal){
                this.$refs.form.reset()
                let _user = JSON.parse( JSON.stringify(  this.user ) ) 
                this.obj = _user.invoice
                this.obj.telephone_number = _user.telephone_number
                this.obj.grade_id = _user.grade_attuale[0].id
            }
        },
        'user': function(newVal){
            if(newVal){
                let _user = JSON.parse( JSON.stringify(  newVal ) ) 
                this.obj = _user.invoice
                this.obj.telephone_number = _user.telephone_number
                this.obj.grade_id = _user.grade_attuale[0].id
            }
        }
    },
    methods: {
        async conferma_dati(){
            this.$store.dispatch('updateLoadingState', true)

            const _resp = await UserRepository.update_user_invoice_data(this.obj).catch(() => {})
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
            }

            this.$store.dispatch('updateLoadingState', false)
            this.$emit('callbackReload');
        }
    }
}
</script>