<template>
    <div id="page-container">

        <!-- Main Container -->
        <main id="main-container">

            <!-- Page Content -->
            <div class="bg-image" style="background-image: url('assets/media/photos/photo6@2x.jpg');">
                <div class="hero-static bg-white-95">
                    <div class="content">
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-lg-6 col-xl-4">
                                <!-- Reminder Block -->
                                <div class="block block-themed block-fx-shadow mb-0">
                                    <div class="block-header">
                                        <h3 class="block-title">RESET PASSWORD</h3>
                                        <router-link to="/login">
                                            <div class="block-options">
                                                <a class="btn-block-option" title="Sign In">
                                                    <i class="fa fa-sign-in-alt"></i>
                                                </a>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div class="block-content">
                                        <div class="p-sm-3 px-lg-4 py-lg-5">
                                            <h1 class="mb-2">MyARA 1857</h1>

                                            <div v-if="isTokenValid === 'false'">
                                                <h3>Il token non è valido, prova a rifare la richiesta di reset o contatta l'amministrazione.</h3>
                                            </div>

                                            <div  v-if="isTokenValid === 'true'">
                                                <p>Inserisci la tua nuova password.</p>

                                                <ValidationObserver ref="observer" v-slot="{ invalid }">
                                                    <form class="js-validation-reminder" action="be_pages_auth_all.html" method="POST">
                                                        <div class="form-group">
                                                            <label for="example-text-input">Password</label>
                                                            <ValidationProvider rules="required|min:6" name="password" v-slot="{ errors }">
                                                                <input v-model="password" type="password" class="form-control" name="password" placeholder="Password">
                                                                <span class="validation-error">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="form-group">
                                                            <label for="example-text-input">Ripeti Password</label>
                                                            <ValidationProvider rules="required|min:6|confirmed:password" name="Ripeti Password" v-slot="{ errors }">
                                                                <input v-model="repeat_password" type="password" class="form-control" name="repeat_password" placeholder="Ripeti Password">
                                                                <span class="validation-error">{{ errors[0] }}</span>
                                                            </ValidationProvider>
                                                        </div>
                                                        <div class="form-group row">
                                                            <div class="col-md-6">
                                                                <button :disabled="invalid" v-on:click.prevent="recovery_password" type="submit" class="btn btn-block btn-primary">
                                                                    <i class="fa fa-fw fa-envelope mr-1"></i> Cambia Password
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </ValidationObserver>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                                <!-- END Reminder Block -->
                            </div>
                        </div>
                    </div>
                    <Copyright />
                </div>
            </div>
            <!-- END Page Content -->

        </main>
        <!-- END Main Container -->
    </div>
</template>

<script>
import Copyright from '../components/Copyright'
import AuthenticationRepository from "../services/apis/authentication"

export default {
    data(){
        return {
            password:'',
            repeat_password:'',
            token:'',
            isTokenValid: 'wait'
        }
    },
    components: {
        Copyright
    },
    methods: {
        async recovery_password(){
            this.$store.dispatch('updateLoadingState', true)

            let obj = {
                "password":this.password,
                "token":this.token,
            }

            let new_password = await AuthenticationRepository.reset_password(obj).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)

            if(new_password){
                this.$toasted.success(new_password.data.message).goAway(3000)
                this.$router.push({ path: '/login' })
            }

        }
    },
    async created(){

        let _valid = await AuthenticationRepository.validate_recovery_password(this.$route.params.token).catch(() => {
            this.isTokenValid = 'false'
        })

        if(_valid){
            this.token = this.$route.params.token
            this.isTokenValid = 'true'
        }        
    }
}
</script>