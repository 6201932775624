<template>
    <input :id="isId" :value="value" type="text" @blur="onBlur($event.target.value)" class="form-control date" placeholder="Scegli data" />
</template>

<script>
import "bootstrap-datepicker"
import Cleave from 'cleave.js'
import Utility from "../services/utility"
export default {
    props:["value", "isId"],
    data(){
        return {
            isValid:false
        }
    },
    methods: {
        //@input="updateValue($event.target.value)"
        onBlur(value){
            var dateReg = /^\d{2}([./-])\d{2}\1\d{4}$/
            if(value.match(dateReg)){
                const data = this.$moment(value, 'DD-MM-YYYY')
                if(data.isValid()){
                    this.$emit('input', data.format('DD-MM-YYYY'));
                }else{
                    this.$emit('input', null);
                }
            }else{
                this.$emit('input', null);
            }
        },
        
    },
    mounted(){
        var _this = this
        this.$nextTick(function () {
            window.$.fn.datepicker.dates['it'] = {
                days: ["Domenica", "Lunedì", "Martedì", "Mercoledì", "Giovedì", "Venerdì", "Sabato"],
                daysShort: ["Dom", "Lun", "Mar", "Mer", "Gio", "Ven", "Sab"],
                daysMin: ["Do", "Lu", "Ma", "Me", "Gi", "Ve", "Sa"],
                months: ["Gennaio", "Febbraio", "Marzo", "Aprile", "Maggio", "Giugno", "Luglio", "Agosto", "Settembre", "Ottobre", "Novembre", "Dicembre"],
                monthsShort: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
                today: "Oggi",
                monthsTitle: "Mesi",
                clear: "Cancella",
                weekStart: 1,
                format: "dd/mm/yyyy"
            };
            
            window.$(`#${this.isId}`).datepicker({
                format:'dd-mm-yyyy',
                language:'it',
                endDate:'0d'
            }).on('changeDate', function(e) {
                // console.log(e);
                // console.log(e.target);
                // console.log(e.date);
                
                _this.$emit('input', _this.$moment(e.date).format('DD-MM-YYYY'));
                window.$(this).datepicker('hide');
            });
            
            if(this.value){
                window.$(`#${this.isId}`).datepicker('update', Utility.convertDataToEuropeanFormat(this.value))
            }

            new Cleave(`#${this.isId}`, {
                date: true,
                delimiter: '-',
                datePattern: ['d', 'm', 'Y']
            });
        })
    }
}
</script>

<style scope>
    .table-condensed {
        text-align: center;
    }
    .disabled.day{
        color: lightgray;
    }
    .active.day {
        color: white;
    }
    .day {
        cursor: pointer;
    }
</style>