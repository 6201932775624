<template>
    <th @click="changeOrder" :class="`text-center ${!order ? 'sorting' : `sorting_${order}`} `" :style="`cursor:pointer;${width ? `width: ${width}%` : ''}`">
        {{name}}
    </th>
</template>

<script>
export default {
    props:['orderKey', 'name', 'width', 'orderBy'],
    data() {
        return{
            order:null
        }
    },
    watch:{
        "orderBy":function(v){
            this.orderFunc(v)
        }
    },
    methods: {
        changeOrder(){
            this.$emit('updateOrder', `${this.orderKey}:${!this.order ? 'desc' : this.order == 'desc' ? 'asc' : 'desc'}`)
        },
        orderFunc(v){
            if(!v) {
                return
            }
            const _order = v.split(':')
            if(_order.length == 2){
                if(_order[0] == this.orderKey){
                    this.order = _order[1]
                }else{
                    this.order = null
                }
            }
        }
    },
    created(){
        this.orderFunc(this.orderBy)
    }
}
</script>