<template>
    <div>
    <h4>Modifica Password</h4>
    <ValidationObserver ref="form" v-slot="{ invalid }">
        <form class="js-validation-reminder">
            <div v-if="isEdit" class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Password Attuale</label>
                        <ValidationProvider rules="required|min:6" name="old_password" v-slot="{ errors }">
                            <input v-model="obj.old_password" :disabled="!isEdit" type="password" class="form-control" name="old_password" placeholder="Inserisci la password attuale">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Nuova Password</label>
                        <ValidationProvider rules="required|min:6" name="nuova_password" v-slot="{ errors }">
                            <input v-model="obj.nuova_password" :disabled="!isEdit" type="password" class="form-control" name="nuova_password" placeholder="Inserisci nuova password">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label for="example-text-input">Ripeti Nuova Password</label>
                        <ValidationProvider rules="required|min:6|confirmed:nuova_password" name="password_confirmation" v-slot="{ errors }">
                            <input v-model="obj.password_confirmation" :disabled="!isEdit" type="password" class="form-control" name="password_confirmation" placeholder="Ripeti nuova password">
                            <span class="validation-error">{{ errors[0] }}</span>
                        </ValidationProvider>
                    </div>
                </div>
            </div>
            <div v-if="isEdit" class="form-group row">
                <div class="col-md-12 col-xl-12">
                    <button :disabled="invalid" v-on:click.prevent="modifica_password" type="submit" class="btn btn-block btn-primary">
                        <i class="fa fa-fw fa-envelope mr-1"></i> Modifica Password
                    </button>
                </div>
            </div>
        </form>
    </ValidationObserver>
    </div>
</template>

<script>
import UserRepository from "../../services/apis/user"

export default {
    props:['isEdit'],
    data(){
        return {
            obj: {
                old_password: '',
                password_confirmation:'',
                nuova_password:''
            }
        }
    },
    watch: {
        'isEdit': function(newVal) {
            if(!newVal){
                this.$refs.form.reset()
                this.obj = {
                    old_password: '',
                    password_confirmation:'',
                    nuova_password:''
                }
            }
        }
    },
    methods: {
        async modifica_password(){
            this.$store.dispatch('updateLoadingState', true)

            let obj = this._.cloneDeep(this.obj)
            obj.password = obj.password_confirmation

            const _resp = await UserRepository.update_user_password(obj).catch(() => {})
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
            }

            this.$store.dispatch('updateLoadingState', false)
            this.$emit('callbackReload');
        }
    }
}
</script>