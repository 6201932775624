<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Archivio Fatture <small>Lista delle fatture utente.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="fatture.length == 0">Nessuna fattura presente</h3>
                    <div v-if="fatture.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="fatture.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="data_fattura" name="DATA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="n_fattura" name="N° FATTURA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="imp_netto" name="IMPORTO NETTO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="totale_fattura" name="TOTALE FATTURA" :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">SCARICA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(fattura, index) in fatture" :key="index">
                                <td class="font-size-sm">
                                    {{data_fattura(fattura.data_fattura)}}
                                </td>
                                <td class="font-size-sm">
                                    {{fattura.n_fattura}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{fattura.imp_netto}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{fattura.totale_fattura}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    
                                    <div class="btn-group">
                                        <button @click="downloadFattura(fattura.link_fattura)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="File PDF">
                                            <i class="fas fa-file-pdf"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="search.last_page > 1" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="'Precedente'"
                            :next-text="'Successivo'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ThTable from '../../components/ThTable'
import FatturaRepository from "../../services/apis/fattura"

export default {
    components: {
        ThTable, Paginate
    },
    data(){
        return {
            fatture:[],
            data: {
                last_page:1
            },
            search: {
                per_page: 10,
                page:1,
                orderBy:'data_fattura:desc'
            },
        }
    },
    methods: {
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_fatture()
        },
        data_fattura(data){
            return data ? this.$moment(data).format('DD-MM-YYYY') : ''
        },
        async get_fatture(){
            const _resp = await FatturaRepository.get_mie_fatture(this.search).catch(() => {})

            if(_resp){
                this.data = _resp.data.data
                this.fatture = _resp.data.data.data
            }
        },
        async downloadFattura(link){
            const _resp = await FatturaRepository.download_fattura(link).catch(() => {})
            
            if(_resp){
                
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download = link;
            
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }


        },

        perPage(){
            this.search.page = 1
            this.get_fatture()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_fatture()
        },
    },
    created(){
        this.get_fatture()
    }
}
</script>