<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Report Varietà <small>Report per varietà.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Provincia</label>
                                    <model-list-select
                                        id="search-select"
                                        :list="provincia.found"
                                        option-value="id"
                                        option-text="short"
                                        v-model="search.provincia_id"
                                        placeholder="Seleziona"
                                        @searchchange="searchProvincia"
                                        >
                                    </model-list-select>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Comune</label>
                                    <model-list-select
                                        :isDisabled="!search.provincia_id"
                                        id="search-select"
                                        :list="comune.found"
                                        option-value="id"
                                        option-text="nome"
                                        v-model="search.comune_id"
                                        placeholder="Seleziona"
                                        @searchchange="searchComune"
                                        >
                                    </model-list-select>
                                </div>
                            </div>    
                        </div>    
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-material">
                                    <label class="form-material">Seleziona VARIETA (max 9)</label>
                                    <MultiselectCMOON
                                        :lists="varietas.found"
                                        :selected_lists="chips"
                                        :placeholder="'Seleziona Varietà'"
                                        @searchChange="searchVarieta"
                                        @selectedTag="addChip"
                                        @removeTag="onRemove" 
                                    >
                                    </MultiselectCMOON>
                                </div>
                                <div class="form-material" style="margin-top:20px">
                                    <button @click="print('pdf')" type="submit" class="btn btn-sm btn-primary" title="Crea Pdf" style="margin-right: 10px;" >Crea Pdf</button>
                                    <button @click="print('csv')" type="submit" class="btn btn-sm btn-primary" title="Crea Csv" >Crea Csv</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="certificati.length == 0">Nessuna certificato corrispondente</h3>
                    <div v-if="certificati.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="certificati.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="text-center">NUMERO ORDINE</th>
                                <th class="d-none d-sm-table-cell">NUMERO CERTIFICATO</th>
                                <th class="d-none d-sm-table-cell">AGENZIA</th>
                                <th class="d-none d-sm-table-cell">CONSORZIO</th>
                                <th class="d-none d-sm-table-cell">SPECIE</th>
                                <th class="d-none d-sm-table-cell">COMUNE</th>
                                <th class="d-none d-sm-table-cell">DENOMINAZIONE CERT.</th>
                                <th class="d-none d-sm-table-cell">NR PARTITE.</th>
                                <th style="text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(certificato, index) in certificati" :key="index">
                                <td class="font-size-sm">
                                    {{certificato.ordine_id}}
                                </td>
                                <td class="font-size-sm">
                                    {{certificato.certificato}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.agenzia.codice_identificativo}} - {{certificato.agenzia.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{certificato.consorzio.codice}} - {{certificato.consorzio.name}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.specie.etichetta}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{certificato.comune.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.denominazione}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.partite.length}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="infoCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Info Certificato">
                                            <i class="fas fa-search"></i>
                                        </button>
                                       <button @click="openHailbox(certificato.ordine_id)" type="button" class="btn btn-sm btn-light" title="Open Hailbox">
                                            <i class="fas fa-cloud"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="certificati.length > 0" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                </div>
            </div>
        </div>

        <ModalInfoCert 
            :isModalVisible="isModalInfoVisible" 
            ref="modal_info_cert" 
            :certificato="this.certificato"
            :ispettore_id="this.search.ispettore_id" 
            :certificato_id="this.certificato.id"
            :readOnly="true"
            :denunceNoAction="true"
            @closeModalCallback="close_info_cert_modal">
        </ModalInfoCert>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import MultiselectCMOON from '../../components/MultiselectCMOON'
import { ModelListSelect } from 'vue-search-select'
import ModalInfoCert from "../../components/Certificato/ModalInfoCert"
import 'vue-search-select/dist/VueSearchSelect.css'
import Utility from '../../services/utility'
import UtilityRepository from "../../services/apis/utility"
import CertificatoRepository from "../../services/apis/certificato"
export default {
    components: {
        Paginate,   
        ModalInfoCert,
        MultiselectCMOON,
        ModelListSelect
    },
    data(){
        return {
            isModalInfoVisible: false,
            certificati:[],
            provincia:{
                list:[],
                found:[]
            },
            comune:{
                list:[],
                provincia_list:[],
                found:[]
            },
            data: {
                last_page:1
            },
            search: {
                ispettore_id: 0,
                per_page: 10,
                page: 1,
                varieta : '',
                provincia_id:'',
                comune_id:''
            },
            varietas:{
                list:[],
                found:[]
            },
            certificato: {
                ispettore_id:null,
                certificato:null,
                agenzia_id:'',
                denominazione:'',
                provincia_id:'',
                comune_id:'',
                data_carico:'',
                data_notifica:'',
                franchigia:10,
                soglia:true,
                provvisorio:false,
                ordini:[],
                agevolata:false,
                note:''
            },
            chips: []
        }
    },
    watch: {
        search:{
            handler: function (){
                if(this.varietas.list !== undefined && this.varietas.list.length > 0)
                    this.get_certificati();
            },
            deep: true
        },
        '$route.params.ispettore_id': function (id) {        
            this.search.ispettore_id = id
            this.certificati = []
            this.chips = []
            this.search = {
                ispettore_id: 0,
                per_page: 10,
                page: 1,
                varieta : ''
            }
        },
        'search.provincia_id' (item){
            this.search.comune_id = ''
            this.filterComuneByIdProvincia(item)
        }
    },
    methods: {
        async get_certificati(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await CertificatoRepository.report_varieta(this.search).catch(()=>{})

            if(_resp && _resp !== undefined){
                this.certificati = _resp.data.data.data
                this.data = _resp.data.data

            }
            else this.certificati = [];
            this.$store.dispatch('updateLoadingState', false);
        },
        async searchVarieta(text){
            if(text != null && text != undefined && text != '') {
                var search = {
                    ispettore_id: this.search.ispettore_id,
                    cerca: text
                }

                const _resp = await UtilityRepository.search_varieta(search).catch(()=>{})

                _resp.data.data.unshift({
                    id: -1,
                    value: -1,
                    text: `Cerca: - ${text}`,
                    specie_id: -1,
                    codice_identificativo: "",
                    codice_varieta: "9999",
                    etichetta: `Cerca: - ${text}`,
                    created_at: "2019-05-25 09:02:53",
                    updated_at: "2019-05-25 09:02:53"
                });
                
                if(_resp)
                    this.varietas.found = _resp.data.data.map((el) => {
                        if(el.id !== -1)
                            el.name = `${el.codice_identificativo} - ${el.etichetta} (${el.specie.codice_identificativo} - ${el.specie.etichetta})`
                        else
                            el.name = `${el.etichetta}`
                        return el;
                    });

                this.varietas.list = [];

                for (let i=0; i<this.varietas.found.length; i++) {
                    if(i !== 0)
                        this.varietas.list.push(this.varietas.found[i].id);
                }
                
            }
        },
        perPage(){
            this.search.page = 1
            this.get_certificati()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_certificati()
        },
        goTo(name){
            this.$router.push({ name: name })
        },
        infoCertificato(cert){
            this.certificato = cert
            this.isModalInfoVisible = true
        },
        close_info_cert_modal(){
            this.isModalInfoVisible = false
        },
        async print(type){
            this.search.type = type;
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await UtilityRepository.print_report_varieta(this.search).catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                if(byteArray.length < 100){
                    const string = Utility.Utf8ArrayToStr(byteArray)
                    const json = JSON.parse(string)
                   if(json.status == "error"){  
                        this.$toasted.error(json.message).goAway(3000)
                   }else{
                        this.$toasted.success(json.message).goAway(3000)
                   }
                }else{
                    let a = window.document.createElement('a');

                    a.href = window.URL.createObjectURL(
                        new Blob([byteArray], { type: 'application/octet-stream' })
                    );

                    if(type == 'csv'){
                        a.download ='report_varieta_csv.csv' ;
                    }else{
                        a.download ='report_varieta_pdf.pdf' ;
                    }

                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        addChip(value) {
            if(this.chips.length < 9) {
                this.chips.push(value);

                var temp = [];

                this.chips.forEach(el => {
                    if(el.id !== -1)
                        temp.push(el.id);
                    else {
                        temp = temp.concat(this.varietas.list);
                    }
                });

                if(temp.length > 0)
                    this.search.varieta = temp.join(",");
            }

            this.varietas.found = []
        },
        onRemove(value){
            this.chips = this.chips.filter(el => el.id !== value.id)
            
            var temp = [];

            this.chips.forEach(el => {
                if(el.id !== -1)
                    temp.push(el.id);
                else {
                    temp = temp.concat(this.varietas.list);
                }
            });

            if(temp.length > 0){
                this.search.varieta = temp.join(",");
                this.get_certificati();
            }else{
                this.certificati = [];
            }
            
        },
        searchComune(text){
            if(text !== undefined){
                const _c = this.comune.provincia_list.filter((item) => {
                    return item.nome.toLowerCase().includes(text.toLowerCase())
                })

                this.comune.found = _c
            }else{
                this.comune.found = []
            }
            
        },
        searchProvincia(text){
             if(text !== undefined){
                const _c = this.provincia.list.filter((item) => {
                    return item.short.toLowerCase().includes(text.toLowerCase())
                })
                
                this.provincia.found = _c
             }else{
                this.provincia.found = []
             }

        },
        filterComuneByIdProvincia(id){            
            const _c = this.comune.list.filter((item) => {
                return item.provincia_id == id
            })

            this.comune.provincia_list = _c
            this.comune.found = _c
        },
         async get_variables(){
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await UtilityRepository.get_variables({provincia:1, comune:1})
            
            if(_resp){   
                this.provincia.list = _resp.data.data.provincia   
                this.comune.list = _resp.data.data.comune   
            }

            this.$store.dispatch('updateLoadingState', false)
        },
        openHailbox(ordine_id){
            const user = this.$store.getters.user
            /*if(user.role == "ispettore"){
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/check-token-service/${ordine_id}?t=${user.service_token}`,'_blank');
            }else{
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/check-token-service/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}`,'_blank');
            }*/
            if(user.role == "ispettore"){
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&source=myara`,'_blank');
            }else{
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&i=${this.search.ispettore_id}&source=myara`,'_blank');
            }
        },
    },
    mounted(){
        this.get_variables()
        this.search.ispettore_id = this.$route.params.ispettore_id
    }
}
</script>