<template>
    <div id="page-container">

        <!-- Main Container -->
        <main id="main-container">

            <!-- Page Content -->
            <div class="bg-image">
                <div class="hero-static bg-white-95">
                    <div class="content">
                        <div class="row justify-content-center">
                            <div class="col-md-8 col-lg-6 col-xl-4">
                                <!-- Reminder Block -->
                                <div style="padding-bottom:30px;" class="block block-themed block-fx-shadow mb-0">
                                    <div class="block-header">
                                        <h3 class="block-title">ATTIVAZIONE UTENTE</h3>
                                        <router-link to="/login">
                                            <div class="block-options">
                                                <a class="btn-block-option" title="Sign In">
                                                    <i class="fa fa-sign-in-alt"></i>
                                                </a>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div v-if="isTokenValid === 'true'" class="block-content">
                                        <h2>Utente attivato correttamente.</h2>
                                        <router-link to="/login">
                                            <button class="btn btn-primary btn-block">Login</button>
                                        </router-link>
                                    </div>
                                    <div v-if="isTokenValid === 'false'" class="block-content">
                                        <h2>Token non valido</h2>
                                        <router-link to="/login">
                                            <button class="btn btn-primary btn-block">Login</button>
                                        </router-link>
                                    </div>
                                </div>
                                <!-- END Reminder Block -->
                            </div>
                        </div>
                    </div>
                    <Copyright />
                </div>
            </div>
            <!-- END Page Content -->

        </main>
        <!-- END Main Container -->
    </div>
</template>

<script>
import Copyright from "../components/Copyright"
import AuthenticationRepository from "../services/apis/authentication"

export default {
    data(){
        return {
            isTokenValid:'wait'
        }
    },
    components: {
        Copyright
    },
    methods: {
        async verificationToken(token){
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await AuthenticationRepository.email_verification(token).catch(()=>{})
            
            if(_resp){
                this.isTokenValid = 'true'
            }

            this.$store.dispatch('updateLoadingState', false)
        }
    },
    mounted(){
        this.verificationToken(this.$route.params.token)
    }    
}
</script>