<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">DATI SPECIFICA</h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <ValidationObserver ref="form">
                <form>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Scegli Data</label>
                                <ValidationProvider rules="required|is_valid_date|is_after_today" name="data_specifica" v-slot="{ errors }">
                                    <DatePicker isId="data_specifica_disabled" disabled v-model="data_specifica" />
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Ispettore per l'approvazione</label>
                                <ValidationProvider rules="required" name="ispettore" v-slot="{ errors }">
                                    <select disabled v-model="specifica.ispettore_id" class="form-control" name="ispettore">
                                        <option value>Seleziona Ispettore</option>
                                        <option v-for="ispettore in ispettori_int" :key="ispettore.id" :value="ispettore.id" >
                                            {{ispettore.name}} {{ispettore.surname}}
                                        </option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Onorario</label>
                                <ValidationProvider rules="required" name="onorario" v-slot="{ errors }">
                                    <select disabled v-model="specifica.onorario" class="form-control" name="onorario">
                                        <option value>Seleziona</option>
                                        <option value="0">0</option>
                                        <option value="0.5">0,5</option>
                                        <option value="1">1</option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Vitto</label>
                                <ValidationProvider rules="required" name="vitto" v-slot="{ errors }">
                                    <select disabled v-model="specifica.vitto" class="form-control" name="vitto">
                                        <option value>Seleziona</option>
                                        <option value="0">0</option>
                                        <option value="0.5">0,5</option>
                                        <option value="1">1</option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Alloggio</label>
                                <ValidationProvider rules="required" name="alloggio" v-slot="{ errors }">
                                    <select disabled v-model="specifica.alloggio" class="form-control" name="alloggio">
                                        <option value>Seleziona</option>
                                        <option value="0">0</option>
                                        <option value="1">1</option>
                                    </select>
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Km percorsi trasferimento dal domicilio - sede perizia</label>
                                <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="km_residenza" v-slot="{ errors }">
                                    <input disabled v-model="specifica.km_residenza" type="number" class="form-control" name="km_residenza" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Pedaggi (€)</label>
                                <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="pedaggi" v-slot="{ errors }">
                                    <input disabled v-model="specifica.pedaggi" type="number" class="form-control" name="pedaggi" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Km percorsi per svolgimento perizie</label>
                                <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="km_perizia" v-slot="{ errors }">
                                    <input disabled v-model="specifica.km_perizia" type="number" class="form-control" name="km_perizia" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Telefono (€)</label>
                                <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="telefono" v-slot="{ errors }">
                                    <input disabled v-model="specifica.telefono" type="number" class="form-control" name="telefono" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-select">Varie (€)</label>
                                <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="varie" v-slot="{ errors }">
                                    <input disabled v-model="specifica.varie" type="number" class="form-control" name="varie" placeholder="">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-select">Descrizione percorso effettuato</label>
                                <textarea disabled v-model="specifica.desc_percorso_effettuato" name="desc_percorso_effettuato" id="" cols="30" rows="5" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-select">Annotazioni</label>
                                <textarea disabled v-model="specifica.annotazioni" name="annotazioni" id="" cols="30" rows="5" class="form-control"></textarea>
                            </div>
                        </div>
                    </div>
                </form>
                </ValidationObserver>
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title">PERIZIE CONNESSE ALLA SPECIFICA</h3>
                </div>
                <div class="block-content block-content-full">
                    <h3 v-if="specifica.perizie.length == 0">Nessuna perizia presente</h3>
                    <table v-if="specifica.perizie.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="text-center" style="width: 15%">CERTIFICATO</th>
                                <th style="width: 15%">AGENZIA</th>
                                <th class="d-none d-sm-table-cell" style="width: 15%;">CONSORZIO</th>
                                <th class="d-none d-sm-table-cell" style="width: 15%;">TIPO</th>
                                <th class="d-none d-sm-table-cell" style="width: 15%;">AUTO</th>
                                <th>NOTE</th>
                                <th>PERITI</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(perizia, index) in specifica.perizie" v-bind:key="index">
                                <td class="font-size-sm">
                                    <span v-if="perizia.certificato">{{perizia.certificato}}</span>
                                    <span v-else><span class="badge badge-info">non disponibile</span></span>
                                </td>
                                <td class="font-size-sm">
                                    <span v-if="perizia.agenzia"> {{get_agenzia_object(perizia.agenzia)}}</span>
                                    <span v-else>Nessuna agenzia</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{get_consorzio_object(perizia.consorzio_id)}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{perizia.tipo_perizia}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm" :class="`${perizia.useCar  ? 'si si-check text-success' : ''} `" >
                                    <div v-for="perito in perizia.periti" :key="perito.id">
                                        <div>
                                            <span v-if="perito.use_car == 1"><i style="color:green;" class="si si-check text-success"></i></span>
                                        </div>
                                    </div>
                                </td>
                                <td class="font-size-sm">
                                    {{trimText(perizia.note)}}
                                </td>
                                <td class="font-size-sm">
                                    <div v-for="perito in perizia.periti" :key="perito.id">
                                        <div>
                                            {{perito.name}} {{perito.surname}} - {{perito.codice_perito}}
                                            <span v-if="perito.use_car == 1"><i style="color:green;" class="fa fa-fw fa-car-alt"></i></span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button v-if="print"
                type="button"
                class="btn btn-primary"
                @click="printSpecifica"
                aria-label="Stampa">
                Stampa
            </button>
            <button
                type="button"
                class="btn btn-primary"
                @click="closeModal"
                aria-label="Chiudi">
                Chiudi
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"
import DatePicker from "../../components/DatePicker"
import Utility from "../../services/utility"
import SpecificaRepository from "../../services/apis/specifica"
export default {
    name: 'ModalSpecifica',
    props:['isModalVisible', 'specifica', 'ispettori_int', 'consorzi_int', 'print', 'agenzie_int'],
    components: {
        Modal, DatePicker
    },
    data(){
        return {}
    },
    computed: {
        data_specifica(){
             return this.specifica ? this.specifica.data_specifica : ''
        }
    },
    methods: {
        trimText(text){
            return Utility.trimText(text)
        },
        closeModal() {
            this.$emit('closeModalCallback', null)
        },
        get_consorzio_object(id){
            const _consorzio = this.consorzi_int.find((item) => item.id == id)
            return _consorzio ? `${_consorzio.codice} - ${_consorzio.name}` : id 
        },
        get_agenzia_object(id){
            const _agenzia = this.agenzie_int.find((item) => item.id == id)
            return _agenzia ? `${_agenzia.codice_identificativo} - ${_agenzia.nome}` : id 
        },
        async printSpecifica(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await SpecificaRepository.print_specifica(this.specifica.id)

            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download =`specifica_${this.specifica.id}.pdf` ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        }
    }
}
</script>