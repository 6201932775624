<template>
    <!-- Header -->
        <header id="page-header">
            <!-- Header Content -->
           
            <div  class="content-header">

                <!-- Left Section -->
                <div class="d-flex align-items-center">
                    <!-- END Toggle Mini Sidebar -->
                    <span v-if="env && env != 'production'" style="font-weight: bold;color: #f34b34;font-size: 18px;">PIATTAFORMA TEST</span>
                </div>
                <!-- END Left Section -->

                <!-- Right Section -->
                <div class="d-flex align-items-center">
                    <!-- User Dropdown -->
                    <div class="dropdown d-inline-block ml-2">
                        <button v-click-outside="hide" @click="toggleDropdown" type="button" class="btn btn-sm btn-dual" id="page-header-user-dropdown">
                            <img class="rounded" :src="avatar" alt="Header Avatar" style="width: 18px;">
                            <i class="fa fa-fw fa-angle-down d-none d-sm-inline-block"></i>
                        </button>
                        <div v-show="show" class="dropdown-menu dropdown-menu-right p-0 border-0 font-size-sm show" aria-labelledby="page-header-user-dropdown">
                            <div class="p-2">
                                <a @click="returnAdmin" class="dropdown-item d-flex align-items-center justify-content-between">
                                    <span>Torna Admin</span>
                                    <i class="si si-user ml-1"></i>
                                </a>
                                <a @click="logout" class="dropdown-item d-flex align-items-center justify-content-between">
                                    <span>Esci</span>
                                    <i class="si si-logout ml-1"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!-- END User Dropdown -->

                </div>
                <!-- END Right Section -->
            </div>
            <!-- END Header Content -->
        </header>
        <!-- END Header -->
</template>

<script>
import ClickOutside from 'vue-click-outside'
import AuthenticationRepository from "../services/apis/authentication"

export default {
    props:['isLogged'],
    data(){
        return {
            show: false,
            env:null,
            avatar: require('@/assets/images/avatar.jpg'),
        }
    },
    methods: {
        toggleDropdown(){
            this.show = this.show ? false : true
        },
        hide () {
            this.show = false
        },
        async logout(){
            this.$store.dispatch('logout');
        },
        async returnAdmin(){
            
            this.$store.dispatch('updateLoadingState', true)
            const token = localStorage.getItem(process.env.VUE_APP_ADMIN_TOKEN_STORAGE_NAME)

            if(token){
                this.$store.dispatch('updateAdminTokenState', null) 
                this.$store.dispatch('updateAccessTokenState', token) 

                let resp = await AuthenticationRepository.me().catch(() => {})
                if(resp){
                    const user = resp.data.user
                    user.service_token = resp.data.service_token
                    this.$store.dispatch('updateUserObjState', user)
                    this.$store.dispatch('updateChoiseState','specifiche')
                    localStorage.setItem('choise', 'specifiche')
                    this.$router.push({ name: 'controllo-utenti' })
                }
            }
        
            this.$store.dispatch('updateLoadingState', false)
        }
    },
    directives: {
        ClickOutside
    },
    mounted(){
        this.env = process.env.NODE_ENV 
        this.$nextTick(function () {
            window.$("#hamburger-menu").on('click',function() {
                window.$("#sidebar").addClass('menu');
            })

        })

        if(navigator.userAgentData.mobile) {
            this.callbackSidebarMini()
        }
    }
}
</script>

<style lang="css">
    #hamburger-menu {
        display: none;
    } 
    @media (max-width: 992px){
        #hamburger-menu {
            display: block!important;
        } 
    }
    
</style>