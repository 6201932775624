<template>
    <div class="bg-image">
        <div class="hero-static bg-white-95">
            <div class="content">
                <div class="row justify-content-center">
                    <div class="col-sm-8 col-sm-offset-2 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3">
                        <!-- Sign In Block -->
                        <div class="block block-themed block-fx-shadow mb-0">
                            <div class="block-header bg-success">
                                <h3 class="block-title">CONFERMA PROFILO</h3>
                                <div class="block-options">
                                    <a class="btn-block-option" @click="logout" data-toggle="tooltip" data-placement="left" title="Sign In">
                                        <i class="fa fa-sign-in-alt"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="block-content">
                                <div class="p-sm-3 px-lg-4 py-lg-5">
                                    <h1 class="mb-2">MyARA 1857</h1>
                                    <p>Compila tutti i campi per confermare {{completaProfilo ? 'il tuo profilo.' : 'la tua registrazione.'}}</p>

                                    <!-- Sign In Form -->
                                    <ValidationObserver ref="form" v-slot="{ invalid }">
                                        <form>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-nome"><small>Nome</small></label>
                                                        <input disabled="true" v-model="user.name" type="text" class="form-control" name="nome" placeholder="Inserisci nome">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-cognome"><small>Cognome</small></label>
                                                        <input disabled="true" v-model="user.surname" type="text" class="form-control" name="cognome" placeholder="Inserisci cognome">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-codice_perito"><small>Codice Perito</small></label>
                                                            <input disabled="true" v-model="user.codice_perito" type="text" class="form-control" name="codice_perito" placeholder="Inserisci codice perito">
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-grado"><small>Grado Utente dal 1 maggio {{thisYear}}</small></label>
                                                            <input disabled="true" v-model="user.grade" type="text" class="form-control" name="grado" placeholder="Inserisci grado utente">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="example-ltf-email"><small>Email</small></label>
                                                            <input disabled="true" v-model="user.email" type="text" class="form-control" name="email" placeholder="Inserisci la tua email">
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="!completaProfilo" class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-password"><small>Password</small></label>
                                                        <ValidationProvider rules="required" name="password" v-slot="{ errors }">
                                                            <input v-model="data.password" type="password" class="form-control" name="password" placeholder="Inserisci nuova password">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-password_conf"><small>Conferma Password</small></label>
                                                        <ValidationProvider :rules="'required|confirmed:password'" name="conferma password" v-slot="{ errors }">
                                                            <input v-model="data.password_conf" type="password" class="form-control" name="password_conf" placeholder="Conferma password">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="example-ltf-indirizzo"><small>Indirizzo</small></label>
                                                        <ValidationProvider rules="required" name="indirizzo" v-slot="{ errors }">
                                                            <input v-model="data.indirizzo" type="text" class="form-control" name="indirizzo" placeholder="Inserisci Indirizzo">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="example-ltf-soggetto_fiscale"><small>Soggetto Fiscale</small></label>
                                                        <ValidationProvider rules="required" name="soggetto_fiscale" v-slot="{ errors }">
                                                            <input v-model="data.soggetto_fiscale" type="text" class="form-control" name="soggetto_fiscale" placeholder="Inserisci Soggetto Fiscale">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-indirizzo"><small>Codice Fiscale</small></label>
                                                        <ValidationProvider rules="required" name="codice_fiscale" v-slot="{ errors }">
                                                            <input v-model="data.codice_fiscale" type="text" class="form-control" name="codice_fiscale" placeholder="Inserisci Codice Fiscale">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-indirizzo"><small>Partita Iva</small></label>
                                                        <ValidationProvider rules="required" name="partita_iva" v-slot="{ errors }">
                                                            <input v-model="data.partita_iva" type="text" class="form-control" name="partita_iva" placeholder="Inserisci Partita Iva">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-2">
                                                    <div class="form-group">
                                                        <label for="example-ltf-cap"><small>Cap</small></label>
                                                        <ValidationProvider rules="required" name="cap" v-slot="{ errors }">
                                                            <input v-model="data.cap" type="text" class="form-control" name="cap" placeholder="Cap">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label for="example-ltf-provincia"><small>Provincia</small></label>
                                                        <ValidationProvider rules="required" name="provincia" v-slot="{ errors }">
                                                        <model-list-select
                                                            id="search-select-provincia"
                                                            :list="provincia.found"
                                                            option-value="id"
                                                            :custom-text="provinceListText"
                                                            v-model="data.provincia"
                                                            name="provincia"
                                                            placeholder="Seleziona Provincia"
                                                            @searchchange="searchProvincia"
                                                        >
                                                        </model-list-select>
                                                         <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-md-5">
                                                    <div class="form-group">
                                                        <label for="example-ltf-comune"><small>Comune</small></label>
                                                        <ValidationProvider rules="required" name="comune" v-slot="{ errors }">
                                                            <model-list-select
                                                                :isDisabled="!data.provincia"
                                                                id="search-select-comune"
                                                                :list="comune.found"
                                                                option-value="id"
                                                                option-text="nome"
                                                                v-model="data.comune"
                                                                name="comune"
                                                                @searchchange="searchComune"
                                                                placeholder="Seleziona Comune"
                                                            >
                                                            </model-list-select>
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-telefono"><small>Telefono</small></label>
                                                        <ValidationProvider rules="required" name="telefono" v-slot="{ errors }">
                                                            <input v-model="data.telephone_number" type="text" class="form-control" name="telefono" placeholder="Inserisci telefono">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-contributo_prev"><small>Contributo Previdenziale</small></label>
                                                        <ValidationProvider rules="required" name="contributo previdenziale" v-slot="{ errors }">
                                                            <select v-model="data.contributo_previdenziale" class="form-control" name="contributo_prev">
                                                                <option value="">Seleziona Contributo Previdenziale</option>
                                                                <option value="0">0%</option>
                                                                <option value="4">4%</option>
                                                            </select>
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-contributo_inte"><small>Contributo Integrativo</small></label>
                                                        <ValidationProvider rules="required" name="contributo integrativo" v-slot="{ errors }">
                                                            <select v-model="data.contributo_integrativo" class="form-control" name="contributo_inte">
                                                                <option value="">Seleziona Contributo Integrativo</option>
                                                                <option value="0">0%</option>
                                                                <option value="2">2%</option>
                                                                <option value="4">4%</option>
                                                                <option value="5">5%</option>
                                                            </select>
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-ritenuta"><small>Ritenuta d'acconto in deduzione</small></label>
                                                        <ValidationProvider rules="required" name="ritenuta d'acconto" v-slot="{ errors }">
                                                            <select v-model="data.ritenuta_deduzione" class="form-control" name="ritenuta"> 
                                                                <option value="">Seleziona Ritenuta d'acconto in deduzione</option>
                                                                <option value="0">0%</option>
                                                                <option value="20">20%</option>
                                                            </select>
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="example-ltf-iva"><small>Aliquota IVA</small></label>
                                                        <ValidationProvider rules="required" name="aliquota IVA" v-slot="{ errors }">
                                                            <select v-model="data.aliquota_iva" class="form-control" name="iva">
                                                                <option value="">Seleziona Aliquota IVA</option>
                                                                <option value="0">0%</option>
                                                                <option value="22">22%</option>
                                                            </select>
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="data.aliquota_iva === '0' || data.aliquota_iva === 0" class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="example-ltf-dicitura_fattura"><small>Dicitura da riportare in fattura</small></label>
                                                        <ValidationProvider rules="required" name="dicitura fattura" v-slot="{ errors }">
                                                            <select v-model="data.altro_regime_fiscale" class="form-control" name="dicitura_fattura">
                                                                <option value="">Seleziona Dicitura da riportare in fattura</option>
                                                                <option value="1">Operazione effettuata ai sensi dell’art. 1, commi da 54 a 89 della Legge n. 190/2014 – Regime forfettario</option>
                                                                <option value="2">Operazione fuori campo IVA per mancanza del presupposto soggettivo o oggettivo (Art. 2, 3, 4 e 5 DPR 633/1972), territoriale (Art. da 7 a 7-septies DPR 633/1972)</option>
                                                                <option value="3">Operazione esente da IVA (Art. 10 DPR 633/1972)</option>
                                                                <option value="4">Compenso non assogettato a ritenuta d'acconto ai sensi dell'art. 27 del D.L. 98 del 06/07/2011 Operazione effettuata da soggetto in regime fiscale di vantaggio di cui all'art. 27 commi 1 e 2 D.L. 98/2011</option>
                                                                <option value="5">Altro regime fiscale</option>
                                                            </select>
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="data.altro_regime_fiscale == 5" class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="example-ltf-banca"><small>Riporta la dicitura</small></label>
                                                        <ValidationProvider rules="required" name="nome banca" v-slot="{ errors }">
                                                            <input v-model="data.dicitura_fattura" type="text" class="form-control" name="banca" placeholder="">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="example-ltf-banca"><small>Nome della Banca</small></label>
                                                        <ValidationProvider rules="required" name="nome banca" v-slot="{ errors }">
                                                            <input v-model="data.nome_banca" type="text" class="form-control" name="banca" placeholder="Inserisci il nome della Banca">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="example-ltf-iban"><small>IBAN</small></label>
                                                        <ValidationProvider rules="required" name="IBAN" v-slot="{ errors }">
                                                            <input v-model="data.iban" type="text" class="form-control" name="iban" placeholder="Inserisci l'IBAN">
                                                            <span class="validation-error">{{ errors[0] }}</span>
                                                        </ValidationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-md-12">
                                                    <button :disabled="invalid" v-on:click.prevent="signup" class="btn btn-block btn-success" type="submit">
                                                        Conferma {{completaProfilo ? 'Profilo' : 'Registrazione'}}<i class="fa fa-fw fa-plus ml-3"></i> 
                                                    </button>
                                                </div>
                                            </div> 
                                        </form>
                                    </ValidationObserver>
                                    <!-- END Sign In Form -->
                                </div>
                            </div>
                        </div>
                        <!-- END Sign In Block -->
                    </div>
                </div>
            </div>
            <Copyright />
        </div>
    </div>
</template>

<script>

import Copyright from '../components/Copyright'
import { ModelListSelect } from 'vue-search-select'
import UserRepository from "../services/apis/user";
import UtilityRepository from "../services/apis/utility";

export default {
    data(){
        return {
            completaProfilo:false,
            user: {},
            data: {
                contributo_previdenziale:"",
                contributo_integrativo:"",
                ritenuta_deduzione:"",
                aliquota_iva:"",
                altro_regime_fiscale:"",
            },
            provincia:{
                list:[],
                found:[]
            },
            comune:{
                list:[],
                provincia_list:[],
                found:[]
            },
            thisYear: new Date().getFullYear(),
        }
    },
    watch:{
        'data.provincia': function() {
            this.data.comune = ''
            this.comune.found = []
        }
    },
    components: {
        Copyright,
        ModelListSelect
    },
    methods: {
        logout(){
            this.$store.dispatch('logout')
        },
        async selectProvincia(provincia, comune){
            await this.searchProvincia(provincia)
            if(this.provincia.found.length == 0){
                return
            }

            this.data.provincia = this.provincia.found[0].id
            await this.searchComune(comune)
            
            if(this.comune.found.length == 0){
                return
            }

            this.data.comune = this.comune.found[0].id
        },
        async getUserData(){
            const resp = await UserRepository.get_user_data().catch(() => {})
            
            if(resp){
                const {user} = resp.data
                this.selectProvincia(user.invoice.provincia, user.invoice.comune)
                this.data = user.invoice
                this.data.telephone_number = user.telephone_number
            }
        },
        async signup(){
            
            let data = {}

            for (const key in this.data) {
                if (this.data.hasOwnProperty(key)) {
                    const element = this.data[key];
                    if(element != ''){
                        if(key == 'comune' || key == 'provincia'){
                            if(isNaN(element)){
                                data[key] = element
                            }else{
                                const v = this[key].found.find(f => f.id == element)
                                if(v){
                                    data[key] = v.nome
                                }
                            }
                        }else{
                            data[key] = element
                        }
                    }
                }
            }

            this.$store.dispatch('updateLoadingState', true)

            let _resp = null

            if(this.completaProfilo){
                _resp = await UserRepository.update_user_invoice_data(data).catch(() => {})
            }else{
                _resp = await UserRepository.add_user_invoice(data).catch(() => {})
            }

            if(_resp) {
                this.user.profile_complete = true
                this.$store.dispatch('updateUserObjState', this.user)  
                this.$toasted.success(_resp.data.message).goAway(3000)
                
                
                if(this.user.role != 'perito' && this.user.role != 'ispettore'){
                    this.$store.dispatch('updateChoiseState','specifiche')
                    this.$router.push({ name: 'dashboard'})
                }else {
                    this.$router.push({ name: 'scegli-sezione' })
                }
            }
            
            this.$store.dispatch('updateLoadingState', false)
        },
        async searchProvincia(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_province(search).catch(()=>{})

                if(_resp)
                    this.provincia.found = _resp.data.data;
            }
        },
        async searchComune(text){
            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_comuni(search).catch(()=>{})

                if(_resp){
                    this.comune.found = _resp.data.data.filter(comune => comune.provincia_id == this.data.provincia);
                }
            }
        },
        provinceListText(item){
            return `${item.short} - ${item.nome}`
        },
    },
    mounted(){
        if(this.$router.history.current.fullPath == '/conferma-profilo'){
            this.completaProfilo = true;
            this.getUserData()        
        }
        let user = this.$store.getters.user

        const current_year = process.env.VUE_APP_YEAR
        const grade_found = user.grades.find(grade => grade.anno == current_year)

        if(grade_found){
            user.grade = grade_found.name
        }

        this.user = user
    }
}
</script>