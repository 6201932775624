<template>
    <!-- DELETE MODAL CERT -->
    <div>
    <Modal v-if="isModalVisible">
        <template v-slot:header>
            <slot name="header-true">
                <h1 style="margin-bottom:0px;">
                    <span>DETTAGLI</span>
                </h1>
            </slot>
        </template>
        <template v-slot:body>
            <table class="table table-bordered table-striped table-vcenter js-dataTable-full">
                <thead>
                    <tr>
                        <th v-for="(key_, i) in csv_keys" :key="i" class="d-none d-sm-table-cell">
                            <span v-if="key_ == 'ordine_id'">CERTIFICATO</span>
                            <span v-if="key_ == 'partita_id'">PARTITA NR.</span>
                            <span v-if="key_ != 'partita_id' && key_ != 'ordine_id'">{{key_}}</span>
                        </th>
                        <th style="text-align:center;">#</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(csv_int, index) in csv" :key="index">
                        <td v-for="(key_, i) in csv_keys" :key="i" class="d-none d-sm-table-cell font-size-sm">
                            <span v-if="key_ == 'ordine_id'">{{csv_int.ordine}}</span>
                            <span v-if="key_ == 'partita_id'">{{csv_int.numero_partita}}</span>
                            <span v-if="key_ != 'partita_id' && key_ != 'ordine_id'">{{csv_int[key_]}}</span>
                        </td>
                        <td class="d-none d-sm-table-cell font-size-sm">
                            <div class="btn-group">
                                <button @click="goToOrder(csv_int)" type="button" class="btn btn-sm btn-light" title="Watch Certificato">
                                    <i class="fas fa-eye"></i>
                                </button>
                                <button v-if="csv_int.type == 'aggiornato'" @click="showListsHistories(csv_int)" type="button" class="btn btn-sm btn-light" title="Lists Histories">
                                    <i class="fas fa-list"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            
        </template>
        
    </Modal>
    <ModalCsvHistories 
            ref="csv_lists"
            :isModalVisible="isModalVisibleCsv" 
            @closeModalCallback="closeCsvListModal">
        </ModalCsvHistories>
    </div>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "./Modal"
import ModalCsvHistories from "./ModalCsvHistories"
export default {
    name:'ModalDettagliCsv',
    props:['isModalVisible', 'csv_keys', 'csv'],
    components: {
        Modal, ModalCsvHistories
    },
    data(){
        return {
            isModalVisibleCsv:false
        }
    },
    methods: {
        goToOrder(csv){
            this.$router.push({name:'modifica-certificato', params:{ispettore_id:csv.ispettore_id, certificato_id:csv.ordine_id}})
        },
        showListsHistories(csv){
            //ordine,partita,denuncia
            let type = 'ordine'
            let resource_number = csv.ordine_id
            if(csv.denuncia_id){
                type = 'denuncia'
                resource_number = csv.denuncia_id
            }else if(csv.partita_id){
                type = 'partita'
                resource_number = csv.partita_id
            }

            const obj = {
                user_id:csv.ispettore_id,
                type:type,
                resource_number:resource_number
            }
            this.$refs.csv_lists.initModal(obj)
            this.isModalVisibleCsv = true
            
        },
        closeModal(){
            this.$emit('closeModalCallback')
        },
        closeCsvListModal(){
            this.isModalVisibleCsv = false
        }
    }
}
</script>