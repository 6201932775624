<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Cambio Password <small>Creazione link per il cambio della password.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals pb-5">
                    <div class="block-content">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <label class="form-material">Seleziona Utente</label>
                                    <SelettoreRicerca
                                        type="all"
                                        placeholder="Seleziona un Utente"
                                        voidText="Nessun utente trovato"
                                        :selectedModel="search.ispettore"
                                        @updateSelector="updateSelector"
                                    />
                                </div>
                            </div>
                        </form>
                        <div v-if="search.ispettore && !link" class="row">
                            <div class="col-md-12 mt-3">
                                <div class="form-group">
                                    <button  v-on:click.prevent="generaLink" type="submit" class="btn btn-primary">Genera Link</button>
                                </div>
                            </div>
                        </div>
                        <div v-if="link" class="row">
                            <div class="col-md-12 mt-3">
                                <div class="input-group mb-3">
                                    <button class="btn btn-primary" @click="copy" type="button" id="inputGroupFileAddon03">Copia Link</button>
                                    <input v-on:focus="$event.target.select()" 
                                        ref="clone" 
                                        readonly 
                                        :value="link" id="inputGroupFile03" type="text" class="form-control form-control">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'vue-search-select/dist/VueSearchSelect.css'
    import SelettoreRicerca from "../../components/SelettoreRicerca";
    import AdminRepository from "../../services/apis/admin";

    export default {
        components: {
            SelettoreRicerca,
        },
        data(){
            return {
                search: {
                    ispettore: null
                },
                link: null
            }
        },
        methods: {
            updateSelector(_, item){
                this.link = null;
                this.search.ispettore = item
            },
            async generaLink(){
                this.$store.dispatch('updateLoadingState', true);
                
                const _resp = await AdminRepository.recovery_password({email:this.search?.ispettore?.email})
                if(_resp){
                    this.link = _resp?.data?.link;
                }

                this.$store.dispatch('updateLoadingState', false);
            },
            copy() {
                this.$refs.clone.focus();
                document.execCommand('copy');
            }
        },
        mounted(){
            const user = this.$store.getters.user
            this.ispettore_id = user.role == 'admin' ? user.id : 0
        }
    }
</script>