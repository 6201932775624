<template>
    <select style="width:150px" v-model="inputDataLocal" @change="change" name="DataTables_Table_0_length" class="form-control form-control-sm">
        <option value="1">Seleziona pagina</option>
        <option v-for="(page, index) in returnArrayofPages" :key="index" :value="page">{{page}}</option>
    </select>
</template>

<script>
export default {
    props: {
        lastPage:{
            type:Number,
            default:0,
            required:true
        },
        inputData:{
            type:Number,
            required:true
        },
    },
    model: {
        prop: 'inputData',
        event: 'inputDataChange'
    },
    computed:{
        inputDataLocal: {
            get: function() {
                return this.returnArrayofPages.includes(this.inputData) ? this.inputData : 1
            },
            set: function(value) {
                this.$emit('inputDataChange', parseInt(value))
            }
        },
        returnArrayofPages(){
            let pages = []
            
            for (let i = 1; i < this.lastPage; i++) {
                if((5*i) % this.lastPage && (5*i) <= this.lastPage){
                    pages.push(i*5)
                }
            }

            return pages
        }
    },
    methods: {
        change(e){
            this.$emit('changeCallback', e.target.value)
        }
    }
}
</script>

<style>

</style>