<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span v-if="!denuncia.id">NUOVA DENUNCIA</span>
                <span v-else>MODIFICA DENUNCIA</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <ValidationObserver ref="form_denuncia">
                <form style="min-height: 300px;">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-text-input">Data</label>
                                <ValidationProvider rules="required|is_valid_date" name="data" v-slot="{ errors }">
                                    <DatePicker :disabled="only_view" isId="data" v-model="denuncia.data" />
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-text-input">Numero Danno</label>
                                <ValidationProvider rules="required" name="n_danno" v-slot="{ errors }">
                                    <input :disabled="only_view" v-model="denuncia.n_danno" type="text" class="form-control" name="n_danno" placeholder="Numero Danno">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                    <label for="example-text-input">Evento</label>
                                    <ValidationProvider rules="required" name="evento_id" v-slot="{ errors }">
                                        <model-list-select
                                            id="search-select"
                                            :list="eventi"
                                            :isDisabled="only_view"
                                            option-value="id"
                                            option-text="nome"
                                            v-model="denuncia.evento_id"
                                            placeholder="Seleziona"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </template>
        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                v-if="!only_view && canPerito('full|edit')"
                @click="saveDenuncia"
                :disabled="!is_form_valid"
                type="button"
                class="btn btn-primary"
                aria-label="Nuova">
                <span v-if="!denuncia.id">Salva</span>
                <span v-else>Modifica</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import Modal from "../Modal"
import DatePicker from "../../components/DatePicker"
import CertificatoRepository from "../../services/apis/certificato"
import Utility from "../../services/utility"

export default {
    name:'ModalEditDenunce',
    props:['isModalVisible', 'eventi', 'ispettore_id', 'ispettorePermission'],
    components: {
        Modal, DatePicker, ModelListSelect
    },
    data(){
        return {
            denuncia:{},
            is_form_valid:false,
            ordine_id:null,
            partita_ids:[],
            only_view:false
        }
    },
    watch:{
        denuncia:{
            handler(){
                this.form_is_valid()
            },
            deep:true
        }
    },
    methods: {
        canPerito(permissions){
            return Utility.canPerito(permissions, this.ispettorePermission)
        },
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        async form_is_valid(){

            if(this.$refs.form_denuncia){
                let success = await this.$refs.form_denuncia.validate()                
                this.is_form_valid = success
                return
            }
            
            this.is_form_valid = false
        },
        addDenuncia(obj, ordine_id){
            this.ordine_id = ordine_id
            this.partita_ids = obj.partita_ids
            
            if(obj.denuncia){
                this.only_view = obj.only_view
                this.denuncia = this._.cloneDeep(obj.denuncia, true)
            }else{
                this.denuncia = {}
            }
        },
        async saveDenuncia(){

            this.$store.dispatch('updateLoadingState', true)

            var obj = {
                ordine_id: this.ordine_id,
                partita_ids: this.partita_ids,
                evento_id:this.denuncia.evento_id,
                n_danno:this.denuncia.n_danno,
                ispettore_id: this.ispettore_id,
                data: this.$moment(this.denuncia.data, 'DD-MM-YYYY').format('YYYY-MM-DD') 
            }

            let _resp = null

            if('id' in this.denuncia){
                obj.denuncia_id = this.denuncia.id
                _resp = await CertificatoRepository.edit_denuncia(obj).catch(() => {})
            }else{
                _resp = await CertificatoRepository.new_denuncia(obj).catch(() => {})
            }

            this.$store.dispatch('updateLoadingState', false)

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.$emit('closeModalCallback', 'refresh')
            }
        }
    }
}
</script>