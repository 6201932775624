<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Report carico scarico
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                    <div v-if="updated_at" class="form-material">
                        <button @click="print('pdf')" type="submit" class="btn btn-sm btn-primary" title="Crea Pdf" style="margin-right: 10px;" >Crea Pdf</button>
                        <button @click="print('csv')" type="submit" class="btn btn-sm btn-primary" title="Crea Csv" >Crea Csv</button>
                    </div>
                    <div class="form-material">
                        <button @click="reload()" type="submit" class="btn btn-sm btn-primary" title="Aggiorna i dati" >Aggiorna i dati</button>
                    </div>
                </div>
                <div v-if="updated_at" class="block-header">
                    <div class="form-material">
                        <div style="font-size:14px"><b>Ultimo aggiornamento {{formatted_updated_at}}</b></div>
                    </div>
                </div>
            </div>
            <div style="overflow: auto;">
                <h3 v-if="data == null">Download in corso...</h3>
                <h3 v-if="data !== null && data.length == 0">Nessun dato presente</h3>
                <table v-if="data !== null && data.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full dataTable">
                    <thead>
                        <tr>
                            <th class="d-none d-sm-table-cell th-report-carico text-center" @click="orderchange('COMUNE','')" :class="`text-center ${column_cat !='COMUNE' ? 'sorting' : `sorting_${order_column_cat}`} `" >
                                <small>COMUNE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('carico','')" :class="`text-center ${column_cat !='carico' ? 'sorting' : `sorting_${order_column_cat}`} `" >
                                <small>CARICO</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('evase','')" :class="`text-center ${column_cat !='evase' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>EVASE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('periziate','')" :class="`text-center ${column_cat !='periziate' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>PERIZIATE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('tot_bollettini_bozza','')" :class="`text-center ${column_cat !='tot_bollettini_bozza' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>VIDIMATI</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('residue','')" :class="`text-center ${column_cat !='residue' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>RESIDUE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('AGRUMI','')" :class="`text-center ${column_cat !='AGRUMI' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>AGRUMI</small>
                            </th>
                            
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('ALTRI PRODOTTI','')" :class="`text-center ${column_cat !='ALTRI PRODOTTI' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>ALTRI PRODOTTI</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('BIOMASSE','')" :class="`text-center ${column_cat !='BIOMASSE' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>BIOMASSE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('CEREALI','')" :class="`text-center ${column_cat !='CEREALI' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>CEREALI</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('CUCURBITACEE','')" :class="`text-center ${column_cat !='CUCURBITACEE' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>CUCURBITACEE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('FORAGGERE','')" :class="`text-center ${column_cat !='FORAGGERE' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>FORAGGERE</small>
                            </th>
                            
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('FRUTTA','')" :class="`text-center ${column_cat !='FRUTTA' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>FRUTTA</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('FRUTTA A GUSCIO','')" :class="`text-center ${column_cat !='FRUTTA A GUSCIO' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>FRUTTA A GUSCIO</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('INDUSTRIALI','')" :class="`text-center ${column_cat !='INDUSTRIALI' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>IMPIANTI</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('LEGUMINOSE','')" :class="`text-center ${column_cat !='LEGUMINOSE' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>LEGUMINOSE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('OLEAGINOSE','')" :class="`text-center ${column_cat !='OLEAGINOSE' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>OLEAGINOSE</small>
                            </th>
                            
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('ORTICOLE','')" :class="`text-center ${column_cat !='ORTICOLE' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>ORTICOLE</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('POMODORO','')" :class="`text-center ${column_cat !='POMODORO' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>POMODORO</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('RADICI E TUBERI','')" :class="`text-center ${column_cat !='RADICI E TUBERI' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>RISO</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('SPECIALE','')" :class="`text-center ${column_cat !='SPECIALE' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>SPECIALI</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('TABACCO','')" :class="`text-center ${column_cat !='TABACCO' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>TABACCO</small>
                            </th>
                            
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('UVA','')" :class="`text-center ${column_cat !='UVA' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>UVA</small>
                            </th>
                            <th class="d-none d-sm-table-cell th-report-carico" @click="orderchange('VIVAI','')" :class="`text-center ${column_cat !='VIVAI' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                <small>VIVAI</small>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(dt, index) in data" :key="index">
                            <td class="font-size-sm" style="text-align: start;">
                                <small>{{dt.COMUNE}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm gray-cell">
                                <small>{{dt.carico}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm gray-cell">
                                <small>{{dt.evase}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm gray-cell">
                                <small>{{dt.periziate}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm gray-cell">
                                <small>{{dt.tot_bollettini_bozza}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm gray-cell">
                                <small>{{dt.residue}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.AGRUMI}}</small>
                            </td>

                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt['ALTRI PRODOTTI']}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.BIOMASSE}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.CEREALI}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.CUCURBITACEE}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.FORAGGERE}}</small>
                            </td>

                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.FRUTTA}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt['FRUTTA A GUSCIO']}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.IMPIANTI}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.LEGUMINOSE}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.OLEAGINOSE}}</small>
                            </td>

                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.ORTICOLE}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.POMODORO}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.RISO}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.SPECIALI}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.TABACCO}}</small>
                            </td>
                            
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.UVA}}</small>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <small>{{dt.VIVAI}}</small>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import Utility from '../../services/utility'
import UtilityRepository from "../../services/apis/utility"
import CertificatoRepository from "../../services/apis/certificato"

export default {
    data(){
        return {
            search: {
                ispettore_id: 0,
            },
            column_cat:'',
            order_column_cat:'',
            data: null,
            updated_at:null
        }
    },
    computed: {
        formatted_updated_at: function() {
            return this.updated_at ? this.$moment(this.updated_at).format('DD-MM-YYYY HH:mm'): this.updated_at
        }
    },
    watch: {
        '$route.params.ispettore_id': function (id) {        
            this.search.ispettore_id = id
            this.get_carico_scarico()
        }
    },
    methods: {
        orderchange(orderCat, order,extra){ 
            if (this.column_cat == orderCat){
                if (this.order_column_cat == 'asc'){
                    this.order_column_cat='desc';
                    order = 'desc'
                }else{
                    order = 'asc'
                    this.order_column_cat='asc';
                }
            }else{
                this.column_cat=orderCat;
                this.order_column_cat='asc';
                order = 'asc'
            }
            let index = this.data.map(e => e.COMUNE).indexOf('Totale')
            let totale = {...this.data[index]}
            this.data.splice(index,1)
            //this.data.splice(this.data.map(e => e.COMUNE).indexOf('Totale'),1)   
             if(order == 'asc'){
                 //this.certificati = _.orderBy(this.certificati, [orderBy], ['asc'])
                 if (extra) {
                     this.data.sort((a,b) => (a[orderCat][extra] > b[orderCat][extra]) ? 1 : ((b[orderCat][extra] > a[orderCat][extra]) ? -1 : 0))
                     console.log(this.certificati)
                 }else{
                     this.data.sort((a,b) => (a[orderCat] > b[orderCat]) ? 1 : ((b[orderCat] > a[orderCat]) ? -1 : 0))
                 }
                 
             }else{
                 if (extra) {
 
                     this.data.sort((a,b) => (a[orderCat][extra] < b[orderCat][extra]) ? 1 : ((b[orderCat][extra] < a[orderCat][extra]) ? -1 : 0))
                 }else {
                     this.data.sort((a,b) => (a[orderCat] < b[orderCat]) ? 1 : ((b[orderCat] < a[orderCat]) ? -1 : 0))
                 }
             }

             this.data.push(totale)
 
         },
        async get_carico_scarico(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await CertificatoRepository.report_carico_scarico(this.search).catch(()=>{})

            if(_resp){
                this.data = _resp.data.data
                this.updated_at = _resp.data.updated_at
            }
            
            this.$store.dispatch('updateLoadingState', false);
        },
        async reload(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await CertificatoRepository.report_carico_scarico_update(this.search).catch(()=>{})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        resetAll() {
            let _search = this._.cloneDeep(this.search);
            for (const key in _search) {
                if (_search.hasOwnProperty(key)) {
                    if(key !== 'ispettore_id') {
                        if(key == 'per_page') {
                            _search[key] = 10;
                        }
                        else if(key == 'page') {
                            _search[key] = 1;
                        }
                        else {
                            _search[key] = null;
                        }
                    }
                }
            }
            this.search = _search;
        },
        async print(type){
            this.search.type = type;
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await UtilityRepository.print_carico_scarico(this.search).catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);

                if(byteArray.length < 100){
                    const string = Utility.Utf8ArrayToStr(byteArray)
                    const json = JSON.parse(string)
                    
                    this.$toasted.success(json.message).goAway(3000)
                }else{
                    let a = window.document.createElement('a');

                    a.href = window.URL.createObjectURL(
                        new Blob([byteArray], { type: 'application/octet-stream' })
                    );

                    if(type == 'csv'){
                        a.download ='report_csv.csv' ;
                    }else{
                        a.download ='report_pdf.pdf' ;
                    }

                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                }
                
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        goTo(name){
            this.$router.push({ name: name })
        },
    },
    mounted(){
        this.search.ispettore_id = this.$route.params.ispettore_id
        this.get_carico_scarico()
    }
}
</script>
<style>
    @import '../../assets/css/style.css';
</style>