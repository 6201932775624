<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>SELEZIONA A</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <select v-model="cause_selected" class="form-control" name="grade">
                            <option value="">Seleziona</option>
                            <option v-for="(cause, index) in causes" :key="index" :value="cause">{{cause}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                @click="set_data">
                <span>Salva Modifiche</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../../Modal"
export default {
    name:'BollettinoModalSelectA',
    props:['isModalVisible'],
    components: {
        Modal
    },
    data(){
        return {
            causes: ["Ritiro di denuncia","Ritrapianto","PARTITA NON DENUNCIATA","Risemina", "Fuori rischio", "A forfait", "Già Liquidato","Mancato Prodotto","No Percentuale","No rigrandine",
            "Perizia negativa"
            ],
            row_position:{
                x:null,
                y:null
            },
            cause_selected:""
        }
    },
    methods: {
        get_data(cause, x, y){
            if(cause){
                this.cause_selected = cause
            }
            this.row_position = {
                x:x, y:y
            }
        },
        reset(){
            this.cause_selected = ""
            this.row_position = {
                x:null,
                y:null
            }
        },
        set_data(){
            this.$emit('closeModalCallback', 'select_a', this.cause_selected, this.row_position)
            this.reset()
        },
        closeModal() {
            this.$emit('closeModalCallback', null)
        },
    }
}
</script>