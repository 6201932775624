<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>ELIMINA SUB PARTITE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <h2>Se elimini questa sub partita eliminerai tutte le sub partite relative alla partita selezionata.</h2>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Annulla
            </button>
            <button
                @click="deleteSubPartite"
                type="button"
                class="btn btn-danger"
                aria-label="Conferma">
                <span>Conferma</span>
            </button>
            
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"
import CertificatoRepository from "../../services/apis/certificato"

export default {
    name:'ModalSubPartitaDeleteAll',
    props:['partita', 'isModalVisible', 'id_ispettore'],
    components: {
        Modal
    },
    methods: {
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        async deleteSubPartite(){
            
            var obj = {
                partita_id: this.partita.id,
                sub_partite: [],
                ispettore_id:this.id_ispettore
            }

            let _resp = await CertificatoRepository.new_sub_partita(obj).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.$emit('closeModalCallback', 'refresh')
            }
            
        }
    }
}
</script>