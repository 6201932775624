<template>
    <v-select 
        :options="lists" 
        :filterable="false" 
        @search="search" 
        :value="selectedModel" 
        label="id"
         @input="setSelected"
        :placeholder="placeholder"  >
        
        <template slot="no-options">
            Nessun dato trovato
        </template>
        <template slot="option" slot-scope="option">
            <div v-if="type == 'consorzio'" class="d-center">
                {{ `${option.codice} - ${option.name}` }}
            </div>
            <div v-if="type == 'specie'" class="d-center">
                {{ `${option.codice_identificativo} - ${option.etichetta}` }}
            </div>
            <div v-if="type == 'provincia'" class="d-center">
                {{ `${option.nome}` }}
            </div>
            <div v-if="type == 'comune'" class="d-center">
                {{ `${option.nome}` }}
            </div>
            <div v-if="type == 'agenzia'" class="d-center">
                {{ `${option.codice_identificativo} - ${option.nome}` }}
            </div>
            <div v-if="type == 'certificato'" class="d-center">
                {{ `${option.certificato}` }}
            </div>
            <div v-if="type == 'denominazione'" class="d-center">
                {{ `${option.nome}` }}
            </div>
            <div v-if="type == 'foglio_catastale'" class="d-center">
                {{ `${option.nome}` }}
            </div>
            <div v-if="type == 'perito' || type == 'all' || type == 'ispettore'" class="d-center">
                {{ `${option.codice_perito} -  ${option.name} ${option.surname}` }}
            </div>
        </template>
        <template slot="selected-option" slot-scope="option">
            <div v-if="type == 'specie'" class="d-center">
                {{ `${option.codice_identificativo} - ${option.etichetta}` }}
            </div>
            <div v-if="type == 'provincia'" class="d-center">
                {{ `${option.nome}` }}
            </div>
            <div v-if="type == 'comune'" class="d-center">
                {{ `${option.nome}` }}
            </div>
             <div v-if="type == 'agenzia'" class="d-center">
                {{ `${option.codice_identificativo} - ${option.nome}` }}
            </div>
            <div v-if="type == 'denominazione'" class="d-center">
                {{ `${option.nome}` }}
            </div>
            <div v-if="type == 'foglio_catastale'" class="d-center">
                {{ `${option.nome}` }}
            </div>
            <div v-if="type == 'certificato'" class="d-center">
                {{ `${option.certificato}` }}
            </div>
            <div v-if="type == 'perito' || type == 'all' || type == 'ispettore'" class="d-center">
                {{ `${option.codice_perito} -  ${option.name} ${option.surname}` }}
            </div>
            <div v-if="type == 'consorzio'" class="d-center">
                {{ `${option.codice} - ${option.name}` }}
            </div>
        </template>
    </v-select>
</template>

<script>

import UtilityRepository from "../services/apis/utility"

export default {
    props:{
        selectedModel:Object,
        type:{
            type:String,
            required:true
        },
        placeholder:{
            type:String,
            default:'Seleziona'
        },
        voidText:{
            type:String,
            default:'Nessun dato trovato'
        },
        ispettoreId:{
            type:Number,
        }
    },
    data() {
        return{
            lists:[],
            timeout:null
        }
    },
    methods: {
        setSelected(value){
            this.$emit('updateSelector', this.type, value)
        },
        search(text, loading){
            if(!text || text == ""){
                return
            }

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                loading(true);
                var _search;
                switch (self.type) {
                    case 'ispettore':
                    case 'perito':
                    case 'all':
                        _search = {
                            cerca:text,
                            type:self.type
                        }
                        self.getIspettore(loading, _search)
                        break;
                    case 'certificato':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            search: text
                        }
                        self.getCertificato(loading, _search)
                        break;
                    case 'denominazione':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            text: text,
                            type: 'denominazioni'
                        }
                        self.getDenominazioni(loading, _search)
                        break;
                    case 'agenzia':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            cerca: text,
                        }
                        self.getAgenzie(loading, _search)
                        break;
                    case 'provincia':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            cerca: text,
                        }
                        self.getProvince(loading, _search)
                        break;
                    case 'specie':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            cerca: text,
                        }
                        self.getSpecie(loading, _search)
                        break;
                    case 'foglio_catastale':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            text: text,
                            type: 'catasto'
                        }
                        self.getDenominazioni(loading, _search)
                        break;
                    case 'consorzio':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            cerca: text,
                        }
                        self.getConsorzio(loading, _search)
                        break;
                    case 'comune':
                        _search = {
                            ispettore_id: self.ispettoreId,
                            cerca: text,
                        }
                        self.getComune(loading, _search)
                        break;
                    default:
                        break;
                }


               
            }, 1000);
        },
        async getIspettore(loading, search){
             const _resp = await UtilityRepository.search_utenti(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
        async getCertificato(loading, search){
             const _resp = await UtilityRepository.search_certificati(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
        async getDenominazioni(loading, search){
             const _resp = await UtilityRepository.search_denom_foglio(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data
            }

            loading(false)
        },
        async getAgenzie(loading, search){
             const _resp = await UtilityRepository.search_agenzie(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
        async getProvince(loading, search){
             const _resp = await UtilityRepository.search_province(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
        async getSpecie(loading, search){
             const _resp = await UtilityRepository.search_specie(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
        async getFoglioCatastale(loading, search){
            const _resp = await UtilityRepository.search_denom_foglio(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
        async getConsorzio(loading, search){
            const _resp = await UtilityRepository.search_consorzi(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
        async getComune(loading, search){
            const _resp = await UtilityRepository.search_comuni(search).catch(()=>{})

            if(_resp){
                this.lists = _resp.data.data
            }

            loading(false)
        },
    }
}
</script>