import Vue from 'vue'
import Vuex from 'vuex'
// import api from '../services/api'
import router from '../router/index'

Vue.use(Vuex)

const baseState = {
  isLogged: false,
  access_token: null,
  first_open:false,
  user:null,
  loading:false,
  choise:null,
  bollettino:null,
  ispettori:[],
  basePermission:'owner'
}

export default new Vuex.Store({
  state: JSON.parse(JSON.stringify(baseState)),
  mutations: {
    CHANGE_ACCESS_TOKEN_STATE(state, value) {
      
      if(!value){
        localStorage.removeItem('myara1857_token')
        localStorage.removeItem('myara1857_admin_token')
        localStorage.removeItem('choise')
        state = JSON.parse(JSON.stringify(baseState))
        // state.user = null
        // state.isLogged = false
        // state.choise = null
        // state.ispettori = []
        // state.basePermission='owner'
        // state.bollettino = null
      }else{
        localStorage.setItem('myara1857_token', value)
      }

      state.access_token = value
    },
    CHANGE_ACCESS_ADMIN_TOKEN_STATE(state, value){
      if(value){
        localStorage.setItem('myara1857_admin_token', value)
      }else{
        localStorage.removeItem('myara1857_admin_token')
      }
    },
    CHANGE_FIRST_OPEN_STATE(state, value) {
      state.first_open = value
    },
    CHANGE_USER_OBJ_STATE(state, value) {
      state.user = value
    },
    CHANGE_ISPETTORI_OBJ_STATE(state, value) {
      state.ispettori = value
    },
    CHANGE_LOADING_STATE(state, value) {
      state.loading = value
    },
    CHANGE_LOGGED_STATE(state, value) {
      state.isLogged = value
    },
    CHANGE_BOLLETTINO_STATE(state, value) {
      state.bollettino = value
    },
    CHANGE_CHOISE_STATE(state, value) {
      if(value){
        state.choise = value
      }
    }
  },
  actions: {
    updateAccessTokenState({ commit }, state) {
      commit('CHANGE_ACCESS_TOKEN_STATE', state)
    },
    updateAdminTokenState({ commit }, state) {
      commit('CHANGE_ACCESS_ADMIN_TOKEN_STATE', state)
    },
    updateFirstOpenState({ commit }, state) {
      commit('CHANGE_FIRST_OPEN_STATE', state)
    },
    updateUserObjState({ commit }, state) {
      commit('CHANGE_USER_OBJ_STATE', state)
    },
    updateIspettoriObjState({ commit }, state) {
      commit('CHANGE_ISPETTORI_OBJ_STATE', state)
    },
    updateLoadingState({ commit }, state) {
      commit('CHANGE_LOADING_STATE', state)
    },
    updateLogginState({ commit }, state) {
      commit('CHANGE_LOGGED_STATE', state)
    },
    updateChoiseState({ commit }, state) {
      commit('CHANGE_CHOISE_STATE', state)
    },
    updateBollettinoState({ commit }, state) {
      commit('CHANGE_BOLLETTINO_STATE', state)
    },
    async logout({ commit }){
      // await api.post(`/v2/auth/logout?token=${this.getters.access_token}`);
      commit('CHANGE_ACCESS_TOKEN_STATE', null);
      commit('CHANGE_LOGGED_STATE', false)
      router.push({path:'/login'})
    }
  },
  modules: {
  },
  getters: {
    isLoggedIn: state => state.isLogged,
    access_token: state => state.access_token,   
    first_open: state => state.first_open,   
    user: state => state.user,   
    ispettori: state => state.ispettori,   
    loading: state => state.loading,   
    choise: state => state.choise,   
    bollettino: state => state.bollettino,   
    base_permission: state => state.basePermission,   
  }
})
