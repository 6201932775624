<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Lista Csv <small>Lista caricamenti csv.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <!-- <div class="block-header">
                    <div class="col-sm-12" style="text-align: right">
                        <div class="n_order_search">
                            <input v-model="search.ordine_id" placeholder="Numero Ordine">
                            <button v-on:click="get_bollettini">Cerca</button>
                        </div>
                    </div>
                </div> -->
                <div class="block-content block-content-full">
                    <h3 v-if="lists.length == 0">Nessun Csv presente</h3>
                    <div v-if="lists.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="lists.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="d-none d-sm-table-cell">DATA</th>
                                <th class="d-none d-sm-table-cell">NUOVI ORDINI</th>
                                <th class="d-none d-sm-table-cell">NUOVE PARTITE</th>
                                <th class="d-none d-sm-table-cell">CAPITALI AGGIORNATI</th>
                                <th class="d-none d-sm-table-cell">ORDINI RICOLPITI</th>
                                <th class="d-none d-sm-table-cell">ORDINI AGGIORNATI</th>
                                <th class="d-none d-sm-table-cell">PARTITE AGGIORNATE</th>
                                <th class="d-none d-sm-table-cell">DENUNCE AGGIORNATE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(csv, index) in lists" :key="index">
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{csv.csv_actions_formatted.data | toEuropeData}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="pointer" @click="showDetails(csv.csv_actions_formatted.nuovo_ordine, 'nuovo_ordine')">
                                        {{csv.csv_actions_formatted.nuovo_ordine.length}}
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="pointer" @click="showDetails(csv.csv_actions_formatted.nuova_partita, 'nuova_partita')">
                                        {{csv.csv_actions_formatted.nuova_partita.length}}
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="pointer" @click="showDetails(csv.csv_actions_formatted.partita_capitale_agg, 'partita_capitale_agg')">
                                        {{csv.csv_actions_formatted.partita_capitale_agg.length}}
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="pointer" @click="showDetails(csv.csv_actions_formatted.ordine_ricolpito, 'ordine_ricolpito')">
                                        {{csv.csv_actions_formatted.ordine_ricolpito.length}}
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="pointer" @click="showDetails(csv.csv_actions_formatted.ordini_aggiornati, 'ordini_aggiornati')">
                                        {{csv.csv_actions_formatted.ordini_aggiornati.length}}
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="pointer" @click="showDetails(csv.csv_actions_formatted.partite_aggiornate, 'partite_aggiornate')">
                                        {{csv.csv_actions_formatted.partite_aggiornate.length}}
                                    </span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="pointer" @click="showDetails(csv.csv_actions_formatted.denunce_aggiornate, 'denunce_aggiornate')">
                                        {{csv.csv_actions_formatted.denunce_aggiornate.length}}
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="csv.length > 0" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                </div>
            </div>
        </div>
        <ModalDettagliCsv 
            :isModalVisible="isModalVisible" 
            :csv_keys="resource_selected.csv_keys" 
            :csv="resource_selected.csv" 
            @closeModalCallback="close_modal">
        </ModalDettagliCsv>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ModalDettagliCsv from '../../components/ModalDettagliCsv'
import CertificatoRepository from "../../services/apis/certificato"
export default {
    components: {
        Paginate, ModalDettagliCsv
    },
    data(){
        return {
            csv:[],
            lists:[],
            data: {
                last_page:1
            },
            search: {
                ispettore_id: 0,
                per_page: 10,
                page: 1,
            },
            isModalVisible:false,
            resource_selected:{
                csv:[],
                csv_keys:[]
            }
        }
    },
     watch: {
        '$route.params.ispettore_id': function (id) {        
            this.search.ispettore_id = id
            this.get_csv()
        }
    },
    methods: {
        async get_csv(){
            this.$store.dispatch('updateLoadingState', true);

            const _resp = await CertificatoRepository.get_csv_actions(this.search).catch(()=>{})

            if(_resp){
                // let arr = []
                // _resp.data.data.data.forEach(element => {
                //     console.log(element.csv_actions_formatted);
                //     for (const key in element.csv_actions_formatted) {
                //         if (element.csv_actions_formatted.hasOwnProperty(key)) {
                //             const _element = element.csv_actions_formatted[key];
                //             const obj = {
                //                 data: key,
                //                 collections: _element
                //             }
                //             arr.push(obj)
                //         }
                //     }
                // });
                //this.lists = arr
                
                this.lists = _resp.data.data.data
                this.data = _resp.data.data   
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        perPage(){
            this.search.page = 1
            this.get_csv()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_csv()
        },
        showDetails(data, type){
            let _csv_keys = []

            switch (type) {
                case 'nuova_partita':
                    _csv_keys = ['ordine_id', 'partita_id', 'type']
                    break;
            
                default:
                     _csv_keys = ['ordine_id', 'type']
                    break;
            }


            this.resource_selected.csv_keys = _csv_keys
            this.resource_selected.csv = data
            this.isModalVisible = true
        },
        close_modal(){
            this.isModalVisible = false
            this.resource_selected = {
                csv:[],
                csv_keys:[]
            }
        },
        goToResource(csv){
            if(csv.type !== "aggiornato"){
                this.$router.push({name:'modifica-certificato', params:{ispettore_id:this.search.ispettore_id, certificato_id:csv.ordine_id}})
                return
            }

            if(csv.denuncia_id){
                this.$router.push({name:'lista-csv-histories', params:{type:'denuncia', resource_number:csv.denuncia_id}})
                return
            }
            if(csv.partita_id){
                this.$router.push({name:'lista-csv-histories', params:{type:'partita', resource_number:csv.partita_id}})
                return
            }
            if(csv.ordine_id){
                this.$router.push({name:'lista-csv-histories', params:{type:'ordine', resource_number:csv.ordine_id}})
                return
            }
        },
        async printOrdine(csv){
            
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await CertificatoRepository.print_bollettino_from_certificato(csv.ordine_id, this.search.ispettore_id).catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download ='bollettino-demo.pdf' ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        }
    },
    mounted(){
        this.search.ispettore_id = this.$route.params.ispettore_id
        this.get_csv()
    }
}
</script>

<style scoped>
    .pointer {
        cursor: pointer;
        color: #5c80d1;
        font-weight: bold;
    }
</style>