import api from '../api.js'
import utility from '../utility'
import store from '../../store'

const base = 'utility'

export default({
    check(){
        return api.get(`/v2/${base}/check?token=${store.getters.access_token}`);
    },
    get_variables(search){        
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                _search.push(`${key}=${element}`)
            }
        }

        _search = _search.join('&')     
        return api.get(`/v2/utility/get-variables?token=${store.getters.access_token}&${_search}`);
    },
    cerca_ordin(search){        
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                if(element && element !== ''){
                    _search.push(`${key}=${element}`)
                }
            }
        }

        _search = _search.join('&')     
        return api.get(`/v2/utility/cerca-ordini?token=${store.getters.access_token}&${_search}`);
    },
    get_note(){        
        return api.get(`/v2/utility/get-note?token=${store.getters.access_token}`);
    },
    get_eventi(){        
        return api.get(`/v2/utility/get-eventi?token=${store.getters.access_token}`);
    },
    search_certificati(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/search-certificati?token=${store.getters.access_token}&${_search}`);
    },
    search_agenzie(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/get-agenzie?token=${store.getters.access_token}&${_search}`);
    },
    search_consorzi(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/get-consorzi?token=${store.getters.access_token}&${_search}`);
    },
    search_province(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/get-province?token=${store.getters.access_token}&${_search}`);
    },
    search_comuni(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/get-comuni?token=${store.getters.access_token}&${_search}`);
    },
    search_specie(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/get-specie?token=${store.getters.access_token}&${_search}`);
    },
    search_varieta(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/get-varieta?token=${store.getters.access_token}&${_search}`);
    },
    get_varieta_by_specie_id(specie_id){
        return api.get(`/v2/utility/get-varieta-specie?token=${store.getters.access_token}&specie_id=${specie_id}`);
    },
    search_denom_foglio(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/get-by-type?token=${store.getters.access_token}&${_search}`);
    },
    search_utenti(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/utility/search-utenti?token=${store.getters.access_token}&${_search}`);
    },
    trasferisci_certificato(search){
        return api.post(`/v2/utility/trasferisci-certificato?token=${store.getters.access_token}`, search);
    },
    get_lista_ordini(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/utility/cerca-ordini-new?token=${store.getters.access_token}&${_search}`);
    },
    
    print_ordini(search) {
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/print/ordini/ordini?token=${store.getters.access_token}&${_search}`, {responseType: 'arraybuffer'});
    },
    
    print_carico_scarico(search) {
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/print/report-carico-scarico?token=${store.getters.access_token}&${_search}`, {responseType: 'arraybuffer'});
    },
    print_report_varieta(search) {
        var _search = utility.appendParameters(search); 
        return api.post(`/v2/print/report-varieta?token=${store.getters.access_token}`,_search, {responseType: 'arraybuffer'});
    },
    export_anagrafiche() {
        return api.get(`/v2/utility/export-utenti?token=${store.getters.access_token}`, {responseType: 'arraybuffer'});
    },
    export_specifiche() {
        return api.get(`/v2/utility/export-specifiche?token=${store.getters.access_token}`, {responseType: 'arraybuffer'});
    },
    export_perizie() {
        return api.get(`/v2/utility/export-perizie?token=${store.getters.access_token}`, {responseType: 'arraybuffer'});
    }
})