<template>
  <div v-if="show" class="row">
        <div class="col-sm-6 col-md-6">
            <SelectPageNumber v-if="lastPage > 5" v-model="inputDataLocal" :lastPage="lastPage" @changeCallback="paginateClick" />
        </div>
        <div class="col-sm-6 col-md-6">
            <paginate
                v-model="inputDataLocal"
                :page-count="lastPage"
                :click-handler="paginateClick"
                :prev-text="'Prev'"
                :next-text="'Next'"
                :container-class="'pagination'"
                :page-class="'page-item'"
                :prev-class="'prev'"
                :next-class="'next'">
            </paginate>
        </div>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import SelectPageNumber from "./SelectPageNumber"
export default {
    props:{
        show:{
            type:Boolean,
            required:true
        },
        lastPage:{
            type:Number,
            default:1,
            required:true
        },
        inputData:{
            type:Number,
            required:true
        },
    },
    computed: {
        inputDataLocal: {
            get: function() {
                return this.inputData 
            },
            set: function(value) {
                this.$emit('inputDataChange', value)
            }
        },
    },
     model: {
        prop: 'inputData',
        event: 'inputDataChange'
    },
    components: {
        Paginate, SelectPageNumber
    },
    methods: {
        paginateClick(pageNum){
            this.$emit('paginateClick', pageNum)
        }
    }
}
</script>

<style>

</style>