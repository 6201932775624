<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Trasferisci Certificato <small>Trasferisci certificato ad un altro ispettore.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                    <div>
                        <label v-if="certificato && certificato_selected && ispettore_role === 'admin'" class="form-material">Ispettore Owner: {{certificato_selected.ispettore.name}} {{certificato_selected.ispettore.surname}}</label>
                        <label v-if="ispettore_role === 'ispettore'" class="form-material">Ispettore Owner: {{ispettore_name}} {{ispettore_surname}}</label>
                    </div>
                </div>
                <div class="block-header search_totals">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-material">Associa Certificati</label>
                        <v-select 
                            v-if="ispettore_role !== 'admin'"
                            :options="cert_list" 
                            :filterable="false" 
                            @search="searchCertificato" 
                            v-model="certificato_selected" 
                            label="id"
                            placeholder="Seleziona un numero d'Ordine"  >
                            
                            <template slot="no-options">
                                Nessun certificato trovato
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ certificatiListText(option) }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ certificatiListText(option) }}
                                </div>
                            </template>
                        </v-select>
                        
                        <v-select 
                             v-if="ispettore_role === 'admin'"
                            :options="cert_list" 
                            :filterable="false" 
                            @search="searchCertificatoGlobal" 
                            v-model="certificato_selected" 
                            label="id"
                            placeholder="Seleziona un numero d'Ordine"  >
                            
                            <template slot="no-options">
                                Nessun certificato trovato
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ certificatiListText(option) }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ certificatiListText(option) }}
                                </div>
                            </template>
                        </v-select>
                        <div>
                            <div class="form-material" style="text-align: left; margin-top: 10px; margin-bottom: 10px;">
                                <button :disabled="certificato_selected && certificato_selected.ordine_id === null || ispettore_selected === null" 
                                    class="btn btn-sm btn-primary" type="submit" @click="trasferisci_certificato">Avvia Trasferimento</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <label class="form-material">Trasferisci Ispettore</label>
                        <v-select 
                            :options="ispettori_list" 
                            :filterable="false" 
                            @search="searchIspettore" 
                            v-model="ispettore_selected" 
                            label="id"
                            placeholder="Seleziona un Ispettore"  >
                            
                            <template slot="no-options">
                                Nessun ispettore trovato
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ `${option.name} ${option.surname}` }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ `${option.name} ${option.surname}` }}
                                </div>
                            </template>
                        </v-select>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CertificatoRepository from "../../services/apis/certificato"
import UtilityRepository from "../../services/apis/utility"
export default {
    data(){
        return {
            ispettore_id: 0,
            ispettore_surname: '',
            ispettore_name: '',
            ispettore_role: '',
            ispettori:{
                list:[],
                found:[]
            },
            certificati:{
                list:[],
                found:[]
            },
            ispettore: {
                id: null,
                name: '',
                surname: '',
                codice_perito: ''
            },
            certificato: null,
            certificato_selected: null,
            ispettore_selected: null,
            cert_list:[],
            ispettori_list:[],
            timeout: null
        }
    },
    methods: {
        async trasferisci_certificato(){
            this.$store.dispatch('updateLoadingState', true);

            var search = {
                id_ordine: this.certificato_selected.id,
                id_ispettore: this.ispettore_selected.id,
            }

            const _resp = await UtilityRepository.trasferisci_certificato(search).catch(()=>{})
            
            if(_resp.success === "success" || _resp.data.status === "success"){
                this.$toasted.success(_resp.data.message).goAway(3000);
                this.resetAll();
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        async searchCertificatoGlobal(text, loading){            
            if(!text || text == ""){
                return
            }

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                loading(true);
                self.getOrdini(loading, {
                    ordine_id: text,
                    per_page:100
                })
            }, 1000);
        },
        async getOrdini(loading, search){
            const _resp = await UtilityRepository.get_lista_ordini(search).catch(()=>{})

            if(_resp){
                this.cert_list = _resp.data.data.data;
            }

            loading(false)
        },
        async getCertificati(loading, search){
             const _resp = await CertificatoRepository.get_lista_certificati(search).catch(()=>{})

            if(_resp){
                this.cert_list = _resp.data.data.data;
            }

            loading(false)
        },
        searchCertificato(text, loading){
            if(!text || text == ""){
                return
            }

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                loading(true);
                self.getCertificati(loading, {
                    ispettore_id: self.ispettore_id,
                    per_page: 999999,
                    page: 1,
                    numero_ordine: text
                })
            }, 1000);
        },
        async getIspettore(loading, search){
            
            const _resp = await UtilityRepository.search_utenti(search).catch(()=>{})

            if(_resp){
                this.ispettori_list = _resp.data.data
            }

            loading(false)
            
        },
        searchIspettore(text, loading){

            if(!text || text == ""){
                return
            }

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                loading(true);
                self.getIspettore(loading, {
                    type: 'ispettore',
                    cerca: text
                })
            }, 1000);
        },
        resetAll() {
            this.ispettori = {
                list:[],
                found:[]
            };
            this.certificati = {
                list:[],
                found:[]
            };
            this.ispettore = {
                id: null,
                name: '',
                surname: '',
                codice_perito: ''
            };
            this.certificato = null;
            this.certificato_selected = null
            this.ispettore_selected = null
        },
        
        certificatiListText(item){
            if(this.ispettore_role !== 'admin')
                return `${item.ordine_id} - ${this.ispettore_surname}`
            else {
                return `${item.ordine_id} - ${item.ispettore.surname}`
            }
            
        },
    },
    mounted(){
        const user = this.$store.getters.user
        this.ispettore_id = user.role == 'ispettore' ? user.id : 0
        this.ispettore_role = user.role;
        this.ispettore_surname = user.surname;
        this.ispettore_name = user.name;
    }
}
</script>
<style>
    @import '../../assets/css/style.css';
</style>