<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span v-if="!partita.id">NUOVA PARTITA</span>
                <span v-else>MODIFICA PARTITA</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <ValidationObserver ref="form_partita">
                <form style="padding-bottom:100px">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-text-input">Numero Partita</label>
                                <ValidationProvider rules="required" name="certificato" v-slot="{ errors }">
                                    <input v-model="partita.numero_partita" type="text" class="form-control" name="numero_partita" placeholder="Numero Partita">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-text-input">Foglio Catastale</label>
                                <ValidationProvider rules="required" name="foglio_catastale" v-slot="{ errors }">
                                    <input v-model="partita.foglio_catastale" type="text" class="form-control" name="foglio_catastale" placeholder="Foglio Catastale">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-text-input">Importo Assicurato €</label>
                                <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/, required:true}" name="importo_assicurato" v-slot="{ errors }">
                                    <input v-model="partita.importo_assicurato" type="number" class="form-control" name="importo_assicurato" placeholder="Importo Assicurato in €">
                                    <span class="validation-error">{{ errors[0] }}</span>
                                </ValidationProvider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-10">
                            <div class="form-group">
                                    <label for="example-text-input">Varietà</label>
                                    <ValidationProvider rules="required" name="varieta_id" v-slot="{ errors }">
                                        <model-list-select
                                            id="search-select"
                                            :list="varieta_found"
                                            option-value="id"
                                            v-model="partita.varieta_id"
                                            :custom-text="varietaEtichetta"
                                            placeholder="Seleziona"
                                            @searchchange="searchVarieta"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                            </div>
                        </div>
                        <div class="col-md-2" style="display: flex;align-items: center;">
                            <i v-tooltip="{
                                content: 'nel caso in cui non venga elencata la varietà che stavi cercando per la specie che hai selezionato, imposta la varietà generica e invia una email al <a href=\'mailto:support@myara1857.it\'>supporto</a> per richiedere l’inserimento',
                                placement: 'bottom-center',
                                classes: ['info'],
                                html:true,
                                targetClasses: ['it-has-a-tooltip'],
                                offset: 10,
                                autoHide:false,
                                delay: {
                                    show: 200,
                                    hide: 1000,
                                }}" style="margin-top: -10px;font-size: 30px;" class="fas fa-info-circle"></i>
                        </div>
                    </div>
                   
                </form>
            </ValidationObserver>
        </template>
        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                @click="savePartita"
                :disabled="!is_form_valid"
                aria-label="Nuova">
                <span v-if="!partita.id">Salva</span>
                <span v-else>Modifica</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import Modal from "../Modal"
import CertificatoRepository from "../../services/apis/certificato"
export default {
    name:'ModalPartita',
    props:['ordine_id', 'isModalVisible', 'varieta', 'ispettore_id'],
    components: {
        Modal, ModelListSelect
    },
    data(){
        return {
            partita: {
                numero_partita:'',
                varieta_id:'',
                importo_assicurato:'',
                foglio_catastale:''
            },
            is_form_valid:false,
            varieta_found:[]
        }
    },
    watch:{
        partita:{
            handler(){
                this.form_partita_is_valid()
            },
            deep:true
        }
    },
    methods: {
        varietaEtichetta(item){
            return `${item.codice_identificativo} - ${item.codice_varieta} - ${item.etichetta}`
        },
        async form_partita_is_valid(){

            if(this.$refs.form_partita){
                let success = await this.$refs.form_partita.validate()                
                this.is_form_valid = success
                return
            }
            
            this.is_form_valid = false
        },
        searchVarieta(text){
            const _c = this.varieta.filter((item) => {
                const _mix = `${item.codice_identificativo} ${item.codice_varieta} ${item.etichetta}`
                return _mix.toLowerCase().includes(text.toLowerCase())
            })

            this.varieta_found = _c
        },
        closeModal() {
            this.$emit('closeModalCallback', null)
        },
        editPartita(partita){
            this.partita = this._.cloneDeep(partita, true)
            this.varieta_found = this._.cloneDeep(this.varieta, true)
        },
        async savePartita(){
            this.$store.dispatch('updateLoadingState', true)
            
            this.partita.ordine_id = this.ordine_id
            this.partita.ispettore_id = this.ispettore_id

            let _resp = null

            if(this.partita.id){
                this.partita.partita_id = this.partita.id
                _resp = await CertificatoRepository.edit_partita(this.partita).catch(() => {})
            }else{
                _resp = await CertificatoRepository.new_partita(this.partita).catch(() => {})
            }

            this.$store.dispatch('updateLoadingState', false)

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.$emit('closeModalCallback', true)
            }
        }
    }
}
</script>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: black;
    color: white;
    border-radius: 8px;
    padding: 5px 10px 4px;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}
</style>