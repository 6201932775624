import { render, staticRenderFns } from "./MappaCatastale.vue?vue&type=template&id=3d5060c2"
import script from "./MappaCatastale.vue?vue&type=script&lang=js"
export * from "./MappaCatastale.vue?vue&type=script&lang=js"
import style0 from "./MappaCatastale.vue?vue&type=style&index=0&id=3d5060c2&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./MappaCatastale.vue?vue&type=custom&index=0&blockType=link&rel=stylesheet&href=https%3A%2F%2Funpkg.com%2Fleaflet%2Fdist%2Fleaflet.css"
if (typeof block0 === 'function') block0(component)

export default component.exports