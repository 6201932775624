<template>
    <div>
        <transition name="modal"  v-if="showModal" @close="chiudi_modal = false">
            <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-partite">

                <div class="modal-header">
                    <slot name="header">

                    </slot>
                </div>

                <div class="modal-body">
                    <div id="leaflet"></div>
                </div>

                <div class="modal-footer">
                    <slot name="footer">
                        <input type="checkbox" name="satellite" @click="updateSatellite" class="btn-select-all"/> Satellite<br/>
                    <button class="modal-default-button" @click="chiudi_modal">
                        OK
                    </button>
                    </slot>
                </div>
                </div>
            </div>
            </div>
        </transition>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Modifica Certificato n° {{certificato.ordine_id}} <small>- {{numero_denuncia}}° Denuncia.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                    <form>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Certificato</label>
                                    <input disabled :value="certificato.certificato + '/' + certificato.edizione" type="text" class="form-control" name="certificato" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Agenzia</label>
                                    <input disabled :value="formattedData(certificato.agenzia_id, 'agenzie')" type="text" class="form-control" name="agenzia" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Consorzio</label>
                                    <input disabled :value="formattedData(certificato.consorzio_id, 'consorzio')" type="text" class="form-control" name="consorzio" placeholder="">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Denominazione</label>
                                    <ValidationProvider rules="required" name="denominazione" v-slot="{ errors }">
                                       <input :disabled="!editMode" v-model="certificato.denominazione" type="text" class="form-control" name="denominazione" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-text-input">Prov</label>
                                    <ValidationProvider rules="required" name="agenzia" v-slot="{ errors }">
                                        <model-list-select
                                            id="search-select-agenzia"
                                            :list="provincia.found"
                                            :isDisabled="!editMode"
                                            option-value="id"
                                            option-text="short"
                                            v-model="certificato.provincia_id"
                                            placeholder="Seleziona"
                                            @searchchange="searchProvincia"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-text-input">Comune</label>
                                    <ValidationProvider rules="required" name="comune" v-slot="{ errors }">
                                        <model-list-select
                                            :isDisabled="!editMode"
                                            id="search-select-comun"
                                            :list="comune.found"
                                            option-value="id"
                                            option-text="nome"
                                            v-model="certificato.comune_id"
                                            placeholder="Seleziona"
                                            @searchchange="searchComune"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Specie</label>
                                    <input disabled :value="formattedData(certificato.specie_id, 'specie')" type="text" class="form-control" name="specie" placeholder="">
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-ltf-email">Data Carico</label>
                                    <ValidationProvider rules="required|is_valid_date" name="data_carico" v-slot="{ errors }">
                                        <DatePicker :disabled="!editMode" isId="data_carico" v-model="certificato.data_carico" />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-ltf-email">Data Notifica</label>
                                    <ValidationProvider rules="required|is_valid_date" name="data_notifica" v-slot="{ errors }">
                                        <DatePicker :disabled="!editMode" isId="data_notifica" v-model="certificato.data_notifica" />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                         <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Franchigia</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]/}" name="franchigia" v-slot="{ errors }">
                                       <input :disabled="!editMode" v-model="certificato.franchigia" type="number" class="form-control" name="franchigia" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input :disabled="!editMode" v-model="certificato.soglia" type="checkbox" class="custom-control-input" id="soglia" name="soglia">
                                        <label class="custom-control-label" for="soglia">Soglia</label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input :disabled="!editMode" v-model="certificato.provvisorio" type="checkbox" class="custom-control-input" id="provvisorio" name="provvisorio">
                                        <label class="custom-control-label" for="provvisorio">Provvisorio</label>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="example-text-input">Associa Certificati</label>
                                    <multi-select
                                        id="search-select-ordini"
                                        :options="certificati.found"
                                        :selected-options="ordini"
                                        placeholder="Seleziona"
                                        @searchchange="searchCertificati"
                                        @select="onSelectCertificati"
                                        :isDisabled="!editMode"
                                        >
                                    </multi-select>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input disabled v-model="tipo_mer" type="checkbox" class="custom-control-input" id="agevolata" name="agevolata">
                                        <label class="custom-control-label" for="agevolata">Agevolata</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-text-input">Note</label>
                                    <textarea :disabled="!editMode" v-model="certificato.note" name="note" id="" cols="30" rows="5" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input :disabled="!editMode" v-model="flag_data_preventiva" type="checkbox" class="custom-control-input" id="flag_data_preventiva" name="flag_data_preventiva">
                                        <label class="custom-control-label" for="flag_data_preventiva">Data Preventiva?</label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="flag_data_preventiva" class="col-md-4">
                                <div class="form-group">
                                    <label for="example-ltf-email">Data Preventiva</label>
                                    <ValidationProvider rules="required|is_valid_date" name="data_preventiva" v-slot="{ errors }">
                                        <DatePicker :disabled="!editMode" isId="data_preventiva" v-model="data_preventiva" />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row pointer_click" v-if="coordinate_ordine_partite.all" >
                            <div class="col-md-4" @click="chiudi_modal">
                                <i class="fa fa-map-marked-alt"  >
                                    Geolocalizza le partite del certificato
                                </i>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button v-if="!editMode && canEdit && canPerito('full')"  v-on:click.prevent="editMode = true" class="btn btn-primary">Modifica Certificato</button>
                                    <button v-if="editMode"  v-on:click.prevent="closeEditMode" class="btn btn-primary">Annulla</button>
                                    <button style="margin-left:10px" v-if="editMode  && canEdit" :disabled="invalid"  v-on:click.prevent="updateCertificato" type="submit" class="btn btn-primary">Modifica</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </ValidationObserver>
                    <div style="margin-top: 100px;" class="row">
                        <div style="border: 1px solid lightgray;padding: 10px;" class="col-md-12">
                            <ListaPartite v-if="ispettore_id && certificato_id" 
                                :data_evasione="data_evasione"
                                :canEvadi="canEvadi"
                                :editMode="editMode"
                                :varieta="varieta"
                                :eventi="eventi"
                                :consorzio="consorzio"
                                :comuni="comune.list"
                                :specie="specie"
                                :agenzie="agenzie"
                                :ispettore_id="ispettore_id"
                                :certificato_id="certificato_id"
                                :certificato="certificato"
                                :ispettorePermission="ispettorePermission"
                                @callBackPartite="callBackPartite"    
                            ></ListaPartite>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script src="https://unpkg.com/leaflet/dist/leaflet-src.js"></script>
<script>
import { ModelListSelect, MultiSelect } from 'vue-search-select'
import DatePicker from "../../components/DatePicker"
import 'vue-search-select/dist/VueSearchSelect.css'
import ListaPartite from "../../components/Certificato/ListaPartite"
import Utility from '../../services/utility'
import UtilityRepository from "../../services/apis/utility"
import apiCatasto from "../../services/apis/catasto"
import CertificatoRepository from "../../services/apis/certificato"
import * as proj4 from '../Admin/proj4';
import * as Proj from '../Admin/leaftproj4';
export default {
    components: {
        ModelListSelect, DatePicker, ListaPartite, MultiSelect
    },
    data(){
        return{
            certificato_id:null,
            ispettore_id:null,
            certificato:{},
            certificato_copy:{},
            editMode:false,
            agenzie:[],
            consorzio:[],
            tipo_mer:false,
            data_preventiva:null,
            flag_data_preventiva:null,
            data_evasione:false,
            ordini:[],
            provincia:{
                list:[],
                found:[]
            },
            comune:{
                list:[],
                provincia_list:[],
                found:[]
            },
            specie:[],
            certificati:{
                list:[],
                found:[]
            },
            varieta:[],
            eventi:[],
            partite:[],
            ispettorePermission:'',
            coordinate_ordine_partite:{
                all:false,
                coordinate:[]
            },
            showModal:false,
            isSatellite:0.2,
            tmpCoordinate:[]

        }
    },
    watch: {
        'certificato.provincia_id' (item_new, item_old){

            if(item_old && item_new !== this.certificato_copy.provincia_id){
                this.certificato.comune_id = ''
            }
            
            this.filterComuneByIdProvincia(item_new)
        },
        'flag_data_preventiva' (item) {
            if(!item){
                this.data_preventiva = null
            }
        }
    },
    computed: {
        canEdit(){

            if(this.certificato.caricamento_ordine == "automatico"){
                return false
            }

            const nr_ricolpita = this.numero_denuncia
            if(nr_ricolpita > 1){
                return false
            }

            const bollettini_evasi = this.partite.filter((partita) => {
                return partita.denunce.filter((denuncia) => {
                    return denuncia.bollettino_id && denuncia.bollettino && !denuncia.bollettino.bozza && denuncia.bollettino.approvato
                }).length > 0
            })

            if(bollettini_evasi.length > 0){
                return false
            }

            return true

        },
        numero_denuncia(){
            return this.certificato.ultima_ricolpita ? this.certificato.ultima_ricolpita[0].numero_denuncia : 1
        },
        canEvadi(){
            return this.certificato.can_evadi
        }
    },
    methods: {
         canPerito(permissions){
            return Utility.canPerito(permissions, this.ispettorePermission)
        },
        updateSatellite (e) {
            if(e.target.checked) {
                this.isSatellite = 1
                this.createMap(1,1)
            }else {
                this.isSatellite = 0.2
                this.createMap(1,1)
            }
        },
        callBackPartite(partite, refresh){
            this.partite = partite
            console.log(this.partite)
            let idPartite = [];
            this.partite.forEach(partita =>{
                idPartite.push(partita.id)
            })
            // ritorna o false o le coordinate
            this.check_if_partite_complete(idPartite)

            if(refresh){
                this.$store.dispatch('updateLoadingState', true)
                this.get_certificato();
            }
        },
        async get_certificato(){
            const _resp = await CertificatoRepository.get_certificato(this.certificato_id, this.ispettore_id).catch(() => {})

            if(_resp){
                this.certificato = _resp.data.data
                this.certificato.flag_data_preventiva = this.certificato.ultima_ricolpita[0].flag_data_preventiva
                this.certificato.data_preventiva = Utility.convertDataToEuropeanFormat(this.certificato.ultima_ricolpita[0].data_preventiva)

                this.certificato_copy = this._.cloneDeep(this.certificato, true);
                this.ordini = this.certificato.certificati.map((certificato) => {
                    return { value: certificato.id, text: `${certificato.ordine_id}`, ordine_id:certificato.ordine_id }
                })     
                this.tipo_mer = this.certificato.tipo_mer !== null && this.certificato.tipo_mer !== "S" ? true : false
                this.certificato.data_carico = Utility.convertDataToEuropeanFormat(this.certificato.data_carico)
                this.certificato.data_notifica = Utility.convertDataToEuropeanFormat(this.certificato.data_notifica)
                this.data_preventiva = Utility.convertDataToEuropeanFormat(this.certificato.ultima_ricolpita[0].data_preventiva)
                this.flag_data_preventiva = this.certificato.ultima_ricolpita[0].flag_data_preventiva
                this.data_evasione = this.certificato.ultima_ricolpita[0].data_evasione ? true : false

                // Check canEvadi
            }
            this.$store.dispatch('updateLoadingState', false)
        },

        async searchCertificati(text){
            const _resp = await UtilityRepository.cerca_ordin({per_page:9999, certificato:text, ispettore_id:this.ispettore_id})
            
            if(_resp){
                this.certificati.found = _resp.data.data.data.map((certificato) => {
                    return { value: certificato.id, text: `${certificato.ordine_id}`, ordine_id:certificato.ordine_id }
                })
            }            
        },

        onSelectCertificati(certificati){            
            this.ordini = certificati
        },
        
        async get_variables(){
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await UtilityRepository.get_variables({agenzia:1, consorzio:1, provincia:1, comune:1, specie:1, varieta:1, evento:1})
            
            if(_resp){
                this.agenzie = _resp.data.data.agenzia   
                this.consorzio = _resp.data.data.consorzio   
                this.provincia.list = _resp.data.data.provincia   
                this.provincia.found = _resp.data.data.provincia   
                this.comune.list = _resp.data.data.comune   
                this.specie = _resp.data.data.specie   
                this.eventi = _resp.data.data.evento   
            }
        },
        async get_varieta_by_specie_id(specie_id){
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await UtilityRepository.get_varieta_by_specie_id(specie_id)
            
            if(_resp){
                this.varieta = _resp.data.data  
            }
            this.$store.dispatch('updateLoadingState', false)
        },
        formattedData(id, type){
            let formatted_text = ''
            const _found = this[type].find((el) => {
                return el.id == id
            })

            if(_found){
                switch (type) {
                    case 'agenzie':
                        formatted_text = `${_found.codice_identificativo} - ${_found.nome}`
                        break;
                    case 'consorzio':
                        formatted_text = `${_found.codice} - ${_found.name}`
                        break;
                    case 'specie':
                        formatted_text = `${_found.codice_identificativo} - ${_found.etichetta}`
                        break;
                
                    default:
                        break;
                }   
            }
            return formatted_text
        },
        searchComune(text){
            const _c = this.comune.provincia_list.filter((item) => {
                return item.nome.toLowerCase().includes(text.toLowerCase())
            })

            this.comune.found = _c
        },
        searchProvincia(text){
            const _c = this.provincia.list.filter((item) => {
                return item.short.toLowerCase().includes(text.toLowerCase())
            })

            this.provincia.found = _c
        },
        filterComuneByIdProvincia(id){
            const _c = this.comune.list.filter((item) => {
                return item.provincia_id == id
            })

            this.comune.provincia_list = _c
            this.comune.found = _c
        },
        closeEditMode(){
            this.certificato = this._.cloneDeep(this.certificato_copy, true);
            this.flag_data_preventiva = this.certificato.flag_data_preventiva
            this.data_preventiva = this.certificato.data_preventiva
            this.editMode = false
        },
        async updateCertificato(){
            this.$store.dispatch('updateLoadingState', true)
            

            let obj = this._.cloneDeep(this.certificato)
            obj.data_notifica = Utility.convertDataToDBFormat(obj.data_notifica)
            obj.data_carico = Utility.convertDataToDBFormat(obj.data_carico)
            obj.data_preventiva = Utility.convertDataToDBFormat(this.data_preventiva)
            obj.flag_data_preventiva = this.flag_data_preventiva
            obj.certificati = this.ordini.map((certificato) => {
                return certificato.value
            })
            obj.ordine_id = obj.id
            obj.ispettore_id = this.ispettore_id

            const _resp = await CertificatoRepository.update_certificato(obj).catch(() => {})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.editMode = false
                this.get_certificato()
            }
            

            this.$store.dispatch('updateLoadingState', false)
        },


        async check_if_partite_complete (partite_id) {
            
            const  _resp = await apiCatasto.all_coordinate_order_partite(partite_id)
            if (_resp.data.message != false) {
                this.coordinate_ordine_partite.all = true;
                // faccio il parse delle coordinate

                let coordinate = []
                _resp.data.coordinate.map((item) => {
                    coordinate.push(JSON.parse(item));
                })
                this.coordinate_ordine_partite.coordinate= [...coordinate];
                console.log(coordinate)
            }
        },

        chiudi_modal(){
            var _this = this;
            if(this.showModal) {
                this.showModal = false
            }else {
                this.showModal = true
            setTimeout(() => {
                _this.createMap();
            }, 500);
            }
        },
        createMap () {

            /*this.showModal = true*/
            
            let coordinate =[]
            if(this.mapIsActive) {
                this.map.remove();
            }
            if(!satellite){
                coordinate = [...this.coordinate_ordine_partite.coordinate]
            }else {
                this.tmpCoordinate = [...coordinate]
            }

            proj4.defs('EPSG:6706', '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
            const Bbox_width = 18.99 - 5.93;
            const startResolution = Bbox_width / 1024;
            const resolutions = new Array(22);
                for (var i = 0; i < 22; ++i) {
                    resolutions[i] = startResolution / Math.pow(2, i);
                }


            var crs_6706 = new Proj.CRS(
                "EPSG:6706",
                "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs",
                {
                bounds: L.bounds([5.93, 34.76], [18.99, 47.1]),
                resolutions,
                /*origin: [coordinate[0][0][0],coordinate[0][0][1]],*/
                origin: [0,0],
                }
            );


            var layer = ""
            var baseLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                    maxZoom: 70,
                });

                var cadastralLayer = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "CP.CadastralParcel",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.6,
                    attribution:
                        'Geoportale Cartografico Catastale © <a href="https://geoportale.cartografia.agenziaentrate.gov.it/">Geoportale</a> contributors'
                });
                var vestizioni = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "vestizioni",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.8
                });

              var satellite = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
                    minZoom: 10,
                    maxZoom: 50,
                    subdomains:['mt1'],
                    transparent: true,
                    opacity: this.isSatellite
                });
                //var layer = ""
                this.map = L.map("leaflet", {
                    layers: [baseLayer,cadastralLayer,vestizioni,satellite],
                    center: [0,0],
                    zoom: 0,
                   /* crs: crs_6706*/
                });

                this.mapIsActive = true

                const polygonStyle = {
                    fillColor: 'white',
                    weight: 4,
                    opacity: 1,
                    color: 'red',  
                    fillOpacity: 0.0
                }
                if(coordinate.length > 1) {
                    // creo tanti poligoni quante sono le coordinate
                    var multiplePolygon = [

                    ]

                    coordinate.forEach((item1,index) => {
                        item1.forEach((item,index) => {
                            var polygon = []
                            multiplePolygon.push(
                            polygon[index]={
                                type: "Polygon",
                                coordinates: [item],
                        })
                        })

                    })

                    multiplePolygon.forEach((element,index) => {
                        layer = L.geoJSON(element);
                
                        layer.setStyle(polygonStyle);
                
                        this.map.addLayer(layer);

                    });

                    this.map.fitBounds(layer.getBounds());
                                
                }else {
                    const polygon = {
                    type: "Polygon",
                    coordinates: coordinate,
                    
                };
                layer = L.geoJSON(polygon);
                
                layer.setStyle(polygonStyle);

                this.map.addLayer(layer);
                this.map.fitBounds(layer.getBounds());
                }

}
        
    },
    async mounted(){
        this.certificato_id = this.$route.params.certificato_id
        this.ispettore_id = this.$route.params.ispettore_id
        this.ispettorePermission = Utility.getIspettorePeritoById(this.ispettore_id)

        await this.get_variables()
        await this.get_certificato()
        await this.get_varieta_by_specie_id(this.certificato.specie_id)
    }
}
</script>
<link type="text/css" rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
<style>
.pointer_click {
    cursor: pointer !important;
}
#leaflet {
    height: 500px;
    position: relative;
    top:0;
  }
  .btn-select-all {
    /* faccio lo style del button */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    margin: 0 10px;
  }

  .test-catasto {
    margin: auto;
    text-align: center;
    color: rgb(172, 72, 72);
  }
</style>