<template>
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span >SELEZIONA SUB PARTITE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
             <table v-if="partite_selezionate.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                <tbody>
                    <div v-for="(partita, index) in partite_selezionate" :key="index">
                        <tr>
                            <th width="20%" class="text-center">SELEZIONA TUTTI</th>
                            <th width="20%" class="d-none d-sm-table-cell">NR.</th>
                            <th width="20%" class="d-none d-sm-table-cell">VARIETÀ</th>
                            <th width="20%" class="d-none d-sm-table-cell">IMPORTO ASSICURATO</th>
                            <th width="20%" class="d-none d-sm-table-cell">FG. CAT.</th>
                        </tr>
                        <tr>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <input  @change="changePartitaSelezionata(partita)" type="checkbox" v-model="partita.sub_selezionata">
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.numero_partita}}    
                            </td>
                             <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.varieta.etichetta}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{ partita.importo_assicurato | toCurrency}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.foglio_catastale}}
                            </td>
                        </tr>
                        <tbody v-if="partita.sub_partite.length > 0">
                            <tr width="100%">
                                <th width="25%" class="text-center">SELEZIONATA</th>
                                <th width="25%" class="d-none d-sm-table-cell">SUB. INDEX</th>
                                <th width="25%" class="d-none d-sm-table-cell">PERCENTUALE</th>
                                <th width="25%" class="d-none d-sm-table-cell">VALORE</th>
                            </tr>
                            <tr width="100%" v-for="(sub_partita, i) in partita.sub_partite" :key="i">
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <input @change="changeSubPartitaSelezionata(sub_partita, partita)" type="checkbox" v-model="sub_partita.selezionata">
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{sub_partita.sub_index}}    
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{sub_partita.percentuale}}%    
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{sub_partita.valore}}    
                                </td>
                            </tr>
                        </tbody>
                    </div>
                    
                </tbody>
            </table>
            <div v-if="paginate.page_count > 1" class="col-sm-12 col-md-12">
                <paginate
                    v-model="paginate.page"
                    :page-count="paginate.page_count"
                    :click-handler="paginateClick"
                    :prev-text="'Sub partita Precedente'"
                    :next-text="'Sub partita Successiva'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :prev-class="'prev'"
                    :next-class="'next'">
                </paginate>
            </div>
        </template>
        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                @click="continua"
                aria-label="Nuova">
                <span>Continua</span>
            </button>
        </template>
        
    </Modal>
</template>

<script>
import Modal from "../Modal"
import Paginate from 'vuejs-paginate'

export default {
    name: 'ModalSelectPartiteSubPartite',
    props: ['isModalVisible'],
    components: {
        Modal, Paginate
    },
    data(){
        return {
            partite_selezionate:[],
            orig:[],
            paginate: {
                page_count:1,
                page:1,
                per_page:1,
                original_data:[]
            },
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModalCallback', null)
        },
        setPartiteSelezionate(partite){
            this.orig = this._.cloneDeep(partite, true)
            let _partite = partite.filter((el) => {
                if(el.selezionata && el.sub_partite.length > 0){

                    el.sub_partite = el.sub_partite.filter((el_sub) => {
                        if(!el_sub.has_all_denunce_bollettino){
                            el.sub_selezionata = true
                            el_sub.selezionata = true
                            return el_sub
                        }
                    })

                    return el
                }
            })
            this.paginate.original_data = _partite
            this.paginate.page_count = Math.ceil(this.paginate.original_data.length / 1);
            this.fakePagination()
        },
        fakePagination(){
            const {per_page, original_data, page} = this.paginate
            this.partite_selezionate = original_data.slice((page - 1) * per_page, page * per_page) 
        },
        paginateClick(pageNum){
            this.paginate.page = pageNum
            this.fakePagination()
        },
        changePartitaSelezionata(partita){
            this.paginate.original_data = this.paginate.original_data.map((el => {
                if(el.id == partita.id){
                    el.sub_selezionata = partita.sub_selezionata
                    el.sub_partite = el.sub_partite.map((el_sub) => {
                        el_sub.selezionata = partita.sub_selezionata
                        return el_sub
                    })
                }
                return el
            }))
            this.fakePagination()
        },
        changeSubPartitaSelezionata(sub_partita, partita){

            let false_found = false

            this.paginate.original_data = this.paginate.original_data.map((el => {
                if(el.id == partita.id){
                    el.sub_partite = el.sub_partite.map((el_sub) => {
                        if(el_sub.id == sub_partita.id){
                            el_sub.selezionata = sub_partita.selezionata
                        }
                        if(!el_sub.selezionata){
                            false_found = true
                        }
                        return el_sub
                    })
                }
                return el
            }))

            this.paginate.original_data = this.paginate.original_data.map((el) => {
                if(el.id == partita.id){
                    el.sub_selezionata = !false_found
                }
                return el
            })
            

            this.fakePagination()
        },
        continua(){

            const partite_no_subs = this.paginate.original_data.filter((partita) => {
                if(partita.selezionata && partita.sub_partite.length > 0){
                    const found = partita.sub_partite.filter(sub_partita => !sub_partita.selezionata)
                    if(found.length === partita.sub_partite.length){
                        return partita
                    }
                }
            })

            if(partite_no_subs.length > 0){
                this.$toasted.error("Ci deve essere almeno una sub partita selezionata per ogni partita").goAway(4000)
                return
            }
            
            const merge_data = this.orig.map((el) => {
                const found = this.paginate.original_data.find((el1) => {
                    return el1.id === el.id
                })
                
                if(found){
                    el.sub_selezionata = found.sub_selezionata
                    el.sub_partite = el.sub_partite.map((el_sub) => {
                        const sub_found = found.sub_partite.find((el_sub_1) => {
                            return el_sub.id === el_sub_1.id
                        })

                        if(sub_found){
                            el_sub.selezionata = sub_found.selezionata
                        }
                        return el_sub
                    })
                }

                return el
            })

            this.$emit('closeModalCallback', merge_data)
        }
    }    
}
</script>