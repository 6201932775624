<template>
    <div>
        <div  style="position:relative;z-index:1000;top:0px;left:0px;display:none;" id="context-menu">
            <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg" style="display: block; position: static; margin-bottom: 5px; margin-left: 5px; top: 0px; left: 0px; transform: translate3d(0px, 0px, 0px);" x-placement="bottom-end">
                <div class="dropdown-header" @click="copyvalue">Copia</div>
                <a class="dropdown-item" @click="pastevalue">Incolla singola</a>
                <a class="dropdown-item"  @click="pastevalueall">Incolla tutti</a>
            </div>
        </div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Bollettino Campagna
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <BollettinoData 
                        :readOnly="readOnly" 
                        ref="bollettino_data" 
                        @callBackSelectPeriti="peritiSelected" 
                        @callBackScomposizioneDanno="scomposizione_danno"
                        :stored_scomposizione_danno="stored_scomposizione_danno"
                        :certificato="bollettino_data.certificato"
                        :edizione="ordine && ordine.edizione ? ordine.edizione :  ''" 
                        style="margin-bottom: 70px;padding: 30px;"
                        ></BollettinoData>
                    <Bollettino 
                        ref="bollettino" 
                        @callBackSelectOsservazioni="osservazioneSelected"
                        @callBackValidations="bollettinoValidationRows"
                        @callBackSelectEvents="callBackSelectEvents" 
                        @passAllDataRaccoltaData="saveAllDataRaccoltaData"
                        @callBackACheckboxSelect="aCheckboxSelect" 
                        :last_bollettino_number="last_bollettino_number"
                        :note_deduzioni_colonna_A="note_deduzioni_colonna_A"
                        :lista_note="lista_note"
                        :note_deduzioni_danni_anteriori="note_deduzioni_danni_anteriori" 
                        :lista_eventi="lista_eventi" 
                        :partite="bollettino_data.partite" 
                        :rows="rows"
                        :denunce_blocks="denunce_blocks"
                        :denunce="bollettino_data.denunce"
                        :copy_rows_value="copy_rows_value"
                        :scomposizione="scomposizione"
                        :azzera_scomposizione="azzera_scomposizione"
                        :ordine_have_bollettino="ordine_have_bollettino"
                    ></Bollettino>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group" style="text-align:right;">
                        <button v-on:click.prevent="goBack" class="btn btn-primary">INDIETRO</button>
                        <button style="margin-left:10px;" v-if="rows_a.length > 0 && !copy.active" @click="openAssegnaModal" class="btn btn-primary">ASSEGNA A</button>
                        <button style="margin-left:10px;" v-if="rows_a.length == 1 && !copy.active" @click="setCopyRows" class="btn btn-primary">COPIA</button>
                        <button style="margin-left:10px;" v-if="copy.copy_row && copy.active" @click="annullaCopyRows" class="btn btn-primary">ANNULLA COPIA</button>
                        <button style="margin-left:10px;" v-if="copy.copy_row && copy.active && copy.paste_rows.length > 0" @click="incollaCopyRows" class="btn btn-primary">INCOLLA</button>
                        <button style="margin-left:10px;" v-on:click.prevent="openAnnotazioniModal" class="btn btn-primary">DICHIARAZIONI E ANNOTAZIONI</button>
                        <button :disabled="!can_save" v-if="bollettino_id && !readOnly" @click="salvaBollettino('preview')" style="margin-left:10px;" class="btn btn-primary">PREVIEW PDF</button>
                        <button :disabled="!can_save" v-if="!readOnly" @click="salvaBollettino('bozza')" style="margin-left:10px;" class="btn btn-primary">SALVA BOZZA</button>
                        <button v-if="bollettino_id && !readOnly" @click="eliminaBozza" style="margin-left:10px;" class="btn btn-danger">ELIMINA BOZZA</button>
                        <button :disabled="!can_save" v-if="bollettino_id && !readOnly && canCreate" @click="salvaBollettino('crea')" style="margin-left:10px;" class="btn btn-primary">CREA BOLLETTINO</button>
                        <button :disabled="!can_save" v-if="bollettino_id && bollettino_url" @click="scaricaBollettino()" style="margin-left:10px;" class="btn btn-primary">SCARICA BOLLETTINO</button>
                    </div>
                </div>
            </div>
             <div v-if="!canCreate" class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger d-flex align-items-center justify-content-between" role="alert">
                        <div class="flex-fill mr-3">
                            <p class="mb-0">il bollettino definitivo non può essere ancora creato in quanto la denuncia non è ancora pervenuta.<br> Salvare come bozza per visualizzare l'anteprima PDF</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="show_note_danni_anteriori" class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger d-flex align-items-center justify-content-between" role="alert">
                        <div class="flex-fill mr-3">
                            <p class="mb-0"> il sistema si aspetta che venga inserita una nota tra le note seguenti {1,2,3,4,5,6,7,8,9,33,34}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="show_note_deduzioni_colonna_A" class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger d-flex align-items-center justify-content-between" role="alert">
                        <div class="flex-fill mr-3">
                            <p class="mb-0"> il sistema si aspetta che venga inserita una nota tra le note seguenti:</p>
                            <ul>
                                <li>Fuori rischio</li>
                                <li style="list-style: none;">
                                    <ul>
                                        <li>{65,82,85,88}</li>
                                    </ul>
                                </li>
                                <li>Perizia Negativa</li>
                                <li style="list-style: none;">
                                    <ul>
                                        <li>{11,13,14}</li>
                                    </ul>
                                </li>
                                <li>Perizia Rinviata</li>
                                <li style="list-style: none;">
                                    <ul>
                                        <li>{71}</li>
                                    </ul>
                                </li>
                                <li>Ritiro di Denuncia</li>
                                <li style="list-style: none;">
                                    <ul>
                                        <li>{57,61}</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="errorsNote.denunceAfterCertificateDate" class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger d-flex align-items-center justify-content-between" role="alert">
                        <div class="flex-fill mr-3">
                            <p class="mb-0">La data della perizia non può essere antecedente alla data delle denunce.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="errorsNote.col10NotEqualToOther" class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger d-flex align-items-center justify-content-between" role="alert">
                        <div class="flex-fill mr-3">
                            <p class="mb-0">Scomposizione del danno non coerente.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="display_error_colonna_11" class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger d-flex align-items-center justify-content-between" role="alert">
                        <div class="flex-fill mr-3">
                            <p class="mb-0">il valore della colonna 11 non può essere negativo.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="check_events.length > 0" class="row">
                <div class="col-md-12">
                    <div class="alert alert-danger d-flex align-items-center justify-content-between" role="alert">
                        <div class="flex-fill mr-3">
                            <p class="mb-0">Seleziona l'evento per le colonne: {{check_events.join(',')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BollettinoModalAnnotazioni 
            :readOnly="readOnly"
            :isModalVisible="modals.isBollettinoModalAnnotazioniVisible"
            ref="modal_annotazioni"
            @closeModalCallback="close_modal">
        </BollettinoModalAnnotazioni>
        <BollettinoSelectA
            :isModalVisible="modals.isBollettinoSelectAVisible"
            @closeModalCallback="closeSelectAModal">
        </BollettinoSelectA>

    </div>
</template>

<script>
import Bollettino from "../../components/Certificato/Bollettino"
import BollettinoData from "../../components/Certificato/BollettinoData"
import BollettinoModalAnnotazioni from "../../components/Certificato/ModalBollettino/ModalAnnotazioni"
import BollettinoSelectA from "../../components/Certificato/ModalBollettino/SelectA"
import UtilityRepository from "../../services/apis/utility"
import CertificatoRepository from "../../services/apis/certificato"
import ApiBollettino from "../../services/apis/bollettino"
export default {
    data(){
        return {
            ispettore_id:null,
            certificato_id:null,
            lista_note:[],
            rows_a:[],
            denunce_blocks:[],
            show_note_deduzioni_colonna_A:false,
            note_deduzioni_colonna_A:{
                fuori_rischio:{
                    ids:[44,55,58,61],
                    data:[]
                },
                 perizia_negativa:{
                    ids:[11,13,14],
                    data:[]
                },
                 perizia_rinviata:{
                    ids:[49],
                    data:[]
                },
                 ritiro_di_denuncia:{
                    ids:[36,40],
                    data:[]
                }
            },
            note_deduzioni_danni_anteriori:[],
            lista_ids_danni_anteriori:[1,2,3,4,5,6,7,8,9,33,34],
            show_note_danni_anteriori:false,
            lista_eventi:[],
            numerazione_note:false,
            bollettino_id:null,
            bollettino_url:null,
            last_bollettino_number:null,
            bollettino_data:{
                partite:[],
                denunce:[],
                certificato:{
                    periti:[]
                },
                scomposizione_danno:false,
            },
            readOnly:false,
            rows:[],
            note_aggiuntive:[],
            note_aggiuntive_old:null,
            annotazioni_obj:{},
            bollettino_can_save:true,
            annotazioni:{
                complete:false,
                ore_ultimata:'',
                data_ultimata:'',
                bool_eventi:'no',
                eventi_atmosferici:'',
                bool_totalita:'si',
                bool_altre_societa:'no',
                nome_societa:'',
                misura:'',
                consegna:true,
                spedizione:false,
                ricevuta:'',
                ufficio_postale:'',
                note_text:""
            },
            can_save:false,
            modals: {
                isBollettinoModalAnnotazioniVisible:false,
                isBollettinoSelectAVisible:false
            },
            denunce:[],
            note_globali:{
                edizione:"Per il certificato in oggetto è stata fatta richiesta di riduzione da parte dell'assicurato",
                tipo_mer:"L'Assicurato prende atto che il diritto all'indennizzo maturerà solo ed esclusivamente al superamento della soglia di danno a termine di polizza relativamente all'intera produzione aziendale della specie dichiarata, anche se assicurata con uno o più certificati, ubicata in un medesimo comune. La presente perizia è soggetta a possibili limitazioni dell'indennizzo e/o all'applicazione di franchigie e scoperti se previsti dalle norme contrattuali.",
                ricolpita:"La perizia e' stata eseguita in via puramente conservativa ed il presente bollettino viene rilasciato con ogni riserva a favore della Societa' poichè la perizia è stata eseguita in mancanza della documentazione contrattuale definitiva"
            },
            errorsNote: {
                denunceAfterCertificateDate:false,
                col10NotEqualToOther:false
            },
            forceGoBack:false,
            notes:[],
            copy:{
                active:false,
                copy_row:null,
                paste_rows:[]
            },
            contextmenux:0,
            contextmenuy:0,
            contextmenu:{
                active:false,
                row:null,
                col:null
            },
            copy_value:null,
            paste_single_value:null,
            paste_value:null,
            check_events:[],
            copy_rows_value:[],
            ordine:null,
            scomposizione:"",
            azzera_scomposizione:false,
            stored_scomposizione_danno:false,
            ordine_have_bollettino:false,
            colonna_11:{},
            display_error_colonna_11:false,
        }
    },
    components: {
        Bollettino, BollettinoData, BollettinoModalAnnotazioni, BollettinoSelectA
    },
    watch:{
        'bollettino_data.certificato.data_perizia':function(){
            this.canSave()
        },
        '$route.name': function () { 
            this.get_notes()
            this.get_eventi()
            this.ispettore_id = this.$route.params.ispettore_id
            this.forceGoBack = true
            console.log(this.$route.params, 'this.$route.params')
            if(this.$route.params.bollettino_id){                
                this.$refs.bollettino.reInit()
                this.init_bollettino(this.$route.params.bollettino_id)
            }
        }
    },
    computed: {
        canCreate(){
            const denunce_manuali = this.denunce.filter(denuncia => denuncia.caricamento_ordine === "manuale").length
            if(denunce_manuali === 0){
                return true
            }

            return false
        },
    },
    methods:{
        saveAllDataRaccoltaData(type,value,row){
            console.log(type,value,row)
            console.log(this.copy_rows_value) 
            
        },
        copyvalue(value){
            this.copy_value = value
            this.contextmenu.active = false
        },
        pastevalue(value){
            this.paste_value = value
            this.contextmenu.active = false
        },
        pastevalueall(value){
            this.paste_value = value
            this.contextmenu.active = false
            //this.$refs.bollettino.set_rows_value(this.rows, this.copy.copy_row)
        },
        pasteSingleValue(value){
            this.paste_single_value = value
            this.contextmenu.active = false
        },
        closeSelectAModal(type, cause){
            if(type){
                this.$refs.bollettino.set_rows_a_value(this.rows_a, cause)
            }
            this.modals.isBollettinoSelectAVisible = false
        },  
        openAssegnaModal(){
            this.modals.isBollettinoSelectAVisible = true
        },
        aCheckboxSelect(rows){
            this.copy_rows_value=[...rows]
            if(this.copy.active){
                if(rows.length == 0){
                    this.copy = {
                        active: false, 
                        copy_row: null,
                        paste_rows:[]
                    }
                }else{
                    const rows_filter = rows.filter(el => el !== this.copy.copy_row)
                    this.copy.paste_rows = rows_filter
                }
            }
            this.rows_a = rows
        },
        setCopyRows(){
            this.copy.active = true
            this.copy.copy_row = this.rows_a[0]
        },
        annullaCopyRows(){
            this.copy = {
                active: false,
                copy_row: null,
                paste_rows:[]
            }
        },
        incollaCopyRows(){
            this.$refs.bollettino.paste_rows_values(this.copy.copy_row, this.copy.paste_rows)
        },
        callBackSelectEvents(value){
            console.log(value)
            console.log('callBackSelectEvents')
            if(this.check_events.length > 0){
                const findIndex = this.check_events.findIndex(el => el === value)
                if(findIndex !== -1){
                    this.check_events.splice(findIndex, 1)
                }
            }
            this.canSave()
        },
        bollettinoValidationRows(value){     
            this.bollettino_can_save = false

            this.errorsNote.col10NotEqualToOther = !value.col_9


            
            if(value.col_9 && value.col_10 && value.col_5 && value.col_A){
                this.bollettino_can_save = true
            }

            this.show_note_danni_anteriori = !value.col_10;
            this.show_note_deduzioni_colonna_A = !value.col_A;
            this.numerazione_note = !value.col_10;
            
            if(value.columns_16_to_20?.length){
                value.columns_16_to_20.forEach(element => {
                    const find = this.check_events.find(el => el === element);
                    if(!find){
                        this.check_events.push(element);
                    }
                });
            }else{
                this.check_events = [];
            }

            
            this.check_events = this.check_events.sort();
            if (value.column_11.value) {
                this.bollettino_can_save = false
                //this.$toasted.error('il valore della colonna 11 non può essere negativo.').goAway(4000)
                this.colonna_11[value.column_11.index] = true
                this.display_error_colonna_11 = true
            }else {
                let findEroor = false
                this.colonna_11[value.column_11.index] = false
                // this colonna_11[value] è un oggetto che contiene la chiave value e il valore true
                
                // itero tuttè le proprietà dell'oggetto colonna_11
                 for (const key in this.colonna_11) {
                    if (this.colonna_11.hasOwnProperty(key)) {
                        const element = this.colonna_11[key];
                         if (element) {
                            findEroor = true
                         }
                        }
                    }
                    if (findEroor) {
                        this.bollettino_can_save = false
                        this.display_error_colonna_11 = true
                    }else {
                        this.bollettino_can_save = true
                        this.display_error_colonna_11 = false
                    }
            }
            this.canSave();
        },
        async scaricaBollettino (){ 
            const d = new Date();
            let year = d.getFullYear();
            if(this.ordine){
                let path = `${process.env.VUE_APP_YEAR || year}/${this.bollettino_url.codice_perito}/${this.ordine.ordine_id}/${this.bollettino_url.url}`
                 let res = await ApiBollettino.get_temporary_url(path,'_blank')
                window.open(res,'_blank');
            }else{
                let path = `${process.env.VUE_APP_YEAR || year}/${this.bollettino_url.codice_perito}/${this.bollettino_url.certificato}/${this.bollettino_url.url}`
                let res = await ApiBollettino.get_temporary_url(path,'_blank')
                window.open(res,'_blank');
                //window.open(`${process.env.VUE_APP_AWS_URL}/${process.env.VUE_APP_YEAR}/${this.bollettino_url.codice_perito}/${this.bollettino_url.certificato}/${this.bollettino_url.url}`,'_blank');
            }
        },
        goBack(){
            if(!this.forceGoBack){
                window.history.back();
                this.$store.dispatch('updateBollettinoState',null)
            }else{
                this.$router.push({name:'modifica-certificato', params:{ispettore_id:this.ispettore_id, certificato_id:this.certificato_id}})
            }
        },
        canSave(){
            if(!this.bollettino_data.certificato.data_perizia){
                this.can_save = false
                return
            }

            if (this.errorsNote.col10NotEqualToOther) {
                this.can_save = false
                return
            }
            
            const found = this.denunce.find(_denuncia => {
                const _data_perizia_moment = this.$moment(this.bollettino_data.certificato.data_perizia, 'DD-MM-YYYY');
                const _data_denuncia = this.$moment(_denuncia.data);

                if(!_data_denuncia.isSameOrBefore(_data_perizia_moment)){                    
                    return _denuncia
                }
            })

            if(found){
                this.errorsNote.denunceAfterCertificateDate = true
                this.can_save = false
                return
            }
        
            if(this.check_events.length > 0){
                this.can_save = false
                return
            }

            this.errorsNote.denunceAfterCertificateDate = false
            this.can_save = this.annotazioni.complete && this.annotazioni.data_consegna && this.bollettino_data.certificato.periti.length > 0 && this.bollettino_can_save
        },
        async get_notes(){
            const _resp = await UtilityRepository.get_note().catch(() => {})
            if(_resp){                
                this.note_deduzioni_colonna_A.fuori_rischio.data = this.note_deduzioni_colonna_A.fuori_rischio.ids.map(id => {
                    return _resp.data.data.find(el => el.id == id).nome
                })
                this.note_deduzioni_colonna_A.perizia_negativa.data = this.note_deduzioni_colonna_A.perizia_negativa.ids.map(id => {
                    return _resp.data.data.find(el => el.id == id).nome
                })
                this.note_deduzioni_colonna_A.perizia_rinviata.data = this.note_deduzioni_colonna_A.perizia_rinviata.ids.map(id => {
                    return _resp.data.data.find(el => el.id == id).nome
                })
                this.note_deduzioni_colonna_A.ritiro_di_denuncia.data = this.note_deduzioni_colonna_A.ritiro_di_denuncia.ids.map(id => {
                    return _resp.data.data.find(el => el.id == id).nome
                })

                this.lista_note = _resp.data.data.map((el) => {
                    const found = this.lista_ids_danni_anteriori.find(id => id == el.id)

                    if(found){
                        this.note_deduzioni_danni_anteriori.push(el.nome)
                    }

                    let _el = {
                        id:el.id,
                        value:el.nome,
                        text:el.nome,
                        descrizione:el.descrizione,
                    }

                    return _el
                })
            }
        },
        async get_eventi(){
            const _resp = await UtilityRepository.get_eventi().catch(() => {})
            if(_resp){
                const _eventi = _resp.data.data.filter(el => el.id != 11 && el.id != 12 && el.id != 14)
                this.lista_eventi = _eventi.map((el) => {

                    let _el = {
                        value:el.short_code,
                        text:el.nome,
                    }

                    return _el
                })
            }
        },
        osservazioneSelected(annotazione, row){
            if(row === undefined){return}
            let _annotazione_arr = annotazione && annotazione !== "" ? annotazione.split('|') : []
            
            let notes = this._.cloneDeep(this.notes, true)
            for (let i = 0; i < notes.length; i++) {
                const note = notes[i];
                const found = note.rows.find(r => r.toString() === row.toString())
                
                if(found !== null && found !== undefined){
                    const ann_found = _annotazione_arr.find(ann => ann == note.text)
                    if(!ann_found){
                        notes[i].rows = notes[i].rows.filter(r => r.toString() !== row.toString())
                        if( notes[i].rows.length === 0){
                            notes.splice(i, 1)
                        }
                    }else{
                        _annotazione_arr = _annotazione_arr.filter(_ann => _ann !== ann_found)
                    }
                }
            }  
            
            if(_annotazione_arr.length > 0){
                for (let i = 0; i < _annotazione_arr.length; i++) {
                    const _ann = _annotazione_arr[i];
                    const _ann_found = notes.find(n => n.text === _ann)
                    if(_ann_found){
                        notes = notes.map(note => {
                            if(note.text === _ann){
                                note.rows.push(row)
                            }

                            return note
                        })
                    }else{
                        const _n = this.lista_note.find(el => el.value === _ann)
                        notes.push({note_id:_n.id, text:_ann, edit:_n.descrizione, rows:[row]})
                    }
                }
            }
            this.notes = notes
        },
        openAnnotazioniModal(){
            this.$refs.modal_annotazioni.set(this.annotazioni, this.notes)
            this.modals.isBollettinoModalAnnotazioniVisible = true
        },
        close_modal(annotazioni, notes){
            if(annotazioni){
                this.annotazioni = annotazioni
                this.canSave()
            }
            if(notes){
                this.notes = notes
            }

            this.modals.isBollettinoModalAnnotazioniVisible = false
        },
        peritiSelected(periti){
            this.bollettino_data.certificato.periti = periti
            this.canSave()
        },
        scomposizione_danno(isScomposizione){
            this.azzera_scomposizione = false
            this.scomposizione = isScomposizione
            if(!isScomposizione){
                this.azzera_scomposizione = true
            }
        },
        async eliminaBozza(){
            this.$store.dispatch('updateLoadingState', true)

            const _resp = await CertificatoRepository.delete_bollettino(this.bollettino_id, this.ispettore_id).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.$router.push({name:'modifica-certificato', params:{ispettore_id:this.ispettore_id, certificato_id:this.certificato_id}})
            }

        },
        async salvaBollettino(type){
            
            if(!this.annotazioni || !this.annotazioni.complete){
                this.$toasted.error('Devi rivedere le dichiarazioni e annotazioni prima di salvare.').goAway(4000)
                return;
            }

            const note = this.annotazioni.note_text
            if(note && note.split('$$$').length-1 > 0){
                this.$toasted.error('si prega di compilare tutti i campi dinamici all\'interno delle note di riserva sostituendo i campi marcati come $$$').goAway(4000)
                return
            }
            
            if(this.notes.length > 0){
                const found = this.notes.find(note => note.edit.split('$$$').length-1 > 0)
                if(found){
                    this.$toasted.error('si prega di compilare tutti i campi dinamici all\'interno delle note di riserva sostituendo i campi marcati come $$$').goAway(4000)
                    return
                }
            }
            
           let obj = this._.cloneDeep(this.annotazioni, true)
            obj.ordine_id = this.bollettino_data.certificato.id
            obj.bozza = type === "bozza" || type === "preview" ? true : false
            obj.ispettore_id = this.ispettore_id
            obj.data_perizia = this.$moment(this.bollettino_data.certificato.data_perizia, "DD-MM-YYYY").format("YYYY-MM-DD")
            obj.bool_altre_societa = obj.bool_altre_societa === "si" ? true : false
            obj.bool_eventi = obj.bool_eventi === "si" ? true : false
            obj.bool_totalita = obj.bool_totalita === "si" ? true : false
            obj.ora_data_ultimata = this.$moment(this.annotazioni.data_ultimata+' '+this.annotazioni.ore_ultimata, "DD-MM-YYYY HH:mm").format("YYYY-MM-DD HH:mm:ss")
            obj.bollettino_rows = this.$refs.bollettino.get_jexcel_data().rows
            obj.periti = this.$refs.bollettino_data.get_data()
            obj.denunce = this.$refs.bollettino.get_jexcel_data().denunce_col.join(',')
            obj.denunce_to_save = this.$refs.bollettino.get_jexcel_data().denunce
            obj.numerazione_note = this.numerazione_note // da sistemare
            obj.notes = this.notes.map(nota => {
                return {
                    note_id:nota.note_id,
                    text: nota.text,
                    edit: nota.edit,
                    rows: nota.rows.join(',')
                }
            })
            
            if(this.note_aggiuntive.length > 0){
                if(!this.note_aggiuntive_old){
                    obj.note_aggiuntive = this.note_aggiuntive.join("|")    
                }else{
                    obj.note_aggiuntive = this.note_aggiuntive_old += "|"+this.note_aggiuntive.join("|")
                }

            }

            obj.preview = false
            if(type === "preview"){
                obj.preview = true
            }
            if(obj.data_consegna){
                obj.data_consegna = this.$moment(obj.data_consegna, "DD-MM-YYYY").format("YYYY-MM-DD")
            }
            
            let _resp = null
            this.$store.dispatch('updateLoadingState', true)
            obj.scomposizione_danno_evento = this.scomposizione ? this.scomposizione : false
            if(this.bollettino_id){
                obj.id = this.bollettino_id
                // qui check evemts scomposiziopne
                _resp = await CertificatoRepository.update_bollettino(obj).catch(() => {})
            }else{
                 _resp = await CertificatoRepository.create_bollettino(obj).catch(() => {})
            }
            this.$store.dispatch('updateLoadingState', false)
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(4000)
                if(!this.bollettino_id){
                    //this.init_bollettino(_resp.data.data.bollettino_id)
                    this.$router.push({name:'modifica-bollettino', params:{
                        ispettore_id:obj.ispettore_id, certificato_id:obj.ordine_id, bollettino_id:_resp.data.data.bollettino_id
                    }})
                }

                if(!obj.bozza){
                    this.$router.push({name:'lista-certificati', params:{ispettore_id:this.ispettore_id}})
                }
                
                if(type === 'preview'){
                    var bytes = new Uint8Array(_resp.data);
                    var blob=new Blob([bytes], {type: "application/pdf"});
                    var link=document.createElement('a');
                    link.href=window.URL.createObjectURL(blob);
                    link.download="bollettino.pdf";
                    link.click();
                }
            }
            
        },
        parseDenunceFromLastBollettino({partite, bozza = true, id = null, _denunce_arr}){
            if(!bozza){
                return
            }
            let _denunce_list = []
            let _denunce_blocks =[]
            partite.forEach(partita => {
                if(partita.last_bollettino){
                    const _d = partita.last_bollettino.denunce.split(',')
                    for (let i = 0; i < _d.length; i++) {
                        const element = _d[i];
                        if(!_denunce_list[i]){
                            if(element != "-"){
                                _denunce_blocks.push(i)
                            }
                            _denunce_list.push(element)
                        }else{
                            if(_denunce_list[i] == "-"){
                                if(element != "-"){
                                    _denunce_blocks.push(i)
                                }
                                _denunce_list[i] = element
                            }
                        }
                    }                  
                }
            });

            if(_denunce_list.length > 0){
                let newDenuncie = _denunce_arr?.filter(denuncia => !denuncia?.data_evasione)?.map(denuncia => denuncia?.evento?.short_code)
                if(newDenuncie?.length){

                    _denunce_list =  _denunce_list.map((denuncia) => {
                        if(denuncia === "-" && newDenuncie[0]){
                            const event = newDenuncie[0];
                            newDenuncie.shift();
                            return event
                        }

                        return denuncia;
                    })
                    
                }
                
                this.bollettino_data.denunce = this.splitEvento(_denunce_list) 
                this.denunce_blocks = _denunce_blocks
            }else{
                if(!id){
                    // if it is a new bollettino we set the events of the complaints automatically by removing duplicates
                    let eventi = _denunce_arr?.map(denuncia => denuncia?.evento?.short_code || '-');
                    eventi = eventi?.filter( function( item, index, inputArray ) {
                        return inputArray.indexOf(item) == index;
                    });

                    const eventiDenunce = [];
                    for (let i = 0; i < 5; i++) {
                        eventiDenunce.push(eventi[i] || '-');
                    }

                    this.bollettino_data.denunce = this.splitEvento(eventiDenunce);
                }
            }
        },
        async init_new(bollettino){
            console.log(bollettino?.certificato?.id)
            if(bollettino?.certificato?.id){
                const have_bolletino = await ApiBollettino.check_bollettino_ordine(bollettino?.certificato?.id)
                console.log(have_bolletino.data.data)
                this.ordine_have_bollettino = have_bolletino.data.data
            }
            this.annotazioni.ore_ultimata = this.$moment().format("HH:mm")
            this.bollettino_data = bollettino
            this.last_bollettino_number = bollettino.certificato.last_bollettino_number
            let _denunce = {}
            this.bollettino_data.partite.forEach(partita => {
                if(partita.selezionata){
                    partita.denunce.forEach(denuncia => {
                        if(!_denunce[denuncia.data+'-'+denuncia.evento_id+'-'+denuncia.n_danno]){
                        _denunce[denuncia.data+'-'+denuncia.evento_id+'-'+denuncia.n_danno] = denuncia 
                        }
                    });
                }
                
            });

            if(parseInt(bollettino.certificato.edizione) == 1){
                this.note_aggiuntive.push(this.note_globali.edizione)
            }
            if(bollettino.certificato.tipo_mer == "A"){
                this.note_aggiuntive.push("L'Assicurato prende atto che il diritto all'indennizzo maturerà solo ed esclusivamente al superamento della soglia di danno a termine di polizza relativamente all'intera produzione aziendale della specie dichiarata, anche se assicurata con uno o più certificati, ubicata in un medesimo comune. La presente perizia è soggetta a possibili limitazioni dell'indennizzo e/o all'applicazione di franchigie e scoperti se previsti dalle norme contrattuali.")
            }
            if(bollettino.certificato.ultima_ricolpita[0].caricamento_ordine == "manuale"){
                this.note_aggiuntive.push(this.note_globali.ricolpita)
            }

            if(this.note_aggiuntive.length > 0){
                const _ann = this.note_aggiuntive.join(",")
                this.annotazioni.note_text = _ann
            }
        
            let _denunce_arr = [];
            
            for (const key in _denunce) {
                if (_denunce.hasOwnProperty(key)) {
                    const element = _denunce[key];
                    _denunce_arr.push(element)
                }
            }

            let _denunce_order = this._.orderBy(_denunce_arr, ['data'], ['desc']);
            _denunce_order.length = _denunce_order.length > 5 ? 5 : _denunce_order.length
            
            _denunce_order = _denunce_order.map((el) => {
                return `(${el.n_danno} / ${el.data} / ${el.evento.short_code})` 
            })
            
            this.bollettino_data.certificato.denunce_order = _denunce_order.join(' ')
            this.bollettino_data.certificato.periti = []
        
            this.parseDenunceFromLastBollettino({partite:bollettino.partite, _denunce_arr})
            this.$refs.bollettino.init_new_rows(bollettino.partite)
        },

        splitEvento(evento) {
            console.log(evento,'evento')
            let eventi_scomposti = []
            evento.forEach(el => {
                if(el.includes('+')){
                    const eventi = el.split('+')
                    eventi.forEach(e => {
                    eventi_scomposti.push(e.trim())
                })
                }else {
                    eventi_scomposti.push(el.trim())
                }
            })
            for (let i = 0; i<=5; i++ ) {
                if (i <= eventi_scomposti.length) {
                    continue;
                }
                eventi_scomposti.push('-')
            }
            

            /* nuovo codice */
            let uniqueArr = [...new Set(eventi_scomposti)].filter(item => item !== '-');

            // Aggiungi "-" fino a raggiungere 5 elementi
            while (uniqueArr.length < 5) {
                uniqueArr.push('-');
            }

            // Se ci sono più di 5 elementi, tronca l'array
            uniqueArr = uniqueArr.slice(0, 5);

            console.log(uniqueArr); // Stampa l'array finale
            /* fine nuovo codice, prima return eventi_scomposti */ 
            return uniqueArr
        },
        async init_bollettino(bollettino_id){
            console.log("qui, caricamento bollettino")
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await CertificatoRepository.get_bolletino(bollettino_id, this.ispettore_id).catch(() => {})
            console.log(_resp.data.bollettino.last_bollettino_number)
            this.stored_scomposizione_danno = _resp.data.bollettino.dati.scomposizione_danno_evento
            this.$store.dispatch('updateLoadingState', false)

            if(!_resp){
                this.$toasted.error("Nessun bollettino connesso alla denuncia").goAway(4000)
                this.$router.push({name:'lista-certificati', params:{ispettore_id:this.ispettore_id}})
                return;
            }

            const _obj = _resp.data
            const have_bolletino = await ApiBollettino.check_bollettino_ordine(_obj.bollettino.ordine_id)
            this.ordine_have_bollettino = have_bolletino.data.data
            this.ordine = _resp.data.bollettino.ordine            
            const data_ora_ultimata = this.$moment(_obj.bollettino.dati.ora_data_ultimata).utc()
            this.certificato_id = _obj.bollettino.ordine_id
            this.annotazioni.ore_ultimata = data_ora_ultimata.format("HH:mm")
            this.annotazioni.data_ultimata = data_ora_ultimata.format("DD-MM-YYYY")
            this.annotazioni.bool_eventi = _obj.bollettino.dati.bool_eventi ? "si" : "no"
            this.annotazioni.eventi_atmosferici = _obj.bollettino.dati.eventi_atmosferici
            this.annotazioni.bool_altre_societa = _obj.bollettino.dati.bool_altre_societa ? "si" : "no"
            this.annotazioni.nome_societa = _obj.bollettino.dati.nome_societa
            this.annotazioni.misura = _obj.bollettino.dati.misura
            this.annotazioni.data_consegna = _obj.bollettino.dati.data_consegna ? this.$moment(_obj.bollettino.dati.data_consegna).format("DD-MM-YYYY") : null
            this.annotazioni.consegna = _obj.bollettino.dati.consegna
            this.annotazioni.spedizione = _obj.bollettino.dati.spedizione
            this.annotazioni.n_ricevuta = _obj.bollettino.dati.n_ricevuta
            this.annotazioni.ufficio_postale = _obj.bollettino.dati.ufficio_postale
            this.annotazioni.note_text = _obj.bollettino.dati.note_text
            this.annotazioni.complete = true;
            this.notes = _obj.bollettino.note.map(nota => {
                const rows = nota.pivot.rows.split(',')
                return {
                    note_id: nota.pivot.nota_id, 
                    text:nota.pivot.text,
                    edit:nota.pivot.edit,
                    rows:rows.map(r => parseInt(r))
                }
            })

            //NOTA: Porzione di codice per adattare la vecchia gestione delle note con la nuova, a regime rimuovere questa porzione
            if(this.notes.length === 0 ){
                let _notes = []
                for (let i = 0; i < _obj.bollettino.bollettino_rows.length; i++) {
                    const row = _obj.bollettino.bollettino_rows[i];
                    if(row['_14']){
                        const rowNotes = row['_14'].split('|')

                        rowNotes.forEach(_not => {
                            const _ann_found = _notes.find(n => n.text === _not)
                            if(_ann_found){
                                _notes = _notes.map(note => {
                                    if(note.text === _not){
                                        note.rows.push(i)
                                    }

                                    return note
                                })
                            }else{
                                const _n = this.lista_note.find(el => el.value === _not)
                                _notes.push({note_id:_n.id, text:_not, edit:_n.descrizione, rows:[i]})
                            }   
                        });
                    }
                }
                this.notes = _notes
            }
            //END NOTA DA RIMUOVERE
            
            if(_obj.bollettino.dati.url_pdf){
                this.bollettino_url = {
                    url:_obj.bollettino.dati.url_pdf,
                    certificato:_obj.bollettino.cert,
                    codice_perito:_obj.bollettino.codice_perito
                }
            }

            this.last_bollettino_number = _obj.bollettino.last_bollettino_number
            this.bollettino_id = _obj.bollettino.dati.id
            this.denunce = _obj.bollettino.dati.lista_denunce
            this.bollettino_data.denunce = _obj.bollettino.dati.denunce.split(",")
            this.bollettino_data.certificato = {
                agenzia:_obj.bollettino.agenzia,
                certificato:_obj.bollettino.cert,
                data_notifica:this.$moment(_obj.bollettino.data_notifica, "YYYY-MM-DD").format("DD-MM-YYYY"),
                contraente:_obj.bollettino.contraente,
                denominazione:_obj.bollettino.assicurato,
                denunce_order:_obj.bollettino.denunce,
                data_perizia:this.$moment(_obj.bollettino.data_perizia, "YYYY-MM-DD").format("DD-MM-YYYY"),
                specie:_obj.bollettino.specie,
                comune:_obj.bollettino.comune,
                periti:_obj.bollettino.periti.map((el) => {
                    return {value:el.id, text:`${el.codice_perito} - ${el.surname.toUpperCase()}`}
                })
            }
            this.note_aggiuntive_old = _obj.bollettino.dati.note_aggiuntive 

            if(_obj.bollettino.dati.bozza){
                
                if(_obj.bollettino.dati.note_aggiuntive){
                    const _note_aggiuntive = _obj.bollettino.dati.note_aggiuntive.split("|")

                    if(parseInt(_obj.bollettino.edizione) == 1){
                        if(!_note_aggiuntive.includes(this.note_globali.edizione)){
                            this.note_aggiuntive.push(this.note_globali.edizione)
                        }
                    }
                    if(_obj.bollettino.tipo_mer == "A"){
                        
                        if(!_note_aggiuntive.includes(this.note_globali.tipo_mer)){
                            this.note_aggiuntive.push(this.note_globali.tipo_mer)
                        }
                    }
                    if(_obj.bollettino.ricolpita == "manuale"){
                        if(!_note_aggiuntive.includes(this.note_globali.ricolpita)){
                            this.note_aggiuntive.push(this.note_globali.ricolpita)
                        }
                    }
                }else{
                    if(parseInt(_obj.bollettino.edizione) == 1){
                        this.note_aggiuntive.push(this.note_globali.edizione)
                    }
                    if(_obj.bollettino.tipo_mer == "A"){
                        this.note_aggiuntive.push(this.note_globali.tipo_mer)
                    }
                    if(_obj.bollettino.ricolpita == "manuale"){
                        this.note_aggiuntive.push(this.note_globali.ricolpita)
                    }
                }

                if(this.note_aggiuntive.length > 0){
                    
                    let diff = this.note_aggiuntive.filter(nota => this.annotazioni.note_text.search(nota) == -1)
                    
                    if(diff.length > 0){
                        const _ann = this.note_aggiuntive.join(",")
                        if(this.annotazioni.note_text){
                            this.annotazioni.note_text += ', '+_ann
                        }else{
                            this.annotazioni.note_text = _ann
                        }
                    }
                }
            }

            if(this.$store.getters.user.role == 'direzione'){
                this.readOnly = true
            }else{
                this.readOnly = !_obj.bollettino.dati.bozza
            }
            
            this.$refs.bollettino_data.set_data(this.bollettino_data.certificato.periti)
            this.rows = _obj.bollettino.bollettino_rows
            this.can_save = true
            this.$refs.bollettino.parse_row_data(this.rows, this.readOnly)
        }
    },
    async mounted(){
        // aggancio un listener per il salvataggio del bollettino
        // il listener è agganciato al tag td con proprietà data-x="13"
        // il listner deve rtornare il valore nella cella quando clicco con il tasto destro del mouse

        
        let _this = this 
        document.addEventListener('contextmenu', function(e) {
            if(e.target && e.target.dataset.x == 13){
                e.preventDefault()
                /*_this.$refs.bollettino.set_cell_value(e.target)*/
                console.log(_this.contextmenu.active)
                console.log(e.target.textContent)
                // modifico lo style del div  #context-menu con jquery
                // selexiono il div con id context-menu e modifico il css
                var p = document.querySelector("#context-menu");
                p.style.top = (e.pageY -100)+'px';
                p.style.left = e.pageX+'px';
                //p.style.display = 'block';
                console.log(p.style.display)
                if(p.style.display == 'block'){
                    p.style.display ='none'
                }else {
                    p.style.display ='block'
                }




            }
        }, false);

        await this.get_notes()
        this.get_eventi()
        this.ispettore_id = this.$route.params.ispettore_id
        if(this.$route.params.bollettino_id){
            this.init_bollettino(this.$route.params.bollettino_id)
        }else{
            const bollettino = this.$store.getters.bollettino
            if(bollettino){
                this.init_new(bollettino)
                return
            }
            this.$toasted.error("Problemi nella creazione del bollettino, riprova o contatta l'assistenza").goAway(4000)
            window.history.back();
        }
    }
}
</script>