import api from '../api.js'
import store from '../../store'
import utility from '../utility'

export default {
    get_user_data(){        
        return api.get(`/v2/user/get-user-data?token=${store.getters.access_token}`);
    },
    update_user_invoice_data(obj){        
        return api.put(`/v2/user/update-user-invoice?token=${store.getters.access_token}`, obj);
    },
    update_user_email(obj){        
        return api.put(`/v2/user/update-email?token=${store.getters.access_token}`, obj);
    },
    update_user_password(obj){        
        return api.put(`/v2/user/update-password?token=${store.getters.access_token}`, obj);
    },
    grades(){        
        return api.get(`/v2/user/grades?token=${store.getters.access_token}`);
    },
    get_ispettori_associati() {
        return api.get(`/v2/user/ispettori-associati?token=${store.getters.access_token}`);
    },
    get_periti_associati() {
        return api.get(`/v2/user/periti-associati?token=${store.getters.access_token}`);
    },
    add_user_invoice(obj) {
        return api.post(`/v2/user/add-user-invoice?token=${store.getters.access_token}`, obj);
    },
    update_associa_perito(search) {
        return api.patch(`/v2/user/update-associa-perito?token=${store.getters.access_token}`, search);
    },
    associa_perito(search) {
        return api.post(`/v2/user/associa-perito?token=${store.getters.access_token}`, search);
    },
    disassocia_perito(search) {
        var _search = utility.appendParameters(search); 
        return api.delete(`/v2/user/disassocia-perito?token=${store.getters.access_token}&${_search}`);
    },
    get_squadra_periti(){
        return api.get(`/v2/user/squadra-perito?token=${store.getters.access_token}`);
    },
    get_report_periti(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/user/report-periti?token=${store.getters.access_token}&${_search}`);
    },
    cerca_perito_libero(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/user/cerca-perito-libero?token=${store.getters.access_token}&${_search}`);
    },
    post_squadra_periti(obj){
        return api.post(`/v2/user/squadra-perito?token=${store.getters.access_token}`, obj);
    },
    put_squadra_periti(id, obj){
        return api.put(`/v2/user/squadra-perito/${id}?token=${store.getters.access_token}`, obj);
    },
    get_tabella_periti(){
        return api.get(`/v2/user/get_tabella_periti?token=${store.getters.access_token}`);
    },
    get_data_input_periti(){
        return api.get(`/v2/user/get_data_input_periti?token=${store.getters.access_token}`);
    },
    save_tabella_periti(obj) {
        return api.post(`/v2/user/save_tabella_periti?token=${store.getters.access_token}`,obj);
    }
}