<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Esportazione CSV
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <form>
                            <div class="row" style="margin-top: 0px; margin-bottom: 20px">
                                <div class="col-sm-12 col-md-12">
                                    <div class="form-material" style="text-align: left; margin-top: 0px;">
                                        <button class="btn btn-sm btn-primary" type="button" @click="espAnagUtenti">Esporta Anagrafica Utenti</button>
                                    </div>
                                    <div class="form-material" style="text-align: left; margin-top: 20px;">
                                        <button class="btn btn-sm btn-primary" type="button" @click="espSpec">Esporta Specifiche</button>
                                    </div>
                                    <div class="form-material" style="text-align: left; margin-top: 20px;">
                                        <button class="btn btn-sm btn-primary" type="button" @click="espPer">Esporta Perizie</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import UtilityRepository from "../../services/apis/utility"
export default {
    methods: {
        async espAnagUtenti(){

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await UtilityRepository.export_anagrafiche().catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download =`anagrafica_utenti.csv` ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        async espSpec(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await UtilityRepository.export_specifiche().catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download =`anagrafica_specifiche.csv` ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        async espPer(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await UtilityRepository.export_perizie().catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download =`anagrafica_perizie.csv` ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        },
    },
}
</script>
<style>

    span.label {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }
    span.label-danger {
        background-color: #d26a5c;
    }
    span.label-success {
        background-color: #60d25c;
    }
</style>