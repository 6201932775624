import api from '../api.js'
import store from '../../store'
import utility from '../utility'

export default({
    get_lista_certificati(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/ordini/lista-ordini?token=${store.getters.access_token}&${_search}`);
    },
    get_lista_bollettini(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/ordini/lista-bollettini?token=${store.getters.access_token}&${_search}`);
    },
    get_lista_certificati_archiviati(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/ordini/lista-ordini-archiviati?token=${store.getters.access_token}&${_search}`);
    },
    new_certificato(obj){
        return api.post(`/v2/ordini/nuovo?token=${store.getters.access_token}`, obj);
    },
    update_certificato(obj){
        return api.put(`/v2/ordini/update?token=${store.getters.access_token}`, obj);
    },
    new_ricolpita(obj){
        return api.post(`/v2/ordini/nuova-ricolpita?token=${store.getters.access_token}`, obj);
    },
    get_certificato(id, ispettore_id){
        return api.get(`/v2/ordini/ordine?token=${store.getters.access_token}&ordine_id=${id}&ispettore_id=${ispettore_id}`);
    },
    delete_certificato(id, ispettore_id){
        return api.delete(`/v2/ordini/elimina-ordine/${id}?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`);
    },
    evadi_certificato(obj){
        return api.post(`/v2/ordini/evadi?token=${store.getters.access_token}`, obj);
    },
    rimuovi_ultima_evasione(obj){
        return api.post(`/v2/ordini/rimuovi-ultima-evasione?token=${store.getters.access_token}`, obj);
    },
    get_bolletino_by_denuncia(id, ispettore_id){
        return api.get(`/v2/ordini/bollettino-da-denuncia/${id}?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`);
    },
    get_bolletino(id, ispettore_id){
        return api.get(`/v2/ordini/bollettino/${id}?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`);
    },
    get_bolletino_rows(id, ispettore_id){
        return api.get(`/v2/ordini/bollettino/${id}/rows?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`);
    },
    get_bolletino_dati(id, ispettore_id){
        return api.get(`/v2/ordini/bollettino/${id}/dati?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`);
    },
    create_bollettino(obj){
        return api.post(`/v2/ordini/crea-bollettino?token=${store.getters.access_token}`, obj);
    },
    delete_bollettino(id, ispettore_id){
        return api.delete(`/v2/ordini/bollettino/${id}?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`);
    },
    approva_bollettino(obj){
        return api.post(`/v2/ordini/approva-bollettino?token=${store.getters.access_token}`, obj);
    },
    update_bollettino(obj){
        const h = obj.preview ? {responseType: 'arraybuffer'} : ''
        return api.put(`/v2/ordini/update-bollettino?token=${store.getters.access_token}`, obj, h);
    },
    get_partite_by_ordine_id(search){        
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                _search.push(`${key}=${element}`)
            }
        }

        _search = _search.join('&')     
        return api.get(`/v2/partite/partite?token=${store.getters.access_token}&${_search}`);
    },
    new_partita(obj){
        return api.post(`/v2/partite/nuova?token=${store.getters.access_token}`, obj);
    },
    edit_partita(obj){
        return api.patch(`/v2/partite/modifica?token=${store.getters.access_token}`, obj);
    },
    delete_partita(id, ispettore_id){
        return api.delete(`/v2/partite/partita/${id}?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`);
    },
    new_sub_partita(obj){
        return api.post(`/v2/partite/nuova-subpartita?token=${store.getters.access_token}`, obj);
    },
    new_denuncia(obj){
        return api.post(`/v2/partite/nuova-denuncia?token=${store.getters.access_token}`, obj);
    },
    edit_denuncia(obj){
        return api.patch(`/v2/partite/modifica-denuncia?token=${store.getters.access_token}`, obj);
    },
    delete_denuncia(obj){
        var _search = utility.appendParameters(obj);   
        return api.delete(`/v2/partite/cancella-denuncia?token=${store.getters.access_token}&${_search}`);
    },
    report_totale_certificati(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/ordini/report-totale-ordini?token=${store.getters.access_token}&${_search}`);
    },
    report_carico_scarico(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/ordini/report-carico-scarico?token=${store.getters.access_token}&${_search}`);
    },
    report_carico_scarico_update(search){
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/ordini/report-carico-scarico-update?token=${store.getters.access_token}&${_search}`);
    },
    report_varieta(search){
        return api.post(`/v2/ordini/report-varieta?token=${store.getters.access_token}`, search);
    },
    get_csv_actions(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/csv/csv-actions?token=${store.getters.access_token}&${_search}`);
    },
    get_csv_actions_histories(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/csv/csv-actions-histories?token=${store.getters.access_token}&${_search}`);
    },
    print_bollettino_from_certificato(ordine_id,ispettore_id) {
        return api.get(`/v2/print/bollettino-from-ordine/${ordine_id}?token=${store.getters.access_token}&ispettore_id=${ispettore_id}`, {responseType: 'arraybuffer'});
    },
    get_denunce_from_data_perizia(obj){
        return api.post(`/v2/ordini/denunce-non-liquidate?token=${store.getters.access_token}`, obj);
    },
    get_report_direzione_ordini(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/ordini/report-direzione?token=${store.getters.access_token}&${_search}`);
    },
    print_report_direzione(search){
        var _search = utility.appendParameters(search);    
        return api.get(`/v2/print/report-direzione?token=${store.getters.access_token}&${_search}`, {responseType: 'arraybuffer'});
    }
});