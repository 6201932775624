
import api from '../api.js'


export default({
    getAllCatasto(){        
        return api.get('/v2/coordinate/givemecoordinate/');
    },
    single_coordinate(id){
        return api.get(`/v2/coordinate/single_coordinate/${id}`);
    },

    all_coordinate_order_partite(obj){
        return api.post('/v2/coordinate/all_coordinate_order_partite/',obj);
    }
});