<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Nuova Specifica Giornaliera <small>Caricamento dei dati per il rimborso spese.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title">DATI GENERALI</h3>
                </div>
                <div class="block-content block-content-full">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                    <form>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-ltf-email">Scegli Data</label>
                                    <ValidationProvider rules="required|is_valid_date|is_after_today" name="data_specifica" v-slot="{ errors }">
                                        <DatePicker isId="data_specifica" v-model="specifica.data_specifica" />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Ispettore per l'approvazione</label>
                                    <ValidationProvider rules="required" name="ispettore" v-slot="{ errors }">
                                        <select v-model="specifica.ispettore_id" class="form-control" name="ispettore">
                                            <option value>Seleziona Ispettore</option>
                                            <option v-for="ispettore in ispettori" :key="ispettore.id" :value="ispettore.id" >
                                                {{ispettore.name}} {{ispettore.surname}}
                                            </option>
                                        </select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4 automobile">
                                <div class="form-group">
                                    <label for="example-select">Auto</label><br/>
                                    <div class="auto_check">
                                        <input type="checkbox" v-model="specifica.auto" name="auto" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Onorario</label>
                                    <ValidationProvider rules="required" name="onorario" v-slot="{ errors }">
                                        <select v-model="specifica.onorario" class="form-control" name="onorario">
                                            <option value>Seleziona</option>
                                            <option value="0">0</option>
                                            <option value="0.5">0,5</option>
                                            <option value="1">1</option>
                                        </select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Vitto</label>
                                    <ValidationProvider rules="required" name="vitto" v-slot="{ errors }">
                                        <select v-model="specifica.vitto" class="form-control" name="vitto">
                                            <option value>Seleziona</option>
                                            <option value="0">0</option>
                                            <option value="0.5">0,5</option>
                                            <option value="1">1</option>
                                        </select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Alloggio</label>
                                    <ValidationProvider rules="required" name="alloggio" v-slot="{ errors }">
                                        <select v-model="specifica.alloggio" class="form-control" name="alloggio">
                                            <option value>Seleziona</option>
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                        </select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Km trasferimento domicilio - sede perizia</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="km_residenza" v-slot="{ errors }">
                                       <input v-model="specifica.km_residenza" type="number" class="form-control" name="km_residenza" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Km percorsi per svolgimento perizie</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="km_perizia" v-slot="{ errors }">
                                       <input v-model="specifica.km_perizia" type="number" class="form-control" name="km_perizia" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                           <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Pedaggi (€)</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="pedaggi" v-slot="{ errors }">
                                       <input v-model="specifica.pedaggi" type="number" class="form-control" name="pedaggi" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div> 
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-select">Descrizione percorso effettuato</label>
                                    <textarea v-model="specifica.desc_percorso_effettuato" name="desc_percorso_effettuato" id="" cols="30" rows="5" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Telefono (€)</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="telefono" v-slot="{ errors }">
                                       <input v-model="specifica.telefono" type="number" class="form-control" name="telefono" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Varie (€)</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]{1,11}(?:\.[0-9]{1,3})?$/}" name="varie" v-slot="{ errors }">
                                       <input v-model="specifica.varie" type="number" class="form-control" name="varie" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-select">Annotazioni</label>
                                    <textarea v-model="specifica.annotazioni" name="annotazioni" id="" cols="30" rows="5" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button :disabled="invalid"  v-on:click.prevent="check_perizie" type="submit" class="btn btn-primary">Salva Specifica</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </ValidationObserver>
                </div>
            </div>
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title">PERIZIE CONNESSE ALLA SPECIFICA</h3>
                </div>
                <div class="block-content block-content-full">
                    <h3 v-if="perizie.length == 0">Nessuna perizia presente</h3>
                    <table v-if="perizie.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="text-center" style="width: 15%">CERTIFICATO</th>
                                <th style="width: 15%">AGENZIA</th>
                                <th class="d-none d-sm-table-cell" style="width: 25%;">CONSORZIO</th>
                                <th class="d-none d-sm-table-cell" style="width: 10%;">TIPO</th>
                                <th class="d-none d-sm-table-cell" style="width: 15%;">AUTO</th>
                                <th style="width: 15%;">NOTE</th>
                                <th style="width: 20%; text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(perizia, index) in perizie" v-bind:key="index">
                                <td class="font-size-sm">
                                    <span v-if="perizia.certificato">{{perizia.certificato}}</span>
                                    <span v-else><span class="badge badge-info">non disponibile</span></span>
                                </td>
                                <td class="font-size-sm">
                                    {{get_agenzia_object(perizia.agenzia)}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{get_consorzio_object(perizia.consorzio_id)}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{perizia.tipo_perizia}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm" :class="`${perizia.useCar  ? 'si si-check text-success' : ''} `" >
                                </td>
                                <td class="font-size-sm">
                                    {{trimText(perizia.note)}}
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button @click="showModalEdit(perizia, index)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Perizia">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button @click="deletePerizia(index)" type="button" class="btn btn-sm btn-danger" data-toggle="tooltip" title="Remove Perizia">
                                            <i class="fa fa-fw fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <button @click="nuova_perizia" type="submit" class="btn btn-success"><i class="fa fa-fw fa-plus"></i> Aggiungi</button>
                                <button 
                                    style="margin-left:10px;"
                                    v-if="bollettini_associati.length > 0" 
                                    @click="openModalBollettiniAssociati" 
                                    type="submit" class="btn btn-primary"><i class="fa fa-fw fa-plus"></i> Aggiungi dai Bollettini</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<!-- :_perizia="perizia_selezionata.perizia"  -->
        <ModalPerizia ref="modal_perizia" 
            v-if="isModalVisible"
            :perizia_selezionata="perizia_selezionata"
            :consorzi="consorzi" 
            :agenzie="agenzie" 
            :data_specifica="specifica.data_specifica"
            :isModalVisible="isModalVisible" @closeModalCallback="close_modal_callback" 
            :auto="specifica.auto"
            />
        
        <Modal v-if="isModalPerizieVisible" @close="close_modal_perizie">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Salva senza Perizie</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal_perizie"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <p style="margin-bottom:0px">Sei sicuro di voler salvare la perizia senza specifiche?</p>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="close_modal_perizie"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="crea_nuova"
                    aria-label="Salva">
                    Salva
                </button>
            </template>
        </Modal>
        <ModalBollettiniAssociati 
            :isModalVisible="isModalBollettiniAssociatiVisible"
             @closeModalCallback="close_modal_bollettini_associati"
             :bollettini="bollettini_associati"
             :bollettini_selected="bollettini_associati_selezionati">
        </ModalBollettiniAssociati>
    </div>
</template>

<script>
import DatePicker from "../../components/DatePicker"
import ModalPerizia from "../../components/Specifiche/ModalPerizia"
import ModalBollettiniAssociati from "../../components/Specifiche/ModalBollettiniAssociati"
import Modal from "../../components/Modal"
import Utility from "../../services/utility"
import UtilityRepository from "../../services/apis/utility"
import SpecificaRepository from "../../services/apis/specifica"

export default {
    data(){
        return {
            perizie:[],
            ispettori:[],
            consorzi:[],
            agenzie:[],
            bollettini_associati:[],
            bollettini_associati_selezionati:[],
            isModalVisible:false,
            isModalPerizieVisible:false,
            isModalBollettiniAssociatiVisible:false,
            dateInput:null,
            specifica: {
                data_specifica:'',
                ispettore_id:'',
                onorario:'',
                vitto:'',
                alloggio:'',
                km_residenza:'',
                pedaggi:'',
                km_perizia:'',
                telefono:'',
                varie:'',
                annotazioni:''
            },
            perizia_selezionata:{
                perizia:{
                    id:undefined,
                    specifica_id:null,
                    certificato:'',
                    cer_non_disponibile:false,
                    agenzia:'',
                    consorzio_id:'',
                    tipo_perizia:'',
                    note:''
                },
                index:null
            }
        }
    },
    components: {
      DatePicker, ModalPerizia, Modal, ModalBollettiniAssociati
    },
    watch: {
        '$route.params.id': function () {        
            if(this.$route.params.id){
                this.get_specifica(this.$route.params.id)
            }else{
                this.reset_form()
            }
        },
        'specifica.data_specifica':function(data){
            if(!this.specifica.id){
                this.get_bollettini_shared(data)
            }
        }
    },
    methods: {
        reset_form(){
            this.specifica = {
                data_specifica:'',
                ispettore_id:'',
                onorario:'',
                vitto:'',
                alloggio:'',
                km_residenza:'',
                pedaggi:'',
                km_perizia:'',
                telefono:'',
                varie:'',
                annotazioni:''
            }
            this.perizie = []
        },
        async get_bollettini_shared(data){
            const _resp = await SpecificaRepository.get_lists_bollettini_shared({data:Utility.convertDataToDBFormat(data)}).catch(() =>{})
            
            if(_resp){
                this.bollettini_associati = _resp.data.data
                
                if(_resp.data.data.length > 0){
                    this.isModalBollettiniAssociatiVisible = true
                }
            }
        },
        trimText(text){
            return Utility.trimText(text, 40, '...')
        },
        openModalBollettiniAssociati(){
            this.isModalBollettiniAssociatiVisible = true
        },
        close_modal_bollettini_associati(bollettino){
            if(bollettino){
                this.bollettini_associati_selezionati.push(bollettino)
                
                this.perizie.push({
                    certificato:bollettino.ordine.certificato,
                    agenzia:bollettino.ordine.agenzia.id,
                    consorzio_id:bollettino.ordine.consorzio.id,
                    cer_non_disponibile:false,
                    periti:bollettino.periti,
                    peritoUseCar: bollettino.peritoUseCar,
                    bollettino:true,
                    useCar:false
                })

                this.showModalEdit(this.perizie[this.perizie.length-1], this.perizie.length-1)

            }
        

            //showModalEdit
            this.isModalBollettiniAssociatiVisible = false
        },
        async get_lista_ispettori(){
            let _resp = await SpecificaRepository.get_lista_ispettori().catch(() => {})

            if(_resp){
                this.ispettori = _resp.data.data
            }
            
        },
        async get_consorzi(){
            let _resp = await UtilityRepository.search_consorzi('').catch(()=> {})
            
            if(_resp){
                this.consorzi = _resp.data.data
                //this.$refs.modal_perizia.consorzi_int = this.consorzi
            }
        },
        async get_agenzie(){
            let _resp = await UtilityRepository.search_agenzie('').catch(()=> {})

            if(_resp){
                this.agenzie = _resp.data.data
                //this.$refs.modal_perizia.agenzie_int = this.agenzie
            }
        },
        check_perizie(){
            if(this.perizie.length == 0){
                this.isModalPerizieVisible = true
                return
            }

            this.crea_nuova()
        },
        close_modal_perizie(){
            this.isModalPerizieVisible = false
        },
        async crea_nuova(){
            this.isModalPerizieVisible = false
            this.$store.dispatch('updateLoadingState', true)

            var obj = this._.cloneDeep(this.specifica)
            obj.data_specifica = this.$moment(obj.data_specifica, 'DD-MM-YYYY').format('YYYY-MM-DD')
            obj = Utility.normalize_object_value(obj)
            
            obj.perizie = this.perizie

            let _resp = undefined;

            if(obj.id){
                _resp = await SpecificaRepository.update_specifica(obj).catch(() => {})
            }else{
                _resp = await SpecificaRepository.add_specifica(obj).catch(() => {})
            }

            this.$store.dispatch('updateLoadingState', false)
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                
                if(!obj.id){
                    // this.reset_form()
                    // this.$refs.form.reset()
                    this.$router.push({name:'archivio-specifica'});
                }else{
                    this.get_specifica(obj.id)
                }
            }
        },
        close_modal_callback(perizia){
            if(perizia){
                if(this.perizia_selezionata.index !== null){
                    if (this.perizia_selezionata.index !== -1) {
                        this.perizie[this.perizia_selezionata.index] = perizia;
                    }
                }else{
                    this.perizie.push(perizia)
                }
            }

            this.perizia_selezionata = {
                perizia:{
                    id:undefined,
                    specifica_id:null,
                    certificato:'',
                    cer_non_disponibile:false,
                    agenzia:'',
                    consorzio_id:'',
                    tipo_perizia:'',
                    note:''
                },
                index:null
            }

            this.isModalVisible = false
        },
        nuova_perizia(){
            this.isModalVisible = true
        },
        get_consorzio_object(id){
            const _consorzio = this.consorzi.find((item) => item.id == id)
            return _consorzio ? `${_consorzio.codice} - ${_consorzio.name}` : id 
        },
        get_agenzia_object(id){
            const _agenzia = this.agenzie.find((item) => item.id == id)
            return _agenzia ? `${_agenzia.codice_identificativo} - ${_agenzia.nome}` : id 
        },
        showModalEdit(perizia, index){            
            this.perizia_selezionata = {
                perizia:this._.cloneDeep(perizia),
                index:index
            }
            // this.$refs.modal_perizia.initData(this.perizia_selezionata.perizia)
            this.isModalVisible = true
        },
        deletePerizia(index){
            const _perizia = this._.cloneDeep(this.perizie[index], true)
            this.perizie.splice(index, 1)
            this.bollettini_associati_selezionati = this.bollettini_associati_selezionati.filter(perizia => perizia.ordine.certificato !== _perizia.certificato)
        },
        async get_specifica(id){
            const _resp = await SpecificaRepository.get_specifica(id).catch(() => {
                //TODO: ritorna alla lista delle perizie
            })

            if(_resp){
                this.specifica = this._.cloneDeep(_resp.data.data)
                this.specifica.ispettore_id = this.specifica.ispettore.id
                this.specifica.data_specifica = this.$moment(this.specifica.data_specifica).format('DD-MM-YYYY')
                delete this.specifica.perizie
                delete this.specifica.ispettore
                const _perizie = this._.cloneDeep(_resp.data.data.perizie)

                _perizie.map(perizia => {
                    perizia.agenzia = perizia.agenzia.id 
                    perizia.consorzio_id = perizia.consorzio.id
                    perizia.useCar = !perizia.peritoUseCar ? false : (perizia.peritoUseCar.id == this.$store.getters.user.id ? true : false) 
                    delete perizia.consorzio 

                    return perizia
                })

                this.perizie = _perizie
            }
        }
    },
    created(){ 
        this.get_lista_ispettori()
        this.get_consorzi()
        this.get_agenzie()

        if(this.$route.params.id){
            this.get_specifica(this.$route.params.id)
        }
    },
    
}
</script>

