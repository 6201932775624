<template>
    <div>
        <div class="test-catasto">Funzionalità in fase di test</div>
        <div id="leaflet"></div>
        <input type="checkbox" name="satellite" @click="updateSatellite" class="btn-select-all"/> Satellite<br/>
        <button @click="createMultipleMap(checkedDenu)" class="btn-select-all">Visualizza Selezione</button>
        <table v-if="paginatedRows.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
            <thead>
                <tr>
                <ThTable orderKey="data_specifica" name="PARITA" />
                <ThTable orderKey="codice_perito" name="DATA" />
                <ThTable orderKey="name_surname" name="CATASTO" />
                <ThTable orderKey="onorario" name="EVENTO" />
                <ThTable orderKey="vitto" name="ISPETTORE" />
                <th orderKey="vitto" name="SELEZIONE MULTIPLA">
                    <span>SELEZIONE MULTIPLA</span><br/>
                    <span class="ml-2">
                    <input type="checkbox" @click="selectAll" />
                    </span>
                </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in paginatedRows" :key="index">
                <td class="font-size-sm" @click="createMap(row.coordinate)">
                    {{ row.partita_id }}
                </td>
                <td class="font-size-sm">
                    <span v-if="row.data_evento" @click="createMap(row.coordinate)">{{ row.data_evento }}</span>
                </td>
                <td class="d-none d-sm-table-cell font-size-sm">
                    <span v-if="row.catasto" @click="createMap(row.coordinate)">{{ row.catasto }}</span>
                </td>
                <td class="d-none d-sm-table-cell font-size-sm" @click="createMap(row.coordinate)">
                    {{ row.evento }}
                </td>
                <td class="d-none d-sm-table-cell font-size-sm" @click="createMap(row.coordinate)">
                    {{ row.name }} {{ row.surname }}
                </td>
                <td class="d-none d-sm-table-cell font-size-sm">
                    <input type="checkbox" :value="row.coordinate" v-model="checkedDenu" />
                </td>
                </tr>
            </tbody>
        </table>

        <!-- Pagination Controls -->
        <div v-if="totalPages > 1" class="pagination-controls">
            <button @click="previousPage" :disabled="currentPage === 1">Previous</button>
            <span>Page {{ currentPage }} of {{ totalPages }}</span>
            <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
        </div>
    </div>
  </template>
  
  <script src="https://unpkg.com/leaflet/dist/leaflet-src.js"></script>
  <script>
  import CatastoApi from "../../services/apis/catasto"
  import Paginate from 'vuejs-paginate'
  import ThTable from '../../components/ThTable'
  import * as proj4 from './proj4';
  import * as Proj from './leaftproj4';
  export default {

    components: {
        Paginate,
        ThTable
    },

    data() {
      return {
        coordinate: [],
        rows: [],
        mapIsActive: false,
        map: null,
        checkedDenu: [],
        isSatellite:0.2,
        tmpCoordinate: [],
        multiple: false,
        currentPage: 1,
        rowsPerPage: 100, // Number of rows per page
      };
    },

    methods: {
        async getCoordinate() {

            const resp = await CatastoApi.getAllCatasto().catch(() => {})
            console.log(resp.data.data)
            if(resp){
                this.rows = [...resp.data.data]
            }
        },

        selectAll() {
            const paginatedCoordinates = this.paginatedRows.map(item => item.coordinate);

            if (this.areAllSelected) {
            // Deseleziona tutte le righe visibili
            this.checkedDenu = this.checkedDenu.filter(coordinate => !paginatedCoordinates.includes(coordinate));
            } else {
            // Seleziona tutte le righe visibili
            paginatedCoordinates.forEach(coordinate => {
                if (!this.checkedDenu.includes(coordinate)) {
                this.checkedDenu.push(coordinate);
                }
            });
            }
        },
        updateSatellite (e) {
            console.log(e.target.checked)
            if(e.target.checked) {
                this.isSatellite = 1
                if(this.multiple) {
                    this.createMultipleMap(this.tmpCoordinate,true)
                }else {
                    this.createMap(1,1)
                }
            }else {
                this.isSatellite = 0.2
                if(this.multiple) {
                    this.createMultipleMap(this.tmpCoordinate,true)
                }else {
                    this.createMap(1,1)
                }

            }
        },
        createMultipleMap (arraCord,satellite) {

            this.multiple = true



            if(satellite){
                arraCord = [...this.tmpCoordinate]
            }else {
                this.tmpCoordinate = [...arraCord]
            }
            proj4.defs('EPSG:6706', '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
            const Bbox_width = 18.99 - 5.93;
            const startResolution = Bbox_width / 1024;
            const resolutions = new Array(22);
                for (var i = 0; i < 22; ++i) {
                    resolutions[i] = startResolution / Math.pow(2, i);
                }


            var crs_6706 = new Proj.CRS(
                "EPSG:6706",
                "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs",
                {
                bounds: L.bounds([5.93, 34.76], [18.99, 47.1]),
                resolutions,
                /*origin: [coordinate[0][0][0],coordinate[0][0][1]],*/
                origin: [0,0],
                }
            );


            if(this.mapIsActive) {
                // elimino la mappa se già creata
                this.map.remove();
            }

            var baseLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                    maxZoom: 70,
                });

                var cadastralLayer = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "CP.CadastralParcel",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.6,
                    attribution:
                        'Geoportale Cartografico Catastale © <a href="https://geoportale.cartografia.agenziaentrate.gov.it/">Geoportale</a> contributors'
                });
                var vestizioni = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "vestizioni",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.8
                });

              var satellite = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
                    minZoom: 10,
                    maxZoom: 50,
                    subdomains:['mt1'],
                    transparent: true,
                    opacity: this.isSatellite
                });
                var layer = ""
                this.map = L.map("leaflet", {
                    layers: [baseLayer,cadastralLayer,vestizioni,satellite],
                    center: [0,0],
                    zoom: 0,
                   /* crs: crs_6706*/
                });

                this.mapIsActive = true

                const polygonStyle = {
                    fillColor: 'white',
                    weight: 4,
                    opacity: 1,
                    color: 'red',  //Outline color
                    fillOpacity: 0.0
                }

                // itero l'array per estrarre le coordinate
                arraCord.forEach((coordinate,index) => {

                    // mi allontano dalla mappa per mostare più superfice
                    this.map.zoomOut(4);


                    // gestisco denunce con più particelle 
                    if(coordinate.length > 1) {
                        var multiplePolygon = [

                        ]

                        coordinate.forEach((item,index) => {
                            var polygon = []
                            multiplePolygon.push(
                                polygon[index]={
                                    type: "Polygon",
                                    coordinates: [item],
                            })
                        })

                        multiplePolygon.forEach((element,index) => {
                            layer = L.geoJSON(element);

                            layer.setStyle(polygonStyle);

                            this.map.addLayer(layer);

                        });
                    }else {
                        const polygon = {
                        type: "Polygon",
                        coordinates: coordinate,
                    
                        };
                        layer = L.geoJSON(polygon);
                
                        layer.setStyle(polygonStyle);
        
                        this.map.addLayer(layer);
                    }

                    this.map.fitBounds(layer.getBounds());
                });
                

        },

        createMap (coordinate,satellite) {
            this.multiple = false

            if(satellite){
                coordinate = [...this.tmpCoordinate]
            }else {
                this.tmpCoordinate = [...coordinate]
            }
            proj4.defs('EPSG:6706', '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs');
            const Bbox_width = 18.99 - 5.93;
            const startResolution = Bbox_width / 1024;
            const resolutions = new Array(22);
                for (var i = 0; i < 22; ++i) {
                    resolutions[i] = startResolution / Math.pow(2, i);
                }


            var crs_6706 = new Proj.CRS(
                "EPSG:6706",
                "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs",
                {
                bounds: L.bounds([5.93, 34.76], [18.99, 47.1]),
                resolutions,
                /*origin: [coordinate[0][0][0],coordinate[0][0][1]],*/
                origin: [0,0],
                }
            );

            if(this.mapIsActive) {
                this.map.remove();
            }



                var baseLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
                    attribution: 'Map data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
                    maxZoom: 70,
                });

                var cadastralLayer = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "CP.CadastralParcel",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.6,
                    attribution:
                        'Geoportale Cartografico Catastale © <a href="https://geoportale.cartografia.agenziaentrate.gov.it/">Geoportale</a> contributors'
                });
                var vestizioni = L.tileLayer.wms("https://wms.cartografia.agenziaentrate.gov.it/inspire/wms/ows01.php", {
                    layers: "vestizioni",
                    format: "image/png",
                    transparent: true,
                    minZoom: 10,
                    maxZoom: 80,
                    crs: crs_6706,
                    opacity: 0.8
                });

              var satellite = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
                    minZoom: 10,
                    maxZoom: 50,
                    subdomains:['mt1'],
                    transparent: true,
                    opacity: this.isSatellite
                });
                var layer = ""
                console.log(coordinate[0][0][0])
                this.map = L.map("leaflet", {
                    layers: [baseLayer,cadastralLayer,vestizioni,satellite],
                    center: [coordinate[0][0][0],coordinate[0][0][1]],
                    zoom: 0,
                   /* crs: crs_6706*/
                });

                this.mapIsActive = true
  
                const polygonStyle = {
                    fillColor: 'white',
                    weight: 4,
                    opacity: 1,
                    color: 'red',  
                    fillOpacity: 0.0
                }
                if(coordinate.length > 1) {
                    // creo tanti poligoni quante sono le coordinate
                    var multiplePolygon = [

                    ]

                    coordinate.forEach((item,index) => {
                        console.log(item)
                        var polygon = []
                        multiplePolygon.push(
                            polygon[index]={
                                type: "Polygon",
                                coordinates: [item],
                        })
                    })

                    multiplePolygon.forEach((element,index) => {
                        layer = L.geoJSON(element);
                
                        layer.setStyle(polygonStyle);
                
                        this.map.addLayer(layer);

                    });

                    this.map.fitBounds(layer.getBounds());
                                
                }else {
                    const polygon = {
                    type: "Polygon",
                    coordinates: coordinate,
                    
                };

                layer = L.geoJSON(polygon);
                
                layer.setStyle(polygonStyle);
        
                this.map.addLayer(layer);
                this.map.fitBounds(layer.getBounds());
                }


        },

        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
            }
        },
        previousPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
            }
        },
    },

    computed:{
        areAllSelected() {
            const paginatedCoordinates = this.paginatedRows.map(item => item.coordinate);
            return paginatedCoordinates.every(coordinate => this.checkedDenu.includes(coordinate));
        },

        totalPages() {
            return Math.ceil(this.rows?.length / this.rowsPerPage);
        },
        paginatedRows() {
            const start = (this.currentPage - 1) * this.rowsPerPage;
            const end = start + this.rowsPerPage;
            return this.rows.slice(start, end);
        }
    },

    mounted() {
        this.getCoordinate()
    }
  };
  </script>
  <link type="text/css" rel="stylesheet" href="https://unpkg.com/leaflet/dist/leaflet.css" />
  <style>
  #leaflet {
    height: 500px;
    position: relative;
    top:0;
  }
  .btn-select-all {
    /* faccio lo style del button */
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    margin: 0 10px;
  }

  .test-catasto {
    margin: auto;
    text-align: center;
    color: rgb(172, 72, 72);
  }

  .pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.pagination-controls button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 3px;
}

.pagination-controls button:disabled {
  background-color: #d3d3d3;
  cursor: not-allowed;
}
  </style>