<template>
<div>
<!-- Hero -->
    <div class="bg-image overflow-hidden" :style="{'background-image': 'url('+background_image+')'}">
        <div class="bg-primary-dark-op">
            <div class="content content-narrow content-full">
                <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mt-5 mb-2 text-center text-sm-left">
                    <div class="flex-sm-fill">
                        <h1 class="font-w600 text-white mb-0" data-toggle="appear">Profilo</h1>
                        <h2 class="h4 font-w400 text-white-75 mb-0" data-toggle="appear" data-timeout="250">Tieni la tua pagina sempre aggiornata!</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content">
        <div class="block">
            <ul class="nav nav-tabs nav-tabs-block align-items-center js-tabs-enabled" data-toggle="tabs" role="tablist">
                <li class="nav-item">
                    <a :class="[selectedSection == 'profilo' ? 'active': '', 'nav-link']" @click="changeSection('profilo')">Profilo</a>
                </li>
                <li class="nav-item">
                    <a :class="[selectedSection == 'fatturazione' ? 'active': '', 'nav-link']" @click="changeSection('fatturazione')">Fatturazione</a>
                </li>
                <li class="nav-item">
                    <a :class="[selectedSection == 'email' ? 'active': '', 'nav-link']" @click="changeSection('email')">Email</a>
                </li>
                <li class="nav-item">
                    <a :class="[selectedSection == 'password' ? 'active': '', 'nav-link']" @click="changeSection('password')">Password</a>
                </li>
                <li v-if="selectedSection !== 'profilo'"  class="nav-item ml-auto" >
                    <div class="btn-group btn-group-sm pr-2">
                        <button :disabled="isEdit" @click="editSection" type="button" class="btn btn-primary">
                            <i class="fa fa-fw fa-pencil-alt"></i>
                        </button>
                    </div>
                </li>
            </ul>
            <div class="block-content tab-content">
                <div :class="['tab-pane',selectedSection == 'profilo' ? 'active': '']">
                    <DettaglioProfilo :global_user="user" />
                </div>
                <div :class="['tab-pane',selectedSection == 'fatturazione' ? 'active': '']">
                    <DettaglioFatturazione @callbackReload="callback_reload" :user="user" :isEdit="isEdit" :grades="grades" />
                </div>
                <div :class="['tab-pane',selectedSection == 'email' ? 'active': '']">
                    <DettaglioEmail @callbackReload="callback_reload" :user="user" :isEdit="isEdit" />
                </div>
                <div :class="['tab-pane',selectedSection == 'password' ? 'active': '']">
                    <DettaglioPassword @callbackReload="callback_reload" :isEdit="isEdit" />
                </div>
            </div>
        </div>
    </div>
<!-- END Hero -->
</div>
</template>

<script>
import DettaglioProfilo from "../components/Profilo/DettaglioProfilo"
import DettaglioFatturazione from "../components/Profilo/DettaglioFatturazione"
import DettaglioEmail from "../components/Profilo/DettaglioEmail"
import DettaglioPassword from "../components/Profilo/DettaglioPassword"
import UserRepository from "../services/apis/user"

export default {
    data(){
        return {
            background_image: require('@/assets/images/photo3@2x.jpg'),
            selectedSection:'profilo',
            user:null,
            isEdit: false,
            grades:[]
        }
    },
    components: {
        DettaglioProfilo, DettaglioFatturazione, DettaglioEmail, DettaglioPassword
    },
    methods: {
        changeSection(section){
            this.selectedSection = section
            this.isEdit = false
        },
        editSection(){
            this.isEdit = !this.isEdit
        },
        async callback_reload(){
            this.isEdit = false
            let _user = await UserRepository.get_user_data().catch(() => {})
            if(_user){
                this.user = _user.data.user
            }
        }
    },
    async created(){
        this.callback_reload()

        let _grades = await UserRepository.grades().catch(() => {})
        
        if(_grades){
            this.grades = _grades.data.data
        }
        //chiamata ai dettagli profilo
    }
}
</script>

<style scope>
    .nav-item a {
        cursor: pointer;
    }
</style>