<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Aggiorna Certificato <small>Forza aggiornamento certificato.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 col-md-6">
                                    <label class="form-material">Seleziona Ispettore</label>
                                    <SelettoreRicerca
                                        type="ispettore"
                                        placeholder="Seleziona un Ispettore"
                                        voidText="Nessun ispettore trovato"
                                        :selectedModel="search.ispettore"
                                        @updateSelector="updateSelector"
                                    />
                                </div>
                            </div>
                            <div v-if="search.ispettore !== null" class="row">
                                <div class="col-sm-12 col-md-8">
                                    <label class="form-material">Seleziona Agenzia Cerificato</label>
                                    <model-list-select
                                        id="search-select"
                                        :list="agenzie"
                                        option-value="id"
                                        option-text="nome"
                                        v-model="search.agenzia"
                                        placeholder="Seleziona un'Agenzia"
                                    >
                                    </model-list-select>
                                </div>
                            </div>
                            <div v-if="search.agenzia !== null" class="row">
                                <div class="col-sm-12 col-md-8">
                                    <label class="form-material">Seleziona Consorzio Cerificato</label>
                                    <model-list-select
                                        id="search-select"
                                        :list="consorzi"
                                        option-value="id"
                                        option-text="name"
                                        v-model="search.consorzio"
                                        placeholder="Seleziona un Consorzio"
                                    >
                                    </model-list-select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="block-content block-content-full">
                <h3 v-if="certificati.length == 0">Nessun certificato trovato</h3>
                <table v-if="certificati.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                    <thead>
                        <tr>
                            <ThTable @updateOrder="orderBy" orderKey="ordine_id" name="NUMERO ORDINE" :orderBy="search.orderBy" />
                            <ThTable @updateOrder="orderBy" orderKey="certificato" name="NUMERO CERTIFICATO" :orderBy="search.orderBy" />
                            <th class="d-none d-sm-table-cell">AGENZIA</th>
                            <th class="d-none d-sm-table-cell">CONSORZIO</th>
                            <ThTable @updateOrder="orderBy" orderKey="specie" name="SPECIE" :orderBy="search.orderBy" />
                            <ThTable @updateOrder="orderBy" orderKey="comune" name="COMUNE" :orderBy="search.orderBy" />
                            <ThTable @updateOrder="orderBy" orderKey="denominazione" name="DENOMINAZIONE CERT." :orderBy="search.orderBy" />
                            <th style="text-align:center;">#</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(certificato, index) in certificati" :key="index">
                            <td class="font-size-sm">
                                {{certificato.ordine_id}}
                            </td>
                            <td class="font-size-sm">
                                {{certificato.certificato}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{certificato.agenzia.codice_identificativo}} - {{certificato.agenzia.nome}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.consorzio.codice}} - {{certificato.consorzio.name}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.specie.etichetta}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.comune.nome}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.denominazione}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <div class="btn-group">
                                    <button @click="editCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Edit Certificato">
                                        <i class="fa fa-search"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>

        <ModalAggiornaCert 
            :isModalVisible="isModalUpdateVisible" 
            ref="modal_update_cert"
            @closeModalCallback="close_cert_modal"
            @updateModalCallback="update_cert_modal">
        </ModalAggiornaCert>
    </div>
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import ModalAggiornaCert from "../../components/Certificato/ModalAggiornaCert"
import SelettoreRicerca from "../../components/SelettoreRicerca"
import ThTable from '../../components/ThTable'
import AdminRepository from "../../services/apis/admin"

export default {
    components: {
        ThTable,
        SelettoreRicerca,
        ModelListSelect,
        ModalAggiornaCert
    },
    data(){
        return {
            isModalUpdateVisible: false,
            ispettore_id: 0,
            ispettori:{
                list:[],
                found:[]
            },
            certificati:[],
            search: {
                ispettore: null,
                agenzia: null,
                consorzio: null,
                orderBy:'ordine_id:desc'
            },
            agenzie: [],
            consorzi: [],
            certificato: null,
        }
    },
    watch: {
        search:{
            handler: function (){
                this.get_certificati();
            },
            deep: true
        }
    },
    methods: {
        updateSelector(type, item){
            if(type == 'ispettore'){
                this.search.ispettore = item
            }
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_certificati()
        },
        async get_certificati(){
            if(this.search.ispettore !== null) {
                this.$store.dispatch('updateLoadingState', true);
                this.certificati = []
                var search = {
                    ispettore_id: this.search.ispettore.id,
                }

                if(this.search.agenzia !== null && this.search.agenzia !== undefined && this.search.agenzia)
                    search["agenzia_id"] = this.search.agenzia;

                if(this.search.consorzio !== null && this.search.consorzio !== undefined && this.search.consorzio)
                    search["consorzio_id"] = this.search.consorzio;

                search.orderBy = this.search.orderBy

                const _resp = await AdminRepository.lista_ordini_ispettore(search).catch(()=>{})

                if(_resp) {
                    if(search["agenzia_id"] === undefined || search["agenzia_id"] === null) {
                        this.agenzie = _resp.data.data;
                    }
                    else if(search["consorzio_id"] === undefined || search["consorzio_id"] === null) {
                        this.consorzi = _resp.data.data;
                    }
                    else {
                        this.certificati = _resp.data.data;
                    }
                }
                
                this.$store.dispatch('updateLoadingState', false);
            }
        },
        close_cert_modal(){
            this.isModalUpdateVisible = false
        },
        async update_cert_modal(payload){
            if(payload !== null && payload !== undefined) {
                payload.ispettore_id = this.ispettore_id

                this.$store.dispatch('updateLoadingState', true);

                const _resp = await AdminRepository.aggiorna_certificato(payload).catch(()=>{})

                if(_resp.data.status === "success"){
                    this.$toasted.success(_resp.data.message).goAway(3000);
                    this.isModalUpdateVisible = false;
                    this.resetAll()
                    this.get_certificati();
                }
                this.$store.dispatch('updateLoadingState', false);
            }
        },
        editCertificato(cert){
            this.$refs.modal_update_cert.getData(cert)
            this.isModalUpdateVisible = true
        },
        resetAll() {
            this.ispettori = {
                list:[],
                found:[]
            };
            this.certificati = {
                list:[],
                found:[]
            };
            this.ispettore = {
                id: null,
                name: '',
                surname: '',
                codice_perito: ''
            };
            this.search = {
                ispettore: null,
                agenzia: null,
                consorzio: null
            }
            
            this.certificato = null
        }, 
    },
    mounted(){
        const user = this.$store.getters.user
        this.ispettore_id = user.role == 'admin' ? user.id : 0
    }
}
</script>
<style>
    @import '../../assets/css/style.css';
</style>