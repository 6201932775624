<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Annulla Fatture <small>Annulla fatture per perito.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <label style="margin-bottom:10px;" class="form-material">Seleziona utente</label>
                                    <SelettoreRicerca
                                        type="all"
                                        placeholder="Seleziona utente"
                                        :selectedModel="perito_selected"
                                        @updateSelector="updateSelector"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <label style="margin-bottom:10px;" class="form-material">Filtro per data</label>
                                    <date-range-picker
                                        style="width: 100%;"
                                        ref="picker"
                                        :locale-data="localeRange"
                                        :autoApply="true"
                                        :ranges="false"
                                        v-model="dateRange"
                                        @select="dayClicked"
                                    >
                                        <template v-slot:input="picker" style="width:100%;">
                                            <span v-if="picker.startDate">{{ picker.startDate | toEuropeData }} - {{ picker.endDate | toEuropeData }}</span>
                                            <span v-else>Seleziona Range di ricerca</span>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </div>
                            <div v-if="dateRange.startDate" style="margin-top: 20px;" class="row">
                                <div class="col-sm-12 col-md-12">
                                    <button @click="resetAll" type="button" class="btn btn-sm btn-primary" title="Edit Perizia">
                                        Reset Filtro
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="fatture.length == 0">Nessuna Fattura</h3>
                    <div v-if="fatture.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="fatture.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="data_fattura" name="DATA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="n_fattura" name="N. FATTURA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="codice_perito" name="COD. PERITO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="name_surname" name="COGNOME" :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">SCARICA</th>
                                <ThTable @updateOrder="orderBy" orderKey="data_contabilita" name="CONTABILIZZATA" :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">ELIMINA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(fattura, index) in fatture" :key="index">
                                <td class="font-size-sm">
                                    {{fattura.data_fattura | toEuropeData}}
                                </td>
                                <td class="font-size-sm">
                                    {{fattura.n_fattura}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="fattura.perito">{{fattura.perito.codice_perito}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="fattura.perito">{{fattura.perito.surname}} {{fattura.perito.name}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="!fattura.data_contabilita" class="badge badge-danger">Non contabilizzata</span>
                                    <span v-else>{{fattura.data_contabilita | toEuropeData}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="eliminaFattura(fattura)" type="button" class="btn btn-sm btn-light" title="Elimina fattura">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="data.last_page > 1" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="prev"
                            :next-text="next"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                </div>
            </div>
        </div>
        <ModalTrueFalse
            :isModalVisible="isModalDeleteVisible" 
            @closeModalCallback="callbackDelete"
            >
             <template v-slot:body-true>
                <h3>Rimuoverai la fattura creata</h3>
             </template>
        </ModalTrueFalse>


    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ModalTrueFalse from '../../../components/ModalTrueFalse'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import FatturaRepository from "../../../services/apis/fattura"
import Utility from '../../../services/utility'
import Config from '../../../services/config'
import ThTable from '../../../components/ThTable'
import SelettoreRicerca from '../../../components/SelettoreRicerca'

export default {
    components: {
        ThTable,
        Paginate,
        DateRangePicker,
        ModalTrueFalse,
        SelettoreRicerca
    },
    data(){
        return {
            dateRange:{},
            seleziona_tutto:false,
            ispettore_id: 0,
            isModalDeleteVisible:false,
            data: {
                last_page:1
            },
            fatture: [],
            certificato: null,
            periti:{
                list:[],
                found:[]
            },
            ispettori:[],
            consorzi:[],
            selected_fattura:null,
            perito_selected:null,
            search: {
                perito_id: null,
                per_page: 10,
                page: 1,
                orderBy:'data_fattura:desc'
            }
        }
    },
    computed: {
        localeRange(){
            return Utility.dateRangeLocale()
        },
        prev(){
            return Config.lang.prev
        },
        next(){
            return Config.lang.next
        },
    },
    watch: {
        'search.perito_id': function (){
            this.search.page = 1
            this.get_fatture();
        },
        'perito_selected': function(item){
            if(item){
                this.search.perito_id = item.id
            }else{
                this.search.perito_id = null
            }
        }
    },
    methods: {
        updateSelector(_, item){
            this.perito_selected = item
        },
        async get_fatture(){

            if(this.dateRange.startDate && this.dateRange.endDate){
                this.search.start_date = Utility.convertDataToDB(this.dateRange.startDate)
                this.search.end_date = Utility.convertDataToDB(this.dateRange.endDate)
            }else{
                delete this.search.start_date
                delete this.search.end_date
            }

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await FatturaRepository.da_perito(this.search).catch(()=>{})
            
            if(_resp){
                this.fatture = _resp.data.data.data
                this.data = _resp.data.data
            }
            

            this.$store.dispatch('updateLoadingState', false);
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_fatture()
        },
        perPage(){
            this.search.page = 1
            this.get_fatture()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_fatture()
        },
        dayClicked(data){
            this.dateRange = data
            this.get_fatture()
        },
        resetAll() {
            this.search.per_page = 10
            this.search.page = 1 
            this.search.perito_id = null

            for (const key in this.dateRange) {
                if (this.dateRange.hasOwnProperty(key)) {
                    this.dateRange[key] = null;
                }
            }
            this.get_fatture()
        },
        eliminaFattura(fattura){
            this.selected_fattura = fattura
            this.isModalDeleteVisible = true
        },
        async callbackDelete(status){            
            this.isModalDeleteVisible = false
            if(!status){
                this.selected_fattura = null
                return
            }
            
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await FatturaRepository.elimina_fattura(this.selected_fattura.id).catch(()=>{})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.search.page = 1
                this.get_fatture()
            }

             this.$store.dispatch('updateLoadingState', false);
        }
    },
    mounted(){
        this.get_fatture()
    }
}
</script>
<style>
    span.label {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }
    span.label-danger {
        background-color: #d26a5c;
    }
    span.label-success {
        background-color: #60d25c;
    }
</style>