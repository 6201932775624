<template>
    <!-- PERIZIA MODAL -->
    <Modal v-show="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>DICHIARAZIONI E ANNOTAZIONI</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div class="row" style="margin-bottom:10px;">
                <div style="display: flex; align-items: center;flex-wrap: wrap;" class="col-md-12">
                    <p style="margin-bottom:0; margin-right:10px;">La presente perizia è stata ultimata alle ore </p>
                    <input :disabled="readOnly" id="ore_ultimata" v-model="annotazioni.ore_ultimata" style="max-width: 100px;" type="text" placeholder="mm:ss" class="form-control" name="ore_ultimata">                    
                    <p style="margin-bottom:0; margin-left:10px;">del</p>
                </div>
            </div>
            <div class="row" style="margin-bottom:10px;">
                <div style="display: flex; align-items: center;flex-wrap: wrap;" class="col-md-12">
                    <DatePicker :disabled="readOnly" style="width:130px;" isId="data_ultimata" v-model="annotazioni.data_ultimata" /> <p style="margin: 0px 0px 0px 10px;">e le percentuali liquidate sono comprensive di tutti i danni provocati dagli eventi assicurati fino al momento.</p>
                </div>
            </div>
            <div class="row" style="margin-bottom:10px;">
                <div style="display: flex; align-items: center;flex-wrap: wrap;" class="col-md-12">
                    <p style="margin: 0px 10px 0px 10px;">1 - Il prodotto è stato colpito da eventi atmosferici assicurati prima della decorrenza della garanzia?</p> 
                    <select :disabled="readOnly" v-model="annotazioni.bool_eventi" name="" id="">
                        <option value="no">NO</option>
                        <option value="si">SI</option>
                    </select>
                    <p v-if="annotazioni.bool_eventi == 'si'" style="margin: 0px 10px 0px 10px;">Se si, quali? </p>
                    <input :disabled="readOnly" v-model="annotazioni.eventi_atmosferici" style="max-width: 300px;" v-if="annotazioni.bool_eventi == 'si'" type="text" class="form-control" name="eventi_atmosferici" placeholder="Eventi atmosferici">                    
                </div>
            </div>
            <div class="row" style="margin-bottom:10px;">
                <div style="display: flex; align-items: center;flex-wrap: wrap;" class="col-md-12">
                    <p style="margin: 0px 10px 0px 10px;">2 - Le quantità di prodotto assicurato rappresentano la totalità? </p> <select :disabled="readOnly" v-model="annotazioni.bool_totalita" name="" id="">
                        <option value="no">NO</option>
                        <option value="si">SI</option>
                    </select>
                </div>
            </div>
            <div class="row" style="margin-bottom:10px;">
                <div style="display: flex; align-items: center;flex-wrap: wrap;" class="col-md-12">
                    <p style="margin: 0px 10px 0px 10px;">3 - Il prodotto sopra indicato è assicurato da altre società?</p> 
                    <select :disabled="readOnly" v-model="annotazioni.bool_altre_societa" name="" id="">
                        <option value="no">NO</option>
                        <option value="si">SI</option>
                    </select>
                    <p v-if="annotazioni.bool_altre_societa == 'si'" style="margin: 0px 10px 0px 10px;">Se si, quali? </p>
                    <input :disabled="readOnly" v-model="annotazioni.nome_societa" style="max-width: 300px;" v-if="annotazioni.bool_altre_societa == 'si'" type="text" class="form-control" name="nome_societa" placeholder="Nome società">
                    <p v-if="annotazioni.bool_altre_societa == 'si'" style="margin: 0px 10px 0px 10px;">E in quale misura? </p>                    
                    <input :disabled="readOnly" v-model="annotazioni.misura" style="max-width: 300px;" v-if="annotazioni.bool_altre_societa == 'si'" type="text" class="form-control" name="misura" placeholder="Misura">
                </div>
            </div>
            <div class="row" style="margin-bottom:10px;">
                <div style="display: flex; align-items: center;flex-wrap: wrap;" class="col-md-12">
                    <p style="margin: 0px 0px 0px 10px;">Data</p> <DatePicker style="width:150px;" isId="data_consegna" :disabled="readOnly" v-model="annotazioni.data_consegna" /> <p style="margin: 0px 0px 0px 10px;">di</p>
                    <input :disabled="readOnly" style="width:15px;margin:0px 10px;" v-model="annotazioni.consegna" type="checkbox" class="form-control" name="consegna"> Consegna
                    <input :disabled="readOnly" style="width:15px;margin:0px 10px;" v-model="annotazioni.spedizione" type="checkbox" class="form-control" name="spedizione"> Spedizione per raccomandata con ricevuta
                    <div style="display: flex;flex-wrap: wrap;width: 100%;align-items: center; margin-top:10px;" v-if="annotazioni.spedizione === true">
                        <p style="margin: 0px 10px 0px 10px;">n.</p>
                        <input :disabled="readOnly" v-model="annotazioni.n_ricevuta" style="max-width: 300px;" type="text" class="form-control" name="ricevuta" placeholder="N. Ricevuta">
                        <p style="margin: 0px 10px 0px 10px;">Ufficio Postale di </p>
                        <input :disabled="readOnly" v-model="annotazioni.ufficio_postale" style="max-width: 300px;" type="text" class="form-control" name="ufficio_postale" placeholder="Ufficio postale">
                    </div>
                </div>
            </div>
            <div class="row" style="margin-bottom:10px;margin-top:20px">
                <div v-for="(note, i) in notes" :key="i" class="col-md-12" style="margin-bottom:10px">
                    <label>{{note.text}}</label>
                    <textarea :disabled="readOnly" v-model="note.edit" name="note_text" id="" cols="10" rows="2" class="form-control"></textarea>
                </div>
            </div>
            <div class="row" style="margin-bottom:10px;">
                <div class="col-md-12">
                    <label>Testo nota</label>
                    <textarea :disabled="readOnly" v-model="annotazioni.note_text" name="note_text" id="" cols="30" rows="10" class="form-control"></textarea>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                v-if="!readOnly"
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                :disabled="!can_save"
                @click="set_data">
                <span>Salva</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../../Modal"
import DatePicker from "../../DatePicker"
import Cleave from 'cleave.js'
export default {
    name:'BollettinoModalDichiarazioniEAnnotazioni',
    props:['isModalVisible', 'readOnly'],
    components: {
        Modal, DatePicker
    },
    data(){
        return {
            can_save: false,
            annotazioni: {},
            notes:[]
        }
    },
    watch: {
        annotazioni: {
            handler:function(){
                this.changeInput()
            },
            deep:true
        }
    },
    methods: {
        changeInput(){
            if(this.annotazioni.bool_eventi == "no"){
                this.annotazioni.eventi_atmosferici = ""
            }
            
            if(this.annotazioni.bool_altre_societa == "no"){
                this.annotazioni.nome_societa = ""
                this.annotazioni.misura = ""
            }
            
            if(!this.annotazioni.spedizione){
                this.annotazioni.n_ricevuta = ""
                this.annotazioni.ufficio_postale = ""
            }

            if( this.annotazioni.data_ultimata && this.annotazioni.data_ultimata !== "" && 
                this.annotazioni.data_consegna && this.annotazioni.data_consegna !== ""){
                
                this.can_save = true
                return
            }

            this.can_save = false
        },
        set(annotazioni, notes){
            this.annotazioni = this._.cloneDeep(annotazioni, true)
            this.notes = this._.cloneDeep(notes, true)
        },
        reset(){
            this.can_save = false
            this.annotazioni =  {}
        },
        set_data(){
            this.annotazioni.complete = true
            this.$emit('closeModalCallback', this.annotazioni, this.notes)
            this.reset()
        },
        closeModal() {
            this.$emit('closeModalCallback', null)
            this.reset()
        },
    },
    mounted(){
        this.$nextTick(function () {
            new Cleave(`#ore_ultimata`, {
                time: true,
                timePattern: [ 'h', 'm']
            });
        })
        
    }
}
</script>