<template>
    <div class="bg-image">
        <div class="hero-static bg-white-95">
            <div class="content">
                <div class="row justify-content-center">
                    <div class="col-md-8 col-lg-6 col-xl-4">
                        <h1 style="text-align:center; font-size:100px">404</h1>
                        <h2 style="text-align:center">Pagina non trovata</h2>
                        <div class="block-options">
                            <a @click="back" style="color:white" class="btn btn-block btn-primary">Torna alla dashboard</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        back(){
            window.location.href = process.env.BASE_URL == '/' ? `${process.env.BASE_URL}dashboard` : `${process.env.BASE_URL}/dashboard`;
        }
    }
}
</script>