<template>
    <!-- DELETE MODAL CERT -->
    <Modal v-if="isModalVisible">
        <template v-slot:header>
            <slot name="header-true">
                <h1 style="margin-bottom:0px;">
                    <span>Sei sicuro?</span>
                </h1>
            </slot>
        </template>
        <template v-slot:body>
            <slot name="body-true">
                <h3>???</h3>
            </slot>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="actionCallback(false)"
                aria-label="Close modal">
                Annulla
            </button>
            <button
                @click="actionCallback(true)"
                type="button"
                class="btn btn-danger"
                aria-label="Procedi">
                <span>Si, procedi</span>
            </button>
            
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "./Modal"
export default {
    name:'ModalTrueFalse',
    props:['isModalVisible'],
    components: {
        Modal
    },
    methods: {
        actionCallback(action){
            this.$emit('closeModalCallback', action)
        },
    }
}
</script>