<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Specifiche da Fatturare <small>Lista delle specifiche inserite, ma non fatturate.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 col-md-8">
                                    <label style="margin-bottom:10px;" class="form-material">Seleziona utente</label>
                                    <SelettoreRicerca
                                        type="all"
                                        placeholder="Seleziona utente"
                                        :selectedModel="perito_selected"
                                        @updateSelector="updateSelector"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <label style="margin-bottom:10px;" class="form-material">Filtro per data</label>
                                    <date-range-picker
                                        style="width: 100%;"
                                        ref="picker"
                                        :locale-data="localeRange"
                                        :autoApply="true"
                                        :ranges="false"
                                        v-model="dateRange"
                                        @select="dayClicked"
                                    >
                                        <template v-slot:input="picker" style="width:100%;">
                                            <span v-if="picker.startDate">{{ picker.startDate | toEuropeData }} - {{ picker.endDate | toEuropeData }}</span>
                                            <span v-else>Seleziona Range di ricerca</span>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </div>
                            <div v-if="dateRange.startDate" style="margin-top: 20px;" class="row">
                                <div class="col-sm-12 col-md-12">
                                    <button @click="resetAll" type="button" class="btn btn-sm btn-primary" title="Edit Perizia">
                                        Reset Filtro
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="specifiche.length == 0">Nessuna Specifica</h3>
                    <div v-if="specifiche.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="specifiche.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="data_specifica" name="DATA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="codice_perito" name="COD. PERITO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="name_surname" name="COGNOME" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="onorario" name="ONORARIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="vitto" name="VITTO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="alloggio" name="ALLOGGIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="km_totali" name="KM TOTALI" :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">DETTAGLI</th>
                                <th class="d-none d-sm-table-cell">STATO. FATT.</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(specifica, index) in specifiche" :key="index">
                                <td class="font-size-sm">
                                    {{specifica.data_specifica | toEuropeData}}
                                </td>
                                <td class="font-size-sm">
                                    <span v-if="specifica.perito">{{specifica.perito.codice_perito}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="specifica.perito">{{specifica.perito.name}} {{specifica.perito.surname}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.onorario}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{specifica.vitto}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{specifica.alloggio}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{specifica.km_perizia+specifica.km_residenza}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="infoCertificato(specifica)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Perizia">
                                            <i class="fa fa-fw fa-eye"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="specifica.fatturata == 0" class="badge badge-danger">non fatturata</span>
                                    <span v-if="specifica.fatturata == 1" class="badge badge-success">fatturata</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.fattura | fatturaAraDataFormat}}
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="data.last_page > 1" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="prev"
                            :next-text="next"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                </div>
            </div>
        </div>

        <ModalSpecifica ref="modal_specifica"
            :ispettori_int="ispettori"
            :consorzi_int="consorzi"
            :agenzie_int="agenzie"
            :specifica="selected_specifica"
            :isModalVisible="isModalInfoVisible" 
            @closeModalCallback="close_modal_callback" />

    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ModalSpecifica from '../../../components/Specifiche/ModalSpecifica'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import UtilityRepository from "../../../services/apis/utility"
import SpecificaRepository from "../../../services/apis/specifica"
import Utility from '../../../services/utility'
import Config from '../../../services/config'
import ThTable from '../../../components/ThTable'
import SelettoreRicerca from '../../../components/SelettoreRicerca'

export default {
    components: {
        ThTable,
        Paginate,
        ModalSpecifica,
        SelettoreRicerca,
        DateRangePicker
    },
    data(){
        return {
            dateRange:{},
            ispettore_id: 0,
            isModalInfoVisible: false,
            data: {
                last_page:1
            },
            specifiche: [],
            certificato: null,
            periti:{
                list:[],
                found:[]
            },
            ispettori:[],
            consorzi:[],
            agenzie:[],
            perito_selected:null,
            selected_specifica:null,
            search: {
                perito_id: null,
                per_page: 10,
                page: 1,
                orderBy:'data_specifica:desc'
            }
        }
    },
    computed: {
        localeRange(){
            return Utility.dateRangeLocale()
        },
        prev(){
            return Config.lang.prev
        },
        next(){
            return Config.lang.next
        }
    },
    watch: {
        'search.perito_id': function (){
            this.get_specifiche();
        },
        'perito_selected': function(item){
            if(item){
                this.search.perito_id = item.id
            }else{
                this.search.perito_id = null
            }
        }
    },
    methods: {
        updateSelector(_, item){
            this.perito_selected = item
        },
        async get_specifiche(){

            if(this.dateRange.startDate && this.dateRange.endDate){
                this.search.start_date = Utility.convertDataToDB(this.dateRange.startDate)
                this.search.end_date = Utility.convertDataToDB(this.dateRange.endDate)
            }else{
                delete this.search.start_date
                delete this.search.end_date
            }

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await SpecificaRepository.specifiche_da_fatturare(this.search).catch(()=>{})
            
            if(_resp){
                if(_resp.data.data.data !== null && _resp.data.data.data !== undefined)
                    this.specifiche = _resp.data.data.data
                    this.data = _resp.data.data
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_specifiche()
        },
        perPage(){
            this.search.page = 1
            this.get_specifiche()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_specifiche()
        },
        dayClicked(data){
            this.dateRange = data
            this.get_specifiche()
        },
        infoCertificato(cert){
            this.selected_specifica = cert
            this.isModalInfoVisible = true
        },
        close_modal_callback(){
            this.isModalInfoVisible = false
            this.selected_specifica = null
        },
        resetAll() {
            this.search.per_page = 10
            this.search.page = 1 

            for (const key in this.dateRange) {
                if (this.dateRange.hasOwnProperty(key)) {
                    this.dateRange[key] = null;
                }
            }
            this.get_specifiche()
        },
        async get_lista_ispettori(){
            let _resp = await SpecificaRepository.get_lista_ispettori().catch(() => {})

            if(_resp){
                this.ispettori = _resp.data.data
            }
            
        },
        async get_consorzi(){
            let _resp = await UtilityRepository.search_consorzi('').catch(()=> {})
            
            if(_resp){
                this.consorzi = _resp.data.data
            }
        },
        async get_agenzie(){
            let _resp = await UtilityRepository.search_agenzie('').catch(()=> {})

            if(_resp){
                this.agenzie = _resp.data.data
            }
        },
    },
    mounted(){
        this.get_lista_ispettori()
        this.get_consorzi()
        this.get_agenzie()
        this.get_specifiche()
    }
}
</script>
<style>
    span.label {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }
    span.label-danger {
        background-color: #d26a5c;
    }
    span.label-success {
        background-color: #60d25c;
    }
</style>