<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1  class="page-heading">
                        <span v-if="type == 'pre'">Fatture per specifiche fino al 30/04/{{currentYear}} </span>
                        <span v-if="type == 'post'">Fatture per specifiche dopo al 30/04/{{currentYear}} </span>
                        <small>
                            Seleziona tra le specifiche approvate quelle per le quali richiedi la fattura. La fattura poi, 
                            una volta numerata e datata, deve essere inviata a <a href="mailto:luca.gianini@ara1857.it">luca.gianini@ara1857.it</a> e 
                            <a href="mailto:ufficio.grandine@ara1857.it">ufficio.grandine@ara1857.it</a> <br> <br>
                            Per tutti coloro che hanno adottato il regime dei minimi la fattura, corredata di marca da bollo, dovrà essere spedita in 
                            originale l'indirizzo della compagnia anticipandola a mezzo e-mail agli stessi indirizzi sopra indicati. Grazie Come è noto, 
                            dal 1.1.2019 è scattato l'obbligo dí fattura elettronica tra privati, ad esclusione dei soggetti che rientrano nel 
                            "regime forfettario", di cui all'articolo 1, commi da 54 a 89 della legge 23.12.2014 n 190 o nel cosiddetto "regime dí 
                            vantaggio" di cui all'articolo 27, commi 1 e 2, del decreto-legge 6.7.2011 n. 98 convertito dalla legge 15.7.2011 n 111.<br> <br>
                            Per chi è soggetto all'obbligo, le fatture dovranno essere emesse esclusivamente in formato XML, il che richiederà che ogni soggetto 
                            si doti degli strumenti informatici necessari o si avvalga di professionisti. <br><br>
                            Al fine del ricevimento delle fatture elettroniche, comunichiamo che il codice ID che identifica la società Ara SpA è <b>A4707H7</b>. 
                            Inoltre, per garantire una maggior tempestività nel trattamento delle fatture elettroniche, vi chiediamo di riportare la dicitura 
                            fissa PERIZIE nel campo "causale" di ogni fattura (codice 2.1.1.11 del tracciato fornito dall'Agenzia delle Entrate).
                        </small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <div v-if="specifiche.length > 0" class="block-header">
                        <h2 class="ng-binding">Importo fattura € {{importo_totale}}</h2>
                    </div>
                    <h3 v-if="specifiche.length == 0">Nessuna specifica presente</h3>
                    <table v-if="specifiche.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <th class="text-center">
                                    <input type="checkbox" v-model="select_all" @change="selectAll">
                                </th>

                                <ThTable @updateOrder="orderBy" orderKey="data_specifica" name="DATA" :orderBy="search.orderBy" />
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(specifica, index) in specifiche" :key="index">
                                <td class="font-size-sm">
                                    <input @change="calcolo_previsionale" v-model="specifica.selezionata" type="checkbox">
                                </td>
                                <td class="font-size-sm">
                                    {{data_specifica(specifica.data_specifica)}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <button :disabled="ids_specifiche.length == 0"  @click="open_modal_fattura = true" class="btn btn-primary">Crea Fattura</button>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="open_modal_fattura" @close="close_modal">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">AGGIUNGI NUMERO E DATA FATTURA</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                
                <ValidationObserver ref="form_fattura">
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-select">Tipologia</label>
                                    <ValidationProvider rules="required" name="type" v-slot="{ errors }">
                                        <select v-model="fattura.type" class="form-control" name="type">
                                            <option value="">Seleziona tipologia fattura</option>
                                            <option value="fattura">Fattura</option>
                                            <option value="ricevuta">Ricevuta</option>
                                            <option value="nota_spese">Nota spese</option>
                                        </select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-select">Numero fattura</label>
                                    <ValidationProvider rules="required" name="numero_fattura" v-slot="{ errors }">
                                        <input v-model="fattura.numero" type="text" class="form-control" name="numero_fattura" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-ltf-email">Data Fattura</label>
                                    <ValidationProvider rules="required|is_valid_date" name="data_specifica" v-slot="{ errors }">
                                        <DatePicker isId="data_specifica" v-model="fattura.data" />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>

            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="close_modal"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="!is_form_valid"
                    @click="create_invoices"
                    aria-label="Salva">
                    Salva
                </button>
            </template>
        </Modal>
    </div>
</template>

<script>
import DatePicker from "../../components/DatePicker"
import Modal from "../../components/Modal"
import ThTable from '../../components/ThTable'
import FatturaRepository from "../../services/apis/fattura";
import SpecificheRepository from "../../services/apis/specifica";

export default {
    components: {
        ThTable, Modal, DatePicker
    },
    data(){
        return {
            type:'post',
            specifiche:[],
            select_all:false,
            importo_totale:"0",
            ids_specifiche:[],
            currentYear:null,
            fattura: {
                type:'',
                numero:'',
                data:''
            },
            search:{
                orderBy:'data_specifica:desc'
            },
            is_form_valid:false,
            open_modal_fattura:false
        }
    },
    watch: {
        '$route' (to) {
            if(to.name == 'fatture-lista-pre'){
                this.type = 'pre'
            }else{
                this.type = 'post'
            }
            this.get_specifiche()
        },
        'fattura.numero' (){
            this.form_fattura_is_valid()
        },
        'fattura.data' (){
            this.form_fattura_is_valid()
        },
        'fattura.type' (){
            this.form_fattura_is_valid()
        }
    },
    methods: {
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_specifiche()
        },
        async form_fattura_is_valid(){

            if(this.$refs.form_fattura){
                let success = await this.$refs.form_fattura.validate()                
                this.is_form_valid = success
                return
            }
            
            this.is_form_valid = false
        },
        close_modal(){
            this.open_modal_fattura = false
            this.fattura =  {
                    numero:'',
                    data:''
                }
        },
        selectAll(){
            this.specifiche.forEach(element => {
                element.selezionata = this.select_all
            });
            this.calcolo_previsionale()
        },
        async get_specifiche(){
            const _search = this.search
            _search.attuale = this.type == 'pre' ? 0 : 1

            const _resp = await SpecificheRepository.get_specifiche_da_fatturare(_search).catch(() => {})

            if(_resp){
                this.specifiche = _resp.data.data
            }
        },
        async calcolo_previsionale(){
            const filter_specifiche = this.specifiche.filter((el) => el.selezionata)
            const ids = filter_specifiche.map((el) => el.id)   
            this.ids_specifiche = ids         

            let _resp = await FatturaRepository.calcolo_previsionale(ids).catch(() => {})
            if(_resp){
                this.importo_totale = _resp.data.data
            }
        },
        data_specifica(data){
            return data ? this.$moment(data).format('DD-MM-YYYY') : ''
        },
        async create_invoices(){
            if (this.ids_specifiche.length == 0) {
                this.$toasted.error('Non hai selezionato specifiche da fatturare').goAway(3000)
                return;
            }
            
            this.$store.dispatch('updateLoadingState', true)

            const obj = {
                specifiche_id: this.ids_specifiche,
                type: this.fattura.type,
                dataFattura: this.$moment(this.fattura.data, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                numeroFattura: this.fattura.numero
            }

            const _resp = await FatturaRepository.nuova_fattura(obj).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)
            
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.close_modal()
                this.$router.push({name:'fatture-archivio'})
            }
        }
    },
    mounted(){

        this.currentYear = process.env.VUE_APP_YEAR

        if(this.$router.history.current.name == 'fatture-lista-pre'){
            this.type = 'pre'
        }else{
            this.type = 'post'
        }

        this.get_specifiche()
    }
}
</script>