<template>
  <div>
    <transition name="modal"  v-if="showModal" @close="showModal = false">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-container">

            <div class="modal-header">
              <slot name="header" class="modal-events-text">
               <span class="modal-events-text bold-title">SELEZIONA EVENTO</span> 
              </slot>
            </div>

            <div class="modal-body modal-events-text">
              <slot name="body">
                <select v-model="selected_events" class="select-css">
                  <option value="">Seleziona un evento</option>
                  <option value="GR|1">GRANDINE</option>
                  <option value="EP|3">ECCESSO PIOGGIA</option>
                  <option value="GB|4">GELO BRINA</option>
                  <option value="SI|5">SICCITA'</option>
                  <option value="CS|6">COLPO SOLE</option>
                  <option value="VC|7">VENTO CALDO</option>
                  <option value="ST|8">SBALZO TERMICO</option>
                  <option value="AL|9">ALLUVIONE</option>
                  <option value="VF.|10">VENTO F.</option>
                  <option value="EN|13">ECCESSO DI NEVE</option>
                  <option value="UR|16">URAGANO</option>
                  <option value="PS|17">PERONOSPORA</option>
                </select>
              </slot>
            </div>

            <div class="modal-footer">
              <slot name="footer">
                <button class="modal-default-button" @click="select_denuncia_scomposizione">
                  OK
                </button>
              </slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <div id="jexcel-header-img" :style="{background: `url('${this.bollettino_header}')`}">
        <div id="jexcel-denunce">
            <ul>
                <li :style="{marginLeft:40*i+'px'}" 
                    class="evento-denuncia jexcel-denunce-class"
                    :data="i" 
                    @click="select_denuncia(i)"
                    v-for="(denuncia, i) in denunce" :key="i">{{denuncia}} 
                    
                </li>
            </ul>
        </div>
      <input type="checkbox" id="select-checkbox">
    </div>
    <div style="width: 1300px;" id="spreadsheet" ref="spreadsheet"></div>
      <BollettinoSelectCausa 
          :isModalVisible="modals.isBollettinoSelectCausaVisible"
          ref="modal_select_causa"
          @closeModalCallback="close_modal">
      </BollettinoSelectCausa>
      <BollettinoSelectFranchigia 
          :isModalVisible="modals.isBollettinoSelectFranchigiaVisible"
          ref="modal_select_franchigia"
          @closeModalCallback="close_modal">
      </BollettinoSelectFranchigia>
      <BollettinoSelectDataRaccolta 
          :isModalVisible="modals.isBollettinoSelectDataRaccoltaVisible"
          ref="modal_select_data_raccolta"
          @closeModalCallback="close_modal">
      </BollettinoSelectDataRaccolta>
      <BollettinoSelectOsservazioni
          :lista_note="lista_note" 
          :isModalVisible="modals.isBollettinoSelectOsservazioniVisible"
          ref="modal_select_osservazioni"
          @closeModalCallback="close_modal"
          :ordine_have_bollettino="ordine_have_bollettino"
          :partite="partite"
          >
          
      </BollettinoSelectOsservazioni>
      <BollettinoSelectA
          :isModalVisible="modals.isBollettinoSelectAVisible"
          ref="modal_select_a"
          @closeModalCallback="close_modal">
      </BollettinoSelectA>
  </div>
</template>

<script>
/*
NOTE:
L'emento dom gestito con gli eventi jQuery dovrà avare un campo data-id
Creare una classe o una funzione che formatti i campi
*/


import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars
import BollettinoSelectCausa from "../../components/Certificato/ModalBollettino/Select"
import BollettinoSelectFranchigia from "../../components/Certificato/ModalBollettino/Franchigia"
import BollettinoSelectDataRaccolta from "../../components/Certificato/ModalBollettino/DataRaccolta"
import BollettinoSelectOsservazioni from "../../components/Certificato/ModalBollettino/SelectOsservazioni"
import BollettinoSelectA from "../../components/Certificato/ModalBollettino/SelectA"


var cifre_lettere_array = [
{
  "cifre": 0,
  "lettere": "ZERO"
},
{
  "cifre": 1,
  "lettere": "UNO"
},
{
  "cifre": 2,
  "lettere": "DUE"
},
{
  "cifre": 3,
  "lettere": "TRE"
},
{
  "cifre": 4,
  "lettere": "QUATTRO"
},
{
  "cifre": 5,
  "lettere": "CINQUE"
},
{
  "cifre": 6,
  "lettere": "SEI"
},
{
  "cifre": 7,
  "lettere": "SETTE"
},
{
  "cifre": 8,
  "lettere": "OTTO"
},
{
  "cifre": 9,
  "lettere": "NOVE"
},
{
  "cifre": 10,
  "lettere": "DIECI"
},
{
  "cifre": 11,
  "lettere": "UNDICI"
},
{
  "cifre": 12,
  "lettere": "DODICI"
},
{
  "cifre": 13,
  "lettere": "TREDICI"
},
{
  "cifre": 14,
  "lettere": "QUATTORDICI"
},
{
  "cifre": 15,
  "lettere": "QUINDICI"
},
{
  "cifre": 16,
  "lettere": "SEDICI"
},
{
  "cifre": 17,
  "lettere": "DICIASSETTE"
},
{
  "cifre": 18,
  "lettere": "DICIOTTO"
},
{
  "cifre": 19,
  "lettere": "DICIANNOVE"
},
{
  "cifre": 20,
  "lettere": "VENTI"
},
{
  "cifre": 21,
  "lettere": "VENTUNO"
},
{
  "cifre": 22,
  "lettere": "VENTIDUE"
},
{
  "cifre": 23,
  "lettere": "VENTITRE"
},
{
  "cifre": 24,
  "lettere": "VENTIQUATTRO"
},
{
  "cifre": 25,
  "lettere": "VENTICINQUE"
},
{
  "cifre": 26,
  "lettere": "VENTISEI"
},
{
  "cifre": 27,
  "lettere": "VENTISETTE"
},
{
  "cifre": 28,
  "lettere": "VENTOTTO"
},
{
  "cifre": 29,
  "lettere": "VENTINOVE"
},
{
  "cifre": 30,
  "lettere": "TRENTA"
},
{
  "cifre": 31,
  "lettere": "TRENTUNO"
},
{
  "cifre": 32,
  "lettere": "TRENTADUE"
},
{
  "cifre": 33,
  "lettere": "TRENTATRE"
},
{
  "cifre": 34,
  "lettere": "TRENTAQUATTRO"
},
{
  "cifre": 35,
  "lettere": "TRENTACINQUE"
},
{
  "cifre": 36,
  "lettere": "TRENTASEI"
},
{
  "cifre": 37,
  "lettere": "TRENTASETTE"
},
{
  "cifre": 38,
  "lettere": "TRENTOTTO"
},
{
  "cifre": 39,
  "lettere": "TRENTANOVE"
},
{
  "cifre": 40,
  "lettere": "QUARANTA"
},
{
  "cifre": 41,
  "lettere": "QUARANTUNO"
},
{
  "cifre": 42,
  "lettere": "QUARANTADUE"
},
{
  "cifre": 43,
  "lettere": "QUARANTATRE"
},
{
  "cifre": 44,
  "lettere": "QUARANTAQUATTRO"
},
{
  "cifre": 45,
  "lettere": "QUARANTACINQUE"
},
{
  "cifre": 46,
  "lettere": "QUARANTASEI"
},
{
  "cifre": 47,
  "lettere": "QUARANTASETTE"
},
{
  "cifre": 48,
  "lettere": "QUARANTOTTO"
},
{
  "cifre": 49,
  "lettere": "QUARANTANOVE"
},
{
  "cifre": 50,
  "lettere": "CINQUANTA"
},
{
  "cifre": 51,
  "lettere": "CINQUANTUNO"
},
{
  "cifre": 52,
  "lettere": "CINQUANTADUE"
},
{
  "cifre": 53,
  "lettere": "CINQUANTATRE"
},
{
  "cifre": 54,
  "lettere": "CINQUANTAQUATTRO"
},
{
  "cifre": 55,
  "lettere": "CINQUANTACINQUE"
},
{
  "cifre": 56,
  "lettere": "CINQUANTASEI"
},
{
  "cifre": 57,
  "lettere": "CINQUANTASETTE"
},
{
  "cifre": 58,
  "lettere": "CINQUANTOTTO"
},
{
  "cifre": 59,
  "lettere": "CINQUANTANOVE"
},
{
  "cifre": 60,
  "lettere": "SESSANTA"
},
{
  "cifre": 61,
  "lettere": "SESSANTUNO"
},
{
  "cifre": 62,
  "lettere": "SESSANTADUE"
},
{
  "cifre": 63,
  "lettere": "SESSANTATRE"
},
{
  "cifre": 64,
  "lettere": "SESSANTAQUATTRO"
},
{
  "cifre": 65,
  "lettere": "SESSANTACINQUE"
},
{
  "cifre": 66,
  "lettere": "SESSANTASEI"
},
{
  "cifre": 67,
  "lettere": "SESSANTASETTE"
},
{
  "cifre": 68,
  "lettere": "SESSANTOTTO"
},
{
  "cifre": 69,
  "lettere": "SESSANTANOVE"
},
{
  "cifre": 70,
  "lettere": "SETTANTA"
},
{
  "cifre": 71,
  "lettere": "SETTANTUNO"
},
{
  "cifre": 72,
  "lettere": "SETTANTADUE"
},
{
  "cifre": 73,
  "lettere": "SETTANTATRE"
},
{
  "cifre": 74,
  "lettere": "SETTANTAQUATTRO"
},
{
  "cifre": 75,
  "lettere": "SETTANTACINQUE"
},
{
  "cifre": 76,
  "lettere": "SETTANTASEI"
},
{
  "cifre": 77,
  "lettere": "SETTANTASETTE"
},
{
  "cifre": 78,
  "lettere": "SETTANTOTTO"
},
{
  "cifre": 79,
  "lettere": "SETTANTANOVE"
},
{
  "cifre": 80,
  "lettere": "OTTANTA"
},
{
  "cifre": 81,
  "lettere": "OTTANTUNO"
},
{
  "cifre": 82,
  "lettere": "OTTANTADUE"
},
{
  "cifre": 83,
  "lettere": "OTTANTATRE"
},
{
  "cifre": 84,
  "lettere": "OTTANTAQUATTRO"
},
{
  "cifre": 85,
  "lettere": "OTTANTACINQUE"
},
{
  "cifre": 86,
  "lettere": "OTTANTASEI"
},
{
  "cifre": 87,
  "lettere": "OTTANTASETTE"
},
{
  "cifre": 88,
  "lettere": "OTTANTOTTO"
},
{
  "cifre": 89,
  "lettere": "OTTANTANOVE"
},
{
  "cifre": 90,
  "lettere": "NOVANTA"
},
{
  "cifre": 91,
  "lettere": "NOVANTUNO"
},
{
  "cifre": 92,
  "lettere": "NOVANTADUE"
},
{
  "cifre": 93,
  "lettere": "NOVANTATRE"
},
{
  "cifre": 94,
  "lettere": "NOVANTAQUATTRO"
},
{
  "cifre": 95,
  "lettere": "NOVANTACINQUE"
},
{
  "cifre": 96,
  "lettere": "NOVANTASEI"
},
{
  "cifre": 97,
  "lettere": "NOVANTASETTE"
},
{
  "cifre": 98,
  "lettere": "NOVANTOTTO"
},
{
  "cifre": 99,
  "lettere": "NOVANTANOVE"
},
{
  "cifre": 100,
  "lettere": "CENTO"
}
]

function create_tootltip($element, _text) {
var leftPos  = $element[0].getBoundingClientRect().left   + window.$(window)['scrollLeft']();
var topPos   = $element[0].getBoundingClientRect().top    + window.$(window)['scrollTop']();

window.$('body').append('<div class="tool" style="margin-bottom:-30px;position:absolute;left:'+(leftPos+10)+'px; top:'+topPos+'px; background:gray; padding:5px 10px; color:white;border-radius:4px;z-index:999">'+_text+'</div>')

  window.$('.tool').each(function() {
    var _t = (topPos-window.$(this).height()-20) +'px'
    window.$(this).css('top', _t);
  })
}

export default {
  name:'Bollettino',
  props:['copy_rows_value','lista_note', 'lista_eventi', 'partite', 'rows', 'denunce', 'last_bollettino_number', 'note_deduzioni_danni_anteriori', 'denunce_blocks', 'note_deduzioni_colonna_A','scomposizione','azzera_scomposizione','ordine_have_bollettino'],
  components: {
      BollettinoSelectCausa, 
      BollettinoSelectFranchigia, 
      BollettinoSelectDataRaccolta, 
      BollettinoSelectOsservazioni,
      BollettinoSelectA,
  },
  data(){
      return {
          bollettino_header: require('@/assets/images/bollettino-header.jpg'),
          partite_key:{0:true},
          subpartite_key:{1:0,2:0},
          partite_formatted:[],
          mapValidation:{
            col_5:[],
            col_9:[],
            col_10:[],
            col_A:[]
          },
          mapValidationCol10:[],
          column_10_read_only:[],
          readOnly:false,
          colHeaders:[ '1', '2', '3', '4', '5', '6', '7', '8', '9','10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', 'A', '#'],
          colWidths:[35,83,121,67, 102, 120, 41, 43, 43, 43, 42, 127, 35, 64, 34, 41, 43, 43, 43, 43, 34, 34],
          columns: [
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
              //{ type: 'dropdown', source:cause, autocomplete:true},
              { type: 'text'},
              { type: 'text', readOnly:true},
              { type: 'text'},
              { type: 'text'},
              { type: 'text', readOnly:true},
              { type: 'text'},
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
              //{ type: 'dropdown', source:note, autocomplete:true, multiple:true},
              { type: 'text'},
              { type: 'text'},
              { type: 'text'},
              { type: 'text'},
              { type: 'text'},
              { type: 'text', readOnly:true},
              { type: 'text', readOnly:true},
          ],
          modals: {
              isBollettinoSelectCausaVisible:false,
              isBollettinoSelectFranchigiaVisible:false,
              isBollettinoSelectDataRaccoltaVisible:false,
              isBollettinoSelectOsservazioniVisible:false,
              isBollettinoSelectAVisible:false,
          },
          showModal: false,
          selected_events:"",
          row_evento:"",
          initialValueEvent:[],
      }
  },
  computed: {
      jExcelOptions() {
          const _this = this
          return {
              data:this.partite_formatted,
              colHeaders:this.colHeaders,
              colWidths:this.colWidths,
              allowInsertRow:false,
              allowManualInsertRow:false,
              allowInsertColumn:false,
              allowManualInsertColumn:false,
              allowDeleteRow:false,
              allowComments:false,
              selectionCopy:false,
              allowDeleteColumn:false,
              columnResize:false,
              allowExport:false,
              columns: this.columns,
              onchange: function(instance, cell, x, y, value) {
                  //console.log("ON CHANGE", instance, cell, x, y, value);
                  
                  const _x = parseInt(x)
                  const _y = parseInt(y)
                  
                  if(isNaN(value) && _x == 4){
                    value = value.replace(/,/g, '.')
                    let value_arr = value.split('.')
                    if(value_arr.length > 0){
                      value_arr[value_arr.length -1] = `.${value_arr[value_arr.length -1]}` 
                    }
                    value = value_arr.join('')
                    if(!new RegExp(/,/g).test(value)){
                      _this.jExcelObj.setValueFromCoords(_x, _y, value, true)
                    }                    
                  }
                  
                  if(!(_y in _this.partite_key)){

                      if(_x == 4){
                          // TODO: check negative number -> 0
                          // check non number -> 0
                          // check number limit -> 0
                          // console.log(isNaN(parseFloat(value)));
                          
                            
                            const partita_row = _this.subpartite_key[_y].partita

                            
                            // Get all child value and update partita row
                            let _sub_partite_sum = 0
                            for (const key in _this.subpartite_key) {
                                if (_this.subpartite_key.hasOwnProperty(key)) {
                                    const element = _this.subpartite_key[key];
                                    if(element.partita == partita_row){
                                      if(!isNaN(parseFloat(_this.jExcelObj.getValueFromCoords(_x, key)))){
                                        _sub_partite_sum += parseFloat(_this.jExcelObj.getValueFromCoords(_x, key))
                                      }
                                    }
                                }
                            }

                            
                            // Set Partita Value -> check sum or 0
                            _this.jExcelObj.setValueFromCoords(_x, partita_row, _sub_partite_sum, true)
                            _this.jExcelObj.setValueFromCoords(_x+1, partita_row, (parseFloat(_this.jExcelObj.getValueFromCoords(2, partita_row)) - _sub_partite_sum).toFixed(2), true)
                            // Set Row Value column 6 (5)
                            const original_value = isNaN(parseFloat(_this.jExcelObj.getValueFromCoords(2, _y))) ? 0 : parseFloat(_this.jExcelObj.getValueFromCoords(2, _y))
                            const _value = isNaN(parseFloat(value)) ? 0 : parseFloat(value)
                            _this.jExcelObj.setValueFromCoords(_x+1, _y, (original_value-_value).toFixed(2), true)

                         
                      }

                      

                      if(_x == 12){
                          //check if element is negative or if FC
                      }
                      
                      if(_x == 13){
                          //check if element is inside select or is valid date
                      }
                      
                      // if(_x == 13){ //15-19 check if are integer
                      //     //check if element is inside select or is valid date
                      // }
                      // if(_x == 13){ //20 check if are one of list and trigger function like ritiro di denuncia
                      //     //check if element is inside select or is valid date
                      // }
                      
                  }else{
                    if(_x == 4){
                      _this.jExcelObj.setValueFromCoords(_x+1, y, (parseFloat(_this.jExcelObj.getValueFromCoords(2, y)) - parseFloat(value)).toFixed(2), true)
                    }
                  }

                  if(_x == 6 || _x == 7 || _x == 9){
                          // TODO: controlli come sopra
                        
                        if((_this.subpartite_key[_y] && _this.subpartite_key[_y].selezionata) || (_this.partite_key[_y] && _this.partite_key[_y].selezionata)){
                          
                          const _col_6 = isNaN(parseFloat(_this.jExcelObj.getValueFromCoords(6, _y))) ? 0 : parseFloat(_this.jExcelObj.getValueFromCoords(6, _y))
                          const _col_7 = isNaN(parseFloat(_this.jExcelObj.getValueFromCoords(7, _y))) ? 0 : parseFloat(_this.jExcelObj.getValueFromCoords(7, _y))

                          const _sum_6_7 = _col_6+_col_7;
                          _this.jExcelObj.setValueFromCoords(8, _y, _sum_6_7, true)

                          const _col_9 = isNaN(parseFloat(_this.jExcelObj.getValueFromCoords(9, _y))) ? 0 : parseFloat(_this.jExcelObj.getValueFromCoords(9, _y))
                          const _diff_9_6_7 = _sum_6_7 - _col_9

                          _this.jExcelObj.setValueFromCoords(10, _y, _diff_9_6_7, true)
                          const lettere = cifre_lettere_array[_diff_9_6_7] ? cifre_lettere_array[_diff_9_6_7].lettere.toLowerCase() : cifre_lettere_array[0].lettere.toLowerCase()

                          _this.jExcelObj.setValueFromCoords(11, _y, lettere, true)
                        }
                          
                      }
                  _this.validateRowData(_y)
              },
              updateTable: function(el, cell, x, y) {
                  window.$(cell).css('overflow', 'hidden');
                  window.$(cell).css('white-space', 'nowrap');
                  
                  const f = _this.column_10_read_only.find(c => c == y)

                  if(f !== undefined && x == 9){  
                    cell.classList.add('readonly');
                  }

                  if(_this.partite_key[y] && _this.partite_key[y].has_sub){
                      cell.classList.add('readonly');
                      cell.classList.add('partitaBackground');    
                  }
                  
                  if(_this.readOnly){                      
                    cell.classList.add('readonly');
                  }

                  // console.log(el, cell, x, y, source, value, id);
                  
              }
          }
      },
  },
  methods: {
      parseColumnData(value){
        if(value != "" && !isNaN(value)){
          return parseInt(value)
        }
        return 0
      },
      saveInitialEvent(){
        const elemento = document.getElementById('jexcel-denunce'); // Seleziona l'elemento padre
        console.log(elemento)

        const elementi = elemento.getElementsByTagName('li'); // Seleziona tutti gli elementi figli

        // estraggo il testo di ogni elemento figlio
        const testi = Array.from(elementi).map(el => el.textContent);
        // se ci sono eventi dopii, come questi GRANDINE+VENTO F., COLPO SOLE+VENTO CALDO, GRANDINE+ECCESSO P.
        // li scompongo in eventi sigoli e riassegno il valore alle colonne
        let eventi_scomposti = [];
         testi.forEach(el => {
          // se l'elemento include un . lo elimino
          if(el.includes('.')){
            el = el.replace('.', '')
          }
          if(el.includes('+')){
            const eventi = el.split('+')
            eventi.forEach(e => {
              eventi_scomposti.push(e.trim())
            })
          }else {
            eventi_scomposti.push(el.trim())
          }

        })
        // rimuoovo gli eventi duplicati in eventi_scomposti

        eventi_scomposti = eventi_scomposti.filter((v, i, a) => a.indexOf(v) === i);





        if (eventi_scomposti.length > 0 && eventi_scomposti.length <= 4 ){
          //testi.push(...eventi_scomposti)
          this.initialValueEvent = [...eventi_scomposti]
          this.lanciaFunzione()
        }
      },
      select_denuncia (i) {
          if (this.scomposizione){
            this.showModal = true
            this.row_evento = i
          }

      },
      select_denuncia_scomposizione () {
        //console.log(this.selected_events)
        console.log("call function")
        let value = ''
        if(this.selected_events) {
          value = this.selected_events.split('|')[0]
        }else {
           value = '-'
        }
            this.showModal= true
            this.denunce[this.row_evento] = value
            this.$emit('callBackSelectEvents', 16+parseInt(this.row_evento))
            this.showModal = false
           /* this.validateRowData(1)
            const rows = this.jExcelObj.getRowData(1)
            console.log(rows)*/
      },
      lanciaFunzione() {
        let denunce_index = []
        this.selected_events= ""
        for(let i = 0; i < this.denunce.length; i++) {
            denunce_index.push(i)
            console.log(denunce_index)
          }
          denunce_index.forEach((el,i)=>{
            console.log(i)
            console.log(this.initialValueEvent[i])
            this.selected_events= this.initialValueEvent[i]
            this.row_evento = i
            this.select_denuncia_scomposizione()
          })
        },
      getDataFromDivId(row){
        return window.$(row).data( "id" )
      },
      checkColumn15Value(columnA, column){
        
        if(!column || column == "" || column == undefined){
          return false
        }
        
        const data = column.split('|')
        let f = false

        data.forEach(element => {
          const key = columnA.replace(/\s+/g, '_').toLowerCase();
          const found = this.note_deduzioni_colonna_A[key].data.find(el => el == element);
          if(found){
            f = true
          }
        });

        if(f){
          return true
        }else{
          return false
        }
      },
      //VALIDA COLONNE
      validateRowData(index){
        const rows = this.jExcelObj.getRowData(index)
        //const remove_event = this.jExcelObj.rows[0].innerHTML
        //CHECK COLUMN 9, 16, 17, 18, 19, 20
        console.log(rows[10])
        let column_9 = rows[8]
        if(column_9 != 'undefined' && column_9 != null && column_9 !== "" && !isNaN(column_9)){
          column_9 = parseInt(column_9)
          const column_16 = this.parseColumnData(rows[15])
          const column_17 = this.parseColumnData(rows[16])
          const column_18 = this.parseColumnData(rows[17])
          const column_19 = this.parseColumnData(rows[18])
          const column_20 = this.parseColumnData(rows[19])
          const sum_all = column_16 + column_17 + column_18 + column_19 +column_20
          if(sum_all == column_9){
            this.mapValidation.col_9 = this.mapValidation.col_9.filter(el => el != index)
          }else{
            if(!this.mapValidation.col_9.find(el => el == index)){
              this.mapValidation.col_9.push(index)
            }
          }
        }
      
        const col_9_valid = this.mapValidation.col_9.length > 0 ? false : true
        //END CHECK COLUMN 9, 16, 17, 18, 19, 20

        //CHECK COLUMN 10, 15
        let column_10 = rows[9]
        // il controllo sulla colonna 10 va fatto  solo se non è presente un bolletino validato
        if(!this.ordine_have_bollettino){
          if(column_10 !== "" && !isNaN(column_10) && column_10 != 'undefined' && column_10 != null){
            column_10 = parseInt(column_10)
            
            const f = this.column_10_read_only.find(i => i == index)
        
            if(column_10 != 0 && f == undefined){
              let column_15 = rows[14]
              // column_15 = window.$(column_15).data( "id" );
              column_15 = this.getDataFromDivId(column_15)

              if(!column_15 || column_15 == ""){
                this.mapValidation.col_10.push(index)
              }else{
                column_15 = column_15.split('|')
                let f = false
                column_15.forEach(element => {
                  const found = this.note_deduzioni_danni_anteriori.find(el => el == element);
                  if(found){
                    f = true
                  }
                });

                if(f){
                  this.mapValidation.col_10 = this.mapValidation.col_10.filter(el => el != index)
                }else{
                  if(!this.mapValidation.col_10.find(el => el == index)){
                    this.mapValidation.col_10.push(index)
                  }
                }
              } 
            }else{
              this.mapValidation.col_10 = this.mapValidation.col_10.filter(el => el != index)
            }
          }
        }else {
          // disabilitare output colonna 10
          

        }
        const col_10_valid = this.mapValidation.col_10.length > 0 ? false : true
        //END CHECK COLUMN 10, 15

        //CHECK COLUMN 4, 5
        if(!this.partite_key[index] || this.partite_key[index]  && !this.partite_key[index].has_sub){
          let column_5 = rows[4]
          if(column_5 != "" && !isNaN(column_5)){
            if(column_5 != 0 && parseFloat(column_5) !== parseFloat(rows[2])){
              let column_4 = rows[3]
              column_4 = this.getDataFromDivId(column_4)
            
              if(!column_4 || column_4 == "" ){
                if(!this.mapValidation.col_5.find(el => el == index)){
                  this.mapValidation.col_5.push(index)
                }
              }else{
                this.mapValidation.col_5 = this.mapValidation.col_5.filter(el => el != index)
              }
            }else{
              this.mapValidation.col_5 = this.mapValidation.col_5.filter(el => el != index)
            }
          }
        }
        const col_5_valid = this.mapValidation.col_5.length > 0 ? false : true
        //END CHECK COLUMN 4, 5

        //CHECK COLUMN A, 15
        let column_A = this.getDataFromDivId(rows[20])
        if(column_A && column_A !== "" && column_A != 'undefined' && column_A != null){
            let column_15 = this.getDataFromDivId(rows[14])
            if(column_A == "Fuori rischio" || column_A == "Perizia negativa" || column_A == "Perizia rinviata" || column_A == "Ritiro di denuncia"){
                const check = this.checkColumn15Value(column_A, column_15)
                if(check){
                  this.mapValidation.col_A = this.mapValidation.col_A.filter(el => el != index)
                }else{
                  if(!this.mapValidation.col_A.find(el => el == index)){
                    this.mapValidation.col_A.push(index)
                  }
                }
            }else{
              this.mapValidation.col_A = this.mapValidation.col_A.filter(el => el != index)
            }
        }else{
          this.mapValidation.col_A = this.mapValidation.col_A.filter(el => el != index)
        }

        const col_A_valid = this.mapValidation.col_A.length > 0 ? false : true
        //END CHECK COLUMN A, 15

        
        //CHECK COLUMN 16,17,18,19,20 IF EVENT EXISTS
        let columns_16_to_20 = []
        for (let i = 15; i < 20; i++) {
          const column = this.parseColumnData(rows[i])
          if(column !== 0){
            const index = i - 15
            if(this.denunce[index] == "-"){
              columns_16_to_20.push(i+1)
            }
          }
        }
        //END CHECK COLUMN 16,17,18,19,20 IF EVENT EXISTS


        // console.log(this.mapValidation);
        
        this.$emit('callBackValidations', {col_9:col_9_valid, col_10:col_10_valid, col_5:col_5_valid, col_A:col_A_valid, columns_16_to_20:columns_16_to_20,column_11:rows[10] && rows[10] < 0 ? {index:index,value:true} : {index:index,value:false}})
        
        this.checkRitiroDenuncia(this.jExcelObj.rows)
      },
      checkRitiroDenuncia (rows) {
        let remove_event = true
        rows.forEach(item => {
          if(item.innerHTML.includes('Ritiro di denuncia')){
            remove_event = remove_event && true
          }else {
            remove_event = remove_event && false
          }
        })
        if(remove_event){
          this.selected_events = ""
          setTimeout(() => {
            let evento = [0,1,2,3,4]
            evento.forEach(e => {
              this.row_evento = e
              this.select_denuncia_scomposizione()
            }
            )
          }, 3000);
         
        }
      },
      initJExcel(){
          const jExcelObj = jexcel(this.$refs.spreadsheet, this.jExcelOptions);
          // Object.assign(this, jExcelObj); // pollutes component instance
          Object.assign(this, { jExcelObj });
      },
      stringToFloatFixed(value){
        if(value != 'undefined' && value != null && value !== "" && !isNaN(value)){
          return parseFloat(value).toFixed(2)
        }
        return value
      },
      close_modal(type, value, row_position){

          if(type == 'causa'){
              if(value && row_position){
                  this.jExcelObj.setValueFromCoords(row_position.x, row_position.y, ("<div class=\"select-causa\" data-id=\""+value+"\">"+value+" <i class=\"fa fa-caret-down\"></i> </div>"), true)
              }
          }
          
          if(type == 'select_evento'){
              if(value && row_position){
                this.denunce[row_position] = value
                console.log("select_evento",value)
                if(row_position){
                  this.$emit('callBackSelectEvents', 16+parseInt(row_position))
                }
              }
          }
          
          if(type == 'select_osservazioni'){
            if(this.copy_rows_value.length){
               //console.log(this.copy_rows_value)
               this.copy_rows_value.forEach((el) => {
                 this.jExcelObj.setValueFromCoords(14, el, ("<div class=\"select-osservazioni\" data-id=\"" +value+ "\">" +value+ " <i class=\"fa fa-caret-down\"></i> </div>"), true)
               })
             }else{
               if(row_position){
                   this.$emit('callBackSelectOsservazioni', value, row_position.y)
                   this.jExcelObj.setValueFromCoords(row_position.x, row_position.y, ("<div class=\"select-osservazioni\" data-id=\"" +value+ "\">" +value+ " <i class=\"fa fa-caret-down\"></i> </div>"), true)
               }
             }
          }
          
          if(type == 'select_a'){
              if(row_position){
                const ritiro_di_denuncia = "61 - L'Assicurato, avendo constatato che la/e avversita' $$$ denunciata/e ha/hanno prodotto un danno infe..."
                let row_column_y = this.jExcelObj.getValueFromCoords(14, row_position.y)
                const str = window.$(row_column_y);
                const str_data = str.data('id')
                let row_col_array = str_data !== "" ? str_data.split('|') : []                  
                const found = row_col_array.find((el) => el === ritiro_di_denuncia)

                if(value === "" || value !== "Ritiro di denuncia"){
                  if(found){
                    row_col_array = row_col_array.filter((el) => el !== ritiro_di_denuncia)
                  }
                }else if(value == "Ritiro di denuncia"){
                  if(!found){
                    row_col_array.push(ritiro_di_denuncia)
                    this.$emit('callBackSelectOsservazioni', ritiro_di_denuncia)
                  }
                }

                
                row_col_array = row_col_array.join('|')
                this.$emit('callBackSelectOsservazioni', row_col_array, row_position.y)
                this.jExcelObj.setValueFromCoords(14, row_position.y, ("<div class=\"select-osservazioni\" data-id=\""+row_col_array+"\">"+row_col_array+" <i class=\"fa fa-caret-down\"></i> </div>"), true)
                
                if(value !== ""){
                  if(!this.jExcelObj.getMerge('D'+(parseInt(row_position.y)+1))){
                    this.jExcelObj.setValueFromCoords(15, row_position.y, "", true)
                    this.jExcelObj.setValueFromCoords(16, row_position.y, "", true)
                    this.jExcelObj.setValueFromCoords(17, row_position.y, "", true)
                    this.jExcelObj.setValueFromCoords(18, row_position.y, "", true)
                    this.jExcelObj.setValueFromCoords(18, row_position.y, "", true)
                    this.jExcelObj.setMerge('D'+(parseInt(row_position.y)+1), 9, 1)

                  }
                  this.jExcelObj.setValueFromCoords(5, row_position.y, value, true)
                }else{
                  this.jExcelObj.removeMerge('D'+(parseInt(row_position.y)+1))

                  let a = this._.cloneDeep(this.jExcelObj.getRowData(row_position.y))
                  let b = this._.cloneDeep(this.partite_formatted[row_position.y])
                  for (let j = 0; j < a.length; j++) {
                        switch (j) {
                          case 5:  
                            this.jExcelObj.setValueFromCoords(j, row_position.y, b[2])
                            break;
                          case 3:  
                            this.jExcelObj.setValueFromCoords(3, row_position.y, "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", true)
                            break;  
                          case 4:  
                          case 6:
                          case 7:
                          case 8:
                          case 10:
                            this.jExcelObj.setValueFromCoords(j, row_position.y, 0)
                          break;
                          case 11:
                            this.jExcelObj.setValueFromCoords(j, row_position.y, "zero")
                          break;
                          case 9:
                            this.jExcelObj.setValueFromCoords(j, row_position.y, b[j])
                            break;
                          default:
                            break;
                        }
                      }
                }

                this.jExcelObj.setValueFromCoords(row_position.x, row_position.y, ("<div class=\"select-a\" data-id=\""+value+"\">"+value+" <i class=\"fa fa-caret-down\"></i> </div>"), true)
              }
          }

          if(type == 'franchigia'){
              if(value && row_position){
                  this.jExcelObj.setValueFromCoords(row_position.x, row_position.y, value, true)
              }
          }
          
          if(type == 'data_raccolta'){
            if(this.copy_rows_value.length){
               //console.log(this.copy_rows_value)
               this.copy_rows_value.forEach((el) => {
                 this.jExcelObj.setValueFromCoords(13, el, value, true)
               })
             }else {
               if(value && row_position){
                   this.jExcelObj.setValueFromCoords(row_position.x, row_position.y, value, true)
               }
             }
          }
          
          this.init_table_functions()
          this.modals.isBollettinoSelectCausaVisible = false
          this.modals.isBollettinoSelectFranchigiaVisible = false
          this.modals.isBollettinoSelectDataRaccoltaVisible = false
          this.modals.isBollettinoSelectOsservazioniVisible = false
          this.modals.isBollettinoSelectAVisible = false
      },
      checkType(partita){
        partita.denunce_da_liquidare = []
        if(partita.denunce.length == 0){
          partita.text = 'PARTITA NON DENUNCIATA'

          if(partita.sub_partite.length > 0){
            partita.sub_partite.map(el => {
              el.denunce_da_liquidare = []
              el.text = 'PARTITA NON DENUNCIATA'
              return el
            })
          }
        }

        if(partita.denunce.length > 0){
          if(partita.sub_partite.length == 0){
            if(partita.denunce_ids_no_bollettino.length == 0){
              partita.text = 'PARTITA LIQUIDATA'
              return partita;
            }

            const denunce_non_liquidate = partita.denunce.filter(el => {
              if(partita.denunce_ids_no_bollettino.includes(el.id)){
                return el
              }
            })

            if(denunce_non_liquidate.length == 0 ){
              partita.text = 'PARTITA LIQUIDATA'
            }else{
              partita.denunce_da_liquidare = denunce_non_liquidate
              partita.text = 'PERIZIA RINVIATA'
            }
          }else{

            let sub_liquidate = 0;
            let sub_rinviate = 0;
            
            partita.sub_partite = partita.sub_partite.map(sub => {
              sub.denunce_da_liquidare = []
              if(sub.denunce_ids_no_bollettino.length == 0){
                sub.text = 'PARTITA LIQUIDATA'
                sub_liquidate += 1
                
              }else{
                const _denunce_non_liquidate = partita.denunce.filter(el => {
                  if(sub.denunce_ids_no_bollettino.includes(el.id)){
                    return el
                  }
                })
              
                if(_denunce_non_liquidate.length == 0 ){
                  sub.text = 'PARTITA LIQUIDATA'
                  sub_liquidate += 1
                }else{
                  sub.denunce_da_liquidare = _denunce_non_liquidate
                  if(!sub.selezionata){
                    sub.text = 'PERIZIA RINVIATA'
                    sub_rinviate += 1
                  }
                }
              }
               
              return sub;
            })
            
            if(sub_liquidate == partita.sub_partite.length){
              partita.text = 'PARTITA LIQUIDATA'
            }
            
            if(sub_rinviate == partita.sub_partite.length){
              partita.text = 'PERIZIA RINVIATA'
            }

          }
        }

        return partita
      },
      // INIT NEW ROWS
      init_new_rows(partite){
    
        let _partite_formatted = []
        let row_disabled = []
        let counter = 0;

        for (let i = 0; i < partite.length; i++) {
          const _partita = this.checkType(partite[i])

          let _count = counter

          this.partite_key[_count] = {has_sub:_partita.sub_partite.length > 0 ? true : false, selezionata:_partita.selezionata, id: _partita.id, denunce:_partita.denunce_da_liquidare.map(d => d.id)}
          _partita.selezionata = _partita.selezionata !== undefined ? _partita.selezionata : false

          if(!_partita.selezionata){
            row_disabled.push({index:counter+1, label:_partita.text})
            _partite_formatted.push([_partita.numero_partita, _partita.varieta.etichetta, _partita.importo_assicurato])
          }else{
            if(_partita.sub_partite.length > 0){
              _partite_formatted.push([_partita.numero_partita, _partita.varieta.etichetta, this.stringToFloatFixed(_partita.importo_assicurato), "", "0", this.stringToFloatFixed(_partita.importo_assicurato), "", "", "", "", "","", "","", "", "", "", "", "", ""])
            }else{
              if(_partita.last_bollettino){
                const _found = _partita.last_bollettino.rows.find(_row => _row.partita_id == _partita.id && !_row.sub_partita_id && _row.is_selected)
                if(_found){
                  this.column_10_read_only.push(counter)
                  _partite_formatted.push([_partita.numero_partita, _partita.varieta.etichetta, this.stringToFloatFixed(_partita.importo_assicurato), "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "0", this.stringToFloatFixed(_partita.importo_assicurato),  "0", "0", "0", _found["_8"], "0", "zero", "", "", "<div class=\"select-osservazioni\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", _found["_15"], _found["_16"], _found["_17"], _found["_18"], _found["_19"], "<div class=\"select-a\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", this.col_checkbox_html(counter, false)])
                }else{
                  _partite_formatted.push([_partita.numero_partita, _partita.varieta.etichetta, this.stringToFloatFixed(_partita.importo_assicurato), "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "0", this.stringToFloatFixed(_partita.importo_assicurato),  "0", "0", "0", "0", "0", "zero", "", "", "<div class=\"select-osservazioni\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "", "", "", "", "", "<div class=\"select-a\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", this.col_checkbox_html(counter, false)])
                }
              }else{
                _partite_formatted.push([_partita.numero_partita, _partita.varieta.etichetta, this.stringToFloatFixed(_partita.importo_assicurato), "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "0", this.stringToFloatFixed(_partita.importo_assicurato),  "0", "0", "0", "0", "0", "zero", "FC", "", "<div class=\"select-osservazioni\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "", "", "", "", "", "<div class=\"select-a\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", this.col_checkbox_html(counter, false)])
              }
            }
          }

           for (let j = 0; j < _partita.sub_partite.length; j++) {
              const _sub_partita = partite[i].sub_partite[j];
              counter++
              _sub_partita.selezionata = _sub_partita.selezionata !== undefined ? _sub_partita.selezionata : false
              
              this.subpartite_key[counter] = {partita:_count, selezionata:_sub_partita.selezionata, id:_sub_partita.id, denunce:_sub_partita.denunce_da_liquidare.map(d => d.id)}
              if(!_sub_partita.selezionata){
                row_disabled.push({index:counter+1, label:_sub_partita.text})
                _partite_formatted.push([_partita.numero_partita+'/'+_sub_partita.sub_index, _partita.varieta.etichetta, this.stringToFloatFixed(_sub_partita.valore), "", "", "", "", "","", "","", "", "", "", "", "", ""])
              }else{
                if(_partita.last_bollettino){
                  const _found = _partita.last_bollettino.rows.find(_row => _row.partita_id == _partita.id && _row.sub_partita_id == _sub_partita.id && _row.is_selected)
                  if(_found){
                    this.column_10_read_only.push((counter))
                    _partite_formatted.push([_partita.numero_partita+'/'+_sub_partita.sub_index, _partita.varieta.etichetta, this.stringToFloatFixed(_sub_partita.valore), "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "0", this.stringToFloatFixed(_sub_partita.valore),  "0", "0", "0", _found["_8"], "0", "zero", "", "", "<div class=\"select-osservazioni\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", _found["_15"], _found["_16"], _found["_17"], _found["_18"], _found["_19"], "<div class=\"select-a\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", this.col_checkbox_html(counter, false)])
                  }else{
                    const deduzioneDanniAnteriori = _partita.last_bollettino?.rows[0]["_9"];
                    _partite_formatted.push([_partita.numero_partita+'/'+_sub_partita.sub_index, _partita.varieta.etichetta, this.stringToFloatFixed(_sub_partita.valore), "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "0", this.stringToFloatFixed(_sub_partita.valore),  "0", "0", "0", deduzioneDanniAnteriori, "0", "zero", "FC", "", "<div class=\"select-osservazioni\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "", "", "", "", "", "<div class=\"select-a\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", this.col_checkbox_html(counter, false)])
                  }
                }else{
                  _partite_formatted.push([_partita.numero_partita+'/'+_sub_partita.sub_index, _partita.varieta.etichetta, this.stringToFloatFixed(_sub_partita.valore), "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "0", this.stringToFloatFixed(_sub_partita.valore),  "0", "0", "0", "0", "0", "zero", "FC", "", "<div class=\"select-osservazioni\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", "", "", "", "", "", "<div class=\"select-a\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", this.col_checkbox_html(counter, false)])
                }
              }
            }
            counter++            
        }

          this.partite_formatted = _partite_formatted
          this.jExcelObj.setData(this.partite_formatted)
          for (let index = 0; index < this.partite_formatted.length; index++) {
            this.validateRowData(index);
          }
          const _this = this
          
          setTimeout(() => {
            row_disabled.forEach((item) => {
              _this.jExcelObj.setMerge('D'+item.index, 18, 1)
             this.jExcelObj.setValueFromCoords(5, item.index-1, item.label, true)
            })
            _this.init_table_functions_base()
            _this.init_table_functions()
          }, 1);

      },
      col_to_html(value, col){
        if(this.readOnly){
          return value;
        }
        value = value ? value : ''
        let html = ''
        switch (col) {
          case 3:
              html = `<div class="select-causa" data-id="${value}"> ${value} <i class="fa fa-caret-down"></i></div>`
            break;
          case 14:
              html = `<div class="select-osservazioni" data-id="${value}"> ${value} <i class="fa fa-caret-down"></i></div>`
            break;
          case 20:
              html = `<div class="select-a" data-id="${value}"> ${value} <i class="fa fa-caret-down"></i></div>`
            break;
          default:
            break;
        }
        return html   
      },
      col_checkbox_html(row, disabled){

        if(!this.readOnly && !disabled){
          return `<input class="a-checkbox" row-id="${row}" type='checkbox'/>`
        }

        return '';
      },
      parse_row_data(rows, bozza){
        let _partite_formatted = []
        let row_disabled = []
        let rows_20 = []
        let counter = 0;
        let partita = null
        this.readOnly = bozza
        
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          let _count = counter
          
          if(!row.sub_partita_id){
            let has_sub = true;

            if(typeof rows[i+1] === 'undefined' || !rows[i+1].sub_partita_id){
              has_sub = false  
            }
            
            if(row["_20"] !== undefined && row["_20"] !== null && row["_20"] !== ""){
              rows_20.push({index:counter, value:row["_20"]})
            }
            
            let disabled = false
            if(row["_5"] === "PARTITA LIQUIDATA" || row["_5"] === "PERIZIA RINVIATA" || row["_5"] === "PARTITA NON DENUNCIATA"){
              disabled = true
              row_disabled.push({index:counter, value:row["_5"]})
            }

            if(has_sub){
              _partite_formatted.push([row["_0"], row["_1"], this.stringToFloatFixed(row["_2"]),row["_3"],
                this.stringToFloatFixed(row["_4"]), this.stringToFloatFixed(row["_5"]), row["_6"], row["_7"], row["_8"], row["_9"], row["_10"], row["_11"], row["_12"], row["_13"], 
                row["_14"], row["_15"], row["_16"], row["_17"], row["_18"], row["_19"], row["_20"], this.col_checkbox_html(counter, disabled)
              ])
            }else{
              _partite_formatted.push([row["_0"], row["_1"], this.stringToFloatFixed(row["_2"]),this.col_to_html(row["_3"], 3),
                this.stringToFloatFixed(row["_4"]), this.stringToFloatFixed(row["_5"]), row["_6"], row["_7"], row["_8"], row["_9"], row["_10"], row["_11"], row["_12"], row["_13"], 
                this.col_to_html(row["_14"], 14), 
                row["_15"], row["_16"], row["_17"], row["_18"], row["_19"], 
                this.col_to_html(row["_20"], 20), this.col_checkbox_html(counter, disabled)
              ])
            }
        
            partita = _count
            this.partite_key[_count] = {has_sub:has_sub, selezionata:row.is_selected, id: row.partita_id, id_row:row.id}
          }else{
        
           if(row["_20"] !== undefined && row["_20"] !== null && row["_20"] !== ""){
              rows_20.push({index:counter, value:row["_20"]})
            }

          let disabled_sub = false
            if(row["_5"] === "GIÀ LIQUIDATA" || row["_5"] === "PERIZIA RINVIATA" || row["_5"] === "PARTITA NON DENUNCIATA"){
              row_disabled.push({index:counter, value:row["_5"]})
              disabled_sub = true
            }

            if(row.partita &&  !this.readOnly){
              _partite_formatted.push([row["_0"], row["_1"], this.stringToFloatFixed(row["_2"]),this.col_to_html(row["_3"], 3),
                  this.stringToFloatFixed(row["_4"]), this.stringToFloatFixed(row["_5"]), row["_6"], row["_7"], row["_8"], row["_9"], row["_10"], row["_11"], row["_12"], row["_13"], 
                  this.col_to_html(row["_14"], 14), 
                  row["_15"], row["_16"], row["_17"], row["_18"], row["_19"], 
                  this.col_to_html(row["_20"], 20), this.col_checkbox_html(counter, disabled_sub)
                ])
            }else{
               _partite_formatted.push([row["_0"], row["_1"], this.stringToFloatFixed(row["_2"]),this.col_to_html(row["_3"], 3),
                  this.stringToFloatFixed(row["_4"]), this.stringToFloatFixed(row["_5"]), row["_6"], row["_7"], row["_8"], row["_9"], row["_10"], row["_11"], row["_12"], row["_13"], 
                  this.col_to_html(row["_14"], 14), 
                  row["_15"], row["_16"], row["_17"], row["_18"], row["_19"], 
                  this.col_to_html(row["_20"], 20), this.col_checkbox_html(counter, disabled_sub)
                ])
            }

            this.subpartite_key[counter] = {partita:partita, selezionata:row.is_selected, id:row.sub_partita_id, id_row:row.id}
          }
          
          counter++

          this.partite_formatted = _partite_formatted
          this.jExcelObj.setData(this.partite_formatted)
        }
        
          const _this = this
          
          setTimeout(() => {
            rows_20.forEach((item) => {
              _this.jExcelObj.setMerge('D'+(item.index+1), 9, 1)
              _this.jExcelObj.setValueFromCoords(5, item.index, item.value, true)
            })
            
            row_disabled.forEach((item) => {
              _this.jExcelObj.setMerge('D'+(item.index+1), 18, 1)
              _this.jExcelObj.setValueFromCoords(5, item.index, item.value, true)
            })
            _this.init_table_functions_base()
            _this.init_table_functions()
          }, 1);
      },
      set_a_row(row, value){
        const ritiro_di_denuncia = "61 - L'Assicurato, avendo constatato che la/e avversita' $$$ denunciata/e ha/hanno prodotto un danno infe..."
        let row_column_y = this.jExcelObj.getValueFromCoords(14, row)
        const str = window.$(row_column_y);
        const str_data = str.data('id')
        let row_col_array = str_data !== "" ? str_data.split('|') : []                  
        const found = row_col_array.find((el) => el === ritiro_di_denuncia)
        
        if(value === "" || value !== "Ritiro di denuncia"){
          if(found){
            row_col_array = row_col_array.filter((el) => el !== ritiro_di_denuncia)
          }
        }else if(value == "Ritiro di denuncia"){
          if(!found){
            row_col_array.push(ritiro_di_denuncia)
            this.$emit('callBackSelectOsservazioni', ritiro_di_denuncia)
          }
        }

        
        row_col_array = row_col_array.join('|')
        this.$emit('callBackSelectOsservazioni', row_col_array, row)
        this.jExcelObj.setValueFromCoords(14, row, ("<div class=\"select-osservazioni\" data-id=\""+row_col_array+"\">"+row_col_array+" <i class=\"fa fa-caret-down\"></i> </div>"), true)
        
        if(value !== ""){
          if(!this.jExcelObj.getMerge('D'+(parseInt(row)+1))){
            this.jExcelObj.setValueFromCoords(15, row, "", true)
            this.jExcelObj.setValueFromCoords(16, row, "", true)
            this.jExcelObj.setValueFromCoords(17, row, "", true)
            this.jExcelObj.setValueFromCoords(18, row, "", true)
            this.jExcelObj.setValueFromCoords(18, row, "", true)
            this.jExcelObj.setMerge('D'+(parseInt(row)+1), 9, 1)

          }
          this.jExcelObj.setValueFromCoords(5, row, value, true)
        }else{
          this.jExcelObj.removeMerge('D'+(parseInt(row)+1))

          let a = this._.cloneDeep(this.jExcelObj.getRowData(row))
          let b = this._.cloneDeep(this.partite_formatted[row])
          for (let j = 0; j < a.length; j++) {
                switch (j) {
                  case 5:  
                    this.jExcelObj.setValueFromCoords(j, row, b[2])
                    break;
                  case 3:  
                    this.jExcelObj.setValueFromCoords(3, row, "<div class=\"select-causa\" data-id=\"\"> <i class=\"fa fa-caret-down\"></i></div>", true)
                    break;  
                  case 4:  
                  case 6:
                  case 7:
                  case 8:
                  case 10:
                    this.jExcelObj.setValueFromCoords(j, row, 0)
                  break;
                  case 11:
                    this.jExcelObj.setValueFromCoords(j, row, "zero")
                  break;
                  case 9:
                    this.jExcelObj.setValueFromCoords(j, row, b[j])
                    break;
                  default:
                    break;
                }
              }
        }

        this.jExcelObj.setValueFromCoords(20, row, ("<div class=\"select-a\" data-id=\""+value+"\">"+value+" <i class=\"fa fa-caret-down\"></i> </div>"), true)
      },
      get_values_from_A(html_value){
        const str = window.$(html_value);
        const str_data = str.data('id')
        return str_data !== "" ? str_data.split('|') : []   
      },
      set_rows_a_value(ids, value){
        const _this = this
        ids.forEach(function(id){
          _this.set_a_row(id, value)
        })

        window.$('.a-checkbox').each(function(){
          window.$(this).prop('checked', false);
        })
        window.$('#select-checkbox').prop('checked', false);
        this.$emit('callBackACheckboxSelect', [])
        this.init_table_functions()
      },
      paste_rows_values(copy_row, paste_rows){

        const _this = this
        const copy = this._.cloneDeep(this.jExcelObj.getRowData(copy_row))
        this.$store.dispatch('updateLoadingState', true)
        const a_values = this.get_values_from_A(copy[20])

        setTimeout(() => {
          paste_rows.forEach(row => {

            const _row = _this.partite_formatted[parseInt(row)]

            if(a_values.length === 0){
              this.jExcelObj.removeMerge('D'+(parseInt(row)+1))
            }
            
            for (let i = 0; i <= 20; i++) {
              if(i !== 0 && i !== 1 && i !== 2 && i !== 4 && i !== 5){
                _this.jExcelObj.setValueFromCoords(i, row, copy[i], true)  
              }else if(i === 5){
                _this.jExcelObj.setValueFromCoords(i, row, _row[i], true)  
              }else if(i === 4){
                _this.jExcelObj.setValueFromCoords(i, row, 0, true)  
              }
            }

            if(a_values.length > 0){
              if(!this.jExcelObj.getMerge('D'+(parseInt(row)+1))){
                this.jExcelObj.setMerge('D'+(parseInt(row)+1), 9, 1)
              }
              this.jExcelObj.setValueFromCoords(5, row, copy[5], true)
            }
          });

          window.$('.a-checkbox').each(function(){
            window.$(this).prop('checked', false);
          })
          window.$('#select-checkbox').prop('checked', false);
          this.$emit('callBackACheckboxSelect', [])

          this.$store.dispatch('updateLoadingState', false)
          this.init_table_functions()
        }, 500);
      },
      init_table_functions(){
          const _this = this;
          window.$(".select-causa").on('click', function(){
              
            const el = window.$(this);
            const _value = el.data( "id" )
            const _x = el.parent().data('x')
            const _y = el.parent().data('y')
            
            _this.$refs.modal_select_causa.get_data(_value, _x, _y);
            _this.modals.isBollettinoSelectCausaVisible = true
          })
          
          window.$(".select-osservazioni").on('click', function(){
              
            const el = window.$(this);
            const _value = el.data( "id" )
            const _x = el.parent().data('x')
            const _y = el.parent().data('y')
            
            _this.$refs.modal_select_osservazioni.get_data(_value, _x, _y);
            _this.modals.isBollettinoSelectOsservazioniVisible = true
          })
          
          window.$(".select-a").on('click', function(){
              
            const el = window.$(this);
            const _value = el.data( "id" )
            const _x = el.parent().data('x')
            const _y = el.parent().data('y')
            
            _this.$refs.modal_select_a.get_data(_value, _x, _y);
            _this.modals.isBollettinoSelectAVisible = true
          })
          
          window.$("td").on('click', function(){
            
              if(!_this.readOnly){
                const el = window.$(this);
                const _x = el.data('x')
                
                if(parseInt(_x) == 12){
                    const _y = el.data('y')
                    const _value = el.html()
                    _this.$refs.modal_select_franchigia.get_data(_value, _x, _y);
                    _this.modals.isBollettinoSelectFranchigiaVisible = true
                }
                
                if(parseInt(_x) == 13){
                    const _y = el.data('y')
                    const _value = el.html()
                    _this.$refs.modal_select_data_raccolta.get_data(_value, _x, _y);
                    _this.modals.isBollettinoSelectDataRaccoltaVisible = true
                }
              }
          })

          window.$('.a-checkbox').on('click', function(){
            if(_this.readOnly){
              return
            }

            const checkbox_length = window.$('.a-checkbox').length
            const checked = window.$('.a-checkbox:checked');
            let rowIds = []
            checked.each(function(){
              rowIds.push(window.$(this).attr('row-id'))
            })

            _this.$emit('callBackACheckboxSelect',  rowIds)

            if((checkbox_length -1) === checked.length) {
              window.$('#select-checkbox').prop('checked', true);
            }else{
              window.$('#select-checkbox').prop('checked', false);
            }

            

          })
      },
      init_table_functions_base(){
        const _this = this

          window.$('#select-checkbox').on('click', function(){
            if(_this.readOnly){
              return
            }
            
            const isChecked = window.$(this).is(":checked")
            window.$('.a-checkbox').each(function(){
              window.$(this).prop('checked', isChecked);
            })

            const checked = window.$('.a-checkbox:checked');
            let rowIds = []
            checked.each(function(){
              rowIds.push(window.$(this).attr('row-id'))
            })
            
            _this.$emit('callBackACheckboxSelect',  rowIds)
          })

          window.$('.jexcel>tbody>tr>td').mouseenter(function() {
            var _text = '';
            
            if(window.$(this).find('div').length > 0){
              const _str_data = window.$(this).find('div').data('id')
              _text = _str_data;
              
            }else{
              _text = window.$(this).html();
            }

            if(_text != '' && _text != 'on'){
              create_tootltip(window.$(this), _text)
            }
          })

          window.$('.jexcel>tbody>tr>td').mouseleave(function() {
            if(!window.$(this).hasClass('tool')){
              window.$('.tool').each(function() {
                window.$(this).remove()
              })
            }
          })
      },
      get_jexcel_data(){

        const _table_data = this.jExcelObj.getData()
        let save_arr = []
        for (let i = 0; i < _table_data.length; i++) {
          let row = _table_data[i];
          let _save_row = {}
          for (let j = 0; j < row.length; j++) {
            const _row = row[j];

            if(j == 3 || j == 14){
              const col = window.$(row[j]);
              const col_data = col.data('id')
              if(col_data){
                _save_row[`_${j}`] = col_data
              }else{
                _save_row[`_${j}`] = ""
              }
            }else if(j == 20){
              const col = window.$(row[j]);
              const col_data = col.data('id')
              if(col_data){
                _save_row[`_${j}`] = col_data
                _save_row[`_3`] = ""
              }else{
                _save_row[`_${j}`] = ""
              }
            }else{
              if( j !== 21){
                _save_row[`_${j}`] = _row
              }
            }              
          }


          if(this.partite_key[i]){
            const _obj = {
              partita_id:this.partite_key[i].id,
              row:_save_row,
              partita_selected:this.partite_key[i].selezionata,
              is_selected:this.partite_key[i].selezionata ? 1 : 0,
              id: this.partite_key[i].id_row,
              denunce:this.partite_key[i].denunce
            } 
            save_arr.push(_obj)
          }

          if(this.subpartite_key[i]){
            const _obj = {
              partita_id:this.partite_key[this.subpartite_key[i].partita].id,
              sub_partita_id:this.subpartite_key[i].id,
              partita_selected:this.partite_key[this.subpartite_key[i].partita].selezionata,
              sub_partita_selected:this.subpartite_key[i].selezionata,
              is_selected:this.subpartite_key[i].selezionata ? 1 : 0,
              row:_save_row,
              id: this.subpartite_key[i].id_row,
              denunce:this.subpartite_key[i].denunce
            } 
            save_arr.push(_obj)
          }
        }
        let _denunce_to_save = []
        this.partite.forEach(partita => {
          partita.denunce.forEach(denuncia => {
            if(denuncia.selezionata){
              _denunce_to_save.push(denuncia.id)
            }
          });
        });

        return {rows:save_arr, denunce:_denunce_to_save, denunce_col:this.denunce}
      },
      reInit(){
        this.jExcelObj.destroy(document.getElementById('spreadsheet'), true);
        this.jExcelObj = null;
        this.initJExcel()
      }
  },
  watch: {
    azzera_scomposizione(nuovoValore, vecchioValore) {
      console.log(nuovoValore, vecchioValore)
    // Controllo se lo stato è cambiato
    if (nuovoValore !== vecchioValore) {
      if(nuovoValore){
        this.lanciaFunzione();
      }
      
    }
  },
},
  beforeDestroy(){
     window.$('.tool').each(function() {
      window.$(this).remove()
    })
  },
  mounted(){
      const _this = this
      _this.initJExcel()

        window.$( document ).ready(function() {
          _this.init_table_functions()
        })
        window.addEventListener('load', () => {
       // run after everything is in-place

  })

  },
  beforeMount() {
    //setTimeout(() => {
      //  this.saveInitialEvent()
       //}, 1500);
  },
  
}
</script>

<style>
#spreadsheet table{
  width: 100%;
}
#select-checkbox {
position: absolute;
right: 10px;
bottom: 5px;
}
.jexcel_contextmenu {
  display: none !Important;
}
#jexcel-header-img {
  width: 1281.61px;
  margin-left: 50px;
  height: 64px;
  background-repeat: no-repeat !important;
  position: relative;
  background-size: contain !important;
}
#jexcel-denunce {
position: absolute;
right: 0px;
bottom: 0px;
width: 213px;
right: 44px;
}

#jexcel-denunce ul{
padding-left: 0px;
min-height: 9 0px;
}


#jexcel-denunce ul li {
list-style: none;
float: left;
font-weight: bold;
font-size: 15px;
position: absolute;
bottom:0px;
}
.partitaBackground{
  background-color: gray;
  color: #b1b0b0 !important;
}

.modal-mask {
position: fixed;
z-index: 9998;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: rgba(0, 0, 0, 0.5);
display: table;
transition: opacity 0.3s ease;
}

.modal-wrapper {
display: table-cell;
vertical-align: middle;
}

.modal-container {
width: 460px;
margin: 0px auto;
padding: 20px 30px;
background-color: #fff;
border-radius: 20px;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
transition: all 0.3s ease;
font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
margin-top: 0;
color: #42b983;
}

.modal-body {
margin: 20px 0;
}
.modal-events-text {
color:#000000;
font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.modal-default-button {
float: right;
-webkit-text-size-adjust: 100%;
-webkit-tap-highlight-color: transparent;
--jexcel-border-color: #950303;
--button-color: #ebebeb;
--active-color: #007aff;
-webkit-font-smoothing: antialiased;
-webkit-box-direction: normal;
box-sizing: border-box;
margin: 0;
overflow: visible;
text-transform: none;
-webkit-appearance: button;
display: inline-block;
font-weight: 600;
text-align: center;
vertical-align: middle;
user-select: none;
border: 1px solid transparent;
padding: .375rem .75rem;
font-size: 1rem;
line-height: 1.5;
border-radius: .25rem;
transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
color: #fff;
background-color: #5c80d1;
border-color: #5c80d1;
font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
cursor: pointer;
}

/*
* The following styles are auto-applied to elements with
* transition="modal" when their visibility is toggled
* by Vue.js.
*
* You can easily play with the modal transition by editing
* these styles.
*/

.modal-enter {
opacity: 0;
}

.modal-leave-active {
opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
-webkit-transform: scale(1.1);
transform: scale(1.1);
}
.bold-title {
font-weight: 500;
}
/* class applies to select element itself, not a wrapper element */
.select-css {
display: block;
font-size: 16px;
font-family: sans-serif;
font-weight: 700;
color: #444;
line-height: 1.3;
padding: .6em 1.4em .5em .8em;
width: 100%;
max-width: 100%; /* useful when width is set to anything other than 100% */
box-sizing: border-box;
margin: 0;
border: 1px solid #aaa;
box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
border-radius: .5em;
-moz-appearance: none;
-webkit-appearance: none;
appearance: none;
background-color: #fff;
/* note: bg image below uses 2 urls. The first is an svg data uri for the arrow icon, and the second is the gradient. 
  for the icon, if you want to change the color, be sure to use `%23` instead of `#`, since it's a url. You can also swap in a different svg icon or an external image reference
  
*/
background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
background-repeat: no-repeat, repeat;
/* arrow icon position (1em from the right, 50% vertical) , then gradient position*/
background-position: right .7em top 50%, 0 0;
/* icon size, then gradient */
background-size: .65em auto, 100%;
}
/* Hide arrow icon in IE browsers */
.select-css::-ms-expand {
display: none;
}
/* Hover style */
.select-css:hover {
border-color: #888;
}
/* Focus style */
.select-css:focus {
border-color: #aaa;
/* It'd be nice to use -webkit-focus-ring-color here but it doesn't work on box-shadow */
box-shadow: 0 0 1px 3px rgba(59, 153, 252, .7);
box-shadow: 0 0 0 3px -moz-mac-focusring;
color: #222; 
outline: none;
}

/* Set options to normal weight */
.select-css option {
font-weight:normal;
}

/* Support for rtl text, explicit support for Arabic and Hebrew */
*[dir="rtl"] .select-css, :root:lang(ar) .select-css, :root:lang(iw) .select-css {
background-position: left .7em top 50%, 0 0;
padding: .6em .8em .5em 1.4em;
}

/* Disabled styles */
.select-css:disabled, .select-css[aria-disabled=true] {
color: graytext;
background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22graytext%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
  linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
}

.select-css:disabled:hover, .select-css[aria-disabled=true] {
border-color: #aaa;
}


body {
margin: 2rem;
}

</style>