<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Report direzione certificati
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="col-sm-12 col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <select v-model="searchType" class="form-control" name="searchType">
                                    <option value="referente">Referente</option>
                                    <option value="consorzio">Consorzio</option>
                                    <option value="agenzia">Agenzia</option>
                                </select>
                            </div>
                            <div class="col-md-6">
                                <model-list-select
                                    v-if="searchType == 'referente'"
                                    id="search-select"
                                    :list="ispettori.found"
                                    option-value="id"
                                    :custom-text="ispettoriListText"
                                    v-model="search.referente"
                                    placeholder="Seleziona un Ispettore"
                                    @searchchange="searchIspettore"
                                >
                                </model-list-select>
                                <model-list-select
                                    v-if="searchType == 'consorzio'"
                                    id="search-select"
                                    :list="consorzio.found"
                                    option-value="id"
                                    :custom-text="consorziListText"
                                    v-model="search.consorzio"
                                    placeholder="Seleziona un Consorzio"
                                    @searchchange="searchConsorzio"
                                >
                                </model-list-select>
                                <model-list-select
                                    v-if="searchType == 'agenzia'"
                                    id="search-select"
                                    :list="agenzia.found"
                                    option-value="id"
                                    :custom-text="agenzieListText"
                                    v-model="search.agenzia"
                                    placeholder="Seleziona una Agenzia"
                                    @searchchange="searchAgenzia"
                                >
                                </model-list-select>
                            </div>
                        </div>
                        <div style="margin-top:20px;" class="row">
                            <div class="col-md-12">
                                <p style="margin-bottom:0; font-weight:bold">Ultimo aggiornamento: {{data_aggiornamento}}</p>
                            </div>
                        </div>
                        <div v-if="reports.length > 0" style="margin-top:20px;" class="row">
                            <div class="col-md-12">
                                <button @click="printReport"  class="btn btn-sm btn-primary" type="button">Stampa Report</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <table class="table table-condensed table-bordered table-striped">
                        <tbody id="totale-direzione">
                            <tr>
                                <th colspan="1" scope="colgroup">Referente</th>
                                <th colspan="1" scope="colgroup">Numero Denunce</th>
                                <th colspan="6" scope="colgroup">Situazione carico</th>
                                <th colspan="3" scope="colgroup">Risarcimento</th>
                                <th colspan="1" scope="colgroup">Previsione risarcimento su totali</th>
                            </tr>
                            <tr>
                                <td rowspan="1"></td>
                                <td rowspan="1"></td>
                                <th colspan="2" scope="colgroup">Totali</th>
                                <th colspan="2" scope="colgroup">Evase</th>
                                <th colspan="2" scope="colgroup">Residue</th>
                                <th colspan="1" scope="colgroup">Valori in K€</th>
                                <th colspan="1" scope="colgroup">Risarcimento medio per certificato</th>
                                <th colspan="1" scope="colgroup">Risarcimento medio per 1000</th>
                                <td rowspan="1"></td>
                            </tr>
                            <tr>
                                <td rowspan="1"></td>
                                <td rowspan="1"></td>
                                <th scope="col">Nr.</th>
                                <th scope="col">Valori assicurati in K€</th>
                                <th scope="col">Nr.</th>
                                <th scope="col">Valori assicurati in K€</th>
                                <th scope="col">Nr.</th>
                                <th scope="col">Valori assicurati in K€</th>
                                <td rowspan="1"></td>
                                <td rowspan="1"></td>
                                <td rowspan="1"></td>
                                <td rowspan="1"></td>
                            </tr>
                            <tr v-for="report in reports" :key="report.id">
                                <td class="text-center">
                                    <p>{{report.referente}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{report.numero_denunce_univoche}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{report.numero_ordini_totali}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{(report.valore_assicurato_ordini_totali/1000).toFixed(2)}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{report.numero_ordini_evasi}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{(report.numero_assicurato_ordini_evasi/1000).toFixed(2)}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{report.residue}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{(report.valori_assicurati / 1000).toFixed(2)}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{report.valori_in_k.toFixed(2)}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{report.risarcimento_medio_certificato}}</p>
                                </td>
                                <td class="text-center">
                                    <p>{{(report.risarcimento_medio_1000).toFixed(2)}}%</p>
                                </td>
                                <td class="text-center">
                                    <p>{{report.previsione_risarcimento_totali.toFixed(2)}}</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="reports.length > 0" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="searchObj.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="prev"
                            :next-text="next"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import Config from '../../services/config'
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import UtilityRepository from "../../services/apis/utility"
import CertificatoRepository from "../../services/apis/certificato"

export default {
    components:{
        ModelListSelect,
        Paginate
    },
    data(){
        return {
            searchType: 'referente',
            data_aggiornamento:null,
            ispettori: {
                list:[],
                found:[]
            },
            consorzio: {
                list:[],
                found:[]
            },
            agenzia: {
                list:[],
                found:[]
            },
            reports:[],
            searchObj: {
                group_type:'referente',
                referente:null,
                consorzio:null,
                agenzia:null,
                per_page: 10,
                page: 1,
            },
            data: {
                last_page:1
            },
            search:{
                ispettore:null,
                consorzio:null,
                agenzia:null
            },
            prev:Config.lang.prev,
            next:Config.lang.next,
        }
    },
    watch: {
        searchType: function (type) {        
            this.searchObj = {
                group_type:type,
                referente:null,
                consorzio:null,
                agenzia:null,
                per_page: 10,
                page: 1,
            }
            this.getData()
        },
        search:{
            handler: function (_search){
                 this.searchObj.page = 1
                 this.searchObj.referente = _search.referente
                 this.searchObj.consorzio = _search.consorzio
                 this.searchObj.agenzia = _search.agenzia
                this.getData()
            },
            deep: true
        },
    },
    methods: {
        async getData(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await CertificatoRepository.get_report_direzione_ordini(this.searchObj).catch(()=>{})
            
            this.$store.dispatch('updateLoadingState', false);
            if(_resp){
                if(!this.data_aggiornamento){
                    if(_resp.data.data.data.length > 0){
                        this.data_aggiornamento = _resp.data.data.data[0].updated_at
                    }
                }

                this.reports = _resp.data.data.data
                this.data.last_page = _resp.data.data.last_page
            
            }
        },
        ispettoriListText(item){
            return `${item.codice_perito} - ${item.surname}`
        },
        consorziListText(item){
            return `${item.codice} - ${item.name}`
        },
        agenzieListText(item){
            return `${item.codice_identificativo} - ${item.nome}`
        },
        async searchIspettore(text){
            if(text != null && text != undefined && text != '') {
                var search = {
                    type: 'ispettore',
                    cerca: text
                }

                const _resp = await UtilityRepository.search_utenti(search).catch(()=>{})

                if(_resp)
                    this.ispettori.found = _resp.data.data;
                
            }
        },
        async searchConsorzio(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_consorzi(search).catch(()=>{})

                if(_resp)
                    this.consorzio.found = _resp.data.data;
            }
        },
        async searchAgenzia(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_agenzie(search).catch(()=>{})
                if(_resp)
                    this.agenzia.found = _resp.data.data;
            }
        },
        paginateClick(pageNum){
            this.searchObj.page = pageNum
            this.getData()
        },
        async printReport(){
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await CertificatoRepository.print_report_direzione(this.searchObj).catch(()=>{})
            
            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download =`report_statistico.pdf` ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        }
    },
    mounted(){
        this.getData()
    }
}
</script>

<style>
    #totale-direzione {
        font-size: 14px;
    }
</style>