import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { ValidationProvider, extend, ValidationObserver } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import it from 'vee-validate/dist/locale/it';
import Toasted from 'vue-toasted';
import VueLodash from 'vue-lodash';
import lodash from 'lodash'
import VTooltip from 'v-tooltip'

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
const moment = require('moment')
require('moment/locale/it')
window.$ = require('jquery')

Vue.use(require('vue-moment'), {moment})

// loop over all rules
for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: it.messages[rule] // add its message
  });
}

extend('is_valid_date', {
  getMessage: field => 'The ' + field + ' value is not truthy.',
  validate: function(value){
    if(!moment(value, 'DD-MM-YYYY').isValid()){
      return false
    }
    return true    
  }
});

extend('is_after_today', {
  getMessage: field => 'The ' + field + ' value is not truthy.',
  validate: function(value){
    if(moment(value, 'DD-MM-YYYY').diff(moment()) > 0){
      return false
    }
    return true    
  }
});

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
      return value;
  }
  var formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 0
  });
  return formatter.format(value);
});

Vue.filter('fatturaAraDataFormat', function(value){
  if (value.length > 0 ) {
    var dd = moment(value[0].data_fattura).format('YYYY');
    for (var i = 0; i < value.length; i++) {
        var fattura = value[i];
        /*if (fattura.h499 == 1) {
            var n_h499 = fattura.n_fattura + '_h499/'+dd;
        }*/
        if (fattura.h499 == 0) {
            var n_ara = fattura.n_fattura+'/'+dd;
        }
    }
    if(n_ara){
        return n_ara;
    }else{
        return '';
    }
}else{
    return '';
}
});

Vue.filter('toEuropeData', function (value) {
  var data = moment(value)
  if (!data.isValid) {
      return value;
  }

  return data.format('DD-MM-YYYY');
});


Vue.config.productionTip = false

// Register it globally
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component("v-select", vSelect);
Vue.use(Toasted)
Vue.use(VueLodash, {lodash:lodash})
Vue.use(VTooltip)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
