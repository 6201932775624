import api from '../api.js'
import store from '../../store'
import utility from '../utility'

export default({
    get_dashboard_data(){        
        return api.get(`/v2/specifiche/mie-statistiche?token=${store.getters.access_token}`);
    },
    get_specifica(id){        
        return api.get(`/v2/specifiche/${id}?token=${store.getters.access_token}`);
    },
    get_specifiche(search){   
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                _search.push(`${key}=${element}`)
            }
        }

        _search = _search.join('&')     
        return api.get(`/v2/specifiche/all?token=${store.getters.access_token}&${_search}`);
    },
    get_lista_ispettori(obj){
        
        var _obj = {
            paginate:0
        }

        if(obj){
            _obj = obj
        }

        var _search = [];
        for (const key in _obj) {
            if (_obj.hasOwnProperty(key)) {
                const element = _obj[key];
                _search.push(`${key}=${element}`)
            }
        }
        
        _search = _search.join('&')

        return api.get(`/v2/user/get-lista-ispettori?token=${store.getters.access_token}&${_search}`);
    },
    add_specifica(obj){        
        return api.post(`/v2/specifiche/nuova?token=${store.getters.access_token}`, obj);
    },
    update_specifica(obj){        
        return api.patch(`/v2/specifiche/${obj.id}/update?token=${store.getters.access_token}`, obj);
    },
    delete_specifica(id){        
        return api.delete(`/v2/specifiche/${id}?token=${store.getters.access_token}`);
    },
    filtrate_per_status(search){   
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                _search.push(`${key}=${element}`)
            }
        }

        _search = _search.join('&')     
        return api.get(`/v2/specifiche/filtrate-per-status?token=${store.getters.access_token}&${_search}`);
    },
    update_status_specifica(obj){        
        return api.patch(`/v2/specifiche/${obj.id}/change?token=${store.getters.access_token}`, obj);
    },
    get_specifiche_da_fatturare(search){        
        var _search = utility.appendParameters(search);
        return api.get(`/v2/specifiche/da-fatturare?token=${store.getters.access_token}&${_search}`);
    },
    get_lists_bollettini_shared(search){        
        var _search = utility.appendParameters(search);
        return api.get(`/v2/specifiche/lista-bollettini-associati?token=${store.getters.access_token}&${_search}`);
    },
    specifiche_perito(search) {
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/specifiche/perito?token=${store.getters.access_token}&${_search}`);
    },
    specifiche_da_fatturare(search) {
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/specifiche/non-fatturate?token=${store.getters.access_token}&${_search}`);
    },
    print_specifica(id) {
        return api.get(`/v2/print/specifica/${id}?token=${store.getters.access_token}`, {responseType: 'arraybuffer'});
    },
    print_specifiche_presenze(search) {
        var _search = utility.appendParameters(search); 
        return api.get(`/v2/print/specifiche/presenze?token=${store.getters.access_token}&${_search}`, {responseType: 'arraybuffer'});
    },
    get_reports(search){
        var _search = utility.appendParameters(search);   
        return api.get(`/v2/specifiche/report?token=${store.getters.access_token}&${_search}`);
    },
    getPeriziaPeriti(search){
        var _search = utility.appendParameters(search);   
        return api.get(`/v2/specifiche/patita-driver?token=${store.getters.access_token}&${_search}`);
    },
    print_report_statistico(obj) {
        return api.post(`/v2/print/report-statistico?token=${store.getters.access_token}`, obj, {responseType: 'arraybuffer'});
    },
});