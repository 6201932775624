<template>
    <!-- DELETE MODAL CERT -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h1 style="margin-bottom:0px;">
                <span>Aggiorna Ordine</span>
            </h1>
        </template>
        <template v-slot:body>
            <form>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-material">Certificato</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6">
                        <input v-model="certificato.certificato" placeholder="Numero Certificato">
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-8">
                        <label class="form-material">Seleziona Agenzia Cerificato</label>
                        <model-list-select
                            id="search-select"
                            :list="agenzia.found"
                            option-value="id"
                            :custom-text="agenzieListText"
                            v-model="certificato.agenzia_id"
                            placeholder="Seleziona un'Agenzia"
                            @searchchange="searchAgenzia"
                        >
                        </model-list-select>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-8">
                        <label class="form-material">Seleziona Consorzio Cerificato</label>
                        <model-list-select
                            id="search-select"
                            :list="consorzio.found"
                            option-value="id"
                            :custom-text="consorziListText"
                            v-model="certificato.consorzio_id"
                            placeholder="Seleziona un Consorzio"
                            @searchchange="searchConsorzio"
                        >
                        </model-list-select>
                    </div>
                </div>
            </form>
        </template>
        <template v-slot:footer>
            <button
                @click="updateCert"
                type="button"
                class="btn btn-light"
                aria-label="Aggiorna">
                <span>Aggiorna</span>
            </button>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"
import { ModelListSelect } from 'vue-search-select'
import UtilityRepository from "../../services/apis/utility"

export default {
    name:'ModalAggiornaCert',
    props:['isModalVisible'],
    components: {
        Modal,
        ModelListSelect
    },
    data(){
        return {
            certificato:null,
            agenzia:{
                list:[],
                found:[]
            },
            consorzio:{
                list:[],
                found:[]
            },
            agenzie: [],
            consorzi: [],
        }
    },
    methods: {
        closeModal(){
            this.certificato = null
            this.$emit('closeModalCallback', null)
        },
        getData(certificato){
            this.certificato = this._.cloneDeep(certificato, true)
            this.consorzio.found.push(this.certificato.consorzio)
            this.agenzia.found.push(this.certificato.agenzia)
        },
        updateCert(){

            let obj = {
                id: this.certificato.id,
                certificato: this.certificato.certificato,
                agenzia_id: this.certificato.agenzia_id,
                consorzio_id: this.certificato.consorzio_id,
            };
            
            this.$emit('updateModalCallback', obj)
        },
        async searchAgenzia(text){
            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_agenzie(search).catch(()=>{})

                if(_resp)
                    this.agenzia.found = _resp.data.data;
            }
        },
        async searchConsorzio(text){
            if(text != null && text != undefined && text != '') {
                var search = {
                    cerca: text
                }

                const _resp = await UtilityRepository.search_consorzi(search).catch(()=>{})

                if(_resp)
                    this.consorzio.found = _resp.data.data;
            }
        },
        agenzieListText(item){
            return `${item.codice_identificativo} - ${item.nome}`
        },
        consorziListText(item){
            return `${item.codice} - ${item.name}`
        },
    },
}
</script>