<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>Invita Perito</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div class="col-12">
                <div class="form-material" style="min-height:100px;">
                    <label for="example-text-input">Codice Perito</label>
                    <model-list-select
                        id="search-select"
                        :list="periti.found"
                        option-value="id"
                        :custom-text="peritiListText"
                        v-model="perito"
                        placeholder="Cerca codice perito"
                        @searchchange="searchPerito"
                    >
                    </model-list-select>
                </div>
            </div>
            <div class="col-12">
                <div class="form-material">
                    <label for="example-text-input">Seleziona Permesso</label>
                    <div>
                    <select v-model="permission">
                        <option value="view">Sola Lettura</option>
                        <option value="edit">User</option>
                        <option value="full">Super User</option>
                    </select>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                :disabled="!can_save"
                @click="invitoPerito"
                type="button"
                class="btn btn-primary"
                aria-label="Invita">
                <span>Invita</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import { ModelListSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import Modal from "../Modal"
import UtilityRepository from "../../services/apis/utility"

export default {
    name:'ModalFiltroPerito',
    props:['isModalVisible', 'eventi', 'ispettore_id', 'last_comuni', 'last_ordini'],
    components: {
        Modal, ModelListSelect
    },
    search: {
        comune_id: null,
        ordine_id: null,
    },
    data(){
        return {
            permission:'',
            perito:{},
            periti:{
                list:[],
                found:[]
            }
        }
    },
    computed:{
        can_save(){
            if(this.perito.codice_perito && this.permission){
                return true
            }
            return false
        }
    },
    methods: {
        invitoPerito(){
            this.$emit('invitoPeritoCallback', {codice_perito: this.perito.codice_perito, permission: this.permission})
        },
        peritiListText(item){
            return `${item.codice_perito} - ${item.surname}`
        },
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        async searchPerito(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    type: 'perito',
                    cerca: text
                }
                this.$store.dispatch('updateLoadingState', true)
                const _resp = await UtilityRepository.search_utenti(search).catch(()=>{})

                if(_resp)
                    this.periti.found = _resp.data.data;

                this.$store.dispatch('updateLoadingState', false)
            } 
        },
    }
}
</script>