<template>
<div>
    <!-- Page Content -->
        <div class="content bg-gray-lighter">
            <div class="row items-push">
                <div class="col-sm-8">
                    <h1 class="page-heading">
                        Livello Utenti <small>Lista degli utenti con i relativi livelli.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <div class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-6">
                            <div id="DataTables_Table_0_filter" class="dataTables_filter">
                                <label>
                                    <input v-model="search.cerca" @input="isTyping = true" type="search" class="form-control form-control-sm" placeholder="Cerca.." aria-controls="DataTables_Table_0">
                                </label>
                            </div>
                        </div>
                    </div>
                    <table class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="name" width="15" name="NOME" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="surname" width="15" name="COGNOME" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="role" width="15" name="LIVELLO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="grades" width="20" name="GRADO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="codice_perito" width="20" name="COD. PERITO" :orderBy="search.orderBy" />
                                <th style="width: 20%; text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="user in users" v-bind:key="user.id">
                                <td class="font-size-sm">{{user.name}}</td>
                                <td class="font-size-sm">
                                    {{user.surname}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span class="badge badge-info">{{user.role}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{user.grade_attuale[0] ? user.grade_attuale[0].name : ''}}
                                </td>
                                <td class="font-size-sm">
                                    {{user.codice_perito}}
                                </td>
                                <td class="text-center">
                                    <div class="btn-group">
                                        <button @click="showModalView(user)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Client">
                                            <i class="fa fa-fw fa-eye"></i>
                                        </button>
                                        <button @click="showModalEdit(user)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Client">
                                            <i class="fa fa-fw fa-pencil-alt"></i>
                                        </button>
                                        <button @click="showModal(user)" type="button" class="btn btn-sm btn-danger" data-toggle="tooltip" title="Remove Client">
                                            <i class="fa fa-fw fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="row">
                        <div class="col-sm-12 col-md-5">
                            <div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">Pagine <strong>{{search.page}}</strong> di <strong>{{data.last_page}}</strong></div>
                        </div>
                        <div class="col-sm-12 col-md-7">
                            <paginate
                                v-model="search.page"
                                :page-count="data.last_page"
                                :click-handler="paginateClick"
                                :prev-text="'Prev'"
                                :next-text="'Next'"
                                :container-class="'pagination'"
                                :page-class="'page-item'"
                                :prev-class="'prev'"
                                :next-class="'next'">
                            </paginate>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END Dynamic Table Full -->
        </div>
        <!-- END Page Content -->

        <!-- DELETE USER MODAL -->
        <Modal v-if="isModalDeleteVisible" @close="closeModal">
             <template v-slot:header>
                <h2 style="margin-bottom:0px;">Elimina Utente</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="closeModal"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                Sei sicuro di voler eliminare l'utente <b>{{completeName}}</b> ?
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="closeModal"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-danger"
                    @click="confirmDeleteModal"
                    aria-label="Conferma">
                    Conferma
                </button>
            </template>
        </Modal>
        <!-- END DELETE USER MODAL -->

        <!-- SHOW USER MODAL -->
        <Modal v-if="isModalViewVisible" @close="closeModal">
             <template v-slot:header>
                <h2 style="margin-bottom:0px;">Dati Utente</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="closeModal"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <form class="js-validation-signin">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Nome</label>
                                <input v-model="selectedUser.name" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Cognome</label>
                                <input v-model="selectedUser.surname" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Codice Perito</label>
                                <input v-model="selectedUser.codice_perito" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">N° telefono</label>
                                <input v-model="selectedUser.telephone_number" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-ltf-email">E-mail</label>
                                <input v-model="selectedUser.email" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="example-ltf-email">Codice Fiscale</label>
                                <input v-model="selectedUser.invoice.codice_fiscale" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="example-ltf-email">Soggetto Fiscale</label>
                                <input v-model="selectedUser.invoice.soggetto_fiscale" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="example-ltf-email">Partita Iva</label>
                                <input v-model="selectedUser.invoice.partita_iva" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-ltf-email">Indirizzo</label>
                                <input v-model="selectedUser.invoice.indirizzo" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="example-ltf-email">Cap</label>
                                <input v-model="selectedUser.invoice.cap" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="example-ltf-email">Comune</label>
                                <input v-model="selectedUser.invoice.comune" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label for="example-ltf-email">Provincia</label>
                                <input v-model="selectedUser.invoice.provincia" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Contributo Previdenziale</label>
                                <input v-model="selectedUser.invoice.contributo_previdenziale" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Contributo Integrativo</label>
                                <input v-model="selectedUser.invoice.contributo_integrativo" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Ritenuta d’acconto in deduzione</label>
                                <input v-model="selectedUser.invoice.ritenuta_deduzione" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="example-ltf-email">Aliquota IVA</label>
                                <input v-model="selectedUser.invoice.aliquota_iva" type="text" class="form-control" disabled="true" placeholder="Cognome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-ltf-email">Nome della Banca</label>
                                <input v-model="selectedUser.invoice.nome_banca" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label for="example-ltf-email">IBAN</label>
                                <input v-model="selectedUser.invoice.iban" type="text" class="form-control" disabled="true" placeholder="Nome">
                            </div>
                        </div>
                    </div>
                </form>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-primary"
                    aria-label="Stampa"
                    @click="print_dettaglio_utente(selectedUser.id)">
                    Stampa
                </button>
            </template>
        </Modal>
        <!-- END SHOW USER MODAL -->

        <!-- EDIT USER MODAL -->
        <Modal v-if="isModalEditVisible" @close="closeModal">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Modifica Utente</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="closeModal"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Anno</label>
                                    <select v-model="edit_user.anno_selected" class="form-control" name="anno" @change="ChangeYear">
                                        <option v-for="anno in modal_edit_data.anno" :key="anno" :value="anno">{{anno}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row" v-if="edit_user.anno_selected == currentYear">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Seleziona Livello Utente</label>
                                    <select v-model="edit_user.role" class="form-control" name="role">
                                        <option value="perito">Perito</option>
                                        <option value="ispettore">Ispettore</option>
                                        <option value="direzione">Direzione</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Seleziona Grado Utente</label>
                                    <select v-model="edit_user.grades[edit_user.anno_selected].grade_id" class="form-control" name="role">
                                        <option v-for="grade in grades" :key="grade.id" :value="grade.id">{{grade.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </form>

            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="closeModal"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="confirmEditModal"
                    aria-label="Conferma">
                    Conferma
                </button>
            </template>
        </Modal>
        <!-- END EDIT USER MODAL -->
</div>
</template>

<script>
import _ from 'lodash';
import Paginate from 'vuejs-paginate'
import Modal from '../../components/Modal.vue';
import ThTable from '../../components/ThTable'
import AdminRepository from "../../services/apis/admin"
import UserRepository from "../../services/apis/user"

export default {
    data(){
        return {
            data: {
                last_page:1
            },
            grades:[],
            users: [],
            isTyping: false,
            search: {
                per_page: 10,
                page:1,
                cerca:'',
                orderBy:'surname:asc'
            },
            isModalDeleteVisible: false,
            isModalViewVisible: false,
            isModalEditVisible: false,
            selectedUser: null,
            edit_user: {
                anno:'',
                anno_selected:'',
                role:'',
                grade_id:''
            },
            modal_edit_data: {
                anno:[process.env.VUE_APP_YEAR,process.env.VUE_APP_LAST_YEAR]
            },
            currentYear:null
        }
    },
    components:{
        Paginate, Modal, ThTable
    },
    watch: {
        'search.cerca': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        isTyping: function(value) {
            
            if (!value) {
                this.server_call()
            }
        },
        'search.orderBy':function(){
            this.server_call()
        }
    },
    computed: {
        completeName(){
            return this.selectedUser ? `${this.selectedUser.name} ${this.selectedUser.surname}` : ''
        },
    },
    methods: {
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
        },

        async server_call(){    
            let resp = await AdminRepository.get_lista_utenti(this.search).catch(() => {})
        
            if(resp){
                this.users = resp.data.users.data
                this.data = resp.data.users
            }
        },
        perPage(){
            this.search.page = 1
            this.server_call()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.server_call()
        },
        showModal(user) {
            this.selectedUser = user
            this.isModalDeleteVisible = true;
        },
        showModalView(user) {
            this.selectedUser = user
            if(!this.selectedUser.invoice){
                this.selectedUser.invoice = {}
            }
            this.isModalViewVisible = true;
        },
        returnGradeId(name){
            let find = this.grades.find((el) => el.name == name)
            return find.id
        },
        showModalEdit(user) {

            const _user = this._.cloneDeep(user, true)

            this.selectedUser = _user
            this.edit_user.role = this.selectedUser.role
            if(_user.grade_passato.length > 0){
                _user.grade_passato.grade_id =  _user.grade_passato[0].id
            }
            if(_user.grade_attuale.length > 0){
                _user.grade_attuale.grade_id =  _user.grade_attuale[0].id
            }
            
            this.edit_user.grades = {}
            this.edit_user.grades[process.env.VUE_APP_YEAR] = _user.grade_attuale
            this.edit_user.grades[process.env.VUE_APP_LAST_YEAR] = _user.grade_passato

            this.edit_user.anno_selected = process.env.VUE_APP_YEAR
            this.isModalEditVisible = true;
        },
        closeModal() {
            this.isModalDeleteVisible = false;
            this.isModalViewVisible = false;
            this.isModalEditVisible = false;
            this.edit_user =  {anno_selected:'',role:'',grade_id:''}
            this.selectedUser = null
        },
        async confirmDeleteModal() {

            if(!this.selectedUser){
                this.$toasted.error('Utente non selezionato correttamente.').goAway(3000)
                return
            }

            let resp = await AdminRepository.delete_user(this.selectedUser.id).catch(() => {})

            if(resp){
                this.$toasted.success(resp.data.message).goAway(3000)
                this.server_call()
                this.closeModal()
            }
        },
        async print_dettaglio_utente(id){

            let resp = await AdminRepository.print_dettaglio_utente(id).catch(() => {})

            if(resp){
                var fileURL = window.URL.createObjectURL(new Blob([resp.data]));
                var fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'dettaglio_utente.pdf');
                document.body.appendChild(fileLink);

                fileLink.click();
            }
        },
        async confirmEditModal(){
            if(!this.selectedUser){
                return
            }

            let obj = {
                user_id: this.selectedUser.id,
                grade_id: this.edit_user.grades[this.edit_user.anno_selected].grade_id,
                anno: this.edit_user.anno_selected,
                role: this.edit_user.role,
            }

            if(this.edit_user.anno_selected == 2022 && !obj.grade_id){
                this.$toasted.error("Devi selezionare un grado per quest'anno").goAway(3000)
                return
            }
             this.$store.dispatch('updateLoadingState', true)

            let _resp = await AdminRepository.edit_user_grade(obj).catch(() => {})
        
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.server_call()
                this.closeModal()
            }

             this.$store.dispatch('updateLoadingState', false)
            
        },
        ChangeYear(){            
            if(!this.selectedUser){
                return
            }

            if(this.edit_user.grades[this.edit_user.anno_selected].length > 0){
                this.edit_user.grade_id = this.returnGradeId(this.edit_user.grades[this.edit_user.anno_selected][0].name)   
            }else{
                this.edit_user.grade_id = null
            }
        }
    },
    mounted(){
        this.currentYear = process.env.VUE_APP_YEAR
    },
    async created(){
        this.server_call()

        let _grades = await UserRepository.grades().catch(() => {})
        
        if(_grades){
            this.grades = _grades.data.data            
        }
    }
}
</script>

<style scoped>
    .col-sm-8 {
        margin-bottom: 0px !important;
    }
    .pagination, .dataTables_filter {
        justify-content: flex-end;
        display: flex;
    }
    
</style>