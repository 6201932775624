<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>SUB PARTITE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div v-if="!existsSubPartite" class="row">
                <div class="col-md-12">
                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="creaSubPartita"
                        aria-label="Crea sub partita">
                        Crea sub partita
                    </button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <button style="margin-bottom:20px; float:right;" v-if="existsSubPartite" class="btn btn-primary" @click="switch_type">Switch {{isValue ? 'Percentuale' : 'Valore'}}</button>
                    <div id="spreadsheet" ref="spreadsheet"></div>
                    <button v-if="existsSubPartite" class="btn btn-primary" @click="add_sub_partita">Add sub partita</button>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <div style="justify-content: space-between; align-items: center;display: flex;width:100%">
                <p style="font-size: 20px;
        margin: 0;
        font-weight: bold;">Totale residuo: € {{totale_residuo}}</p>
                <div>
                    <button
                        style="margin-right:.50rem"
                        type="button"
                        class="btn btn-light"
                        @click="closeModal"
                        aria-label="Close modal">
                        Chiudi
                    </button>
                    <button
                        :disabled="!can_save"
                        type="button"
                        class="btn btn-primary"
                        aria-label="Nuova"
                        @click="save_update">
                        <span>Salva Modifiche</span>
                    </button>
                </div>
            </div>
            
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"
import jexcelStyle from "jexcel/dist/jexcel.css"; // eslint-disable-line no-unused-vars
import jexcel from "jexcel"; // eslint-disable-line no-unused-vars
import CertificatoRepository from "../../services/apis/certificato"

export default {
    name:'ModalSubPartita',
    props:['id_ispettore', 'isModalVisible'],
    components: {
        Modal
    },
    data(){
        return {
            subPartite:[],
            array_lettere:["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", 
                "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"],
            isVisible:false,
            existsSubPartite:false,
            indexArrotondato:null,
            totale_residuo:0,
            can_save:false,
            isValue:false,
        }
    },
    computed: {
        jExcelOptions() {
            const _this = this
            return {
                data:this.subPartite,
                colHeaders:  [ 'Sub index', 'Percentuale', 'Valore',  'Azioni', 'Arr.' ],
                colWidths: [ 100, 100, 100, 120, 30 ],
                allowInsertRow:true,
                allowManualInsertRow:false,
                allowInsertColumn:false,
                allowManualInsertColumn:false,
                allowDeleteRow:true,
                allowComments:false,
                allowDeleteColumn:false,
                columnResize:false,
                allowExport:false,
                onchange: function(instance, cell, x, y, value) {

                    //  console.log(instance);
                    //  console.log(cell);
                    //  console.log(x); // colonna
                    //  console.log(y); //riga
                    //  console.log(value); // valore
                    const _x = parseInt(x)
                    const _y = parseInt(y)

                    // Colonna percentuale check validità valore inserito
                     if(x == 1){
                        var _value = value.replace('%', '');
                        if(isNaN(_value) || _value < 0){
                            _this.subPartite[y][2] = 0;                            
                            _this.subPartite[y][1] = "0%";                            
                        }else{
                            _this.subPartite[y][2] = _this.toFixed(parseFloat((_this.partita.importo_assicurato/100)*_value), 2)
                            _this.subPartite[y][1] = _value+'%'
                        }

                        _this.jExcelObj.setData(_this.subPartite);
                        window.$("#spreadsheet").trigger("domChanged");
                     }

                     if(x == 2){
                         if(isNaN(value) || parseFloat(value) < 0){
                             value = 0
                             _this.jExcelObj.setValueFromCoords(_x, _y, value, true)
                         }
                         _this.checkTotal(_y, value)
                     }
                },
                columns: [
                    { type: 'text', readOnly:true},
                    { type: 'text'},
                    { type: 'text', readOnly:true},
                    { type: 'text', readOnly:true},
                    { type: 'text', readOnly:true},
                ],
            }
        }
    },
    methods: {
        checkTotal(_y, value){
            let importo = parseFloat(this._.cloneDeep(this.partita.importo_assicurato))
            
            const perc = (value / importo) * 100
            if(perc >= 100){
                return
            }

            this.subPartite[_y][2] = value
            this.subPartite[_y][1] = `${perc.toFixed(2)}%` 

            this.jExcelObj.setData(this.subPartite);
            this.upDateType()
            window.$("#spreadsheet").trigger("domChanged");
            this.subPartite.forEach(element => {
                importo = importo - this.formatNumeberTwoDecimalNotRound(element[2])
            });
            
            this.totale_residuo = importo.toFixed(2)
            this.check_if_can_save()
        },
        switch_type(){
            this.isValue = !this.isValue
            this.upDateType()
            window.$("#spreadsheet").trigger("domChanged");
            //jExcelObj
        },
        upDateType(){
            const _this = this
            window.$('#spreadsheet tbody tr').each(function(){
                window.$(this).children('td').each(function(){
                    const index = parseInt(window.$(this).attr('data-x'))
                    if(index === 1){
                        if(_this.isValue){
                            window.$(this).addClass('readonly')
                        }else{
                            window.$(this).removeClass('readonly')
                        }
                    }
                    if(index === 2){
                        if(_this.isValue){
                            window.$(this).removeClass('readonly')
                        }else{
                            window.$(this).addClass('readonly')
                        }
                    }
                })
            })
        },
        closeModal() {
            this.isValue = false
            this.destroyJExcelObj()
            this.$emit('closeModalCallback', null)
        },
        toFixed(num, fixed) {
            var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
            return num.toString().match(re)[0];
        },
        formatNumeberTwoDecimalNotRound(number){
            let split = parseFloat(number).toString()
            const pointIndex = split.indexOf('.');
            if(pointIndex !== -1){
                return parseFloat(split.substring(0, pointIndex + 3))
            }

            return split
        },
        calcolateSubPartita(partita){
            const _partita = this._.cloneDeep(partita, true)
            this.totale_residuo = _partita.importo_assicurato
            this.partita = _partita
            if(_partita.sub_partite.length == 0){

                const split = _partita.importo_assicurato / 2

                this.subPartite = [
                    ['a', '50%', this.formatNumeberTwoDecimalNotRound(split), this.createDeleteButton(0), this.createCheckboxInput(0, false)], //1000 totale partita
                    ['b', '50%', this.formatNumeberTwoDecimalNotRound(split), this.createDeleteButton(1), this.createCheckboxInput(1, false)]
                ]
            }else{

                for (let i = 0; i < _partita.sub_partite.length; i++) {
                    const element = _partita.sub_partite[i];
                    if(element.arrotondamento == true){
                        this.indexArrotondato = i
                    }
                }

                var index = 0;
                this.subPartite = _partita.sub_partite.map((el) => {
                    index += 1

                    const checked = this.indexArrotondato != null && this.indexArrotondato == index-1 ? "checked" : ""
                    const disabled = this.indexArrotondato != null && this.indexArrotondato != index-1 ? "disabled" : ""

                    return [
                        el.sub_index,
                        el.percentuale+'%',
                        el.valore,
                        this.createDeleteButton(index-1),
                        this.createCheckboxInput(index-1, checked, disabled)
                    ]
                })

                const _this = this
                setTimeout(() => {
                    _this.creaSubPartita()
                }, 100);
            }
        },
        // Importo con arrotondamento a due numeri al ribasso con resto
        update_total(){
            let importo = parseFloat(this._.cloneDeep(this.partita.importo_assicurato))
            
            this.subPartite.forEach(element => {
                importo = importo - this.formatNumeberTwoDecimalNotRound(element[2])
            });
            
            this.totale_residuo = importo.toFixed(2)
        },
        createDeleteButton(index){
            return `<button id="${index}" class="delete_row btn btn-md btn-danger">Elimina riga</button>`
        },
        createCheckboxInput(index, bool = "", disabled = ""){            
            return `<input id="${index}" class="check" type="checkbox" ${bool} name="c4" ${disabled}>`
        },
        updateCheckedElements(){
            for (let i = 0; i < this.subPartite.length; i++) {
                if(this.indexArrotondato != null && parseInt(this.indexArrotondato) != i){
                    this.subPartite[i][4] = this.createCheckboxInput(i, "", "disabled")
                }else if(parseInt(this.indexArrotondato) == i){
                    this.subPartite[i][4] = this.createCheckboxInput(i, "checked")
                }else{
                    this.subPartite[i][4] = this.createCheckboxInput(i)
                }
                this.subPartite[i][3] = this.createDeleteButton(i)
            }

            this.jExcelObj.setData(this.subPartite)
            this.upDateType()
            window.$("#spreadsheet").trigger("domChanged");
            
        },
        deleteRow(index){
            if(this.subPartite.length == 2){
                this.destroyJExcelObj()
                this.$emit('closeModalCallback', 'delete_all')
                return
            }
            
            this.subPartite.splice(index, 1);
            this.indexArrotondato = this.indexArrotondato != null && this.indexArrotondato == index ? null : this.indexArrotondato
            for (var i = 0; i < this.subPartite.length; i++) {
                var _sequence = i / this.array_lettere.length | 0;

                if(this.array_lettere[i] === undefined){
                    this.subPartite[i][0] = this.array_lettere[_sequence-1]+""+this.array_lettere[i % this.array_lettere.length]
                }else{
                    this.subPartite[i][0] = this.array_lettere[i] 
                }
            }

            this.updateCheckedElements()
        },
        add_sub_partita(){
            var _sequence = this.subPartite.length / this.array_lettere.length | 0;

            if(this.array_lettere[this.subPartite.length] === undefined){

                this.subPartite.push([
                    this.array_lettere[_sequence-1]+""+this.array_lettere[this.subPartite.length % this.array_lettere.length], '0%', 0, this.createDeleteButton(this.subPartite.length), this.createCheckboxInput(this.subPartite.length, false)]
                )
            }else{
                this.subPartite.push([
                    this.array_lettere[this.subPartite.length], '0%', 0, this.createDeleteButton(this.subPartite.length), this.createCheckboxInput(this.subPartite.length, false)
                ])
            }
            
            this.updateCheckedElements()
        },
        check_if_can_save(){
            if(this.totale_residuo > 0 && this.totale_residuo <= 0.9 && this.indexArrotondato != null){            
                this.totale_residuo = 0.00;
            }  
            //console.log(this.partita.importo_assicurato)

            const found = this.subPartite.find((el) => {
                return el[2] == 0;
            }) 

            if(found){
                this.can_save = false
                return
            }
            
            if(parseFloat(this.totale_residuo) !== 0.00){
                this.can_save = false
                return
            }

            this.can_save = true
        },
        domChangedEvent(){
            const _this = this;

            window.$('.delete_row').on('click', function(){
                _this.deleteRow(window.$(this).attr('id'));
            })
            
            window.$("input.check[type='checkbox']").on('click', function(){
                const element = window.$(this)
                const index = element.attr('id');
                const checked = element.prop("checked")
                _this.indexArrotondato = !checked ? null : index
                _this.subPartite[parseInt(index)][4] = _this.createCheckboxInput(parseInt(index))
                _this.updateCheckedElements()
            })

            this.update_total()
            this.check_if_can_save()
        },
        destroyJExcelObj(){
            if(this.jExcelObj){
                this.jExcelObj.destroy()
            }
            this.existsSubPartite = false
        },
        creaSubPartita(){

            const _this = this;
            this.existsSubPartite = true
            
            
            const jExcelObj = jexcel(this.$refs.spreadsheet, this.jExcelOptions);
            // Object.assign(this, jExcelObj); // pollutes component instance
            Object.assign(this, { jExcelObj });
            
            window.$("#spreadsheet").on('domChanged', function(){
                _this.domChangedEvent()
            })

            this.domChangedEvent()
        },
        async save_update(){
             this.$store.dispatch('updateLoadingState', true)

            const sub_partite = this.subPartite.map((el) => {
                const checked = el[4].match(/\Wchecked\W/)

                return {
                    sub_index:el[0],
                    percentuale:el[1].replace('%', ''),
                    valore:el[2],
                    arrotondamento:checked ? true : false
                }

            })
            
            var obj = {
                partita_id: this.partita.id,
                sub_partite: sub_partite,
                ispettore_id:this.id_ispettore,
                totale_partita:this.partita.importo_assicurato
            }

            let _resp = await CertificatoRepository.new_sub_partita(obj).catch(() => {})

            this.$store.dispatch('updateLoadingState', false)

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.isValue = false
                this.destroyJExcelObj()
                this.$emit('closeModalCallback', 'refresh')
            }
        }
    }
}
</script>

<style>
#spreadsheet table{
    width: 100%;
}
.jexcel_contextmenu {
    display: none !Important;
}
</style>