<template>
  <div id="app">
    <loading
      :show="show"
      :label="label">
    </loading>
    <MainPage :isLogged="isLogged" :version="version" />
  </div>
</template>

<script>
import loading from 'vue-full-loading'
import MainPage from './views/MainPage.vue'

export default {
  data(){
    return {
      isLogged:false,
      show: false,
      version:1,
      label: 'Caricamento...'
    }
  },
  components: {
    loading, MainPage
  },
  created() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'CHANGE_LOGGED_STATE') {
        this.isLogged = mutation.payload
      }
      if (mutation.type === 'CHANGE_LOADING_STATE') {
        this.show = mutation.payload
      }
    });

    this.version = localStorage.getItem('myara1857_v')

  }
}
</script>

<style>
  @import './assets/css/oneui.min.css';

  #main-container {
    min-height: 916px;
  }
  .page-heading {
    color: #545454;
    font-size: 28px;
    font-weight: 400;
  }
  .page-heading small {
    margin-top: 5px;
    display: block;
    color: #777;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.4;
  }
  .validation-error {
    font-size: 12px;
    color: red;
  }
  .pagination {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .pagination .page-item a {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -2px;
    line-height: 1.25;
    color: #575757;
    background-color: transparent;
    border: 2px solid transparent;
  }

  .pagination .prev a, .pagination .next a{
    padding: 5px 10px;
    display: block;
  } 
  
  .pagination .page-item.active a {
    z-index: 1;
    color: #5c80d1;
    border-bottom: 2px solid #5c80d1;
    background-color: #f9f9f9;
  }
  .block-content-full {
    overflow-x: auto;
  }
  .datepicker-years td, 
  .datepicker-months td,
  .datepicker-centuries td,
  .datepicker-decades td {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
  }
  .datepicker-years td .year,
  .datepicker-months td .month,
  .datepicker-centuries td .century,
  .datepicker-decades td .decade{
    padding: 10px;
    cursor: pointer;
  }
  .datepicker-years td .year.old,
  .datepicker-months td .month.old,
  .datepicker-centuries td .century.old,
  .datepicker-decades td .decade.old{
    background: #98989870;
    color: #8a8484;
  }
  .datepicker-years td .year.disabled,
  .datepicker-months td .month.disabled,
  .datepicker-centuries td .century.disabled,
  .datepicker-decades td .decade.disabled{
    color: lightgray;
  }
  .table-condensed{
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .datepicker-switch {
    cursor: pointer;
  }
  .prev, .next {
    cursor: pointer;
  }
  .old.day{
    background: #d3d3d370;
    color: #a5a5a5;
  } 
  .vfc-multiple-input input{
    width: 50%;
  }
  .order {
    cursor: pointer;
  }
  @media (max-width: 992px){
    .menu {
        transform: translateX(0) translateY(0) translateZ(0) !important;
    }
  }
  @media screen and (min-width: 768px){
    .page-heading small {
      margin-top: 0;
      display: inline;
      line-height: inherit;
    }
  }
</style>
