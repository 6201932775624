<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Lista Bollettini <small>Lista dei bollettini.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="filter-container">
            <div class="filter-container-main">
                <div class="over_md_6">
                    <div class="n_order_search over_n_order">
                        <label>Ricerca libera</label>
                        <input class="search-input" v-model="search.search" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Ordine</label>
                        <input class="search-input" v-model="search.n_ordine" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Certificato</label>
                        <input class="search-input" v-model="search.certificato" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Denominazione</label>
                        <input class="search-input" v-model="search.denominazione" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                </div>
                <div class="block-content block-content-full">
                    <h3 v-if="bollettini.length == 0">Nessun bollettino presente</h3>
                    <div v-if="bollettini.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-bottom:20px">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length">
                                <label>Status approvazione</label><br>
                                <label>
                                    <select v-model="search.statusApprovato" @change="bollettinoStatus"  class="form-control form-control-sm">
                                        <option value="">Tutti</option>
                                        <option value="1">Approvati</option>
                                        <option value="0">Da Approvare</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="bollettini.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th class="d-none d-sm-table-cell">NUMERO ORDINE</th>
                                <th class="d-none d-sm-table-cell">NUMERO CERTIFICATO</th>
                                <th v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell">AGENZIA</th>
                                <th v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell">CONSORZIO</th>
                                <th v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell">COMUNE</th>
                                <th v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell">DENOMINAZIONE CERT.</th>
                                <th v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell">SPECIE</th>
                                <th class="d-none d-sm-table-cell">PERITO</th>
                                <th style="text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(boll, index) in bollettini" :key="index">
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{boll.ordine.ordine_id}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{boll.ordine.certificato}}
                                </td>
                                <td v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell font-size-sm">
                                    {{boll.ordine.agenzia.codice_identificativo}} - {{boll.ordine.agenzia.nome}}
                                </td>
                                <td v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell font-size-sm">
                                     {{boll.ordine.consorzio.codice}} - {{boll.ordine.consorzio.name}}
                                </td>
                                <td v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell font-size-sm">
                                     {{boll.ordine.comune.nome}}
                                </td>
                                <td v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell font-size-sm">
                                      {{boll.ordine.denominazione}}
                                </td>
                                <td v-if="search.ispettore_id !== 0" class="d-none d-sm-table-cell font-size-sm">
                                      {{boll.ordine.specie.etichetta}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{boll.perito.surname}} - {{boll.perito.codice_perito}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="watchBollettino(boll)" type="button" class="btn btn-sm btn-light" title="Watch Certificato">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <button v-if="!boll.approvato && search.ispettore_id !== 0" @click="checkBollettino(boll, true)" type="button" class="btn btn-sm btn-light" title="Check Certificato">
                                            <i class="fa fa-check"></i>
                                        </button>
                                        <button v-if="!boll.approvato" @click="checkBollettino(boll, false)" type="button" class="btn btn-sm btn-light" title="Check Certificato">
                                            <i class="fa fa-times"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="bollettini.length > 0" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="'Prev'"
                            :next-text="'Next'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import _ from 'lodash';
import Paginate from 'vuejs-paginate'
import CertificatoRepository from "../../services/apis/certificato"
export default {
    components: {
        Paginate
    },
    data(){
        return {
            bollettini:[],
            data: {
                last_page:1
            },
            search: {
                ispettore_id: 0,
                per_page: 10,
                page: 1,
                ordine_id: '',
                search:'',
                statusApprovato:'',
                numero_ordine:'',
                certificato:'',
                denominazione:'',
            },
            isTyping: false,
        }
    },
    watch: {
        'search.search': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.certificato': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.denominazione': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.numero_ordine': _.debounce(function() {
            this.isTyping = false;
            }, 1000),

            isTyping: function(value) {
            if (!value) {
                this.search.page = 1
                this.get_bollettini()
            }
        }
    },
    methods: {
        async get_bollettini(){
            this.$store.dispatch('updateLoadingState', true);

            var src = {
                per_page: this.search.per_page,
                page: this.search.page,
                ordine_id: this.search.ordine_id,
                search:this.search.search,
                statusApprovato:this.search.statusApprovato,
                numero_ordine:this.search.numero_ordine,
                certificato:this.search.certificato,
                denominazione:this.search.denominazione,
            };

            const _resp = await CertificatoRepository.get_lista_bollettini(src).catch(()=>{})

            if(_resp){
                this.bollettini = _resp.data.data.data
                this.data = _resp.data.data   
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        bollettinoStatus(){
            this.search.page = 1
            this.get_bollettini()
        },
        perPage(){
            this.search.page = 1
            this.get_bollettini()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_bollettini()
        },
        watchBollettino(boll){
            this.$router.push({name:'modifica-bollettino', params: { certificato_id: boll.ordine_id, bollettino_id:boll.id, ispettore_id:this.search.ispettore_id }})
        },
        async checkBollettino(boll, state){
            this.$store.dispatch('updateLoadingState', true);
            
            const obj = {
                id:boll.id,
                state:state,
                ispettore_id:this.ispettore_id
            }
            const _resp = await CertificatoRepository.approva_bollettino(obj).catch(() => {})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                await this.get_bollettini()
            }

            this.$store.dispatch('updateLoadingState', false);
        },
    },
    mounted(){
        const user = this.$store.getters.user
        if(user.role == 'ispettore'){
            this.search.ispettore_id = user.role == 'ispettore' ? user.id : 0
        }

        this.get_bollettini()
    }
}
</script>