<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Controll Utenti <small>Seleziona l'utente che vuoi controllare.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <div class="block">
                <div class="block-header search_totals">
                    <div class="col-sm-12 col-md-6">
                        <label class="form-material">Seleziona utente</label>
                        <v-select 
                            :options="utenti" 
                            :filterable="false" 
                            @search="searchUtente" 
                            v-model="utenteSelected" 
                            label="id"
                            placeholder="Cerca l'utente che vuoi controllare"  >
                            
                            <template slot="no-options">
                                Nessun utente trovato
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="d-center">
                                    {{ `${option.name} ${option.surname} - ${option.codice_perito}` }}
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="option">
                                <div class="selected d-center">
                                    {{ `${option.name} ${option.surname} - ${option.codice_perito}` }}
                                </div>
                            </template>
                        </v-select>

                        <div v-if="utenteSelected" style="margin-top:20px">
                            <div class="form-material" style="text-align: left; margin-top: 10px; margin-bottom: 10px;">
                                <button class="btn btn-sm btn-primary" type="submit" @click="shadowLogin">Accedi come {{`${utenteSelected.name} ${utenteSelected.surname}`}}</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminRepository from "../../services/apis/admin"
import UserRepository from "../../services/apis/user"
export default {
    data() {
        return{
            utenti:[],
            utenteSelected:null,
            timeout: null
        }
    },
    methods: {
        searchUtente(text, loading){

            if(!text || text.length < 3){
                return;
            }

            clearTimeout(this.timeout);

            var self = this;
            this.timeout = setTimeout(function () {
                loading(true);
                self.getUsers(text, loading)
            }, 1000);
        },
        async getUsers(text, loading){
            const resp = await AdminRepository.search_users({search:text}).catch(() => {})
            if(resp){
                this.utenti = resp.data.users
            }
            loading(false)
        },
        usersText(item){
            return `${item.name} ${item.surname} - ${item.codice_perito}`
        },
        async shadowLogin(){
            this.$store.dispatch('updateLoadingState', true)

            const resp = await AdminRepository.user_control({user_id:this.utenteSelected.id}).catch(() => {})
            if(resp){

                this.$store.dispatch('updateAdminTokenState', this.$store.getters.access_token) 
                this.$store.dispatch('updateAccessTokenState', resp.data.token) 
                
                const user = resp.data.user
                user.service_token = resp.data.service_token
                this.$store.dispatch('updateUserObjState', user)  

                if(user.role === "perito"){
                    const _resp = await UserRepository.get_ispettori_associati().catch(() => {})

                    if(_resp){
                        this.$store.dispatch('updateIspettoriObjState', _resp.data.data)  
                    }
                }

                if(user.role != 'perito' && user.role != 'ispettore'){
                    this.$store.dispatch('updateChoiseState','specifiche')
                    this.$router.push({ name: 'dashboard'})
                }else {
                    this.$router.push({ name: 'scegli-sezione' })
                }
                
            }

            this.$store.dispatch('updateLoadingState', false)
        }
    }
}
</script>