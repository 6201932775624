<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Annulla Bollettini <small>Annulla bollettini per perito.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="filter-container">
                <form class="filter-container-main">
                    <div class="row row_over">
                        <div class="n_order_search over_n_order">
                            <label>Ordine</label>
                            <input v-model="search.numero_ordine" @input="isTyping = true"  placeholder="Ricerca per Numero Ordine">
                        </div>
                        <div class="n_order_search over_n_order">
                            <label>Certificato</label>
                            <input class="search-input" v-model="search.certificato"  @input="isTyping = true"  placeholder="Ricerca per Cerificato">
                        </div>
                        <div class="n_order_search over_n_order">
                            <label>Denominazione</label>
                            <input class="search-input" v-model="search.denominazione" @input="isTyping = true"   placeholder="Ricerca per Denominazione">
                        </div>
                        <div class="n_order_search over_n_order">
                            <label style="margin-bottom:10px;" class="form-material">Seleziona utente</label>
                            <SelettoreRicerca
                                type="ispettore"
                                placeholder="Seleziona utente"
                                :selectedModel="perito_selected"
                                @updateSelector="updateSelector"
                            />
                        </div>
                        <div class="n_order_search over_n_order">
                            <label style="margin-bottom:10px;" class="form-material">Filtro per data perizia</label>
                            <date-range-picker
                                style="width: 100%;"
                                ref="picker"
                                :locale-data="localeRange"
                                :autoApply="true"
                                :ranges="false"
                                v-model="dateRange"
                                @select="dayClicked"
                            >
                                <template v-slot:input="picker" style="width:100%;">
                                    <span v-if="picker.startDate">{{ picker.startDate | toEuropeData }} - {{ picker.endDate | toEuropeData }}</span>
                                    <span v-else>Seleziona Range di ricerca</span>
                                </template>
                            </date-range-picker>
                        </div>
                    </div>
                    <div v-if="dateRange.startDate" style="margin-top: 20px;" class="row">
                        <div class="col-sm-12 col-md-12">
                            <button @click="resetAll" type="button" class="btn btn-sm btn-primary" title="Edit Perizia">
                                Reset Filtro
                            </button>
                        </div>
                    </div>
                </form>
            
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="bollettini.length == 0">Nessun Bollettino</h3>
                    <div v-if="bollettini.length > 0" class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="bollettini.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="ordine_id" name="NUMERO ORDINE" :orderBy="search.orderBy" class="text-center" />
                                <ThTable @updateOrder="orderBy" orderKey="certificato" name="NUMERO CERTIFICATO" :orderBy="search.orderBy" class="text-center" />
                                <ThTable @updateOrder="orderBy" orderKey="ispettore" name="ISPETTORE" :orderBy="search.orderBy" class="text-center" />
                                <ThTable @updateOrder="orderBy" orderKey="data_perizia" name="DATA PERIZIA" :orderBy="search.orderBy" class="text-center" />
                                <ThTable @updateOrder="orderBy" orderKey="agenzia" name="AGENZIA" :orderBy="search.orderBy" class="text-center" />
                                <ThTable @updateOrder="orderBy" orderKey="specie" name="SPECIE" :orderBy="search.orderBy" class="text-center" />
                                <ThTable @updateOrder="orderBy" orderKey="comune" name="COMUNE" :orderBy="search.orderBy" class="text-center" />
                                <ThTable @updateOrder="orderBy" orderKey="denominazione" name="DENOMINAZIONE CERT." :orderBy="search.orderBy" class="text-center" />
                                <th class="d-none d-sm-table-cell">ELIMINA</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(bollettino, index) in bollettini" :key="index">
                                <td class="font-size-sm">
                                    {{bollettino.ordine.ordine_id}}
                                </td>
                                <td class="font-size-sm">
                                    {{bollettino.ordine.certificato}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{bollettino.ordine.ispettore.name}} {{bollettino.ordine.ispettore.surname}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{bollettino.data_perizia | toEuropeData}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{bollettino.ordine.agenzia.codice_identificativo}} - {{bollettino.ordine.agenzia.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{bollettino.ordine.specie.etichetta}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{bollettino.ordine.comune.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{bollettino.ordine.denominazione}}
                                </td>

                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="eliminaBollettino(bollettino)" type="button" class="btn btn-sm btn-light" title="Elimina bollettino">
                                            <i class="fas fa-trash-alt"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="data.last_page > 1" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="prev"
                            :next-text="next"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                </div>
            </div>
        </div>
        <ModalTrueFalse
            :isModalVisible="isModalDeleteVisible" 
            @closeModalCallback="callbackDelete"
            >
             <template v-slot:body-true>
                <h3>Sei sicuro di voler rimuovere il Bollettino?</h3>
             </template>
        </ModalTrueFalse>


    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ModalTrueFalse from '../../../components/ModalTrueFalse'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import BollettinoRepository from "../../../services/apis/bollettino"
import Utility from '../../../services/utility'
import Config from '../../../services/config'
import ThTable from '../../../components/ThTable'
import SelettoreRicerca from '../../../components/SelettoreRicerca'
import _ from 'lodash';
export default {
    components: {
        ThTable,
        Paginate,
        DateRangePicker,
        ModalTrueFalse,
        SelettoreRicerca
    },
    data(){
        return {
            dateRange:{},
            seleziona_tutto:false,
            ispettore_id: 0,
            isModalDeleteVisible:false,
            data: {
                last_page:1
            },
            bollettini: [],
            certificato: null,
            periti:{
                list:[],
                found:[]
            },
            ispettori:[],
            consorzi:[],
            selected_bollettino:null,
            perito_selected:null,
            isTyping: false,
            search: {
                ispettore_id: null,
                per_page: 10,
                page: 1,
                orderBy:'id:desc',
                numero_ordine:'',
                certificato:'',
                denominazione:'',
            }
            
        }
    },
    computed: {
        localeRange(){
            return Utility.dateRangeLocale()
        },
        prev(){
            return Config.lang.prev
        },
        next(){
            return Config.lang.next
        },
    },
    watch: {
        'search.ispettore_id': function (){
            this.search.page = 1
            this.get_bollettini();
        },
        'search.numero_ordine': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.certificato': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.denominazione': _.debounce(function() {
            this.isTyping = false;
            }, 1000),

        isTyping: function(value) {
            if (!value) {
                this.search.page = 1
                this.get_bollettini()
            }
        },
        'perito_selected': function(item){
            if(item){
                this.search.ispettore_id = item.id
            }else{
                this.search.ispettore_id = null
            }
        }
    },
    methods: {
        updateSelector(_, item){
            this.perito_selected = item
        },
        async get_bollettini(){

            if(this.dateRange.startDate && this.dateRange.endDate){
                this.search.start_date = Utility.convertDataToDB(this.dateRange.startDate)
                this.search.end_date = Utility.convertDataToDB(this.dateRange.endDate)
            }else{
                delete this.search.start_date
                delete this.search.end_date
            }

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await BollettinoRepository.lista(this.search).catch(()=>{})
            
            if(_resp){
                /*
                console.log(_resp.data.data)
                console.log(_resp.data.data.data)
                */
                this.bollettini = _resp.data.data.data
                this.data = _resp.data.data
            }
            

            this.$store.dispatch('updateLoadingState', false);
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_bollettini()
        },
        perPage(){
            this.search.page = 1
            this.get_bollettini()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_bollettini()
        },
        dayClicked(data){
            this.dateRange = data
            this.get_bollettini()
        },
        resetAll() {
            this.search.per_page = 10
            this.search.page = 1 
            this.search.ispettore_id = null

            for (const key in this.dateRange) {
                if (this.dateRange.hasOwnProperty(key)) {
                    this.dateRange[key] = null;
                }
            }
            this.get_bollettini()
        },
        eliminaBollettino(bollettino){
            this.selected_bollettino = bollettino
            this.isModalDeleteVisible = true
        },
        async callbackDelete(status){            
            this.isModalDeleteVisible = false
            if(!status){
                this.selected_bollettino = null
                return
            }

            console.log(this.selected_bollettino)
            
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await BollettinoRepository.deleteApprovato(this.selected_bollettino.id).catch(()=>{})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.search.page = 1
                this.get_bollettini()
            }

             this.$store.dispatch('updateLoadingState', false);
        }
    },
    mounted(){
        this.get_bollettini()
    }
}
</script>
<style>
    span.label {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }
    span.label-danger {
        background-color: #d26a5c;
    }
    span.label-success {
        background-color: #60d25c;
    }
    .row_over{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap:10px;
    }
</style>