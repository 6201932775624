<template>
<div>
<!-- Hero -->
    <div class="bg-image overflow-hidden" :style="{'background-image': 'url('+background_image+')'}">
        <div class="bg-primary-dark-op">
            <div class="content content-narrow content-full">
                <div class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center mt-5 mb-2 text-center text-sm-left">
                    <div class="flex-sm-fill">
                        <h1 class="font-w600 text-white mb-0" data-toggle="appear">ARA1857 - Campagna {{currentYear}}</h1>
                        <h2 class="h4 font-w400 text-white-75 mb-0" data-toggle="appear" data-timeout="250">Bentornato!</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="content bg-white border-b">

    <div v-if="role == 'ispettore'" class="row items-push text-uppercase">
        <div class="col-xs-6 col-sm-4">
            <div class="font-w700 text-gray-darker animated fadeIn">Specifiche Inserite</div>
            <router-link class="h2 font-w300 text-primary animated flipInX " to="/specifica/archivio">
                {{data.totale_specifiche}}
            </router-link>
        </div>
        <div class="col-xs-6 col-sm-4">
            <div class="font-w700 text-gray-darker animated fadeIn">Specifiche da liquidare</div>
            <p class="h2 font-w300 text-primary animated flipInX">{{data.totale_da_fatturare}}</p>
        </div>
       <div class="col-xs-6 col-sm-4">
            <div class="font-w700 text-gray-darker animated fadeIn">Specifiche da approvare</div>
            <router-link class="h2 font-w300 text-primary animated flipInX " to="/specifica/da-approvare">
                {{data.tot_spec_da_approvare}}
            </router-link>
        </div>
    </div>
   
    <div v-if="role == 'direzione'" class="row items-push text-uppercase">
        <div class="col-xs-6 col-sm-4">
            <div class="font-w700 text-gray-darker animated fadeIn">Residuo da liquidare</div>
            <p class="h2 font-w300 text-primary animated flipInX">{{data}}</p>
        </div>
    </div>

    </div>
</div>
<!-- END Hero -->
</template>

<script>
import SpecificaRepository from "../services/apis/specifica"
import FatturaRepository from "../services/apis/fattura"
export default {
    data(){
        return {
            background_image: require('@/assets/images/photo3@2x.jpg'),
            role:this.$store.getters.user.role,
            data: {
                totale_specifiche:0,
                totale_da_fatturare:0,
                tot_spec_da_approvare:0
            },
            currentYear:null
        }
    },
    methods: {
        async get_dashboard(){
            const _resp = await SpecificaRepository.get_dashboard_data().catch(() => {})
            if(_resp){
                this.data = _resp.data.data
            }
        },
        async get_calcolo_residuo(){
            this.data = ''
            const _resp = await FatturaRepository.calcolo_residuo().catch(() => {})
            if(_resp){
                this.data = _resp.data.data
            }
        }
    },
    mounted(){
        this.currentYear = process.env.VUE_APP_YEAR

        if(this.role == 'ispettore'){
            this.get_dashboard()
        }
        if(this.role == 'direzione'){
            this.get_calcolo_residuo()
        }
        
    }
}
</script>
