<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>DATA RACCOLTA</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <ValidationObserver ref="form">
                <form>
                    <!-- <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <select @change="changeSelect" v-model="select" class="form-control" name="grade">
                                    <option value="">Seleziona</option>
                                    <option value="INSERISCI">INSERISCI DATA</option>
                                    <option value="FUORI RISCHIO">FUORI RISCHIO</option>
                                    <option value="IMM.TE">IMM.TE</option>
                                    <option value="IN CORSO">IN CORSO</option>
                                </select>
                            </div>
                        </div>
                    </div> -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                
                                <div class="form-group">
                                    <div class="form-check form-check-inline">
                                        <input @change="changeSelect" v-model="select" value="INSERISCI" class="form-check-input" type="radio" id="example-radios-inline1" name="example-radios-inline">
                                        <label class="form-check-label" for="example-radios-inline1">INSERISCI DATA</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input @change="changeSelect" v-model="select" class="form-check-input" type="radio" id="example-radios-inline2" name="example-radios-inline" value="FUORI RISCHIO">
                                        <label class="form-check-label" for="example-radios-inline2">FUORI RISCHIO</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input @change="changeSelect" v-model="select" class="form-check-input" type="radio" id="example-radios-inline3" name="example-radios-inline" value="IMM.TE">
                                        <label class="form-check-label" for="example-radios-inline3">IMM.TE</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input @change="changeSelect" v-model="select" class="form-check-input" type="radio" id="example-radios-inline4" name="example-radios-inline" value="IN CORSO">
                                        <label class="form-check-label" for="example-radios-inline4">IN CORSO</label>
                                    </div>
                                </div>
                                <input :disabled="select !== 'INSERISCI'" v-model="month_value" type="text" id="decadi" placeholder="Seleziona decade">
                                <div id="grid-deca">
                                    <div id="grid-decadi">
                                        <div v-for="el in 36" :key="el" :class="[month(el) == month_value ? 'cell-selected' : '', 'cell']">{{month(el)}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ValidationObserver>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                :disabled="!can_save"
                @click="set_data">
                <span>Salva Modifiche</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../../Modal"
// import DatePicker from "../../DatePicker"
export default {
    name:'BollettinoModalDataRaccolta',
    props:['isModalVisible'],
    components: {
        Modal
    },
    data(){
        return {
            row_position:{
                x:null,
                y:null
            },
            select:"",
            value:"",
            month_value:"",
            can_save: false,
            months:{
                "gen":[0,1,2],
                "feb":[3,4,5],
                "mar":[6,7,8], 
                "apr":[9,10,11],
                "mag":[12,13,14],
                "giu":[15,16,17],
                "lug":[18,19,20],
                "ago":[21,22,23],
                "set":[24,25,26],
                "ott":[27,28,29], 
                "nov":[30,31,32],
                "dic":[33,34,35]
            }
        }
    },
    watch: {
        'month_value' (){
            this.changeInput()
        }
    },
    methods: {
        month(index){

            for (const key in this.months) {
                if (this.months.hasOwnProperty(key)) {                    
                    const find = this.months[key].find(el => {
                        return el == index-1
                    } )

                    if(find || find == 0){
                        return this.months[key].indexOf(index-1)+1 +'° '+ key
                    }
                }
            }
        },
        disableGrid(){
            setTimeout(() => {
                window.$('#grid-decadi .cell').each(function(){
                    window.$(this).addClass('cell-disabled')
                    window.$(this).unbind( "click" );
                });
            }, 150);
        },
        enableGrid(){
            const _this = this
            window.$('#grid-decadi .cell').each(function(){
                window.$(this).removeClass('cell-disabled')
                window.$('#grid-decadi .cell').on('click', function(){
                    _this.month_value = window.$(this).html()
                })
            });
        },
        get_data(value, x, y){
            
            if(value){
                if(value !== "FUORI RISCHIO" && value !== "IMM.TE" && value !== "IN CORSO"){
                    this.select = "INSERISCI"
                    this.month_value = value
                    this.initJqueryFunc()
                }else{
                    this.select = this.value
                    this.value = value
                    this.disableGrid()
                }
            }else{
                this.disableGrid()
            }

            this.row_position = {
                x:x, y:y
            }
        },
        initJqueryFunc(){
            this.$nextTick(function () {
                const _this = this

                setTimeout(() => {
                    window.$('#grid-decadi .cell').on('click', function(){
                        _this.month_value = window.$(this).html()
                    })
                }, 1000);
            })
        },
        changeInput(){
            if(this.month_value !== ""){
                this.can_save = true
                return
            }
            this.can_save = false
        },
        changeSelect(){
            if(this.select =="INSERISCI" ){
                this.enableGrid()
                this.value = ""
                this.month_value = ""
                this.can_save = false
                return
            }
            
            this.disableGrid()
            this.month_value = ""
            this.value = this.select
            this.can_save = true
        },
        reset(){
            this.select = ""
            this.value = ""
            this.month_value = ""
            this.can_save = false
            this.row_position = {
                x:null,
                y:null
            }
        },
        checkDecadiValue(value){
            const val_split = value.split(" ")

            if(val_split.length !== 2){
                this.$toasted.error('Errore nella formattazione del campo').goAway(3000)
                return false
            }

            const first_value = val_split[0].replace('°','');
            const found_first_value = [1,2,3].find(el => el == parseInt(first_value))
            if(!found_first_value){
                this.$toasted.error('Errore nella formattazione del campo').goAway(3000)
                return false
            }

            if(!(val_split[1] in this.months)){
                this.$toasted.error('Errore nella formattazione del campo').goAway(3000)
                return false
            }

            return true
        },
        set_data(){
            if(this.select == "INSERISCI"){
                if(!this.checkDecadiValue(this.month_value)){
                    return
                }
                this.value = this.month_value
            }

            this.$emit('closeModalCallback', 'data_raccolta', this.value, this.row_position)
            this.reset()
        },
        closeModal() {
            this.$emit('closeModalCallback', null)
            this.reset()
        },
    }
}
</script>

<style lang="css" scoped>
    #grid-deca {
        max-width: 400px;
        border: 1px solid;
        font-size: 13px;
        margin-top: 18px;
    }
    .cell {
        padding: 5px 8px;
        border: 1px solid;
        cursor: pointer;
    }
    .cell-disabled {
        background-color:lightgray;
    }
    .cell:not(.cell-disabled):hover, .cell-selected {
        background: #46c37b;
        color: white;
        font-weight: bold;
    }
    #grid-decadi {
        margin: 0rem;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
</style>