import moment from 'moment'
import store from '../store/index'

export default({
    normalize_object_value(obj){
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const element = obj[key];
                if(element === "" || element === null){
                    obj[key] = undefined
                }else if(!isNaN(element)){
                    obj[key] = parseFloat(element)
                }
            }
        }

        return obj
    },
     
    // Used to append all parameters in GET url taking an obj as input parameter
    appendParameters(search) {
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                if(element !== null && element !== undefined && element !== '')
                    _search.push(`${key}=${encodeURIComponent(element)}`)
            }
        }
        _search = _search.join('&')
        return _search;
    },

    convertDataToDBFormat(string){
        if(!string){ return string}
        return moment(string, "DD-MM-YYYY").format("YYYY-MM-DD")
    },

    convertDataToEuropeanFormat(string){

        if (!string) { return string; }
        
        // Proviamo a dedurre il formato dalla stringa
        let momentDate;

        if (string.match(/^\d{4}-\d{2}-\d{2}$/)) {
            // Il formato è YYYY-MM-DD
            momentDate = moment(string, "YYYY-MM-DD");
        } else if (string.match(/^\d{2}-\d{2}-\d{4}$/)) {
            // Il formato è DD-MM-YYYY
            momentDate = moment(string, "DD-MM-YYYY");
        } else if (string.match(/^\d{2}\/\d{2}\/\d{4}$/)) {
            // Il formato è DD/MM/YYYY
            momentDate = moment(string, "DD/MM/YYYY");
        } else {
            // Se non riconosciamo il formato, usiamo moment per analizzarlo automaticamente (meno affidabile)
            momentDate = moment(string);
        }
        // Se la data è valida, la formattiamo in DD-MM-YYYY
        if (momentDate.isValid()) {
            return momentDate.format("DD-MM-YYYY");
        } else {
            console.error("Formato data non valido:", string);
            return string; // Ritorna la stringa originale se non è valida
        }
    },
    
    convertDataToDB(string){
        if(!string){ return string}
        return moment(string).format("YYYY-MM-DD")
    },
    
    trimText(text, n = 100, placeholder = ''){
        if(!text){
            return ''
        }
        return (text.length > n) ? text.substr(0, n-1) + placeholder : text;
    },
    getIspettorePeritoById(id){
        const found = store.getters.ispettori.find(ispettore => ispettore.id == id)        
        if(found){
            return found.permission
        }
        return store.getters.base_permission
    },
    canPerito(permissions, permission){
        const _permissions = permissions.split('|')
        if(_permissions.find(_perm => _perm == permission)){
            return true
        }
        if(permission == 'owner'){
            return true
        }
        return false
    },
    Utf8ArrayToStr(array) {
        var out, i, len, c;
        var char2, char3;
    
        out = "";
        len = array.length;
        i = 0;
        while(i < len) {
        c = array[i++];
        switch(c >> 4)
            {
            case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
                // 0xxxxxxx
                out += String.fromCharCode(c);
                break;
            case 12: case 13:
                // 110x xxxx   10xx xxxx
                char2 = array[i++];
                out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
                break;
            case 14:
                // 1110 xxxx  10xx xxxx  10xx xxxx
                char2 = array[i++];
                char3 = array[i++];
                out += String.fromCharCode(((c & 0x0F) << 12) |
                            ((char2 & 0x3F) << 6) |
                            ((char3 & 0x3F) << 0));
                break;
            }
        }
    
        return out;
    },
    dateRangeLocale(){
        return {
            customRangeLabel: 'Seleziona Range di ricerca',
            daysOfWeek: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
            monthNames: ['Gen', 'Feb', 'Mar', 'Apr', 'Mag', 'Giu', 'Lug', 'Ago', 'Set', 'Ott', 'Nov', 'Dic'],
            firstDay: 1, 
            format: 'DD-MM-YYYY'
        }
    }
})