<template>
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span >SELEZIONA DENUNCE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal(null)"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
             <table v-if="partite_selezionate.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                <tbody>
                    <div v-for="(partita, index) in partite_selezionate" :key="index">
                        <tr>
                            <th width="20%" class="text-center">SELEZIONA TUTTI</th>
                            <th width="20%" class="d-none d-sm-table-cell">NR.</th>
                            <th width="20%" class="d-none d-sm-table-cell">VARIETÀ</th>
                            <th width="20%" class="d-none d-sm-table-cell">IMPORTO ASSICURATO</th>
                            <th width="20%" class="d-none d-sm-table-cell">FG. CAT.</th>
                        </tr>
                        <tr>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <input  @change="changePartitaSelezionata(partita)" type="checkbox" v-model="partita.selezionata_denunce">
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.numero_partita}}    
                            </td>
                             <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.varieta.etichetta}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{ partita.importo_assicurato | toCurrency}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                {{partita.foglio_catastale}}
                            </td>
                        </tr>
                        <tbody v-if="partita.denunce.length > 0">
                            <tr width="100%">
                                <th width="25%" class="text-center">SELEZIONATA</th>
                                <th width="25%" class="d-none d-sm-table-cell">DATA</th>
                                <th width="25%" class="d-none d-sm-table-cell">EVENTO</th>
                            </tr>
                            <tr width="100%" v-for="(denuncia, i) in partita.denunce" :key="i">
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <input v-if="!denuncia.hide" @change="changeSubPartitaSelezionata(denuncia, partita)" type="checkbox" v-model="denuncia.selezionata">
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{denuncia.data | toEuropeData}}  
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{denuncia.evento.nome}}    
                                </td>
                            </tr>
                        </tbody>
                    </div>
                    
                </tbody>
            </table>
            <div v-if="paginate.page_count > 1" class="col-sm-12 col-md-12">
                <paginate
                    v-model="paginate.page"
                    :page-count="paginate.page_count"
                    :click-handler="paginateClick"
                    :prev-text="'Denuncia Precedente'"
                    :next-text="'Denuncia Successiva'"
                    :container-class="'pagination'"
                    :page-class="'page-item'"
                    :prev-class="'prev'"
                    :next-class="'next'">
                </paginate>
            </div>
        </template>
        <template v-slot:footer>
            <!-- <button
                type="button"
                class="btn btn-light"
                @click="closeModal('indietro')"
                aria-label="Close modal">
                Indietro
            </button> -->
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal(null)"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                @click="continua"
                aria-label="Nuova">
                <span>Continua</span>
            </button>
        </template>
        
    </Modal>
</template>

<script>
import Modal from "../Modal"
import Paginate from 'vuejs-paginate'

export default {
    name: 'ModalSelectPartiteDenunce',
    props: ['isModalVisible'],
    components: {
        Modal, Paginate
    },
    data(){
        return {
            partite_selezionate:[],
            orig:[],
            paginate: {
                page_count:1,
                page:1,
                per_page:1,
                original_data:[]
            },
        }
    },
    methods: {
        closeModal(action) {
            this.$emit('closeModalCallback', action)
        },
        setPartiteSelezionate(partite){
            
            this.orig = this._.cloneDeep(partite, true)

            let _partite = partite.filter((el) => {
                if(el.selezionata && !el.partita_has_all_denunce_bollettino){

                    let denunce_found = [];
                    el.selezionata_denunce = true

                    if(el.sub_partite.length == 0){
                        el.denunce = el.denunce.filter((denuncia) => {
                            if(el.denunce_ids_no_bollettino.find(id => id == denuncia.id)){
                                denuncia.selezionata = true
                                return denuncia
                            }
                        })

                        return el
                    }

                    el.sub_partite.forEach((sub_partita) => {
                        
                        if(sub_partita.selezionata){
                            sub_partita.denunce_ids_no_bollettino.forEach(id => {
                                denunce_found.push(id)
                            });
                        }
                    })

                    if(denunce_found.length  > 0){
                        el.denunce = el.denunce.filter((denuncia) => {
                            if(denunce_found.find(id => id == denuncia.id)){
                                denuncia.selezionata = true
                                return denuncia
                            }
                        })

                        return el
                    }
                }
            })

            this.paginate.original_data = _partite
            this.paginate.page_count = Math.ceil(this.paginate.original_data.length / 1);
            this.fakePagination()
        },
        fakePagination(){
            const {per_page, original_data, page} = this.paginate
            this.partite_selezionate = original_data.slice((page - 1) * per_page, page * per_page) 
        },
        paginateClick(pageNum){
            this.paginate.page = pageNum
            this.fakePagination()
        },
        changePartitaSelezionata(partita){
            this.paginate.original_data = this.paginate.original_data.map((el => {
                if(el.id == partita.id){
                    el.selezionata_denunce = partita.selezionata_denunce
                    el.el_den = el.denunce.map((el_den) => {
                        el_den.selezionata = partita.selezionata_denunce
                        return el_den
                    })
                }
                return el
            }))
            this.fakePagination()
        },
        changeSubPartitaSelezionata(denuncia, partita){

            let false_found = false

            this.paginate.original_data = this.paginate.original_data.map((el => {
                if(el.id == partita.id){
                    el.denunce = el.denunce.map((el_den) => {
                        if(el_den.id == denuncia.id){
                            el_den.selezionata = denuncia.selezionata
                        }
                        if(!el_den.selezionata){
                            false_found = true
                        }
                        return el_den
                    })
                }
                return el
            }))

            this.paginate.original_data = this.paginate.original_data.map((el) => {
                if(el.id == partita.id){
                    el.selezionata_denunce = !false_found
                }
                return el
            })
            

            this.fakePagination()
        },
        continua(){
            const partite_no_denunce = this.paginate.original_data.filter((partita) => {
                if(partita.selezionata && partita.denunce.length > 0){
                    const found = partita.denunce.filter(denuncia => !denuncia.selezionata)
                    if(found.length === partita.denunce.length){
                        return partita
                    }
                }
            })

            if(partite_no_denunce.length > 0){
                this.$toasted.error("Ci deve essere almeno una denuncia selezionata per ogni partita").goAway(4000)
                return
            }

             const merge_data = this.orig.map((el) => {
                const found = this.paginate.original_data.find((el1) => {
                    return el1.id === el.id
                })
                
                if(found){
                    el.selezionata_denunce = found.selezionata_denunce
                    el.denunce = el.denunce.map((el_denuncia) => {
                        const den_found = found.denunce.find(el_denuncia_1 => el_denuncia.id === el_denuncia_1.id)

                        if(den_found){
                            el_denuncia.selezionata = den_found.selezionata
                        }
                        return el_denuncia
                    })
                }

                return el
            })
            
            this.$emit('closeModalCallback', merge_data)
            // this.$emit('closeModalCallback', this.paginate.original_data)
        }
    }    
}
</script>