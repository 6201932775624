<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>SELEZIONA CODICI OSSERVAZIONI RISERVE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div class="row" style="height:200px; overflow:hidden;">
                <div class="col-md-12">
                    <div class="form-group">
                        <multi-select
                            id="search-select"
                            :options="lista_note"
                            :selected-options="cause_selected"
                            placeholder="Seleziona"
                            @select="onSelect"
                            >
                        </multi-select>
                        <!-- <select v-model="cause_selected" class="form-control" name="grade">
                            <option value="">Seleziona</option>
                            <option v-for="(cause, index) in causes" :key="index" :value="cause">{{cause}}</option>
                        </select> -->
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                @click="set_data">
                <span>Salva Modifiche</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../../Modal"
import { MultiSelect } from 'vue-search-select'
export default {
    name:'BollettinoModalSelectOsservazioni',
    props:['isModalVisible', 'lista_note'],
    components: {
        Modal, MultiSelect
    },
    data(){
        return {
            // causes: ["MANCATO PRODOTTO","CASCOLA","RACCOLTO PARZIALE","ATTACCO PARASSITI","SICCITA'",
            //     "ALLUVIONE","RACCOLTO","ESTIRPAZIONE","FUORI RISCHIO","CADUTO FISIOLOGICO","PRODOTTO SOTTO RETE",
            //     "NUOVO IMPIANTO","REVISIONE D'UFFICIO", "SOVRA INNESTO","DANNO IRRISARCIBILE","GELO BRINA"
            // ],
            causes:  [
                { value: "MANCATO PRODOTTO", text: "MANCATO PRODOTTO" },
                { value: "CASCOLA", text: "CASCOLA" },
                { value: "RACCOLTO PARZIALE", text: "RACCOLTO PARZIALE" }
            ],
            row_position:{
                x:null,
                y:null
            },
            cause_selected:[]
        }
    },
    methods: {
        get_data(cause, x, y){
            if(cause){
                const cause_array = cause.split('|')
                this.cause_selected = cause_array.map((el) => {
                    return this.lista_note.find((el1) => el1.value === el)
                })
            }
            this.row_position = {
                x:x, y:y
            }
        },
        onSelect(items){
            this.cause_selected = items
        },
        reset(){
            this.cause_selected = []
            this.row_position = {
                x:null,
                y:null
            }
        },
        set_data(){
            const select_array_string = this.cause_selected.map((el) => el.value)            
            const select_string = select_array_string.join('|')

            this.$emit('closeModalCallback', 'select_osservazioni', select_string, this.row_position)
            this.reset()
        },
        closeModal() {
            this.reset()
            this.$emit('closeModalCallback', null)
        },
    }
}
</script>