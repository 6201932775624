<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">AGENZIA</label>
                    <input disabled :value="certificato.agenzia" type="text" class="form-control" name="agenzia">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">CERT./POL.N.</label>
                    <input disabled :value="certificato.certificato + '/' + (edizione || '')" type="text" class="form-control" name="certificato">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">DATA NOTIFICA</label>
                    <input disabled :value="certificato.data_notifica" type="text" class="form-control" name="data_notifica">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">CONTRAENTE</label>
                    <input disabled :value="certificato.contraente" type="text" class="form-control" name="contraente">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">ASSICURATO</label>
                    <input disabled :value="certificato.denominazione" type="text" class="form-control" name="assicurato">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">SELEZIONA PERITI</label>
                    <MultiselectCMOON
                        :lists="periti"
                        :selected_lists="periti_selected"
                        :placeholder="'Seleziona Periti'"
                        @searchChange="search_utenti"
                        @selectedTag="onSelect"
                        @removeTag="onRemove" 
                    >
                    </MultiselectCMOON>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="example-select">DENUNCIA NUMERO / DATA EVENTO / EVENTO DENUNCIATO</label>
                    <input disabled :value="certificato.denunce_order" type="text" class="form-control" name="contraente">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">DATA PERIZIA</label>
                    <input type="text" class="form-control" disabled v-model="certificato.data_perizia" />
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">SPECIE</label>
                    <input disabled :value="certificato.specie" type="text" class="form-control" name="specie">
                </div>
            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="example-select">COMUNE</label>
                    <input type="text" disabled :value="certificato.comune" class="form-control" name="comune">
                </div>
                <div class="form-group flexed">
                    <div>
                        <input  type="checkbox" value="scomp_danno" @click="scomposizioneDanno"  v-model="scomposizione_danno" class="input-scomposizione"  name="scomposizione_danno"/>
                        <span class="label_scompo-danno">Voglio modificare manualmente la scomposizione del danno </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import  MultiselectCMOON  from '../MultiselectCMOON'
import UtilityRepository from "../../services/apis/utility"

export default {
    name:'BollettinoData',
    props:['certificato', 'edizione','readOnly','stored_scomposizione_danno'],
    components: {
     MultiselectCMOON
    },
    data(){
        return {
            periti:[],
            periti_selected:[],
            scomposizione_danno:false
        }
    },
    methods: {
        async search_utenti(text){                                    
            const _res = await UtilityRepository.search_utenti({cerca:text}).catch(() => {})

            if(_res){
                this.periti = _res.data.data.map((item) => {
                    return { id: item.id, name: `${item.codice_perito} - ${item.surname.toUpperCase()}` }
                });                
            }
        },
        onSelect (item) {
            this.periti_selected.push(item)
            this.$emit('callBackSelectPeriti', this.periti_selected)
        },
        scomposizioneDanno(){
            this.$emit('callBackScomposizioneDanno', !this.scomposizione_danno)
        },
        onRemove(item){
            this.periti_selected = this.periti_selected.filter(el => el.id != item.id)
            this.$emit('callBackSelectPeriti', this.periti_selected)
        },
        set_data(periti){
            this.periti_selected = periti.map(p => { return {id:p.value, name: p.text} })            
        },
        get_data(){
            return this.periti_selected
        }
    },
    beforeMount() {
        setTimeout(() => {
            console.log(this.stored_scomposizione_danno)
            this.scomposizione_danno = this.stored_scomposizione_danno
        }, 2000);
    },
}
</script>

<style>
.input-scomposizione{
    /* rimuovo il bordo quando viene cliccato l'input */
    outline: none;
    width: 20px;
      /* Double-sized Checkboxes */
    -ms-transform: scale(2); /* IE */
    -moz-transform: scale(2); /* FF */
    -webkit-transform: scale(2); /* Safari and Chrome */
    -o-transform: scale(2); /* Opera */
    transform: scale(2);
    padding: 10px;
    margin: auto;
    margin-right: 5px;

}
.label_scompo-danno {
    margin-left: 8px;
}

</style>