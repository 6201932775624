<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>Seleziona Filtri</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div style="height:300px;">
                <div class="col-12">
                    <div class="form-material">
                        <label for="example-text-input">Seleziona Comuni</label>
                        <multi-select
                            :options="comuni.found"
                            option-value="id"
                            :selected-options="filter_comuni"
                            @searchchange="searchComuni"
                            @select="addComune"
                        >
                        </multi-select>
                    </div>
                </div>
                <div class="col-12">
                    <div class="form-material">
                        <label for="example-text-input">Seleziona Ordini</label>
                        <multi-select
                            :options="certificati.found"
                            option-value="id"
                            :selected-options="filter_certificati"
                            @searchchange="searchCertificati"
                            @select="addCertificato"
                        >
                        </multi-select>
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                @click="updatePerito"
                type="button"
                class="btn btn-primary"
                aria-label="Salva">
                <span>Salva</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import { MultiSelect } from 'vue-search-select'
import 'vue-search-select/dist/VueSearchSelect.css'
import Modal from "../Modal"
import UtilityRepository from "../../services/apis/utility"
import CertificatoRepository from "../../services/apis/certificato"

export default {
    name:'ModalFiltroPerito',
    props:['isModalVisible', 'eventi', 'ispettore_id'],
    components: {
        Modal, MultiSelect
    },
    search: {
        comune_id: null,
        ordine_id: null,
    },
    data(){
        return {
            filter_certificati:[],
            filter_comuni:[],
            certificati:{
                list:[],
                found:[]
            },
            comuni:{
                list:[],
                found:[]
            },
        }
    },
    methods: {
        updatePerito(){
            const ordini = this.filter_certificati.map(certificato => certificato.id)
            const comuni = this.filter_comuni.map(comune => comune.id)

            this.$emit('updatePeritoCallback', {ordini: ordini, comuni: comuni})
        },
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        addComune(values) {
            this.filter_comuni = values;
        },
        getData(perito){
            const _perito = this._.cloneDeep(perito, true)
            this.filter_comuni = _perito.comuni.map(comune =>   {
               return {value:comune.id, text:comune.nome}
            })
            this.filter_certificati = _perito.ordini.map(ordine => {
                return {value:ordine.id, text:ordine.ordine_id}
            })
        },
        addCertificato(values) {
            this.filter_certificati = values;
        },
        async searchComuni(text){
            if(text != null && text != undefined && text != '') {
                var search = {
                    ispettore_id: this.ispettore_id,
                    cerca: text
                }

                const _resp = await UtilityRepository.search_comuni(search).catch(()=>{})

                if(_resp)
                    this.comuni.found = _resp.data.data.map((el) => {
                        el.value = el.id;
                        el.text = `${el.nome}`
                        return el;
                    });
                
            }
        },
        async searchCertificati(text){

            if(text != null && text != undefined && text != '') {
                var search = {
                    ispettore_id: this.ispettore_id,
                    per_page: 999999,
                    page: 1,
                    numero_ordine: text
                }

                const _resp = await CertificatoRepository.get_lista_certificati(search).catch(()=>{})
                
                if(_resp)
                    this.certificati.found = _resp.data.data.data.map((el) => {
                        el.value = el.id;
                        el.text = `${el.ordine_id}`
                        return el;
                    });
                
            }
        },
    },
    async mounted(){
        // if(this.last_comuni !== null && this.last_comuni !== undefined)
        //     this.comuni.found = this.last_comuni;
        // if(this.last_ordini !== null && this.last_ordini !== undefined)
        //     this.certificati.found = this.last_ordini;
    }
}
</script>