<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Registro Fatture <small>Visualizzazione delle fatture emesse ed invio alla contabilità.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <div class="row">
                            <div class="col-sm-12 col-md-12" style="padding-left: 0px;">
                                <label style="margin-bottom:10px;" class="form-material">Filtro per data</label>
                                <date-range-picker
                                    style="width: 100%;"
                                    ref="picker"
                                    :locale-data="localeRange"
                                    :autoApply="true"
                                    :ranges="false"
                                    v-model="dateRange"
                                    @select="dayClicked"
                                >
                                    <template v-slot:input="picker" style="width:100%;">
                                        <span v-if="picker.startDate">{{ picker.startDate | toEuropeData }} - {{ picker.endDate | toEuropeData }}</span>
                                        <span v-else>Seleziona Range di ricerca</span>
                                    </template>
                                </date-range-picker>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12">
                                <div class="row">
                                    <div class="form-material" style="text-align: right; margin-top: 40px;">
                                        <button class="btn btn-sm btn-primary" type="submit" @click="resetAll">Reset Filtro</button>
                                    </div>
                                    <div v-if="!contabilita" class="form-material" style="text-align: right; margin-top: 40px;  margin-left: 20px;">
                                        <button class="btn btn-sm btn-primary" type="button" @click="toggleContabilita">Filtro Contabilità</button>
                                    </div>
                                    <div v-if="contabilita && fatture.length > 0 && dateRange.startDate" class="form-material" style="text-align: right; margin-top: 40px;  margin-left: 20px;">
                                        <button class="btn btn-sm btn-primary" type="button" @click="printReport()">Stampa Report</button>
                                    </div>
                                    <div v-if="contabilita" class="form-material" style="text-align: right; margin-top: 40px;  margin-left: 20px;">
                                        <button class="btn btn-sm btn-primary" type="button" @click="toggleContabilita">Chiudi</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="fatture.length == 0">Nessuna Fattura</h3>
                    <div v-if="fatture.length > 0" class="row" style="margin-bottom:10px;">
                        <div style="margin-top:30px;" class="col-sm-12 col-md-12">
                            <div class="form-group">
                                <div v-if="!contabilita" class="custom-control custom-checkbox mb-1">
                                    <input @change="selezionaTutto" v-model="seleziona_tutto" type="checkbox" class="custom-control-input" id="seleziona_tutto" name="seleziona_tutto">
                                    <label class="custom-control-label" for="seleziona_tutto">Seleziona tutte le Fatture</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <table v-if="fatture.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                        <thead>
                            <tr>
                                <th v-if="!contabilita" class="d-none d-sm-table-cell">#</th>
                                <th @click="orderColumn('codice_perito')" class="d-none d-sm-table-cell order">COD. PERITO <span v-html="sortIcon('codice_perito')"></span></th>
                                <th @click="orderColumn('surname')" class="d-none d-sm-table-cell order">PERITO <span v-html="sortIcon('surname')"></span></th>
                                <th @click="orderColumn('grado_perito')" class="d-none d-sm-table-cell order">GRADO <span v-html="sortIcon('grado_perito')"></span></th>
                                <th @click="orderColumn('gg')" class="d-none d-sm-table-cell order">N. GG <span v-html="sortIcon('gg')"></span></th>
                                <th @click="orderColumn('n_fattura')" class="d-none d-sm-table-cell order">N. FATT. <span v-html="sortIcon('n_fattura')"></span></th>
                                <th @click="orderColumn('data_fattura')" class="text-center order">DATA <span v-html="sortIcon('data_fattura')"></span></th>
                                <th @click="orderColumn('totale_fattura')" class="d-none d-sm-table-cell order">TOT. FATTURA <span v-html="sortIcon('totale_fattura')"></span></th>
                                <th @click="orderColumn('imp_netto')" class="d-none d-sm-table-cell order">IMP. NETTO A PAGARE <span v-html="sortIcon('imp_netto')"></span></th>
                                <th class="d-none d-sm-table-cell">LINK PDF</th>
                                <th class="d-none d-sm-table-cell">CONTABILITA'</th>
                                <th v-if="!contabilita && !isSelect" class="d-none d-sm-table-cell">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(f, index) in fatture" :key="index">
                                <td v-if="!contabilita" class="font-size-sm">
                                    <input v-if="!f.data_contabilita"  @change="changeFatturaSelezionata(f)" type="checkbox" v-model="f.selezionata">
                                </td>
                                <td class="font-size-sm">
                                    {{f.perito.codice_perito}}
                                </td>
                                <td class="font-size-sm">
                                    {{f.perito.surname}} {{f.perito.name}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{filterGradoFattura(f)}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{f.gg}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{f.n_fattura}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{f.data_fattura | toEuropeData}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    € {{f.totale_fattura}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    € {{f.imp_netto}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="showPdf(f.link_fattura)" type="button" class="btn btn-sm btn-light" title="Show PDF">
                                            <i class="fas fa-file-pdf"></i>
                                        </button>
                                    </div>
                                </td>
                                <td v-if="f.data_contabilita !== null && f.data_contabilita !== undefined && f.data_contabilita !== ''" class="font-size-sm">
                                    {{f.data_contabilita | toEuropeData}}
                                </td>
                                <td v-else class="d-none d-sm-table-cell font-size-sm">
                                    <span class="label label-danger ng-scope">da trasferire</span>
                                </td>
                                <td v-if="!f.data_contabilita && !contabilita && !isSelect" class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="editModal(f)" type="button" class="btn btn-sm btn-light" title="Edit Certificato">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <h6 v-if="fatture.length > 0">{{fatture.length}} Elementi visualizzati</h6>
                    <div v-if="!contabilita && isSelect" class="row">
                        <div class="col-sm-12 col-md-12">
                            <div class="row">
                                <div class="form-material" style="text-align: right; margin-top: 40px;  margin-left: 20px;">
                                    <button class="btn btn-sm btn-primary" type="button" @click="trasferisciSelezionati">Trasferisci Selezionati</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ModalDataDiTrasferimento
            :isModalVisible="isModalEditVisible" 
            @closeModalCallback="close_modal_callback" />
    </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import Utility from '../../../services/utility'
import Config from '../../../services/config'
import ModalDataDiTrasferimento from "../../../components/ModalDataDiTrasferimento"
import 'vue-search-select/dist/VueSearchSelect.css'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import UtilityRepository from "../../../services/apis/utility"
import FattureRepository from "../../../services/apis/fattura"

export default {
    components: {
        DateRangePicker, ModalDataDiTrasferimento
    },
    data(){
        return {
            dateRange:{},
            ispettore_id: 0,
            isModalInfoVisible: false,
            fatture: [],
            isTyping: false,
            certificato: null,
            periti:{
                list:[],
                found:[]
            },
            fattureSelected:[],
            ispettori:[],
            consorzi:[],
            selected_specifica:null,
            seleziona_tutto:false,
            isModalEditVisible:false,
            search: {
                h499: 0
            },
            order:{key:'data_fattura', order:'desc'},
            contabilita: false
        }
    },
    computed: {
        localeRange(){
            return Utility.dateRangeLocale()
        },
        prev(){
            return Config.lang.prev
        },
        next(){
            return Config.lang.next
        },
        isSelect(){
            return this.fatture.filter(f => f.selezionata).length > 0
        }
    },
    methods: {
        filterGradoFattura(fattura){
            if(this.$moment(fattura.data_fattura).isSameOrBefore(this.$moment('2022-04-30'))){                
                return fattura.perito.grades.find(g => g.pivot.anno == process.env.VUE_APP_LAST_YEAR).name
            }
            return fattura.perito.grades.find(g => g.pivot.anno == process.env.VUE_APP_YEAR).name
        },
        async get_fatture(){

            if(this.dateRange.startDate && this.dateRange.endDate){
                this.search.start_date = Utility.convertDataToDB(this.dateRange.startDate)
                this.search.end_date = Utility.convertDataToDB(this.dateRange.endDate)
            }else{
                delete this.search.start_date
                delete this.search.end_date
            }

            this.search.sorting = this.order.key
            this.search.sorting_order = this.order.order

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await FattureRepository.get_fattureH499(this.search).catch(()=>{})
            
            if(_resp){
                this.fatture = _resp.data.data
            }

            this.$store.dispatch('updateLoadingState', false);
        },
        async searchPerito(text){
            if(text != null && text != undefined && text != '') {
                var search = {
                    type: 'perito',
                    cerca: text
                }

                const _resp = await UtilityRepository.search_utenti(search).catch(()=>{})

                if(_resp)
                    this.periti.found = _resp.data.data;
            } 
        },
        toggleContabilita(){
            this.contabilita = !this.contabilita
        },
        dayClicked(data){
            this.dateRange = data
            this.get_fatture()
        },
        showPdf(link){
            window.open(`${process.env.VUE_APP_STORAGE_URL}/fatture/${link}`);
        },
        async printReport(){
            const obj = {
                fatture:this.fatture.map(fatt => {
                    return  {
                        'PERITO':`${fatt.perito.surname} ${fatt.perito.name}`,
                        'GRADO':this.filterGradoFattura(fatt),
                        'NUMERO FATTURA':fatt.perito.codice_perito,
                        'DATA':fatt.data_fattura,
                        'TOTALE FATTURA':parseFloat(fatt.totale_fattura),
                        'IMPORTO NETTO A PAGARE':parseFloat(fatt.imp_netto),
                    }
                }),
                start_date:Utility.convertDataToEuropeanFormat(this.search.start_date),
                end_date:Utility.convertDataToEuropeanFormat(this.search.end_date),
            }
            this.$store.dispatch('updateLoadingState', true);
            const _resp = await FattureRepository.stampa_registro_fatture(obj)

            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download ='registro_fatture.pdf' ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        editModal(fattura){
            this.fattureSelected.push(fattura.id)
            this.isModalEditVisible = true
        },
        async close_modal_callback(value){
            this.isModalEditVisible = false
            if(!value){
                this.fattureSelected = []
                return
            }

            const obj = {
                fatture_ids: this.fattureSelected,
                data:Utility.convertDataToDBFormat(value)
            }
            
            const _resp = await FattureRepository.update_data_contabilita(obj).catch(()=>{})
            if(_resp){
                this.$toasted.success(_resp.data.messsage).goAway(3000)
                this.fattureSelected = []
                this.get_fatture()
            }
            
        },
        resetAll() {
            this.search = {
                h499: 0
            }

            for (const key in this.dateRange) {
                if (this.dateRange.hasOwnProperty(key)) {
                    this.dateRange[key] = null;
                }
            }
            this.get_fatture()
        },
        selezionaTutto(){
            this.fatture = this.fatture.map((el) => {
                if(!el.data_contabilita){
                    el.selezionata = this.seleziona_tutto
                }
                return el
            })
        },
        changeFatturaSelezionata(fattura){
            this.fatture = this.fatture.map((el) => {
                if(fattura.id == el.id && !el.data_contabilita){
                    el.selezionata = fattura.selezionata
                }
                return el
            })
            
            var found = this.fatture.find((el) => {
                return !el.selezionata 
            })

            if(!found){
                this.seleziona_tutto = true
            }else{
                this.seleziona_tutto = false
            }
        },
        trasferisciSelezionati(){
            this.fattureSelected = this.fatture.filter(fatt => fatt.selezionata).map(fatt => fatt.id)
            this.isModalEditVisible = true
        },
        orderColumn(column){
            if(column == this.order.key){
                if(this.order.order == 'desc'){
                    this.order.order = 'asc'
                }else{
                    this.order.order = 'desc'
                }
            }else{
                this.order.key = column
                this.order.order = 'desc'
            }
            this.get_fatture()
        },
        sortIcon(column){
            if(column == this.order.key){
                if(this.order.order == 'desc'){
                    return ' <i class="fas fa-sort-down"></i>'
                }else{
                    return '<i class="fas fa-sort-up"></i>'
                }
            }
        }
    },
    mounted(){
        this.get_fatture()
    }
}
</script>
<style>
    span.label {
        display: inline;
        padding: .2em .6em .3em;
        font-size: 75%;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        text-align: center;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: .25em;
    }
    span.label-danger {
        background-color: #d26a5c;
    }
    span.label-success {
        background-color: #60d25c;
    }
</style>