<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Registro Presenze <small>Lista delle specifiche inserite per la campagna corrente.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header search_totals">
                    <div class="block-content">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <label style="margin-bottom:10px;" class="form-material">Filtro per data</label>
                                    <date-range-picker
                                        style="width: 100%;"
                                        ref="picker"
                                        :locale-data="localeRange"
                                        :autoApply="true"
                                        :ranges="false"
                                        v-model="dateRange"
                                        @select="dayClicked"
                                    >
                                        <template v-slot:input="picker" style="width:100%;">
                                            <span v-if="picker.startDate">{{ picker.startDate | toEuropeData }} - {{ picker.endDate | toEuropeData }}</span>
                                            <span v-else>Seleziona Range di ricerca</span>
                                        </template>
                                    </date-range-picker>
                                </div>
                            </div>
                            <div v-if="dateRange.startDate" style="margin-top: 20px;" class="row">
                                <div class="col-sm-12 col-md-12">
                                    <button @click="clearFilter()" type="button" class="btn btn-sm btn-primary" title="Edit Perizia">
                                        Reset Filtro
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
            <div class="block">
                <div class="block-content block-content-full">
                    <h3 v-if="specifiche.list.length == 0">Nessuna Specifica</h3>
                    <table v-if="specifiche.list.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                        <thead>
                            <tr>
                                <ThTable @updateOrder="orderBy" orderKey="data_specifica" name="DATA" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="codice_perito" name="COD. PERITO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="name_surname" name="NOME COGNOME" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="onorario" name="ONORARIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="vitto" name="VITTO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="alloggio" name="ALLOGGIO" :orderBy="search.orderBy" />
                                <ThTable @updateOrder="orderBy" orderKey="km_totali" name="KM TOTALI" :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">DETTAGLI</th>
                                <ThTable @updateOrder="orderBy" orderKey="fatturata" name="STATO FATT." :orderBy="search.orderBy" />
                                <th class="d-none d-sm-table-cell">FATTURE</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(specifica, index) in specifiche.list" :key="index">
                                <td class="font-size-sm">
                                    {{specifica.data_specifica | toEuropeData}}
                                </td>
                                <td class="font-size-sm">
                                    <span v-if="specifica.perito">{{specifica.perito.codice_perito}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="specifica.perito">{{specifica.perito.name}} {{specifica.perito.surname}}</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.onorario}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{specifica.vitto}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{specifica.alloggio}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{specifica.km_perizia+specifica.km_residenza}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button @click="showModalView(specifica)" type="button" class="btn btn-sm btn-light" data-toggle="tooltip" title="Edit Perizia">
                                            <i class="fa fa-fw fa-eye"></i>
                                        </button>
                                        <button @click="compareSpecifica(specifica)" type="button" :class="['btn btn-sm', specifica.selected ? 'btn-success':'btn-light']" data-toggle="tooltip" title="Compare Specifica">
                                            <i class="fas fa-arrows-alt-h"></i>
                                        </button>
                                    </div>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <span v-if="specifica.fatturata == 0" class="badge badge-danger">non fatturata</span>
                                    <span v-if="specifica.fatturata == 1" class="badge badge-success">fatturata</span>
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{specifica.fattura | fatturaAraDataFormat}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="dateRange.startDate && specifiche.list.length > 0" class="col-sm-12 col-md-12">
                        <button @click="printRegister()" type="button" class="btn btn-sm btn-primary" data-toggle="tooltip" title="Stampa Registro">
                            Stampa Registro
                        </button>
                    </div>
                    <div v-if="data.last_page > 1" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="prev"
                            :next-text="next"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>

                    <ComparaSpecifiche ref="compara_specifiche"></ComparaSpecifiche>
                    
                </div>
            </div>
        </div>

        <ModalSpecifica ref="modal_specifica" 
            :print="true"
            :ispettori_int="ispettori"
            :consorzi_int="consorzi"
            :agenzie_int="agenzie"
            :specifica="selected_specifica"
            :isModalVisible="isModalVisible" 
            @closeModalCallback="close_modal_callback" />
    </div>
</template>

<script>
import Paginate from 'vuejs-paginate'
import ThTable from '../../../components/ThTable'
import ModalSpecifica from '../../../components/Specifiche/ModalSpecifica'
import ComparaSpecifiche from "../../../components/Specifiche/ComparaSpecifiche"
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import AdminRepository from "../../../services/apis/admin"
import UtilityRepository from "../../../services/apis/utility"
import SpecificaRepository from "../../../services/apis/specifica"
import Utility from '../../../services/utility'
import Config from '../../../services/config'

export default {
    components: {
        ThTable,
        Paginate,
        ModalSpecifica,
        DateRangePicker,
        ComparaSpecifiche
    },
    data(){
        return {
            dateRange:{},
            ispettore_id: 0,
            isModalInfoVisible: false,
            data: {
                last_page:1
            },
            specifiche:{
                list:[]
            },
            search: {
                per_page: 10,
                page:1,
                orderBy:'data_specifica:desc'
            },
            periti:{
                list:[],
                found:[]
            },
            order:{key:'data_specifica', order:'desc'},
            ispettori:[],
            consorzi:[],
            agenzie:[],
            isModalVisible:false,
            selected_specifica:null
        }
    },
    computed: {
        localeRange(){
            return Utility.dateRangeLocale()
        },
        prev(){
            return Config.lang.prev
        },
        next(){
            return Config.lang.next
        }
    },
    methods: {
        async get_specifiche(){

            if(this.dateRange.startDate && this.dateRange.endDate){
                this.search.start_date = Utility.convertDataToDB(this.dateRange.startDate)
                this.search.end_date = Utility.convertDataToDB(this.dateRange.endDate)
            }else{
                delete this.search.start_date
                delete this.search.end_date
            }

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await AdminRepository.specifiche_paginating(this.search).catch(()=>{})

            if(_resp){
                this.specifiche.list = _resp.data.specifiche.data
                this.data = _resp.data.specifiche
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        perPage(){
            this.search.page = 1
            this.get_specifiche()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_specifiche()
        },
        showModalView(specifica){
            this.selected_specifica = specifica
            this.isModalVisible = true
        },
        close_modal_callback(){
            this.isModalVisible = false
            this.selected_specifica = null
        },
        close_info_cert_modal(){
            this.isModalInfoVisible = false
        },
        compareSpecifica(specifica){
            this.$refs.compara_specifiche.compara(specifica)
            this.$forceUpdate();
        },
        dayClicked(data){
            this.dateRange = data
            this.get_specifiche()
        },
        clearFilter(){
            for (const key in this.dateRange) {
                if (this.dateRange.hasOwnProperty(key)) {
                    this.dateRange[key] = null
                }
            }
            this.search.page = 1
            this.get_specifiche()
        },
        async get_lista_ispettori(){
            let _resp = await SpecificaRepository.get_lista_ispettori().catch(() => {})

            if(_resp){
                this.ispettori = _resp.data.data
            }
            
        },
        async get_consorzi(){
            let _resp = await UtilityRepository.search_consorzi('').catch(()=> {})
            
            if(_resp){
                this.consorzi = _resp.data.data
            }
        },
        async get_agenzie(){
            let _resp = await UtilityRepository.search_agenzie('').catch(()=> {})

            if(_resp){
                this.agenzie = _resp.data.data
            }
        },
        async printRegister(){
            const obj = {
                specifiche_id:this.specifiche.list.map(spec => spec.id),
                start_date:Utility.convertDataToEuropeanFormat(this.search.start_date),
                end_date:Utility.convertDataToEuropeanFormat(this.search.end_date)
            }

            this.$store.dispatch('updateLoadingState', true);
            const _resp = await SpecificaRepository.print_specifiche_presenze(obj)

            if(_resp){
                let byteArray = new Uint8Array(_resp.data);
                let a = window.document.createElement('a');

                a.href = window.URL.createObjectURL(
                    new Blob([byteArray], { type: 'application/octet-stream' })
                );

                a.download ='registro_presenze.pdf' ;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            }
            this.$store.dispatch('updateLoadingState', false);
        },
        orderBy(_orderBy){
            this.search.orderBy = _orderBy
            this.get_specifiche()
        },
        sortIcon(column){
            if(column == this.order.key){
                if(this.order.order == 'desc'){
                    return ' <i class="fas fa-sort-down"></i>'
                }else{
                    return '<i class="fas fa-sort-up"></i>'
                }
            }
        }
    },
    mounted(){
        this.get_lista_ispettori()
        this.get_consorzi()
        this.get_agenzie()
        const user = this.$store.getters.user
        this.ispettore_id = user.role == 'admin' ? user.id : 0
        this.get_specifiche()
    }
}
</script>