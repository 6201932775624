<template>
    <div>
    <!-- Page Content -->
        <div class="content bg-gray-lighter">
            <div class="row items-push">
                <div class="col-sm-8">
                    <h1 class="page-heading">
                        Aggiungi Utente <small>Form per aggiunta di nuovi utenti.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title">Compila tutti campi</h3>
                </div>
                <div class="block-content block-content-full">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-ltf-email">Nome</label>
                                    <ValidationProvider rules="required" name="nome" v-slot="{ errors }">
                                        <input v-model="new_user.name" type="text" class="form-control" name="nome" placeholder="Nome">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-ltf-email">Cognome</label>
                                    <ValidationProvider rules="required" name="cognome" v-slot="{ errors }">
                                        <input v-model="new_user.surname" type="text" class="form-control" name="cognome" placeholder="Cognome">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-ltf-email">Codice Perito</label>
                                    <ValidationProvider rules="required" name="codice_perito" v-slot="{ errors }">
                                        <input v-model="new_user.codice_perito" type="text" class="form-control" name="codice_perito" placeholder="Codice Perito">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-select">Livello Utente</label>
                                    <ValidationProvider rules="required" name="role" v-slot="{ errors }">
                                        <select v-model="new_user.role" class="form-control" name="role">
                                            <option value>Seleziona Livello Utente</option>
                                            <option value="ispettore">Ispettore</option>
                                            <option value="perito">Perito</option>
                                            <option value="direzione">Direzione</option>
                                        </select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-select">Grado Utente</label>
                                    <ValidationProvider rules="required" name="grade" v-slot="{ errors }">
                                        <select v-model="new_user.grade_id" class="form-control" name="grade">
                                            <option value>Seleziona Grado Utente</option>
                                            <option v-for="grade in grades" :key="grade.id" :value="grade.id">{{grade.name}}</option>
                                        </select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-ltf-email">Email</label>
                                    <ValidationProvider rules="required|email" name="email" v-slot="{ errors }">
                                        <input v-model="new_user.email" type="email" class="form-control" name="email" placeholder="Email">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button :disabled="invalid"  v-on:click.prevent="crea_nuovo" type="submit" class="btn btn-primary">Crea Nuovo</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AdminRepository from "../../services/apis/admin"
import UserRepository from "../../services/apis/user"

export default {
    data(){
        return {
            grades:[],
            new_user: {
                name:'',
                surname:'',
                codice_perito:'',
                email:'',
                grade_id:'',
                role:''
            }
        }
    },
    methods: {
        async crea_nuovo(){
            this.$store.dispatch('updateLoadingState', true)
            let _resp = await AdminRepository.add_user(this.new_user).catch(()=> {})

            this.$store.dispatch('updateLoadingState', false)
            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.new_user = {
                    name:'',
                    surname:'',
                    codice_perito:'',
                    email:'',
                    grade_id:'',
                    role:''
                }
                this.$refs.form.reset()
            }
        }
    },
    async created(){
        let _grades = await UserRepository.grades().catch(() => {})
        
        if(_grades){
            this.grades = _grades.data.data
        }
    },
}
</script>

<style scoped>
    .col-sm-8 {
        margin-bottom: 0px !important;
    }
</style>