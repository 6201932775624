<template>
    <!-- PERIZIA MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span v-if="step==1">SELEZIONA LA DATA DELLA PERIZIA</span>
                <span v-if="step==2">SELEZIONA LE DENUNCE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div v-if="step == 1">
                 <div v-if="denunce_number != null" class="row">
                    <div class="col-md-12">
                        <div v-if="denunce_number == 0" class="form-group">
                            <b>Non ci sono denunce</b> da liquidare antecedenti alla data selezionata.
                        </div>
                        <div v-if="denunce_number != 0" class="form-group">
                            Ci sono <b>{{denunce_number}} denunce</b> da liquidare antecedenti alla data selezionata.
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <DatePicker isId="dataPerizia" v-model="data_perizia" placeholder="Seleziona data" />
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="step == 2">
                <table v-if="denunce_found.length > 0" class="table table-bordered table-striped js-dataTable-full dataTable">
                    <thead>
                        <tr>
                            <th class="text-center">
                                <input type="checkbox" v-model="select_all" @change="selectAll">
                            </th>

                            <th>
                                DATA
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(denuncia, index) in denunce_found" :key="index">
                            <td class="font-size-sm">
                                <input :disabled="!denunceDisabled(denuncia)" v-model="denuncia.selected" type="checkbox">
                            </td>
                            <td class="font-size-sm">
                                {{data_specifica(denuncia.data)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                v-if="step==2"
                type="button"
                class="btn btn-primary"
                aria-label="Indietro"
                @click="goBack">
                <span>Indietro</span>
            </button>
            <button
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                :disabled="denunce_number == 0 || denunce_number == null || denunce_selected().length == 0"
                @click="set_data">
                <span>Crea Bollettino ({{denunce_selected().length}} Denunce)</span>
            </button>
            <button
                v-if="step==1 && denunce_number > 1"
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                :disabled="denunce_number == 0 || denunce_number == null"
                @click="seleziona_denunce_view">
                <span>Seleziona denunce</span>
            </button>
        </template>
        
    </Modal>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"
import DatePicker from "../DatePicker"
import Utility from "../../services/utility"
import CertificatoRepository from "../../services/apis/certificato"
export default {
    name:'ModalSelectDataPerizia',
    props:['isModalVisible', 'ordine_id', 'ispettore_id'],
    components: {
        Modal, DatePicker
    },
    data(){
        return {
            origin:[],
            partite_selezionate:[],
            data_perizia:'',
            partite_denunce_found:[],
            denunce_found:[],
            denunce_number:null,
            step:1,
            select_all:true,
        }
    },
    watch: {
        'denunce_found':{
            deep:true,
            handler(){
                let _selectedAll = true
                this.denunce_found.forEach((f) => {
                    if(!f.selected){
                        _selectedAll = false
                    }
                })
                this.select_all = _selectedAll
            }
        },
        'data_perizia'(value) {
            if(this.isModalVisible && value){
                this.search_denunce_data()
            }
        }
    },
    methods: {
        denunceDisabled(denuncia){
            var active = true

            for (let i = 0; i < this.origin.length; i++) {
                const partita = this.origin[i];
                const isEvasa = partita.denunce.find(_d => _d.data == denuncia.data && _d.n_danno == denuncia.n_danno && _d.evento_id == denuncia.evento_id )
                if(isEvasa && isEvasa.data_evasione){
                    active = false
                }
            }

            return active;
        },
        denunce_selected(){
            return this.denunce_found.filter(f => f.selected)
        },
        data_specifica(data){
            return data ? this.$moment(data).format('DD-MM-YYYY') : ''
        },
        selectAll(){
            this.denunce_found.forEach(element => {
                element.selezionata = this.select_all
            });
        },
        get_data(partite){
            
            this.origin = this._.cloneDeep(partite, true)

            var obj = []

            partite.forEach(partita => {
                var _partita = {
                    id: partita.id,
                    selezionata: partita.selezionata,
                    subPartite:[],
                    subPartiteSelezionate:[]
                }

                if(!_partita.selezionata){
                    _partita.selezionata = false
                }
                
                if( partita.sub_partite){
                    partita.sub_partite.forEach(sub => {
                        _partita.subPartite.push(sub.id)
                        if(sub.selezionata){
                            _partita.subPartiteSelezionate.push(sub.id)
                        }
                    })
                }

                obj.push(_partita)
            }); 
            
            this.partite_selezionate = obj
        },
        goBack(){
            this.step = 1
        },
        seleziona_denunce_view(){
            this.step = 2
        },
        async search_denunce_data(){
            const search_data = Utility.convertDataToDBFormat(this.data_perizia)
            let obj = {
                partite: this.partite_selezionate,
                dataPerizia: search_data,
                ordine_id:this.ordine_id,
                ispettore_id:this.ispettore_id
            }
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await CertificatoRepository.get_denunce_from_data_perizia(obj).catch(() => {})

            if(_resp) {
                this.denunce_number = _resp.data.data.count_denunce
                this.partite_denunce_found = _resp.data.data.partite                
                this.denunce_found = _resp.data.data.denunce_found                      
            }

            this.$store.dispatch('updateLoadingState', false)
        },
        set_data(){
            
            if(this.denunce_found.length == 0){
                return
            }

            this.partite_denunce_found = this.partite_denunce_found.map(p => {
                p.denunce_selected = p.denunce_selected.filter(d => this.denunce_found.find(dd => {
                    if(dd.data == d.data && dd.n_danno == d.n_danno && dd.evento_id == d.evento_id && dd.selected){
                        return d
                    }
                    return null
                }))
                return p
            })

            const partite = this.origin.map(partita => {
                const found = this.partite_denunce_found.find(_partita => _partita.id == partita.id)
                if(found){
                    const _p = this.partite_selezionate.find(_p => _p.id == found.id)
                    partita.selezionata = _p.selezionata
                
                    // partita.denunce_already_liquidate = found.denunce_already_liquidate
                    
                    if(found.denunce_selected){
                        partita.denunce = found.denunce_selected.map(_den => {
                            _den.selezionata = _p.selezionata
                            return _den
                        })
                    }
                    

                    if(partita.sub_partite){
                        partita.sub_partite.map(sub => {
                            const sub_found = found.sub_partite.find(_sub => _sub.id == sub.id)
                            if(sub_found){
                                const _s = _p.subPartiteSelezionate.find(_sel => _sel == sub_found.id)
                                if(_s){
                                    sub.selezionata = true
                                    partita.sub_selezionata = true
                                }else{
                                    sub.selezionata = false
                                }
                            }else{
                                sub.selezionata = false
                            }
                            return sub
                        })
                    }

                }else{
                    partita.denunce = []
                    partita.selezionata = false
                    if(partita.sub_partite){
                        partita.sub_partite.map(sub => {
                            sub.selezionata = false
                            return sub
                        })
                    }
                }
                return partita
            });

            this.$emit('closeModalCallback', partite, this.data_perizia)
            this.reset()
        },
        closeModal() {
            this.$emit('closeModalCallback')
            this.reset()
        },
        reset(){
            this.origin = []
            this.partite_selezionate = []
            this.data_perizia = ''
            this.partite_denunce_found = []
            this.denunce_found = []
            this.denunce_number = null
            this.step = 1
        }
    }
}
</script>