<template>
    <!-- PERIZIA MODAL -->
    <div>
        <Modal v-if="isModalVisible" @close="closeModal">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">
                    <span>LISTA DENUNCE</span>
                </h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="closeModal"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                
                <h2 v-if="denunce.length == 0">Nessuna denuncia presente</h2>

                <table v-if="denunce.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full">
                    <thead>
                        <tr>
                            <th class="d-none d-sm-table-cell">DATA</th>
                            <th class="d-none d-sm-table-cell">SCARICATA</th>
                            <th class="d-none d-sm-table-cell">PERVENUTA</th>
                            <th class="d-none d-sm-table-cell">EVENTO</th>
                            <th class="d-none d-sm-table-cell">N. DANNO</th>
                            <th class="d-none d-sm-table-cell">EVASO</th>
                            <th class="d-none d-sm-table-cell">DATA</th>
                            <th class="d-none d-sm-table-cell">AZIONI</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(denuncia, index) in denunce" :key="index">
                            <td class="font-size-sm">
                                {{denuncia.data | toEuropeData}}
                            </td>
                            <td class="font-size-sm">
                                <div v-if="!denuncia.scaricata" style="width: 10px;height: 10px;background: red;border-radius: 100%;margin:0 auto;"></div>
                                <div v-else style="width: 10px;height: 10px;background: green;border-radius: 100%;margin:0 auto;"></div>
                            </td>
                            <td class="font-size-sm">
                                <div v-if="denuncia.caricamento_ordine == 'manuale'" style="width: 10px;height: 10px;background: red;border-radius: 100%;margin:0 auto;"></div>
                                <div v-else style="width: 10px;height: 10px;background: green;border-radius: 100%;margin:0 auto;"></div>
                            </td>
                            <td class="font-size-sm">
                                {{denuncia.evento.nome}}
                            </td>
                            <td class="font-size-sm">
                                {{denuncia.n_danno}}
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <i v-if="denuncia.bollettini_status == 'da_approvare'" class="si si-check text-danger"></i> 
                                <i v-if="denuncia.bollettini_status == 'approvati' && denuncia.data_evasione" class="si si-check text-success"></i> 
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <span v-if="denuncia.data_evasione">{{denuncia.data_evasione | toEuropeData }}</span>
                            </td>
                            <td class="d-none d-sm-table-cell font-size-sm">
                                <div class="btn-group">
                                    <button v-if="denunciaPermittedAction(denuncia) && canPerito('full|edit')" @click="deleteDenuncia(denuncia)" type="button" class="btn btn-sm btn-danger" title="Elimina Denuncia">
                                        <i class="fa fa-fw fa-trash"></i>
                                    </button>
                                    <button v-if="denunciaPermittedAction(denuncia) && canPerito('full|edit')" @click="editDenuncia(denuncia)" type="button" class="btn btn-sm btn-light" title="Modifica Denuncia">
                                        <i class="fas fa-edit"></i>
                                    </button>
                                    <button v-if="readOnly || data_evasione || !denunciaPermittedAction(denuncia)" @click="editDenuncia(denuncia, true)" type="button" class="btn btn-sm btn-light" title="Visualizza Denuncia">
                                        <i class="fas fa-eye"></i>
                                    </button>
                                    <button v-if="denuncia.bollettini_ids.length > 0" @click="goToBollettino(denuncia.bollettini_data)" type="button" class="btn btn-sm btn-light" title="Vai al Bollettino">
                                        <i class="fas fa-money-bill-alt"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <!-- <div v-if="partite.length > 0" class="col-sm-12 col-md-12">
                    <paginate
                        v-model="search.page"
                        :page-count="data.last_page"
                        :click-handler="paginateClick"
                        :prev-text="'Prev'"
                        :next-text="'Next'"
                        :container-class="'pagination'"
                        :page-class="'page-item'"
                        :prev-class="'prev'"
                        :next-class="'next'">
                    </paginate>
                </div> -->


            </template>
            <template v-slot:footer>
                <button
                    v-if="!data_evasione && !readOnly && canPerito('full|edit')"
                    @click="nuovaDenuncia"
                    type="button"
                    class="btn btn-primary"
                    aria-label="Conferma">
                    <span>Nuova Denuncia</span>
                </button>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="closeModal"
                    aria-label="Close modal">
                    Chiudi
                </button>
                
            </template>
            
        </Modal>
        <ModalBollettini
            style="z-index: 9999;"
            :isModalVisible="isModalBollettiniVisible" 
            ref="modal_bollettini" 
            :certificato_id="ordine_id"
            :ispettore_id="ispettore_id"
            :id_direzione="is_direzione"
            @closeModalCallback="close_modal_bollettini"></ModalBollettini>
    </div>
    <!-- END PERIZIA MODAL -->
</template>

<script>
import Modal from "../Modal"
import ModalBollettini from "../Certificato/ModalBollettini"
import CertificatoRepository from "../../services/apis/certificato"
import Utility from "../../services/utility"

export default {
    name:'ModalDenunce',
    props:['isModalVisible', 'ordine_id', 'data_evasione', 'readOnly', 'ispettore_id', 'ispettorePermission', 'is_direzione'],
    components: {
        Modal, ModalBollettini
    },
    data(){
        return {
            denunce:[],
            partita:null,
            isModalBollettiniVisible:false
        }
    },
    methods: {
        canPerito(permissions){
            return Utility.canPerito(permissions, this.ispettorePermission)
        },
        closeModal(){
            this.$emit('closeModalCallback', null)
        },
        // bollettiniApprovati(bollettini_data){
        //     let da_approvare = [];
        //     let approvati = [];
        //     let bozze = [];
            
        //     bollettini_data.forEach(bollettino => {
        //         if(!bollettino.bozza && bollettino.approvato){
        //            approvati.push(bollettino.id)
        //         }else if(!bollettino.bozza && !bollettino.approvato){
        //             da_approvare.push(bollettino.id)
        //         }else{
        //             bozze.push(bollettino.id)
        //         }
        //     });

        //     if(bozze.length > 0){
        //         return 'bozza'
        //     }else if(da_approvare.length > 0){
        //         return 'da_approvare'
        //     }else if(approvati.length > 0){
        //         return 'approvati'
        //     }
            
        //     return false

        // },
        denunciaPermittedAction(denuncia){
            return denuncia.bollettini_ids.length == 0 && !this.data_evasione && !this.readOnly && denuncia.caricamento_ordine == 'manuale'
        },
        addDenunce(partita){
            this.partita = partita
            this.denunce = partita.denunce
        },
        nuovaDenuncia(){ 
            this.$emit('closeModalCallback', {partita_ids:[this.partita.id]})
        },
        async deleteDenuncia(denuncia){
            this.$store.dispatch('updateLoadingState', true)
            const obj = {
                denuncia_id:denuncia.id,
                ricolpita_id:denuncia.ricolpita_id,
                ispettore_id:this.ispettore_id
            }
            const _resp = await CertificatoRepository.delete_denuncia(obj).catch(() => {})
            this.$store.dispatch('updateLoadingState', false)

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.$emit('closeModalCallback', 'refresh')
            }
        },
        close_modal_bollettini(){
            this.isModalBollettiniVisible = false
        },
        editDenuncia(denuncia, only_view = false){
            this.$emit('closeModalCallback', {partita_ids:[this.partita.id], denuncia:denuncia, only_view:only_view})
        },
        goToBollettino(bollettini){
            if(bollettini.length === 1){
                if(this.is_direzione){
                    const edit_bollettino = this.$router.resolve({name:'modifica-bollettino', params: { certificato_id: this.ordine_id, bollettino_id:bollettini[0].id, ispettore_id:this.ispettore_id}})
                    window.open(edit_bollettino.href, '_blank');
                }else{
                    this.$router.push({name:'modifica-bollettino', params: { certificato_id: this.ordine_id, bollettino_id:bollettini[0].id, ispettore_id:this.ispettore_id, }})
                }
                
            }else{
                this.$refs.modal_bollettini.setData(bollettini)
                this.isModalBollettiniVisible = true
            }

        }
    }
}
</script>