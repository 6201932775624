<template>
    <div id="page-container" :class="[isSidebarMini && isLogged && !isBlankPage ? 'sidebar-mini' : '', isLogged && !isBlankPage ? 'sidebar-o sidebar-dark enable-page-overlay side-scroll page-header-fixed': '' ]" >
        
        <Sidebar v-if="isLogged && !isBlankPage" />

        <PageHeader v-if="isLogged && !isBlankPage" @callbackSidebarMini="callback_sidebar_mini" />

        <!-- Main Container -->
        <main id="main-container">

            <!-- Page Content -->
                
                <router-view/>
        
            <!-- END Page Content -->

        </main>
        <!-- END Main Container -->

        <Footer v-if="isLogged" :version="version" /> 
    </div>
</template>

<script>

import Footer from '../components/Footer'
import Sidebar from '../components/Sidebar'
import PageHeader from '../components/PageHeader'

export default {
    props:['isLogged', 'version'],
    data(){
        return {
            isSidebarMini:false,
            isBlankPage:false
        }
    },
    watch: {
        '$route' (to) {
            if(to.name == 'scegli-sezione' || to.name == 'completa-profilo'){
                this.isBlankPage = true
            }else{
                this.isBlankPage = false
            }
        }
    },
    components: {
        Sidebar, Footer, PageHeader
    },
    methods: {
        callback_sidebar_mini(){
            this.isSidebarMini = this.isSidebarMini ? false : true;
        }
    },
    mounted(){
    }
}
</script>