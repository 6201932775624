<template>
    <div>
        <div class="content bg-gray-lighter">
            <div class="row items-push">
                <div class="col-sm-8">
                    <h1 class="page-heading">
                        Tabelle Rimborso <small>Rimborsi previsti per grado.</small>
                    </h1>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-content block-content-full">
                    <table class="table table-bordered table-striped js-dataTable-full dataTable no-footer" id="DataTables_Table_3" role="grid" aria-describedby="DataTables_Table_3_info">
                        <thead>
                            <tr role="row">
                                <th class="text-center" rowspan="1" colspan="1" aria-label="" style="width: 412px;"></th>
                                <th class="text-center" tabindex="0" aria-controls="DataTables_Table_3" rowspan="1" colspan="1" aria-label="Onorario: activate to sort column ascending" style="width: 111px;">Onorario</th>
                                <th class="text-center" tabindex="0" aria-controls="DataTables_Table_3" rowspan="1" colspan="1" aria-label="Vitto: activate to sort column ascending" style="width: 67px;">Vitto</th>
                                <th class="text-center" tabindex="0" aria-controls="DataTables_Table_3" rowspan="1" colspan="1" aria-label="Alloggio: activate to sort column ascending" style="width: 106px;">Alloggio</th>
                                <th class="text-center" tabindex="0" aria-controls="DataTables_Table_3" rowspan="1" colspan="1" aria-label="Km: activate to sort column ascending" style="width: 42px;">Km</th>
                                <th class="text-center" rowspan="1" colspan="1" aria-label="" style="width: 37px;">#</th>
                            </tr>
                        </thead>
                        <tbody v-for="(values, propertyName) in tabelle_rimborso" :key="propertyName">
                            <tr class="group">
                                <td colspan="6">{{propertyName}}</td>
                            </tr>

                            <tr v-for="(tab, index) in values" :key="index" role="row" class="odd">
                                <td class="text-center">{{tab.grade.name}}</td>
                                <td class=" text-center">{{tab.onorario}}</td>
                                <td class=" text-center">{{tab.vitto}}</td>
                                <td class=" text-center">{{tab.alloggio}}</td>
                                <td class=" text-center">{{tab.km}}</td>
                                <td class=" text-center">
                                    <button class="btn btn-default btn-sm" @click="editTabella(tab)"><i class="fa fa-fw fa-pencil-alt"></i></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- EDIT TABELLA MODAL -->
        <Modal v-if="isModalVisible" @close="closeModal">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Modifica Riga</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="closeModal"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <ValidationObserver ref="form">
                    <form>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Onorario</label>
                                    <ValidationProvider rules="required|numeric" name="onorario" v-slot="{ errors }">
                                        <input v-model="selected_tabella.onorario" type="text" class="form-control" name="onorario" placeholder="Onorario">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Vitto</label>
                                    <ValidationProvider rules="required|numeric" name="vitto" v-slot="{ errors }">
                                        <input v-model="selected_tabella.vitto" type="text" class="form-control" name="vitto" placeholder="Vitto">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">Alloggio</label>
                                    <ValidationProvider rules="required|numeric" name="alloggio" v-slot="{ errors }">
                                        <input v-model="selected_tabella.alloggio" type="text" class="form-control" name="alloggio" placeholder="Alloggio">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group">
                                    <label for="example-text-input">KM</label>
                                    <ValidationProvider :rules="{ required: true, regex: /^[0-9]+(\.[0-9]{2})?$/ }" name="km" v-slot="{ errors }">
                                        <input v-model="selected_tabella.km" type="text" class="form-control" name="km" placeholder="Km">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                    </form>
                </ValidationObserver>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="closeModal"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="confirmEditModal"
                    aria-label="Modifica">
                    Modifica
                </button>
            </template>
            
        </Modal>
        <!-- END EDIT TABELLA MODAL -->
    </div>
</template>

<script>
import Modal from '../../components/Modal.vue';
import AdminRepository from "../../services/apis/admin"

export default {
    data(){
        return {
            tabelle_rimborso:null,
            isModalVisible:false,
            selected_tabella:null,
        }
    },
    components: {
        Modal
    },
    methods: {
        async get_tabelle_rimborso(){
            let _tabelle = await AdminRepository.tabelle_rimborso().catch(() => {})

            if(_tabelle){
                this.tabelle_rimborso = _tabelle.data.data                
            }
        },
        editTabella(tabella){
            this.selected_tabella = tabella
            this.isModalVisible = true
            // this.$watch(
            //     () => {
            //         return this.$refs.form.flags.invalid
            //     },
            //     (val) => {
            //         alert('App $watch this.$refs.form.flags.invalid: ' + val)
            //         }
            // )
            
        },
        closeModal() {
            this.isModalVisible = false;
            this.selected_tabella = null;
        },
        async confirmEditModal(){
            let success = await this.$refs.form.validate()

            if (!success) {
                this.$toasted.error('Tutti i campi devono essere compilati e corretti.').goAway(3000)
                return;
            }

            let obj = {
                rimborso_id:this.selected_tabella.id,
                grade_id:this.selected_tabella.grade_id,
                anno:this.selected_tabella.anno,
                alloggio: this.selected_tabella.alloggio,
                km: this.selected_tabella.km,
                onorario: this.selected_tabella.onorario,
                vitto: this.selected_tabella.vitto,
            }

            let _resp = await AdminRepository.edit_tabelle_rimborso(obj).catch(() => {})

            if(_resp){ 
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.get_tabelle_rimborso()
                this.closeModal()
            } 
        }
    },
    created(){
        this.get_tabelle_rimborso()   
    },
    mounted(){
        
    }
}
</script>

<style scoped>
    .col-sm-8 {
        margin-bottom: 0px !important;
    }
    .pagination, .dataTables_filter {
        justify-content: flex-end;
        display: flex;
    }
</style>