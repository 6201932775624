<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Nuovo Certificato <small>Creazione nuovo Certificato.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div class="block-header">
                    <h3 class="block-title">NUOVO CERTIFICATO</h3>
                </div>
                <div class="block-content block-content-full">
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                    <form>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Certificato</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]/, required:true}" name="certificato" v-slot="{ errors }">
                                       <input v-model="certificato.certificato" type="number" class="form-control" name="certificato" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-text-input">Agenzia</label>
                                    <ValidationProvider rules="required" name="agenzia" v-slot="{ errors }">
                                        <model-list-select
                                            id="search-select"
                                            :list="agenzie.found"
                                            option-value="id"
                                            :custom-text="agenzieListText"
                                            v-model="certificato.agenzia_id"
                                            placeholder="Seleziona"
                                            @searchchange="searchAgenzia"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-text-input">Consorzio di Difesa</label>
                                    <ValidationProvider rules="required" name="consorzio" v-slot="{ errors }">
                                        <model-list-select
                                            id="search-select"
                                            :list="consorzio.found"
                                            option-value="id"
                                            :custom-text="consorziListText"
                                            v-model="certificato.consorzio_id"
                                            placeholder="Seleziona"
                                            @searchchange="searchConsorzio"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Denominazione</label>
                                    <ValidationProvider rules="required" name="denominazione" v-slot="{ errors }">
                                       <input v-model="certificato.denominazione" type="text" class="form-control" name="denominazione" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-text-input">Prov</label>
                                    <ValidationProvider rules="required" name="agenzia" v-slot="{ errors }">
                                        <model-list-select
                                            id="search-select"
                                            :list="provincia.found"
                                            option-value="id"
                                            option-text="short"
                                            v-model="certificato.provincia_id"
                                            placeholder="Seleziona"
                                            @searchchange="searchProvincia"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-text-input">Comune</label>
                                    <ValidationProvider rules="required" name="comune" v-slot="{ errors }">
                                        <model-list-select
                                            :isDisabled="!certificato.provincia_id"
                                            id="search-select"
                                            :list="comune.found"
                                            option-value="id"
                                            option-text="nome"
                                            v-model="certificato.comune_id"
                                            placeholder="Seleziona"
                                            @searchchange="searchComune"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-text-input">Specie</label>
                                    <ValidationProvider rules="required" name="specie" v-slot="{ errors }">
                                        <model-list-select
                                            id="search-select"
                                            :list="specie.found"
                                            option-value="id"
                                            :custom-text="specieListText"
                                            v-model="certificato.specie_id"
                                            placeholder="Seleziona"
                                            @searchchange="searchSpecie"
                                            >
                                        </model-list-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-ltf-email">Data Carico</label>
                                    <ValidationProvider rules="required|is_valid_date" name="data_carico" v-slot="{ errors }">
                                        <DatePicker isId="data_carico" v-model="certificato.data_carico" />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-ltf-email">Data Notifica</label>
                                    <ValidationProvider rules="required|is_valid_date" name="data_notifica" v-slot="{ errors }">
                                        <DatePicker isId="data_notifica" v-model="certificato.data_notifica" />
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <div class="form-group">
                                    <label for="example-select">Franchigia</label>
                                    <ValidationProvider :rules="{regex: /^[0-9]/}" name="franchigia" v-slot="{ errors }">
                                       <input v-model="certificato.franchigia" type="number" class="form-control" name="franchigia" placeholder="">
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input v-model="certificato.soglia" type="checkbox" class="custom-control-input" id="soglia" name="soglia">
                                        <label class="custom-control-label" for="soglia">Soglia</label>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input v-model="certificato.provvisorio" type="checkbox" class="custom-control-input" id="provvisorio" name="provvisorio">
                                        <label class="custom-control-label" for="provvisorio">Provvisorio</label>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="form-group">
                                    <label for="example-text-input">Associa Certificati</label>
                                    <ValidationProvider rules="required" name="associa_certificati" v-slot="{ errors }">
                                        <multi-select
                                            id="search-select"
                                            :options="certificati.found"
                                            :selected-options="certificato.ordini"
                                            placeholder="Seleziona"
                                            @searchchange="searchCertificati"
                                             @select="onSelectCertificati"
                                            >
                                        </multi-select>
                                        <span class="validation-error">{{ errors[0] }}</span>
                                    </ValidationProvider>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="form-group">
                                    <div class="custom-control custom-checkbox mb-1">
                                        <input v-model="certificato.tipo_mer" type="checkbox" class="custom-control-input" id="agevolata" name="agevolata">
                                        <label class="custom-control-label" for="agevolata">Agevolata</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <label for="example-text-input">Note</label>
                                    <textarea v-model="certificato.note" name="note" id="" cols="30" rows="5" class="form-control"></textarea>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button :disabled="invalid"  v-on:click.prevent="new_certificato()" type="submit" class="btn btn-primary">Crea Nuovo Ordine</button>
                                </div>
                            </div>
                        </div>
                    </form>
                    </ValidationObserver>
                </div>
            </div>
        </div>
        <Modal v-if="modalEdit.visible" @close="close_modal_edit">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Sei sicuro?</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal_edit"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <p style="margin-bottom:0px">Vuoi visualizzare il certificato</p>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="close_modal_edit"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="edit_certificato"
                    aria-label="Procedi">
                    Si, procedi
                </button>
            </template>
        </Modal>
        
        <Modal v-if="modalRicolpita.visible" @close="close_modal_edit">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Sei sicuro?</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal_edit"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <p style="margin-bottom:0px">Vuoi creare una nuova ricolpita per il certificato?</p>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="close_modal_edit"
                    aria-label="Close modal">
                    Annulla
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="new_ricolpita"
                    aria-label="Procedi">
                    Si, procedi
                </button>
            </template>
        </Modal>
        
        <Modal v-if="modalTappabuchi.visible" @close="close_modal_edit">
            <template v-slot:header>
                <h2 style="margin-bottom:0px;">Sei sicuro?</h2>
                    <button
                        type="button"
                        class="btn-close"
                        @click="close_modal_edit"
                        aria-label="Close modal">
                    x
                </button>
            </template>
            <template v-slot:body>
                <p style="margin-bottom:0px">{{modalTappabuchi.message}}</p>
            </template>
            <template v-slot:footer>
                <button
                    type="button"
                    class="btn btn-light"
                    @click="new_certificato(modalTappabuchi.ordine_id)"
                    aria-label="No, continua progressivo">
                    No, continua progressivo
                </button>
                <button
                    type="button"
                    class="btn btn-primary"
                    @click="new_certificato(modalTappabuchi.order_number)"
                    aria-label="Procedi">
                    Si, procedi
                </button>
            </template>
        </Modal>
    </div>
</template>

<script>
import { ModelListSelect, MultiSelect } from 'vue-search-select'
import Modal from "../../components/Modal"
import DatePicker from "../../components/DatePicker"
import 'vue-search-select/dist/VueSearchSelect.css'
import UtilityRepository from "../../services/apis/utility"
import CertificatoRepository from "../../services/apis/certificato"

export default {
    components: {
        ModelListSelect, DatePicker, MultiSelect, Modal
    },
    data(){
        return {
            certificato: {
                ispettore_id:null,
                certificato:'',
                agenzia_id:'',
                denominazione:'',
                provincia_id:'',
                comune_id:'',
                data_carico:'',
                data_notifica:'',
                franchigia:10,
                soglia:true,
                provvisorio:false,
                ordini:[],
                tipo_mer:false,
                note:''
            },
            agenzie:{
                list:[],
                found:[]
            },
            consorzio:{
                list:[],
                found:[]
            },
            provincia:{
                list:[],
                found:[]
            },
            comune:{
                list:[],
                provincia_list:[],
                found:[]
            },
            specie:{
                list:[],
                found:[]
            },
            certificati:{
                list:[],
                found:[]
            },
            modalEdit:{
                visible:false,
                ordine_id:null
            },
            modalRicolpita:{
                visible:false,
                ordine_id:null
            },
            modalTappabuchi:{
                visible:false,
                ordine_id:null,
                message:'',
                order_number:null
            }
        }
    },
    watch: {
        'certificato.provincia_id' (item){
            this.certificato.comune_id = ''
            this.filterComuneByIdProvincia(item)
        }
    },
    methods: {
        close_modal_edit(){
            this.modalEdit = {
                visible:false,
                ordine_id:null
            }
            this.modalRicolpita = {
                visible:false,
                ordine_id:null
            }
            this.modalTappabuchi = {
                visible:false,
                ordine_id:null,
                message:'',
                order_number:null
            }
        },
        edit_certificato(){
            this.$router.push({ name: 'modifica-certificato', params: { certificato_id: this.modalEdit.ordine_id, ispettore_id:this.certificato.ispettore_id}})
        },
        agenzieListText(item){
            return `${item.codice_identificativo} - ${item.nome}`
        },
        consorziListText(item){
            return `${item.codice} - ${item.name}`
        },
        specieListText(item){
            return `${item.codice_identificativo} - ${item.etichetta}`
        },
        searchAgenzia(text){
            const _c = this.agenzie.list.filter((item) => {
                const _mix = `${item.codice_identificativo} ${item.nome}`
                return _mix.toLowerCase().includes(text.toLowerCase())
            })

            this.agenzie.found = _c
        },
        searchConsorzio(text){
            const _c = this.consorzio.list.filter((item) => {
                const _mix = `${item.codice} ${item.name}`
                return _mix.toLowerCase().includes(text.toLowerCase())
            })

            this.consorzio.found = _c
        },
        searchComune(text){
            const _c = this.comune.provincia_list.filter((item) => {
                return item.nome.toLowerCase().includes(text.toLowerCase())
            })

            this.comune.found = _c
        },
        searchProvincia(text){
            const _c = this.provincia.list.filter((item) => {
                return item.short.toLowerCase().includes(text.toLowerCase())
            })

            this.provincia.found = _c
        },
        searchSpecie(text){
            const _c = this.specie.list.filter((item) => {
                const _mix = `${item.codice_identificativo} ${item.etichetta}`
                return _mix.toLowerCase().includes(text.toLowerCase())
            })

            this.specie.found = _c
        },
        async searchCertificati(text){
            const _resp = await UtilityRepository.cerca_ordin({per_page:9999, certificato:text, ispettore_id:this.ispettore_id})
            
            if(_resp){
                this.certificati.found = _resp.data.data.data.map((certificato) => {
                    return { value: certificato.id, text: `${certificato.ordine_id}`, ordine_id:certificato.ordine_id }
                })
            }            
        },
        onSelectCertificati(certificati){
            this.certificato.ordini = certificati
        },
        filterComuneByIdProvincia(id){
            const _c = this.comune.list.filter((item) => {
                return item.provincia_id == id
            })

            this.comune.provincia_list = _c
            this.comune.found = _c
        },
        async get_variables(){
            this.$store.dispatch('updateLoadingState', true)
            // TODO: inserire ricerca certificati
            const _resp = await UtilityRepository.get_variables({agenzia:1, consorzio:1, provincia:1, comune:1, specie:1})
            
            if(_resp){
                this.agenzie.list = _resp.data.data.agenzia   
                this.consorzio.list = _resp.data.data.consorzio   
                this.provincia.list = _resp.data.data.provincia   
                this.comune.list = _resp.data.data.comune   
                this.specie.list = _resp.data.data.specie   
            }

            this.$store.dispatch('updateLoadingState', false)
        },
        async new_certificato(found_number){
       
            this.$store.dispatch('updateLoadingState', true)
            let obj = {}
            for (const key in this.certificato) {
                if (this.certificato.hasOwnProperty(key)) {
                    const element = this.certificato[key];
                    if(element != ''){
                        if(key == 'data_carico' || key == 'data_notifica'){
                            obj[key] = this.$moment(element, 'DD-MM-YYYY').format('YYYY-MM-DD')
                        }else{
                            obj[key] = element
                        }
                    }
                    
                }
            }

            if(found_number){
                obj.found_number = found_number
            }
            obj.ordini = this.certificato.ordini.map((ordine) => {
                return ordine.value
            })
            obj.tipo_mer = obj.tipo_mer ? "A" : null

            const _resp = await CertificatoRepository.new_certificato(obj).catch(() => {})
            this.$store.dispatch('updateLoadingState', false)
         
            if(_resp){
                const data = _resp.data
                
                if(data.status == 'check'){
                    if(data.data.status == 'ricolpita'){
                        this.modalRicolpita.visible = true
                        this.modalRicolpita.ordine_id = data.data.ordine_id
                        return
                    }
                    
                    if(data.data.status == 'modifica'){
                        this.modalEdit.visible = true
                        this.modalEdit.ordine_id = data.data.ordine_id
                        return
                    }
                    
                    if(!data.data.status){
                        this.modalTappabuchi.visible = true
                        this.modalTappabuchi.ordine_id = data.data.ordine_id
                        this.modalTappabuchi.message = data.message
                        this.modalTappabuchi.order_number = data.data.order_number
                        return
                    }
                } 
                
                this.$toasted.success(data.message).goAway(3000)
                this.$router.push({ name: 'modifica-certificato', params: { certificato_id: data.data.ordine_id, ispettore_id:this.certificato.ispettore_id }})
            }

        },
        async new_ricolpita(){
            
            if(!this.modalRicolpita.ordine_id){
                this.$toasted.error('Nessuna id certificato trovato, se il problema persiste contattare l\'amministratore.').goAway(3000)
                return;
            }

            this.$store.dispatch('updateLoadingState', true)

            const obj = {
                ordine_id: this.modalRicolpita.ordine_id,
                ispettore_id: this.certificato.ispettore_id
            }

            const _resp = await CertificatoRepository.new_ricolpita(obj).catch(() => {})
            this.$store.dispatch('updateLoadingState', false)

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.$router.push({ name: 'modifica-certificato', params: { certificato_id: obj.ordine_id, ispettore_id:this.certificato.ispettore_id }})
            }
        }
    },
    created(){
        this.certificato.ispettore_id = this.$route.params.ispettore_id
        this.get_variables()
    }
}
</script>

<style scope>
    .ui.dropdown .menu .item{
        width: 100%;
    }
</style>