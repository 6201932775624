<template>
    <div>
        <div class="content bg-gray-lighter ng-scope">
            <div class="row items-push">
                <div class="col-sm-10">
                    <h1 class="page-heading">
                        Lista Certificati <small>Lista dei certificati.</small>
                    </h1>
                </div>
            </div>
        </div>

        <div class="filter-container">
            <div class="filter-container-main">
                <div class="over_md_6">
                    <div class="n_order_search over_n_order">
                        <label>Ricerca libera</label>
                        <input class="search-input" v-model="search.search" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Ordine</label>
                        <input class="search-input" v-model="search.n_ordine" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Certificato</label>
                        <input class="search-input" v-model="search.certificato" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                    <div class="n_order_search over_n_order">
                        <label>Denominazione</label>
                        <input class="search-input" v-model="search.denominazione" @input="isTyping = true" placeholder="Cerca...">
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- Dynamic Table Full -->
            <div class="block">
                <div v-if="canPerito('full') && !search.manuale" class="block-header">
                    <button @click="goTo('nuovo-certificato')" class="btn btn-sm btn-primary" title="Nuovo Certificato">
                        NUOVO CERTIFICATO
                    </button>
                </div>
                <div class="block-content block-content-full">
                    <h3 v-if="certificati.length == 0">Nessun certificato presente</h3>
                    <div class="row" style="margin-bottom:30px;">
                        <div class="col-sm-12 col-md-6">
                            <div class="dataTables_length" id="DataTables_Table_0_length">
                                <label>
                                    <select v-model="search.per_page" @change="perPage" name="DataTables_Table_0_length" class="form-control form-control-sm">
                                        <option value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                    </select>
                                </label>
                            </div>
                        </div>
                    </div>
                    <table v-if="certificati.length > 0" class="table table-bordered table-striped table-vcenter js-dataTable-full dataTable" >
                        <thead>
                            <tr>
                                <th class="text-center" @click="orderchange('ordine_id','')" :class="`text-center ${column_cat !='ordine_id' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                    NUMERO ORDINE <br/>
                                </th>
                                <th class="text-center" >PERVENUTA <br/><span style="visibility: hidden">ghost</span></th> 
                                <th class="d-none d-sm-table-cell" @click="orderchange('certificato','')" :class="`text-center ${column_cat !='certificato' ? 'sorting' : `sorting_${order_column_cat}`} `" >
                                    NUMERO CERTIFICATO  <br/> 
                                </th>
                                <th class="d-none d-sm-table-cell" @click="orderchange('agenzia','','nome')" :class="`text-center ${column_cat !='agenzia' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                    AGENZIA <br/>
                                </th>
                                <th class="d-none d-sm-table-cell"  @click="orderchange('consorzio','','name')" :class="`text-center ${column_cat !='consorzio' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                    CONSORZIO <br/>
                                </th>
                                <th class="d-none d-sm-table-cell" @click="orderchange('comune','','nome')" :class="`text-center ${column_cat !='comune' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                    COMUNE <br/></th>
                                <th class="d-none d-sm-table-cell" @click="orderchange('denominazione','')" :class="`text-center ${column_cat !='denominazione' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                    DENOMINAZIONE CERT. <br/> </th>
                                <th class="d-none d-sm-table-cell" @click="orderchange('specie','','etichetta')" :class="`text-center ${column_cat !='specie' ? 'sorting' : `sorting_${order_column_cat}`} `">
                                    SPECIE <br/></th>
                                <th style="text-align:center;">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(certificato, index) in certificati" :key="index">
                                <td class="font-size-sm">
                                    {{certificato.ordine_id}}
                                </td>
                                <td class="font-size-sm">
                                    <i v-if="certificato.caricamento_ordine == 'automatico'" class="si si-check text-success"></i> 
                                    <i v-else class="si si-check text-danger"></i> 
                                </td>
                                <td class="font-size-sm">
                                    {{certificato.certificato}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    {{certificato.agenzia.codice_identificativo}} - {{certificato.agenzia.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{certificato.consorzio.codice}} - {{certificato.consorzio.name}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                     {{certificato.comune.nome}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.denominazione}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                      {{certificato.specie.etichetta}}
                                </td>
                                <td class="d-none d-sm-table-cell font-size-sm">
                                    <div class="btn-group">
                                        <button v-if="canPerito('edit|full')" @click="editCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Edit Certificato">
                                            <i class="fas fa-edit"></i>
                                        </button>
                                        <button v-if="canPerito('view')" @click="viewCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Edit Certificato">
                                            <i class="fas fa-eye"></i>
                                        </button>
                                        <button v-if="canPerito('owner')" @click="deleteCertificato(certificato)" type="button" class="btn btn-sm btn-light" title="Delete Certificato">
                                            <i class="fas fa-times"></i>
                                        </button>
                                        <button @click="openHailbox(certificato.ordine_id)" type="button" class="btn btn-sm btn-light" title="Open Hailbox">
                                            <i class="fas fa-cloud"></i>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div v-if="certificati.length > 1" class="col-sm-12 col-md-12">
                        <paginate
                            v-model="search.page"
                            :page-count="data.last_page"
                            :click-handler="paginateClick"
                            :prev-text="'Indietro'"
                            :next-text="'Avanti'"
                            :container-class="'pagination'"
                            :page-class="'page-item'"
                            :prev-class="'prev'"
                            :next-class="'next'">
                        </paginate>
                    </div>
                    
                </div>
            </div>
        </div>
        <ModalTrueFalse
            :isModalVisible="isModalDeleteVisible" 
            @closeModalCallback="callbackDelete"
            >
             <template v-slot:body-true>
                <h3>Vuoi eliminare il Certificato? Oltre al ceritifcato rimuoverai la cartella corrispondente nel servizio Hailbox Myara.</h3>
             </template>
        </ModalTrueFalse>

         <ModalInfoCert 
            :isModalVisible="isModalInfoVisible" 
            ref="modal_info_cert" 
            :certificato="selected_certificato"
            :ispettore_id="search.ispettore_id" 
            :certificato_id="selected_certificato ? selected_certificato.id : null"
            :readOnly="true"
            @closeModalCallback="close_info_cert_modal">
        </ModalInfoCert>
    </div>
</template>

<script>
import _ from 'lodash';
import Paginate from 'vuejs-paginate'
import ModalTrueFalse from "../../components/ModalTrueFalse"
import ModalInfoCert from "../../components/Certificato/ModalInfoCert"
import Utility from '../../services/utility'
import CertificatoRepository from "../../services/apis/certificato"
export default {
    components: {
        Paginate, ModalTrueFalse, ModalInfoCert
    },
    data(){
        return {
            certificati:[],
            data: {
                last_page:1
            },
            search: {
                manuale:false,
                ispettore_id:0,
                per_page: 10,
                page:1,
                search:'',
                n_ordine:'',
                certificato:'',
                denominazione:'',
            },

            isTyping: false,
            ispettorePermission:'',
            isModalDeleteVisible: false,
            selected_certificato:null,
            isModalInfoVisible:false,
            column_cat:'',
            order_column_cat:'',
        }
    },
    watch: {
        '$route.params.ispettore_id': function (id) {        
            this.search.ispettore_id = id
            this.get_certificati()
        },
        '$route.name': function (name) {            
            if(name == 'lista-certificati-ricolpita-manuale'){
                this.search.manuale = true
            }else{
                this.search.manuale = false
            }
            
            this.search.ispettore_id = this.$route.params.ispettore_id
            this.get_certificati()
        },
        'search.search': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.denominazione': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.n_ordine': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        'search.certificato': _.debounce(function() {
            this.isTyping = false;
            }, 1000),
        
            isTyping: function(value) {
            if (!value) {
                this.search.page = 1
                this.get_certificati()
            }
        }
    },
    methods: {
        /*orderchange(orderCat, order,extra){       
            if(order == 'asc'){
                //this.certificati = _.orderBy(this.certificati, [orderBy], ['asc'])
                if (extra) {
                    this.certificati.sort((a,b) => (a[orderCat][extra] > b[orderCat][extra]) ? 1 : ((b[orderCat][extra] > a[orderCat][extra]) ? -1 : 0))
                    console.log(this.certificati)
                }else{
                    this.certificati.sort((a,b) => (a[orderCat] > b[orderCat]) ? 1 : ((b[orderCat] > a[orderCat]) ? -1 : 0))
                }
                
            }else{
                if (extra) {

                    this.certificati.sort((a,b) => (a[orderCat][extra] < b[orderCat][extra]) ? 1 : ((b[orderCat][extra] < a[orderCat][extra]) ? -1 : 0))
                }else {
                    this.certificati.sort((a,b) => (a[orderCat] < b[orderCat]) ? 1 : ((b[orderCat] < a[orderCat]) ? -1 : 0))
                }
            }

        },*/
        orderchange(orderCat, order,extra){ 
            if (this.column_cat == orderCat){
                if (this.order_column_cat == 'asc'){
                    this.order_column_cat='desc';
                    order = 'desc'
                }else{
                    order = 'asc'
                    this.order_column_cat='asc';
                }
            }else{
                this.column_cat=orderCat;
                this.order_column_cat='asc';
                order = 'asc'
            }
            //let index = this.data.map(e => e.COMUNE).indexOf('Totale')
            //let totale = {...this.data[index]}
            //this.data.splice(index,1)
            //this.data.splice(this.data.map(e => e.COMUNE).indexOf('Totale'),1)   
             if(order == 'asc'){
                 //this.certificati = _.orderBy(this.certificati, [orderBy], ['asc'])
                 if (extra) {
                    this.certificati.sort((a,b) => (a[orderCat][extra] > b[orderCat][extra]) ? 1 : ((b[orderCat][extra] > a[orderCat][extra]) ? -1 : 0))
                 }else{
                    this.certificati.sort((a,b) => (a[orderCat] > b[orderCat]) ? 1 : ((b[orderCat] > a[orderCat]) ? -1 : 0))
                 }
                 
             }else{
                 if (extra) {
 
                    this.certificati.sort((a,b) => (a[orderCat][extra] < b[orderCat][extra]) ? 1 : ((b[orderCat][extra] < a[orderCat][extra]) ? -1 : 0))
                 }else {
                    this.certificati.sort((a,b) => (a[orderCat] < b[orderCat]) ? 1 : ((b[orderCat] < a[orderCat]) ? -1 : 0))
                 }
             }

             //this.data.push(totale)
 
         },
        async get_certificati(){
            this.$store.dispatch('updateLoadingState', true)
            const _resp = await CertificatoRepository.get_lista_certificati(this.search).catch(()=>{})

            if(_resp){
                this.certificati = _resp.data.data.data
                this.data = _resp.data.data
            }

            this.$store.dispatch('updateLoadingState', false)
        },
        canPerito(permissions){
            return Utility.canPerito(permissions, this.ispettorePermission)
        },
        perPage(){
            this.search.page = 1
            this.get_certificati()
        },
        paginateClick(pageNum){
            this.search.page = pageNum
            this.get_certificati()
        },
        goTo(name){
            this.$router.push({ name: name, params:{ispettore_id:this.search.ispettore_id} })
        },
        editCertificato(certificato){
            this.$router.push({ name: 'modifica-certificato', params:{certificato_id:certificato.id, ispettore_id:this.search.ispettore_id} })
        },
        deleteCertificato(certificato){
            this.selected_certificato = certificato
            this.isModalDeleteVisible = true
        },
        async callbackDelete(status){
            this.isModalDeleteVisible = false
            if(status){
                this.$store.dispatch('updateLoadingState', true);
                const _resp = await CertificatoRepository.delete_certificato(this.selected_certificato.id, this.search.ispettore_id).catch(() =>{})

                if(_resp){
                    this.$toasted.success(_resp.data.message).goAway(3000)
                    this.get_certificati()
                    this.selected_certificato = null
                }
                this.$store.dispatch('updateLoadingState', false);
            }
        },
        openHailbox(ordine_id){
            const user = this.$store.getters.user
           /* if(user.role == "ispettore"){
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/check-token-service/${ordine_id}?t=${user.service_token}`,'_blank');
            }else{
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/check-token-service/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}`,'_blank');
            }*/
            if(user.role == "ispettore"){
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&source=myara`,'_blank');
            }else{
                //window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${user.service_token}&i=${this.search.ispettore_id}&source=myara`,'_blank');
                window.open(`${process.env.VUE_APP_HAILBOX_URL}/#/auth/signin/${ordine_id}?t=${localStorage.getItem("myara1857_token")}&i=${this.search.ispettore_id}&source=myara`,'_blank');
            }
        },
        viewCertificato(cert){
            this.selected_certificato = cert
            this.isModalInfoVisible = true
        },
        close_info_cert_modal(){
            this.selected_certificato = null
            this.isModalInfoVisible = false
        }
    },
    mounted(){        
        this.search.ispettore_id = this.$route.params.ispettore_id
        this.ispettorePermission = Utility.getIspettorePeritoById(this.$route.params.ispettore_id)
        
        if(this.$route.name == 'lista-certificati-ricolpita-manuale'){
            this.search.manuale = true
        }
        

        this.get_certificati()
    }
}
</script>

<style>
.over_md_6 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: start;
    gap:10px;
}
/* creo una regola per il media query  di schermi tra 200 e 780px*/
@media screen and (max-width: 780px) {
    .over_md_6 {
        justify-content: center;
    }
    .over_n_order {
        flex-basis: 70% !important;
    }
    .form-control {
        font-size: 15px !important;
    }
} 
.over_n_order {
    flex-basis: 24%;
    display: flex;
    flex-direction: column;
    max-width: 100% !important;
    text-align: start !important;
    justify-content: flex-end;
}
.filter-container {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent;
    --jexcel-border-color: #000;
    --button-color: #298BA8;
    --active-color: #007aff;
    font-family: "Open Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #575757;
    text-align: left;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -webkit-box-direction: normal;
    box-sizing: border-box;
    width: 100%;
    margin: 0 auto;
    padding: 1.875rem 1.875rem 1px;
    overflow-x: visible;
    max-width: 1690px;
    
}
.filter-container-main {
    background-color: white;
    padding: 11px;
}
</style>