<template>
    <!-- DATA EVASIONE MODAL -->
    <Modal v-if="isModalVisible" @close="closeModal">
        <template v-slot:header>
            <h2 style="margin-bottom:0px;">
                <span>INSERISCI DATA EVASIONE</span>
            </h2>
                <button
                    type="button"
                    class="btn-close"
                    @click="closeModal"
                    aria-label="Close modal">
                x
            </button>
        </template>
        <template v-slot:body>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <DatePicker isId="data" v-model="data_evasione" />
                    </div>
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <button
                style="margin-right:.50rem"
                type="button"
                class="btn btn-light"
                @click="closeModal"
                aria-label="Close modal">
                Chiudi
            </button>
            <button
                type="button"
                class="btn btn-primary"
                aria-label="Nuova"
                :disabled="!can_save"
                @click="set_data">
                <span>Evadi</span>
            </button>
        </template>
        
    </Modal>
    <!-- END DATA EVASIONE MODAL -->
</template>

<script>
import Modal from "../Modal"
import DatePicker from "../../components/DatePicker"
import Utility from '../../services/utility'
import CertificatoRepository from "../../services/apis/certificato"

export default {
    name:'ModalDataEvasione',
    props:['isModalVisible'],
    components: {
        Modal, DatePicker
    },
    data(){
        return {
            data_evasione:null,
            can_save: true,
            ricolpita_id:null,
            ispettore_id:null
        }
    },
    watch: {
        'data_evasione': function(item) {
            if(item && item !== ""){
                this.can_save = true
            }
        }
    },
    methods: {
        get_data(ricolpita_id, ispettore_id){
            this.ricolpita_id = ricolpita_id
            this.ispettore_id = ispettore_id
        },
        async set_data(){
            const obj = {
                ispettore_id:this.ispettore_id,
                id: this.ricolpita_id,
                data:Utility.convertDataToDBFormat(this.data_evasione)
            }

            const _resp = await CertificatoRepository.evadi_certificato(obj).catch(() =>{})

            if(_resp){
                this.$toasted.success(_resp.data.message).goAway(3000)
                this.$router.push({name:'lista-certificati', params:{ispettore_id:this.ispettore_id}})
            }
        },
        closeModal() {
            this.data_evasione = this.$moment().format("DD-MM-YYYY")
            this.can_save = true
            this.$emit('closeModalCallback')
        },
    },
    mounted(){
        this.data_evasione = this.$moment().format("DD-MM-YYYY")
    }
}
</script>