import api from '../api.js'
import store from '../../store'
import utility from '../utility'

export default {
    get_lista_utenti(search){
        var _search = [];
        for (const key in search) {
            if (search.hasOwnProperty(key)) {
                const element = search[key];
                _search.push(`${key}=${element}`)
            }
        }

        _search = _search.join('&')

        return api.get(`/v2/user/get-lista-utenti?token=${store.getters.access_token}&${_search}`);
    },
    delete_user(id){        
        return api.delete(`/v2/admin/delete-user?token=${store.getters.access_token}&user_id=${id}`);
    },
    print_dettaglio_utente(id){        
        return api.get(`/v2/admin/print-dettaglio-utente?token=${store.getters.access_token}&user_id=${id}`, {
            responseType: 'blob'
        });
    },
    edit_user_grade(obj){        
        return api.patch(`/v2/admin/edit-user-grade?token=${store.getters.access_token}`, obj);
    },
    tabelle_rimborso(){        
        return api.get(`/v2/admin/tabelle-rimborso?token=${store.getters.access_token}`);
    },
    edit_tabelle_rimborso(obj){        
        return api.patch(`/v2/admin/edit-rimborso?token=${store.getters.access_token}`, obj);
    },
    add_user(obj){        
        return api.post(`/v2/admin/add-user?token=${store.getters.access_token}`, obj);
    },
    annulla_specifiche(obj){        
        return api.post(`/v2/admin/annulla-specifiche?token=${store.getters.access_token}`, obj);
    },
    lista_ordini_ispettore(search){    
        var _search = utility.appendParameters(search);     
        return api.get(`/v2/admin/lista-ordini-ispettore?token=${store.getters.access_token}&${_search}`);
    },
    aggiorna_certificato(obj) {
        return api.put(`/v2/admin/aggiorna-certificato?token=${store.getters.access_token}`, obj);
    },
    specifiche_paginating(search) {
        var _search = utility.appendParameters(search);   
        return api.get(`/v2/admin/specifiche-paginating?token=${store.getters.access_token}&${_search}`);
    },
    search_users(search) {
        var _search = utility.appendParameters(search);   
        return api.get(`/v2/admin/search-users?token=${store.getters.access_token}&${_search}`);
    },
    user_control(obj){        
        return api.post(`/v2/admin/user-control?token=${store.getters.access_token}`, obj);
    },
    recovery_password(obj){        
        return api.post(`/v2/admin/recovery-password?token=${store.getters.access_token}`, obj);
    },
};